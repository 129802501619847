import { BehaviorSubject ,  Observable } from 'rxjs';

import { SubscriberMasterService } from './subscriber-master.service';

export abstract class BehaviorSubjectService extends SubscriberMasterService {
  protected _observer: BehaviorSubject<any> = null;

  protected abstract getDefaultValue(): any;
  protected abstract isValidData(data: any): boolean;

  constructor() {
    super();
    this.initSubject();
  }

  public getCurrentValue(): any {
    return this._observer.getValue();
  }

  public getObserver(): Observable<any> {
    return this._observer.asObservable();
  }

  public reset() {
    try {
      if (this._observer == null) { return; }
      this._observer.complete();
    } catch (e) { console.warn(e); }

    this.initSubject();
  }

  protected setCurrentValue(data: any): boolean {
    try {
      if (!this.isValidData(data)) { return false; }
      this._observer.next(data);
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  private initSubject() {
    let value = null;
    try {
      value = this.getDefaultValue();
    } catch (e) { console.warn(e); }
    this._observer = new BehaviorSubject(value);
  }
}
