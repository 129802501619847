import { Position } from '../position';

export const BLOTTER_STATE_TYPES = {
  POSITION: 'POSITION'
};

export interface BlotterState {
  form: any;
  form_savings: any;
  position: Position;
}
