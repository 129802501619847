import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { environment } from '@env/environment';
import { BILLING_PLAN_ROUTE_PATH } from '@gorila-bot/billing-shell';
import { PremiumService, PremiumSubscribeComponent } from '@gorila-bot/premium';
import { ACCOUNT_SETTINGS_PATH } from '@gorila/core/router';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { equals, path } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { routesWithHeaderButtons } from 'src/app/core/router/app-routes.config';

import { HeaderButtons } from './model';

@Component({
  selector: 'gor-header-buttons',
  templateUrl: './header-buttons.component.html',
  styleUrls: ['./header-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderButtonsComponent implements OnInit, OnDestroy {
  private _routerEvent: NavigationEnd = null;
  @Input() set routerEvent(event: NavigationEnd) {
    if (!event) { return; }
    this._routerEvent = event;
    this.updateButtons();
  }

  private _displayB2BButtons = false;
  @Input() public set displayB2BButtons(display) {
    this._displayB2BButtons = display;
    this.updateButtons();
  }

  @Input() public brokerClientMode = false;

  public buttons = [];
  public readonly lockedModalComponent = PremiumSubscribeComponent;
  public readonly lockedModalOptions = {
    data: {
      mainText: '',
      mainHighlight: '',
      secondaryText: this.translate.instant('update your plan to unlock this feature'),
      secondaryHighlight: this.translate.instant('update your plan to unlock this feature'),
      buttonText: this.translate.instant('premium call to action'),
      lockedURLs: ['app', ACCOUNT_SETTINGS_PATH, BILLING_PLAN_ROUTE_PATH],
    }
  };

  private _showPremiumButton$ = new BehaviorSubject<boolean>(environment.features.tools.header.premium);
  public readonly showPremiumButton$ = this._showPremiumButton$.asObservable();

  private defaultB2BButtons = [HeaderButtons.INVITE_CLIENT];
  private defaultB2CButtons = [HeaderButtons.PREMIUM, HeaderButtons.BLOTTER];

  public constructor(
    private translate: TranslateService,
    private premiumService: PremiumService
  ) { }

  public ngOnInit() {
    this.checkPermissionFeatureBtnPremium();
  }

  private checkPermissionFeatureBtnPremium() {
    // detect user premium level: 0 = free, 1 = koko, 2 = kong
    this.premiumService.userTier$.pipe(
      map(tier => !this._showPremiumButton$.value ? false : (tier === 0)),
      untilDestroyed(this)
    ).subscribe((result: boolean) => this._showPremiumButton$.next(result));
  }

  private updateButtons() {
    const buttons = this.getButtonsFromRoute();
    if (equals(this.buttons, buttons)) {
      return ;
    }
    this.buttons = buttons;
  }

  private getButtonsFromRoute() {
    const url = this.getCurrentURL();
    let buttons = null;
    routesWithHeaderButtons.forEach(route => {
      if (url.indexOf(route.path) !== -1) {
        buttons = route.headerButtons;
      }
    });
    return this.getGuardedButtons(buttons);
  }

  private getCurrentURL() {
    return path(['url'], this._routerEvent) as string || '';
  }

  private getGuardedButtons(buttons: string[]) {
    return buttons
      ? buttons
      : this._displayB2BButtons
        ? this.defaultB2BButtons
        : this.defaultB2CButtons;
  }

  public ngOnDestroy() { }
}
