import { LogService } from '@gorila/shared/services/log.service';
import { findIndex, forEachObjIndexed, indexBy, insert, keys, length, or, prop, propEq, update } from 'ramda';
import { Action } from 'redux';

import { STATE_TYPES } from '../../store.model';
import { TypeReducer } from '../../store.utils';
import { ActionTransition, actionTransitionNameFor, ActionType, actionTypeNameFor } from '../actions.model';
import { PortfolioAPIAction, PortfolioAPIActions } from './portfolio.actions';
import { insertProductOnPortfolio, mapProductToPortfolio } from './portfolio.model';

export function portfolioReducer(state = {}, a: Action) {
  try {
    const action = a as PortfolioAPIAction;
    const propId: (obj: any) => any = prop<'id'>('id');

    if (!action.meta || action.meta.stateType !== STATE_TYPES.PORTFOLIO) {
      return state;
    }

    const caseAction = TypeReducer(actionTypeNameFor(action.meta.actionType), STATE_TYPES.PORTFOLIO);
    if (action.meta.actionType === ActionType.CLEAR) {
      return {
        ...state,
        items: {}
      };
    } else if (action.meta.actionType === ActionType.MAP) {
      const products = action.payload;
      let portfolios = or(state['items'], {});
      portfolios = keys(portfolios).map(portfolioId => portfolios[portfolioId]);

      const zeroedPortfolio = {};
      const mapProductsToPortfolio = (product) => {
        let portfolioIndex = findIndex(propEq('id', product.fundId))(portfolios);
        let portfolio = portfolios[portfolioIndex];

        if (!portfolio) {
          portfolios = insert(length(portfolios), mapProductToPortfolio(product), portfolios);
          portfolioIndex = findIndex(propEq('id', product.fundId))(portfolios);
        } else {
          if (!zeroedPortfolio[portfolioIndex]) {
            portfolio['products'] = [];
          }
          portfolio = insertProductOnPortfolio(product, portfolio);
          portfolios = update(portfolioIndex, portfolio, portfolios);
        }
        zeroedPortfolio[portfolioIndex] = true;
      };
      forEachObjIndexed(mapProductsToPortfolio, products);
      return {
        ...state,
        items: indexBy<any>(propId, portfolios)
      };
    }
    switch (action.type) {
      case caseAction(actionTransitionNameFor(ActionTransition.STARTED)):
        return {
          ...state,
          items: {},
          loading: true,
          error: null,
        };
      case caseAction(actionTransitionNameFor(ActionTransition.SUCCEEDED)):
        return {
          ...state,
          items: indexBy(propId, action.payload),
          loading: false,
          error: null,
        };
      case caseAction(actionTransitionNameFor(ActionTransition.FAILED)):
        return {
          ...state,
          items: {},
          loading: false,
          error: action.error,
        };
      case caseAction(PortfolioAPIActions.CLEAR):
        return {
          ...state,
          items: {},
          loading: false,
          error: action.error,
        };
    }
    if (action.meta.stateType === STATE_TYPES.PORTFOLIO) {
      LogService.reduxLog(`@@@ NOT FILTERED STATE ${STATE_TYPES.PORTFOLIO}`, action);
    }
  } catch (e) { console.warn(e); }
  return state;
}
