import { IPosition, mapProfit2PositionIndexed, IPositionServer } from '@gorila-bot/gorila-front-models';
import { pickBy, indexBy, mapObjIndexed, path, values, merge } from 'ramda';

import { PositionActions, PositionActionTypes } from '../actions/position.actions';
import { initialState, PositionState } from '../position.state';

export const getPositionServerKey = (position: IPositionServer) =>
  `${position.ProductType}-${position.SecurityName}-${position.FundId}-${position.BrokerName}`;
const getOwnerPositionKey = (position: IPosition) =>
  `${position.productType}-${position.security.name}-${position['ownerFundId']}-${position.brokerName}`;
const getPositionKey = (position: IPosition) =>
  `${position.productType}-${position.security.name}-${position.fundId}-${position.brokerName}`;
// gets fundId from externalUserId - 'FUNDid:<fundId>'
const getTradeKey = (trade) =>
  `${trade.security.productType}-${trade.security.symbol}-${trade.externalUserId.split(':')[1]}-${trade.brokerName}`;
const indexByPositionKey = (array: IPosition[]): { [key: string]: IPosition } => indexBy(getPositionKey, array);
export function reducer(state = initialState, action: PositionActions): PositionState {
  try {
    switch (action.type) {
      case PositionActionTypes.PositionLoad: {
        return {
          ...state,
          loading: true
        };
      }

      case PositionActionTypes._PositionProfitabilityLoad: {
        return {
          ...state,
          positions: mapProfit2PositionIndexed(state.positions, action.payload)
        };
      }

      case PositionActionTypes._PositionLoad: {
        return {
          ...state,
          loading: false,
          positions: indexByPositionKey(action.payload.positions),
          positionsServer: action.payload.positionsServer
        };
      }

      case PositionActionTypes._MapTrades: {
        const trades = values(action.payload.trades);
        const positions: { [key: string]: IPosition } = mapObjIndexed(
          (position: IPosition) => ({
            ...position,
            trades: trades
              .filter(
                (trade) => getOwnerPositionKey(position) === getTradeKey(trade)
              )
              .map((trade) => trade.ids[0]) as string[],
          }),
          action.payload.positions
        );
        return {
          ...state,
          positions: { ...positions },
        };
      }

      case PositionActionTypes._UpdatePosition: {
        const { positionServer, position } = action.payload;

        if (state.positionsServer) {
          let indexPosSFounded = -1;
          const positionServerKey = getPositionServerKey(positionServer);
          indexPosSFounded = state.positionsServer.findIndex((p: IPositionServer) => getPositionServerKey(p) === positionServerKey);

          if (indexPosSFounded >= 0) {
            state.positionsServer[indexPosSFounded] = merge(state.positionsServer[indexPosSFounded], positionServer);
          } else {
            state.positionsServer.push(positionServer);
          }
        } else {
          state.positionsServer = [positionServer];
        }

        const key = getPositionKey(position);
        const positions = {
          ...state.positions,
          [key]: {
            ...position,
            trades: !!path(['positions', key, 'trades'], state)
              ? state.positions[key].trades
              : [],
          },
        };

        return !!path(['id'], position)
          ? {
            ...state,
            positions,
          }
          : state;
      }

      case PositionActionTypes.RemovePosition: {
        return {
          ...state,
          // TODO (GRODD-1268) - change the filter logic to avoid removing two different position with the same
          // security symbol and broker
          //  it will be done after changes in mobile as it is necessary to change data received from socket
          positionsServer: state.positionsServer.filter(position => !position.Key.includes(action.payload)),
          positions: { ...pickBy((position: IPosition) => position.id !== action.payload, state.positions) },
        };
      }

      default:
        return state;
    }
  } catch (e) {
    console.warn(e);
    return state;
  }
}
