import { Injectable } from '@angular/core';
import { BookerService } from '@gorila/trade';
import { tradeFromServer } from '@gorila-bot/gorila-front-models';
import { is, isEmpty, isNil } from 'ramda';
import { Action } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, startWith } from 'rxjs/operators';

import { AppState, STATE_TYPES } from '../../store.model';
import { TradeAddAPIAction, TradeLoadAPIAction, TradeAPIActions, TradeRemoveAPIAction } from './trade.actions';
import { PositionAPIAction, PositionAPIActions } from '../position/position.actions';
import { ActionType, ActionTransition } from '../actions.model';
import { BaseEpic } from '../../base/epic.base';

@Injectable()
export class TradeAPIEpics extends BaseEpic {
  constructor(
    private service: BookerService,
    private tradeActions: TradeAPIActions,
    private positionActions: PositionAPIActions
  ) {
    super();
  }

  public getStateType(): string {
    return STATE_TYPES.TRADE;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return combineEpics<Action, any, AppState, any>(
      this.createAddTradeEpic(filterIfNotLogged),
      this.createAddSuccessProductEpic(filterIfNotLogged),
      this.createLoadTradeEpic(filterIfNotLogged),
      this.createLoadSuccessTradeEpic(filterIfNotLogged),
      this.createRemoveTradeEpic(filterIfNotLogged),
      this.createRemoveSuccessTradeEpic(filterIfNotLogged)
    );
  }

  private createAddTradeEpic(ifNotLogged): Epic<TradeAddAPIAction, TradeAddAPIAction, AppState> {
    const actionType = this.getActionName(ActionType.ADD, ActionTransition.DATA);
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => from([action.payload]).pipe(
      filter(data => !(isEmpty(data) || isNil(data))),
      map(data => is(Array, data) ? data.map(tradeFromServer) : tradeFromServer(data)),
      map(data => this.tradeActions.addSucceeded(this.getStateType(), data)),
      catchError(error => from([this.tradeActions.failed(ActionType.ADD, this.getStateType(), error)])),
      startWith(this.tradeActions.started(ActionType.ADD, this.getStateType()))
    ), false);
  }

  private createAddSuccessProductEpic(ifNotLogged): Epic<PositionAPIAction, PositionAPIAction, AppState> {
    const actionType = this.getActionName(ActionType.ADD, ActionTransition.SUCCEEDED);
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) =>
      from([this.positionActions.map(STATE_TYPES.POSITION, [action.payload])]), false);
  }

  private createLoadTradeEpic(ifNotLogged): Epic<TradeLoadAPIAction, TradeLoadAPIAction, AppState> {
    const actionType = this.getActionName(ActionType.LOAD, ActionTransition.DATA);
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => this.service.getTrades().pipe(
      map(data => data.Accounts
        .map(account => account.Trades)
        .filter(trades => !!trades)
        .reduce((prev, curr) => prev.concat(curr))
      ),
      map(trades => trades.map(tradeFromServer)),
      map(trades => this.tradeActions.loadSucceeded(this.getStateType(), trades)),
      catchError(error => from([this.tradeActions.failed(ActionType.LOAD, this.getStateType(), error)])),
      startWith(this.tradeActions.started(ActionType.LOAD, this.getStateType()))
    ));
  }

  private createLoadSuccessTradeEpic(ifNotLogged): Epic<PositionAPIAction, PositionAPIAction, AppState> {
    const actionType = this.getActionName(ActionType.LOAD, ActionTransition.SUCCEEDED);
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) =>
      from([this.positionActions.map(STATE_TYPES.POSITION, action.payload)]), false);
  }

  private createRemoveTradeEpic(ifNotLogged): Epic<TradeRemoveAPIAction, TradeRemoveAPIAction, AppState> {
    const actionType = TradeAPIActions.actionType.remove.data;
    const getTradeFromStoreById = store => id => store.value['trade'].items[id];
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => from([action.payload]).pipe(
      filter(data => !(isEmpty(data) || isNil(data))),
      map(data =>
        is(Array, data)
        ? data.map(getTradeFromStoreById(store)).filter(d => d).map(d => d.id)
        : getTradeFromStoreById(store)(data).id),
      map(data => this.tradeActions.removeSucceeded(this.getStateType(), data)),
      catchError(error => from([this.tradeActions.failed(ActionType.REMOVE, this.getStateType(), error)])),
      startWith(this.tradeActions.started(ActionType.REMOVE, this.getStateType()))
    ), false);
  }

  private createRemoveSuccessTradeEpic(ifNotLogged): Epic<PositionAPIAction, PositionAPIAction, AppState> {
    const actionType = TradeAPIActions.actionType.remove.success;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) =>
      from([this.positionActions.unmap(STATE_TYPES.POSITION, action.payload)]), false);
  }
}
