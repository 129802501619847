<div class="icon-container">
  <img class="icon-close" src="/assets/img/close.svg" (click)="closeDialog()"/>
</div>
<header mat-dialog-title>
  <h2>{{data?.titleText}}</h2>
</header>
<section mat-dialog-content>
  <p *ngIf="!!data?.bodyText" class="mat-dialog-content-question">{{data?.bodyText}}</p>
  <div *ngIf="!!data?.bodyHtml" class="confirmation-body" [innerHtml]="data.bodyHtml | keepHtml"></div>
</section>
<footer mat-dialog-actions>
  <ng-container *ngIf="data?.yesText">
    <button class="btn-action btn-action--primary" mat-button [mat-dialog-close]="true">{{data?.yesText}}</button>
  </ng-container>
  <ng-container *ngIf="data?.noText">
    <button class="btn-action btn-action--secondary" mat-button [mat-dialog-close]="false">{{data?.noText}}</button>
  </ng-container>
</footer>

