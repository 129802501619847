import { Injectable } from '@angular/core';
import { ClientType } from '@gorila-bot/gorila-front-models';
import { selectFeatureByPath, UserDataKey } from '@gorila-bot/gorila-store';
import { Store } from '@ngrx/store';
import { equals } from 'ramda';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class UserService {

  constructor(
    private store$: Store<any>,
  ) { }

  /**
   * Return a Client Type Observable
   */
  public getUserType(): Observable<ClientType> {
    return combineLatest([
      this.store$.pipe(map(state => (selectFeatureByPath(state, [UserDataKey, 'loggedUserFundId'])))),
      this.store$.pipe(map(state => (selectFeatureByPath(state, [UserDataKey, 'currentFundId'])))),
      this.store$.pipe(map(state => (selectFeatureByPath(state, [UserDataKey, 'clientType']))))
    ])
      .pipe(
        map(([loggedFundId, currentFundId, clientType]) => clientType !== ClientType.B2B
          ? ClientType.B2C
          : currentFundId === loggedFundId
            ? ClientType.B2B : ClientType.B2B2C
        ),
        distinctUntilChanged((a, b) => equals(a, b))
      );
  }

}
