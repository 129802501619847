import { Inject, InjectionToken, LOCALE_ID, Optional, Pipe, PipeTransform, SkipSelf } from '@angular/core';
import { BigCurrencyPipe, SymbolDisplay } from '@gorilainvest/ui-toolkit/pipes';

export type PriceByUnitMap = { STOCK: string, TREASURY: string };

export const PRICE_BY_UNIT_MAP = new InjectionToken<PriceByUnitMap>('PRICE_BY_UNIT_MAP');
export const defaultPriceByUnitMap: PriceByUnitMap = { STOCK: 'ação', TREASURY: 'título' };

@Pipe({
  name: 'priceByUnit',
  pure: true
})
export class PriceByUnitPipe implements PipeTransform {
  private currencyPipe: BigCurrencyPipe;

  constructor(
    @Inject(LOCALE_ID) @SkipSelf() _locale: string,
  ) {
    this.currencyPipe = new BigCurrencyPipe(_locale);
  }

  public transform(
    value: any,
    productType = '',
    emptyValue = '',
    currencyCode = 'USD',
    symbolDisplay: SymbolDisplay = 'symbol',
    digits: string = null
  ): any {
    if (!value && emptyValue) { return emptyValue; }
    return this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
  }

  private normalizeProductType(productType: string) {
    if (!productType) { return productType; }

    if (productType.indexOf('TREASURY') !== -1) { return 'TREASURY'; }
    if (productType.indexOf('CORPORATE_BONDS') !== -1) { return 'CORPORATE_BONDS'; }
    if (productType.indexOf('STOCK') !== -1) { return 'STOCK'; }

    return '';
  }
}
