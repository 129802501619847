import { AppInfoAvailableSizes, getSize } from './models/app-info.model';

export const Key = 'app-info';
export interface AppInfoState {
  screen: {
    mobile: boolean;
    size: AppInfoAvailableSizes,
    isPortrait: boolean
  };
}
export const initialState: AppInfoState = {
  screen: {
    mobile: window.innerWidth < 1200,
    size: getSize(window.innerWidth),
    isPortrait: true
  }
};
