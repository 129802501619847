import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { environment } from '@env/environment';
import { Observable, from } from 'rxjs';
import { DashboardRoutePath } from '../app-shell/dashboard/metadata';

@Injectable()

export class FeatureGuard implements CanActivate {

    constructor( protected router: Router ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.router.navigate(['app', DashboardRoutePath]);
        return from([false]);
    }
}
