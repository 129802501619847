import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import Colors from '@gorilainvest/styles/js/Colors';

export interface DonutChartDataPoint {
  id: string;
  color: string | am4core.Color;
  title: string;
  value: number;
  percentValue: number;
  filterKey?: string;
}

export interface DonutChartDataFields<T extends DonutChartDataPoint> {
  value: keyof T;
  category: keyof T;
}

@Injectable()
export class DonutChartService<T extends DonutChartDataPoint> implements OnInit, OnDestroy {
  public readonly transitionDuration = 800;

  // TODO change strategy to add/remove intead changing all array
  private _breadcrumb: T[];
  public get breadcrumb(): T[] {
    return this._breadcrumb;
  }
  public set breadcrumb(breadcrumb: T[]) {
    this._breadcrumb = breadcrumb;
  }

  private chart: am4charts.PieChart;
  private hitListenerFn: Function;

  public constructor() {
  }

  public ngOnInit() {
    am4core.useTheme(am4themes_animated);
  }

  public ngOnDestroy() {
    am4core.unuseTheme(am4themes_animated);
  }

  public create(htmlElement: string | HTMLElement, dataFields: DonutChartDataFields<T> = {
    value: 'percentValue',
    category: 'title'
  }) {
    if (this.chart) {
      console.warn('[DonutChartService create] Only one chart can be created at once.');
      return;
    }

    const chartConf = {
      radius: am4core.percent(85),
      innerRadius: am4core.percent(66),
      hiddenState: {
        properties: {
          innerRadius: am4core.percent(50),
          radius: am4core.percent(50)
        }
      },
      series: [{
        type: 'PieSeries',
        labelsEnabled: false,
        slices: {
          template: {
            propertyFields: {
              fill: 'color'
            },
            stroke: am4core.color(Colors.White),
            strokeWidth: 5,
            strokeOpacity: 1
          }
        },
        dataFields
      }]
    };

    this.chart = am4core.createFromConfig(chartConf, htmlElement, am4charts.PieChart) as am4charts.PieChart;
    this.setSeries0Properties1();
    this.configureActiveState();
    this.configureHitListener();
    this.configureHoverState();
    this.setSeries0Properties2();
  }

  public destroy() {
    if (!this.chart) {
      console.warn('[DonutChartService destroy] Undefined chart can not be destroyed!');
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  public hide() {
    this.chart.hide();
  }

  public show() {
    this.chart.show();
  }

  public setHitListener(fn: Function) {
    this.hitListenerFn = fn;
  }

  public updateData(data: T[] = []) {
    this.hide();

    this.chart.data = data.map(d => ({ ...d, color: am4core.color(d.color) }));
    this.chart.invalidateData();

    this.show();
  }

  private configureActiveState() {
    const as = this.chart.series.getIndex(0).slices.template.states.getKey('active');
    as.properties.scale = 1;
    as.properties.x = 0;
    as.properties.y = 0;
    as.properties.zIndex = 9000;
  }

  private configureHitListener() {
    const series = this.chart.series.getIndex(0);
    series.slices.template.events.on('hit', (e) => {
      series.slices.template.events.disableType('hit');

      try {
        e.target.isActive = false;
        e.target.properties.scale = 1;
        e.target.properties.opacity = 0.5;
        e.target.tooltip.hide();

        const dataContext = e.target.dataItem.dataContext as T;
        /**
         * blocking click on slice if id is others option or last level tree
         */
        if (dataContext.id === '_OTHERS_' || this.breadcrumb.length === 3) {
          return;
        }

        if (dataContext.filterKey === 'securitieName') {
          this.updateSelectedSlice(dataContext.id);
        } else {
          new am4core.Animation(e.target, [{
            property: 'arc',
            to: 360
          },
          { property: 'x', to: 0 },
          { property: 'y', to: 0 }], 500, am4core.ease.cubicInOut).start();
          new am4core.Animation(this.chart, [{
            property: 'opacity',
            to: 0
          }], 1200, am4core.ease.cubicIn).delay(1200).start();
        }

        if (this.hitListenerFn) {
          this.hitListenerFn(e);
        }
      } catch (err) {
        console.warn(err);
      }

      series.slices.template.events.enableType('hit');
    });
  }

  private configureHoverState() {
    const hs = this.chart.series.getIndex(0).slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    hs.properties.zIndex = 900;
    hs.properties.fillOpacity = 1;
  }

  /**
   * TODO check if setSeries0Properties1 and setSeries0Properties2 could be merged into one.
   */
  private setSeries0Properties1() {
    this.chart.defaultState.transitionDuration = this.transitionDuration;
    this.chart.hiddenState.transitionDuration = this.transitionDuration;

    const serie = this.chart.series.getIndex(0);
    serie.defaultState.transitionDuration = this.transitionDuration;
    serie.hiddenState.transitionDuration = this.transitionDuration;

    serie.hiddenState.properties.innerRadius = am4core.percent(100);
    serie.labels.template.disabled = true;
  }

  private setSeries0Properties2() {
    this.chart.series.getIndex(0).slices.template.propertyFields.isActive = 'pulled';
    this.chart.series.getIndex(0).slices.template.alwaysShowTooltip = false;
    this.chart.series.getIndex(0).slices.template.tooltipText = '';
  }

  private updateSelectedSlice(id: string) {
    this.chart.series.getIndex(0).slices.values.forEach((slice) => {
      if (slice.dataItem.dataContext['id'] === id) {
        return;
      }
      slice.opacity = 0.2;
      slice.hoverable = false;
    });
  }
}
