import { Pipe, PipeTransform } from '@angular/core';
import { invalidCNPJ } from '@gorila-bot/gorila-front-utils/dist/cpf-cnpj-validators';

@Pipe({
  name: 'cnpj'
})
export class CnpjPipe implements PipeTransform {

  public transform(cnpj: string): string {
    if (!cnpj || invalidCNPJ(cnpj)) {
      return '';
    }
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

}
