import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Event, EventType } from '@gorila/core/utils';

export enum PnlMode {
  NetNominal = 'netNominal',
  NetPercentual = 'net',
  Nominal = 'nominal',
  Percentual = 'percentual',
  PercentualWithGrossup = 'grossup',
}

export class VariationComponentEvent implements EventType {
  public static ChangePnlMode = 'ChangePnlMode';
}

@Component({
  selector: 'variation-button',
  templateUrl: './variation-button.component.html',
  styleUrls: ['./variation-button.component.scss']
})
export class VariationButtonComponent implements OnInit {

  @Input() public activePnlMode: PnlMode;
  @Input() public showDefaultVariation = true;
  @Input() public showTooltip = false;
  @Output() public changes = new EventEmitter<Event<VariationComponentEvent>>();

  public buttons = new Array();
  public labelStyle = {
    marginRight: '6px',
    marginBottom: '0',
    color: '#555b5b',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'left'
  };
  public buttonStyle = {};
  public activeButtonStyle = {
    backgroundColor: '#555b5b'
  };

  constructor() {
    const k = Object.keys(PnlMode).filter((pnlType: PnlMode) =>
      !['PercentualWithGrossup', 'NetNominal', 'NetPercentual'].includes(pnlType));
    for (let i = 0; i < k.length; i++) {
      const j = PnlMode[k[i]];
      this.buttons.push({ id: j, title: (j === PnlMode.Nominal) ? 'R$' : '%' });
    }
  }

  public ngOnInit() { }

  public emit(event: any) {
    this.changes.emit({ type: VariationComponentEvent.ChangePnlMode, payload: event });
  }
}
