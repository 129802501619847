import { Injectable } from '@angular/core';
import {
  FundIdType,
  IPositionIndexed,
  IPositionServer,
  IProfitabilityList,
  IProfitabilityServer,
  positionFromServer,
  profitabilityFromServer
} from '@gorila-bot/gorila-front-models';
import { ProfitabilityRequestService } from '@gorila-bot/profitability-store';
import { AppConstants } from '@gorila/constants';
import { selectTradeList } from '@gorila/root-store/trade/src/trade.selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { utc } from 'moment-business-days';
import { isEmpty, keys, path, values, zipObj } from 'ramda';
import { concat, from } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import * as PositionActions from '../actions/position.actions';
import { selectPositions } from '../position.selectors';
import { PositionRequestService } from '../services/position-request.service';
import { AllocationPercentagesActions } from '@gorila/root-store/allocation-percentages';
import { FilterSelectors } from '@gorila-bot/filter-container/store';
import { UpdatePosition } from '../actions/position.actions';
import { environment } from '@env/environment';

@Injectable()
export class PositionEffects {

  @Effect()
  public loadPosition$ = this.actions$.pipe(
    ofType(PositionActions.PositionActionTypes.PositionLoad),
    map((action: PositionActions.PositionLoad) => [action, this.getAllPositionsProfitability(action.payload)]),
    mergeMap(([action, requestData]: [PositionActions.PositionLoad, any]) => {
      const maxDate = path<string>(['payload', 'maxDate'], action);
      return concat([
        this.requestService.doRequest({ maxDate }).pipe(
          map((data: any) => [path(['0', 'Positions'], data), path(['0', 'FundId'], data) || '']),
          filter((data: any) => !!data && !!data[0]),
          map(([posList, fundId]) => {
            return {
              server: posList,
              converted: posList.map(this.positionFromServer(fundId))
            };
          }),
          switchMap(data => [
            new PositionActions._PositionLoad(data.server, data.converted),
            new AllocationPercentagesActions.AllocationPercentagesLoad({ refDate: action['payload'].maxDate })
          ]),
          catchError(err => from([new PositionActions._PositionLoad([], [], !!err)]))
        ),
        this.profitabilityService.doMultiRequests(values(requestData)).pipe(

          map((profit: any[][]) => profit.map(this.transformProfitFromServer)),
          map((profit) => zipObj(keys<string>(requestData), profit)),
          switchMap((profit) => [new PositionActions._PositionProfitabilityLoad(profit)]),
          catchError(err => from([new PositionActions._PositionProfitabilityLoad({}, !!err)]))
        )
      ]);
    }),
    concatMap(d => d)
  );

  @Effect() public _loadPosition$ = this.actions$.pipe(
    ofType(PositionActions.PositionActionTypes._PositionLoad),
    withLatestFrom(this.store$),
    map(([_, store]) => values(selectPositions(store) as IPositionIndexed)),
    switchMap(() => [
      new PositionActions.MapTrades()
    ])
  );

  @Effect() public mapTrades$ = this.actions$.pipe(
    ofType(PositionActions.PositionActionTypes.MapTrades),
    withLatestFrom(this.store$),
    map(([_, store]) => [selectPositions(store), selectTradeList(store)]),
    filter(([positions, trades]) => !!trades && !!positions && !isEmpty(trades) && !isEmpty(positions)),
    map(([positions, trades]) => new PositionActions._MapTrades({ trades, positions }))
  );

  @Effect() public updatePosition$ = this.actions$.pipe(
    ofType(PositionActions.PositionActionTypes.UpdatePosition),
    switchMap((action: UpdatePosition) => [
      new PositionActions._UpdatePosition(action.payload, this.positionFromServer(action.fundId)(action.payload))
    ])
  );

  @Effect() public _updatePosition$ = this.actions$.pipe(
    ofType(PositionActions.PositionActionTypes._UpdatePosition),
    debounceTime(environment.features.portfolio.updateDebounceTime),
    withLatestFrom(this.store$),
    map(([_, store]) => [
      values(selectPositions(store) as IPositionIndexed),
      FilterSelectors.getOneFilter('datePicker', false)(store) || {}
    ]),
    filter(data => !isEmpty(data[0])),
    map(([, selectedDate]: [IPositionIndexed, { startDate: string, endDate: string }]) =>
      [selectedDate, this.getAllPositionsProfitability({ minDate: selectedDate.startDate, maxDate: selectedDate.endDate })]),
    mergeMap(([selectedDate, requestData]: [{ startDate: string, endDate: string }, any]) => concat([
      from([
        new PositionActions.MapTrades(),
        new AllocationPercentagesActions.AllocationPercentagesLoad({
          refDate: selectedDate ? selectedDate.endDate : this.getToday()
        })
      ]),
      this.profitabilityService.doMultiRequests(values(requestData)).pipe(
        map((profit: any[][]) => profit.map(this.transformProfitFromServer)),
        map((profit) => zipObj(keys<string>(requestData), profit)),
        switchMap((profit) => [new PositionActions._PositionProfitabilityLoad(profit)]),
        catchError(err => from([new PositionActions._PositionProfitabilityLoad({}, !!err)]))
      )
    ])),
    concatMap(d => d)
  );

  @Effect() public positionRemove$ = this.actions$.pipe(
    ofType(PositionActions.PositionActionTypes.RemovePosition),
    debounceTime(environment.features.portfolio.updateDebounceTime),
    withLatestFrom(this.store$),
    map(([_, store]) => FilterSelectors.getOneFilter('datePicker', false)(store)),
    map((selectedDate) => new AllocationPercentagesActions.AllocationPercentagesLoad({
      refDate: selectedDate ? selectedDate.endDate : this.getToday()
    }))
  );

  public getToday = () => utc().format(AppConstants.Format.Date.American);

  public getAllPositionsProfitability = (selectedPeriod: { minDate?: string, maxDate?: string }) => {
    const today = this.getToday();
    const request = { groupByBroker: true, groupBySecurity: true, periodType: 'DAILY', maxDate: today, minDate: today };
    return {
      daily: request,
      period: {
        ...request,
        reduceProfitMaxPoints: 1,
        ...(!!selectedPeriod && {
          minDate: selectedPeriod.minDate || selectedPeriod.maxDate,
          maxDate: selectedPeriod.maxDate
        })
      },
    };
  }

  public transformProfitFromServer = (p: any[][]) => {
    const profitData: IProfitabilityServer[] = path(['0', 'Profit'], p) || [];
    const fundId: FundIdType = path(['0', 'FundId'], p) || '0';

    return profitData.map((pp) => profitabilityFromServer({ ...pp, fundId }));
  }

  private positionFromServer = (fatherFundId: FundIdType) => (pos: IPositionServer) => ({
    ...positionFromServer(pos, fatherFundId.toString()),
    fundId: fatherFundId.toString(),
    ownerFundId: pos.FundId.toString()
  })

  constructor(
    private actions$: Actions,
    private requestService: PositionRequestService,
    private profitabilityService: ProfitabilityRequestService,
    private store$: Store<any>,
  ) { }
}
