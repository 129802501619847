import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedPipesModule } from '@gorila/shared/pipes/module';
import { LoadingModule } from '@gorilainvest/ui-toolkit/loading';
import { UiSimpleModalComponent } from './ui-simple-modal/ui-simple-modal.component';
import { UiLoadingModalComponent } from './ui-loading-modal/ui-loading-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    SharedPipesModule,
    LoadingModule
  ],
  declarations: [ UiSimpleModalComponent, UiLoadingModalComponent ],
  exports: [ UiSimpleModalComponent, UiLoadingModalComponent ],
  entryComponents: [ UiSimpleModalComponent, UiLoadingModalComponent ]
})
export class UiModalModule { }
