import { brokerFilterBuilder, FilterCategory, sliderFilterBuilder, walletFilterBuilder } from '@gorila-bot/filter-bar';
import { IPosition } from '@gorila-bot/gorila-front-models';
import { breakString } from '@gorila-bot/gorila-front-utils';
import { PositionDescriptionPipe } from '@gorila/pages/wallet/pipes';
import { TranslateService } from '@ngx-translate/core';
import { path } from 'ramda';

export const includeEmail = (it, current = []) =>  (current.indexOf(it.email) === -1) ? [...current, it.email] : current;
export const walletCategoryFactory = (pdpipe: PositionDescriptionPipe, translate: TranslateService): FilterCategory => {

  const keyFn = (it: IPosition, levelsCount?: number) => {
    if (!path(['assetClass', 'id'], it)) {
      return null;
    }
    if ((levelsCount || 0) > 0) {
      return it.assetClass.id;
    }
    return pdpipe.transform(it, true);
  };

  const groupFn = (it: IPosition, levelsCount?: number) => {
    if (!path(['assetClass', 'id'], it)) {
      return {title: '', key: '', cssclass: ''};
    }
    return (levelsCount)
    ? {
      title: it.security.type,
      key: it.security.type,
      cssclass: ['asset-bg', it.assetClass.id.toLowerCase().replace(' ', '_')]
    }
    : {
      title: it.assetClass.id,
      key: it.assetClass.id,
      cssclass: ['asset-bg', it.assetClass.id.toLowerCase().replace(' ', '_')]
    };
  };

  const searchFn = (item, txt) => translate.instant(item.category.title).toLowerCase().startsWith(txt)
    || translate.instant(item.category.category.title).toLowerCase().startsWith(txt)
    || item.title.toLowerCase().startsWith(txt);

  return walletFilterBuilder(
    keyFn,
    includeEmail,
    groupFn,
    searchFn
  );
};

export const brokerFactory = () => brokerFilterBuilder((it) => !!it.brokerName ? breakString(it.brokerName) : null, includeEmail);

export const sliderFactory = (
  key: string,
  title: string,
  format: any = { pipe: 'BigCurrencyPipe' }
) =>  sliderFilterBuilder(
  (it) => it[key],
  includeEmail,
  title,
  format,
  key
);
