import { JwtHelperService } from '@auth0/angular-jwt';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from '@env/environment';
import { ApiService } from '@gorila-bot/gorila-base';
import { IAuth0AudienceResponse } from '@gorila-bot/gorila-front-models';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';
import { AppConstants } from '@gorila/constants';
import { LoginStatusEnum, LoginStatusService } from '@gorila/core';
import {
  AuthGorilaPROSignupRoutePath,
  AuthNoLockEmailRoutePath,
  AuthNoLockForgotPasswordRoutePath,
  AuthNoLockResetPasswordRoutePath,
  AuthNoLockRoutePath,
  AuthNoLockSignupRoutePath,
  LoginRoutePath,
  AuthConnectRoutePath,
} from '@gorila/core/router';
import { SERVICE_BASE_URL } from '@gorila-bot/gorila-base2';
import { LoginService } from '@gorila/shared';
import { WhiteLabelService } from '@gorila-bot/white-label';
import { SignupError, SignupService } from '@gorila-bot/signup';
import { Store } from '@ngrx/store';
import * as auth0 from 'auth0-js';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { forEachObjIndexed } from 'ramda';
import { BehaviorSubject, Observer } from 'rxjs';
import { take, tap, map } from 'rxjs/operators';

import { TOKEN_KEY, URL_STATE_KEY, URL_OAUTH_TOKEN_KEY } from '../models/storage-keys.model';
import { Auth0LoginResponse, AuthFormEvent, AuthUser } from '../models/authnolock.model';
import { AuthService } from '../services/auth.service';
import { getAppMetadata } from '@gorila/utils';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IHeader } from '@gorila-bot/gorila-api-service';
import { TranslateService } from '@ngx-translate/core';
import { SignupUser, USER_TYPE } from '../services/auth.model';
import { SignupUserType } from '../models/auth.model';

@Component({
  selector: 'authnolock',
  templateUrl: './authnolock.component.html',
  styleUrls: ['./authnolock.component.scss']
})
export class AuthNoLockComponent implements OnInit, OnDestroy {
  public readonly pathConnect = AuthConnectRoutePath;
  public readonly pathEmail = AuthNoLockEmailRoutePath;
  public readonly pathLogin = AuthNoLockRoutePath;
  public readonly pathSignup = AuthNoLockSignupRoutePath;
  public readonly pathSignupPRO = AuthGorilaPROSignupRoutePath;
  public readonly pathForgotPassword = AuthNoLockForgotPasswordRoutePath;
  public readonly pathResetPassword = AuthNoLockResetPasswordRoutePath;
  public readonly eventType = AuthFormEvent;
  public readonly homePage = AppConstants.HomePage;

  public hideSocial = false;
  public isConnect = false;
  public isWhiteLabel = false;
  public isLogin = true;
  public formHint: string;
  public formHintForgotPassword: string;
  public formText: string;
  public formSubtitle: string;
  public formMessageEmail: string;
  public formMessageFacebook: string;
  public formMessageSupport: string;
  public formHintCreateAccount: string;
  public formResetCode: string;
  public formTextParams = { broker: '' };
  public message = { text: '', error: false };
  public waitingResponse$ = new BehaviorSubject(false);
  public pageRoute: string;
  public userType: SignupUserType;

  public auth0 = new auth0.WebAuth({
    domain: environment.Auth0.domain,
    clientID: environment.Auth0.clientID,
    redirectUri: `${environment.Auth0.redirectUrl}entrando`,
    responseType: environment.Auth0.responseType,
    scope: environment.Auth0.scope
  });

  private urlState: string;

  constructor(
    private httpClient: HttpClient,
    private loginStatusService: LoginStatusService,
    private loginService: LoginService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    protected store: Store<any>,
    private apiService: ApiService,
    private wlService: WhiteLabelService,
    private signupService: SignupService,
    private jwtHelper: JwtHelperService,
    private titleService: Title,
    private translate: TranslateService,
    @Inject(SERVICE_BASE_URL) private serviceBaseUrl: string
  ) {
    router.events.pipe(untilDestroyed(this)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.onNavigationEnd(event.urlAfterRedirects.split('#')[0]);
      }
    });
    route.queryParams.subscribe( queryParams => {
      this.formMessageEmail = decodeURI(queryParams.username ? queryParams.username : '');
      this.formResetCode = decodeURI(queryParams.code ? queryParams.code : '');
    });
  }
  public ngOnInit() {
    this.urlState = localStorage.getItem(URL_STATE_KEY);
    this.isWhiteLabel = this.wlService.isWhiteLabel();
    this.hideSocial =
      this.isWhiteLabel ||
      !!this.urlState ||
      this.pageRoute === this.pathSignupPRO;

    this.loginStatusService
      .getObserver()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const data = this.loginStatusService.getCurrentValue();
        if (
          [LoginStatusEnum.ConnectingWithServices].indexOf(data['code']) !== -1
        ) {
          this.redirectToLoadingRoute();
          return;
        }
      });
    this.clearOauthKey();
  }

  public onNavigationEnd(url: string) {
    const index = url.indexOf('?');
    const lastSepIndex = url.lastIndexOf('/');
    this.pageRoute =
      index === -1
        ? url.substring(lastSepIndex + 1)
        : url.substring(lastSepIndex + 1, index);
    this.userType = this.pageRoute === this.pathSignupPRO ? 'advisor' : 'client';
    this.isConnect = false;
    switch (this.pageRoute) {
      case AuthConnectRoutePath:
        const TOKEN = localStorage.getItem(URL_OAUTH_TOKEN_KEY);
        const TOKEN_INFO = this.jwtHelper.decodeToken(TOKEN);
        const appMetadata = getAppMetadata(TOKEN_INFO);
        this.isConnect = true;
        this.formHint = 'To complete the integration we need you to access your account or create a new account on Gorila.';
        this.formTextParams.broker = appMetadata.providerName;
        return (this.formText = 'Continue your integration between Gorilla and @broker.');
      case AuthGorilaPROSignupRoutePath:
        this.isLogin = false;
        this.formHintCreateAccount = 'Enter your details below.';
        this.titleService.setTitle(this.translate.instant('Create your GorilaPRO account'));
        return (this.formText = 'Create your GorilaPRO account');
      case AuthNoLockSignupRoutePath:
        this.isLogin = false;
        this.formHintCreateAccount = 'Enter your details below or accept the terms to register with Facebook.';
        this.titleService.setTitle(this.translate.instant('Create your Gorila account'));
        return (this.formText = 'Create your Gorila account');
      case AuthNoLockResetPasswordRoutePath:
        this.isLogin = false;
        this.titleService.setTitle(this.translate.instant('Change Password'));
        this.formHintForgotPassword = 'Enter new password';
        return (this.formText = 'Change Password');
      case AuthNoLockForgotPasswordRoutePath:
      case AuthNoLockEmailRoutePath:
        this.isLogin = false;
        this.formMessageFacebook = 'Did you register via Facebook?';
        this.formMessageSupport = 'Contact our support team';
        this.formHintForgotPassword = 'It will only be possible to reset your password if you have registered manually.';
        this.formSubtitle = 'I forgot my password';
        return (this.formText = 'Gorila, help me!');
      default:
        this.isLogin = true;
        this.formHint = 'Input your data below';
        return (this.formText = 'Access your Gorila account');
    }
  }

  public clearOauthKey(): void {
    // URL_OAUTH_TOKEN_KEY is blocking login
    if (!!this.pageRoute && this.pageRoute !== this.pathConnect) {
      localStorage.removeItem(URL_OAUTH_TOKEN_KEY);
    }
  }

  public ngOnDestroy() {}

  public loginNoLock(user: AuthUser): void {
    this.removeIdToken();
    const url = `${this.serviceBaseUrl}user/login/password`;
    const postData = {
      username: user.email,
      password: user.password
    };

    this.waitingResponse$.next(true);

    const urlOld = `https://${environment.Auth0.domain}/oauth/ro`;
    const postDataOld = {
      username: user.email,
      password: user.password,
      client_id: environment.Auth0.clientID,
      connection: environment.Auth0.database,
      scope: environment.Auth0.scope
    };

    this.httpClient.post(url, postData)
      .subscribe(
        (loginData: {idToken: string, refreshToken: string, access_token: string}) => {
          window.location.href = `/#id_token=${loginData.idToken}`;
        },
        (error: {status: number}) => {
          switch (error.status) {
            case 400:
              this.message = { text: 'LOGIN.ERROR.INVALID_EMAIL_PASSWORD', error: true };
              break;
            case 401:
              this.message = { text: 'LOGIN.ERROR.INCORRECT_EMAIL_PASSWORD', error: true };
              break;
            case 404:
              this.message = { text: 'LOGIN.ERROR.NON_EXISTING_EMAIL', error: true };
              break;
            case 409:
              this.message = { text: 'LOGIN.ERROR.SOCIAL_LOGIN', error: true };
              break;
            case 404:
              this.message = { text: 'LOGIN.ERROR.INCORRECT_ATTEMPTS', error: true };
              break;
            default:
              this.message = { text: 'LOGIN.ERROR.INVALID_EMAIL_PASSWORD', error: true };
          }
          this.waitingResponse$.next(false);
        }
      );
  }

  public signUpUser(user: AuthUser) {
    this.removeIdToken();
    const isGorilaProUser = this.pageRoute === AuthGorilaPROSignupRoutePath;
    const userType: USER_TYPE = isGorilaProUser ? USER_TYPE.ADVISOR : USER_TYPE.INDIVIDUAL;
    this.waitingResponse$.next(true);
    this.authService
      .signUpUser(user, userType)
      .subscribe(() => {
        AnalyticsService.setEvent('sign_up', {});
        this.loginNoLock(user);
        this.waitingResponse$.next(false);
      }, (error: SignupError) => {
        this.message = error;
        this.waitingResponse$.next(false);
      });
  }

  public socialLogin = (socialType: AuthFormEvent) => {
    const socialParams = `connection=${socialType}&redirectUrl=${window.location.protocol}//${window.location.host}`;
    window.location.href = `${this.serviceBaseUrl}user/login/social?${socialParams}`;
  }

  public resetPassword(user: AuthUser): void {
    this.waitingResponse$.next(true);
    this.apiService
      .doPatch(`${this.serviceBaseUrl}user/password`, {username: this.formMessageEmail, code: this.formResetCode, password: user.password})
      .pipe(take(1), tap( () => this.waitingResponse$.next(false) ))
      .subscribe( () => this.message = { text: 'LOGIN.RESET_PASSWORD.SUCCESS', error: false }, (error) => {
        this.waitingResponse$.next(false);
        switch (error.status) {
          case 400:
            this.message = { text: 'LOGIN.RESET_PASSWORD.ERROR_EMPTY', error: true };
            break;
          case 401:
            this.message = { text: 'LOGIN.RESET_PASSWORD.ERROR_CODE', error: true };
            break;
          default:
            this.message = { text: 'LOGIN.RESET_PASSWORD.ERROR_GENERIC', error: true };
        }
      });
  }

  public forgotPassword(user: AuthUser): void {
    this.apiService
      .doPatch(`${this.serviceBaseUrl}user/password/reset`, {username: user.email})
      .pipe(take(1))
      .subscribe( () => this.message = { text: 'LOGIN.RECOVER_PASSWORD.FOUND_EMAIL', error: false }, (error) => {
        switch (error.status) {
          case 400:
            this.message = { text: 'LOGIN.RECOVER_PASSWORD.INVALID_EMAIL', error: true };
            break;
          case 404:
            this.message = { text: 'LOGIN.RECOVER_PASSWORD.NON_EXISTING_EMAIL', error: true };
            break;
          case 409:
            this.message = { text: 'LOGIN.RECOVER_PASSWORD.LOGIN_SOCIAL', error: true };
            break;
          case 200:
            this.message = { text: 'LOGIN.RECOVER_PASSWORD.FOUND_EMAIL', error: false };
            break;
        }
      });
  }

  private runAutentication(
    token
  ) {
    const authResult = {};
    try {
      this.waitingResponse$.next(false);

      const isLogin =
        [AuthNoLockSignupRoutePath, AuthGorilaPROSignupRoutePath].indexOf(
          this.pageRoute
        ) === -1;
      this.authService.authenticationCompleted(
        this.jwtHelper.decodeToken(token),
        token,
        isLogin
      );
      localStorage.removeItem('__user_email__');
      this.redirectToLoadingRoute();
      return true;
    } catch (e) {
      console.warn('error runAutentication', e);
    }
  }

  public toggleLoginSignup() {
    if (this.pageRoute !== AuthNoLockSignupRoutePath) {
      this.router.navigate([AuthNoLockSignupRoutePath], {
        relativeTo: this.route.parent
      });
      return;
    }
    this.router.navigate([AuthNoLockRoutePath], {
      relativeTo: this.route.parent
    });
  }

  public continueOnAuth0(data?: { [key: string]: string }) {
    let url = `https://${environment.Auth0.domain}/continue?state=${this.urlState}`;
    if (data) {
      forEachObjIndexed((value, key) => (url += `&${key}=${value}`), data);
    }

    localStorage.removeItem(URL_STATE_KEY);
    window.location.href = url;
  }

  public onFormEvent(type: AuthFormEvent, data?: AuthUser) {
    this.message = { text: null, error: false };
    switch (type) {
      case AuthFormEvent.LOGIN:
        return this.loginNoLock(data);
      case AuthFormEvent.SIGNUP:
        return this.signUpUser(data);
      case AuthFormEvent.FACEBOOK:
          return this.socialLogin(AuthFormEvent.FACEBOOK);
      case AuthFormEvent.APPLE:
          return this.socialLogin(AuthFormEvent.APPLE);
      case AuthFormEvent.RESET_PASSWORD:
          return this.resetPassword(data);
      case AuthFormEvent.EMAIL:
        return this.pageRoute === AuthNoLockForgotPasswordRoutePath
          ? this.forgotPassword(data)
          : this.continueOnAuth0({ email: data.email });
      case AuthFormEvent.NAV_LOGIN:
        return this.router.navigate([AuthNoLockRoutePath], {
          relativeTo: this.route.parent
        });
      case AuthFormEvent.NAV_RESET:
        return this.router.navigate([AuthNoLockForgotPasswordRoutePath], {
          relativeTo: this.route.parent
        });
      case AuthFormEvent.NAV_SIGNUP:
        return this.router.navigate([AuthNoLockSignupRoutePath], {
          relativeTo: this.route.parent
        });
    }
  }

  private redirectToLoadingRoute = () => {
    this.router.navigate([LoginRoutePath], { relativeTo: this.route.parent });
  }

  private removeIdToken = () => localStorage.removeItem('id_token');
}
