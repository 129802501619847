import { IAuth0AudienceResponse } from '@gorila-bot/gorila-front-models';

export interface Auth0LoginResponse {
  access_token: string;
  id_token: string;
  token_type: string;
}

// authentication using auth0's token (not using API) have some more information, due to the different login flux
export interface AuthResult extends IAuth0AudienceResponse {
  iss?: string;
  aud?: string;
  iat?: number;
  exp?: number;
}

export interface AuthFormMessage {
  text: string;
  error: boolean;
}

export enum AuthFormEvent {
  EMAIL,
  LOGIN,
  SIGNUP,
  RESET_PASSWORD,
  NAV_LOGIN,
  NAV_RESET,
  NAV_SIGNUP,
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE'
}

export interface AuthFormEventData {
  type: AuthFormEvent;
  data: AuthUser;
}

export interface AuthUser {
  email: string;
  password?: string;
  name?: string;
}

export interface AuthSignUp {
  _id: string;
  email_verified: boolean;
  email: string;
  app_metadata: {
    fundId: string;
  };
  user_metadata: {
    name: string;
  };
}
