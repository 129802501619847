import { Injectable } from '@angular/core';
import { ApiService } from '@gorila-bot/gorila-base';
import { IPositionRequestModel } from '@gorila-bot/gorila-front-models';
import { AppConstants } from '@gorila/constants';
import { FundIdType } from '@gorila-bot/user-data-store';
import { RequestServiceBase } from '@gorila/store';
import { Store } from '@ngrx/store';
import * as moment from 'moment-business-days';

@Injectable()
export class PositionRequestService extends RequestServiceBase<IPositionRequestModel> {
  public readonly dateFormats = [
    AppConstants.Format.Date.American,
    AppConstants.Format.Date.Brazilian
  ];

  constructor(
    protected apiService: ApiService,
    protected store: Store<any>
  ) {
    super(apiService, store);
  }

  public getParams(params: IPositionRequestModel): IPositionRequestModel {
    if (!!params.maxDate) {
      params.maxDate = this.getDate(params.maxDate);
    }
    params.includeNoPrice = true;
    return params;
  }

  public getBaseUrl(fundId: FundIdType): string {
    return `portfolio/${fundId}/positions`;
  }

  private getDate(date: string) {
    let dt;
    if (date === '' || date === null || !(dt = moment.utc(date, this.dateFormats)).isValid()) { return ''; }
    return dt.format(AppConstants.Format.Date.American);
  }

  public getHeader = () => ({timeout: 60000});
}
