import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { take } from 'rxjs/operators';
import { SharedModalsUserAccountsService } from '../shared-modals.service';

@Component({
    selector: 'security-disclaimer',
    templateUrl: 'security-disclaimer.component.html',
    styleUrls: ['security-disclaimer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SecurityDisclaimerComponent implements OnDestroy {
    @HostBinding('class')
    public clazz = 'security-disclaimer';

    @Input()
    public urlGuia: string;

    @Output()
    public opened = new EventEmitter<boolean>();

    @Output()
    public closed = new EventEmitter<boolean>();

    constructor(private service: SharedModalsUserAccountsService) { }

    public ngOnDestroy() { }

    public openSecurityModal() {
        this.closed.emit(false);
        const dialogRef = this.service.openConnectionSecurity({
            height: '454px',
            width: '584px',
            urlGuia: this.urlGuia
        });
        this.opened.emit(true);
        dialogRef.beforeClosed().pipe(take(1), untilDestroyed(this)).subscribe(() => this.opened.emit(false));
        dialogRef.afterClosed().pipe(take(1), untilDestroyed(this)).subscribe(() => this.closed.emit(true));
    }
}
