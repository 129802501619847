import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';

import { AppModule } from './app/app.module';

const SentryInit = () => {
  Sentry.init({
    dsn: 'https://fdd15b67a1004283a0508d16bb2784f3@o573039.ingest.sentry.io/5723125',
    environment: environment.name
  });
};

if (!location.host.startsWith('localhost')) {
  SentryInit();
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
