import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { ImportFilesRoutePath, ImportSpreadsheetRoutePath } from './metadata';
import { environment } from '@env/environment';

export const ImportFilesMenuData = {
  featureName: 'importFiles',
  routePath: ImportFilesRoutePath,
  icon: 'Nav/Advanced-Tools/Upload-White',
  text: 'TOOLS_DROPDOWN.IMPORT_FILES',
  cancelNavigation: environment.features.tools.importFiles.modal,
  dataTracking: 'Menu - Import Files',
};

export const ImportFilesRoute: Route = {
  data: {
    title: 'Importar Arquivos',
    hideFilter: true,
    isB2BRoute: true,
  },
  path: ImportFilesRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/import-files-shell#ImportFilesFeatureShellModule'
};

export const ImportSpreadsheetRoute: Route = {
  data: {
    title: 'Importar Transações',
    hideFilter: true,
    isB2BRoute: true,
  },
  path: ImportSpreadsheetRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/import-files-shell#ImportFilesFeatureShellModule'
};
