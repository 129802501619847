import {
  PositionBehavioralState,
  PositionColumns,
  AssetBehavioralState,
  ProductTypeBehavioralState,
  ProductTypeColumns,
  PortfolioBehavioralState
} from '@gorila/pages/store/wallet';
import { Parity } from '@gorila/shared';

export interface WalletState {
  components: {
    'asset': AssetBehavioralState;
    'portfolio': PortfolioBehavioralState;
    'position': PositionBehavioralState;
    'product-type': ProductTypeBehavioralState;
  };
}

export const INITIAL_STATE: WalletState = {
  components: {
    'asset': {
      expandable: Parity.MANY,
      selectable: Parity.NONE,
      animatePercentage: {
        text: true,
        width: true
      }
    },
    'portfolio': {
      multiexpandable: true,
      multiselectable: false
    },
    'position': {
      expandable: Parity.MANY,
      selectable: Parity.NONE,
      showActions: true,
      descriptionLimit: 42,
      columnsToShow: [
        PositionColumns.Broker,
        PositionColumns.OperationalCosts,
        PositionColumns.Maturity,
        PositionColumns.PriceToday,
        PositionColumns.ProfitPeriod,
        PositionColumns.Quantity,
        PositionColumns.Total
      ]
    },
    'product-type': {
      columnsToShow: [
        ProductTypeColumns.ProfitPeriod,
        ProductTypeColumns.Total
      ],
      expandable: Parity.MANY,
      selectable: Parity.NONE,
      waitAnimationOnClose: 250
    }
  }
};
