import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, Input } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';

const DefaultSmoothFactor = 10;
const AvailableAnimations = { title: 'animateTitle' };
const AnimationTime = 1000; // ms

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  animations: [
    trigger('titleOpacity', [
      state('in', style({opacity: 1})),
      transition('void => ' + AvailableAnimations.title, [
        style({opacity: 0}),
        animate(AnimationTime)
      ])
    ])
  ]
})
export class ProgressBarComponent implements OnChanges, OnDestroy, OnInit {

  @Input() public animateOffset = false;
  @Input() public animateTitle = false;
  @Input() public animateWidth = true;
  @Input() public autoBar = false; // auto create timer (debug only)
  @Input() public completedText = ''; // completed text to be displayed
  @Input() public delay = 0; // ms
  @Input() public filledBackground = '';
  @Input() public hoverCompletedText = 'Completed';
  @Input() public hoverText = 'Loading...';
  @Input() public innerProgress: number; // current inner progress number
  @Input() public max = 100; // max progress number
  @Input() public nonFilledBackground = '';
  @Input() public offset = 0;
  @Input() public progress = 0; // current progress number
  @Input() public shadowFilledBackground = '';
  @Input() public shadowTextSize = '0.75rem';
  @Input() public showProgress = true; // show progressbar percentage text
  @Input() public startProgress = 0; // current progress number
  @Input() public text = ''; // alternative text to be displayed
  @Input() public textAlign = 'center'; // where to align the text (flex justify-content)
  @Input() public textSize = '1rem';
  @Input() public waiting = false; // auto create timer (debug only)
  @Input() public waitingText = ''; // waiting text to be displayed

  public animateTitleState = '';
  public offsetLeft: number;
  public shadowTitle: number|string = '0';
  public title: number|string = '0';
  private lastTemp = 0;
  private minDiff = 0.01;
  private progressTemp = 0;
  private shadowWidth = '0';
  private smoothFactor = DefaultSmoothFactor;
  private subscription: Subscription = null;
  private width = '0';

  constructor(private cdref: ChangeDetectorRef) {
    if (this.smoothFactor <= 0) { this.smoothFactor = 1; }
  }

  public ngOnInit() {
    if (this.autoBar) { this.runTimerTest(); }
    if (this.animateTitle) { this.animateTitleState = AvailableAnimations.title; }
  }

  public ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.innerProgress) {
        this.setShadowWidth(this.innerProgress);
      }
      if (changes.progress && !this.innerProgress && this.innerProgress !== 0) {
        this.innerProgress = this.progress;
      }
      this.checkProgress();
      if (changes.offset && this.subscription === null) {
        this.offsetLeft = this.offset;
      }
      if (!changes.progress && !changes.max) { return; }
      let max = (changes.max) ? changes.max.currentValue : this.max;
      let progress = (changes.progress) ? changes.progress.currentValue : this.progress;
      if (this.autoBar) {
        progress = this.progressTemp;
        max = 100;
      }
      this.doChanges(max, progress);
    } catch (e) { console.error(e); }
  }

  public ngOnDestroy() {
    try {
      if (this.subscription !== null) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    } catch (e) { console.warn(e); }
  }

  public calculeWidth(max: number, progress: number): number {
    if (max <= 0 || progress < 0) { return 0; }
    if (progress >= max) { return 100; }
    const res = (progress / max) * 100;
    return res;
  }

  public checkProgress() {
    if (this.progress < 0) { this.progress = 0; }
    if (this.max < 1) { this.max = 1; }
    if (this.max < this.progress) { this.max = this.progress; }
  }

  public shouldDisplayValue(progressBar: HTMLElement): boolean {
    if (this.innerProgress === undefined) {
      return true;
    }

    if (Number(this.progress).toFixed() === Number(this.innerProgress).toFixed()) { return true; }
    return progressBar.offsetWidth > 140 || progressBar.offsetLeft > 140;
  }

  public shouldDisplayShadowValue(progressBar: HTMLElement, progressBarShadow: HTMLElement): boolean {
    if (this.innerProgress === undefined) {
      return true;
    }

    if (this.innerProgress === null) {
      return false;
    }

    const offserWidthDiff = progressBarShadow.offsetWidth - progressBar.offsetWidth;

    return offserWidthDiff > 60 || progressBarShadow.offsetWidth === offserWidthDiff;
  }

  private checkSubscription(init: number) {
    if (this.subscription === null) { return; }
    try {
      this.setWidth(init);
      this.subscription.unsubscribe();
      this.subscription = null;
    } catch (e) { console.warn(e); }
  }

  private doChanges(max: number, progress: number) {
    const temp = this.calculeWidth(max, progress);
    if (this.animateWidth) {
      this.smooth(this.lastTemp, temp);
    } else {
      this.setWidth(temp);
    }
    this.lastTemp = temp;
  }

  private getParams(init: number, finish: number) {
    let factor = this.smoothFactor;
    let diff = (finish - init) / this.smoothFactor;
    if (init + diff < 0) { return; }
    if (diff < this.minDiff && init !== finish) {
      factor = (finish - init) / this.minDiff;
      diff = this.minDiff;
    }
    const n = (+factor === 0) ? factor : DefaultSmoothFactor;
    const time = AnimationTime / n;
    const multiplier = init > finish ? -1 : 1;
    return { diff: diff, time: time, multiplier: multiplier };
  }

  private runTimerTest() {
    try {
      let i = 0;
      let j = 0;
      timer(this.delay, AnimationTime).pipe(untilDestroyed(this)).subscribe(() => {
        i = this.waitProgress(i);
        j = this.waitZero(j);
        this.progressTemp += (Math.random() * 100) / 10;
        if (this.progressTemp > 100) {
          this.progressTemp = 100;
        }
        this.doChanges(this.max, this.progressTemp);
      });
    } catch (e) { console.warn(e); }
  }

  private setWidth(data: number) {
    if (this.animateWidth && this.subscription == null) { return; }
    if (data < 0) { data = 0; }

    try {
      const value = data.toFixed((data < 100) ? 1 : 0);
      this.width = value + '%';
      this.title = Number.isSafeInteger(+value) ? Math.round(+value) : value;
      // Calling directly the DC 'cause view wasn't updating
      this.cdref.markForCheck();
    } catch (e) { console.warn(e); }
  }

  private setShadowWidth(data: number) {
    if (data < 0) { data = 0; }

    try {
      const value = data.toFixed((data < 100) ? 1 : 0);
      this.shadowWidth = value + '%';
      this.shadowTitle = Number.isSafeInteger(+value) ? Math.round(+value) : value;
      // Calling directly the DC 'cause view wasn't updating
      this.cdref.markForCheck();
    } catch (e) { console.warn(e); }
  }

  private smooth(init: number, finish: number) {
    try {
      this.checkSubscription(init);
      const params = this.getParams(init, finish);
      let i = 0;
      if (!this.animateOffset) { this.offsetLeft = this.offset; }
      this.subscription = timer(this.delay, params.time).pipe(
        finalize(() => this.offsetLeft = this.offset)
      ).subscribe(() => {
        i++;
        const width = init + i * params.diff * params.multiplier;
        if (i > 1 && (width <= init || width >= finish)) {
          this.checkSubscription(finish);
        } else { this.setWidth(width); }
      });
    } catch (e) { console.warn(e); }
  }

  private waitProgress(i: number): number {
    if (this.progressTemp < 100) { return 0; }
    if (this.progressTemp > 100) {
      this.progressTemp = 100;
    }
    i++;
    if (i >= 5) {
      i = 0;
      this.progressTemp = 0;
    }
    return i;
  }

  private waitZero(j: number): number {
    if (this.progressTemp !== 0) { return 0; }
    j++;
    if (j < 5) { return j; }
    return 0;
  }
}
