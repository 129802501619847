import { Component, forwardRef, Input, OnChanges, SimpleChange } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioGroupComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => RadioGroupComponent), multi: true }
  ]
})
export class RadioGroupComponent implements ControlValueAccessor, OnChanges {
  @Input() public _selectValue = 1;
  @Input() public title;
  @Input() public inline = false;
  @Input() public formControlName: string;
  @Input() public list: any;
  @Input() public form: FormGroup;

  public propagateChange: Function;
  public touchedChange: Function;

  constructor() {
    this.propagateChange = () => { };
    this.touchedChange = () => { };
  }

  get selectValue(): number {
    return this._selectValue;
  }

  set selectValue(val) {
    this._selectValue = val;
  }

  /***********************************************
   **BEGIN implementation of OnChanges interface**
   ***********************************************/
  public ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
  }

  public validate(c: FormControl) {
  }

  /*********************************************
   **END implementation of OnChanges interface**
   *********************************************/

  /********************************************************
   **BEGIN implementation of ControlValueAccessor interface**
   **********************************************************/

  /**
    writeValue(obj: any) is the method that writes a new value from the form model
    * into the view or (if needed) DOM property. This is where we want to update our
    * counterValue model, as that’s the thing that is used in the view.
    */
  public writeValue(value: any) {
    if (value) {
      this.selectValue = this.fillValue(value);
    } else {
      this.selectValue = 0;
    }
  }

  private fillValue(val: string) {
    return parseInt(val, 10);
  }

  /**
   * registerOnChange(fn: any) is a method that registers a handler that should
   * be called when something in the view has changed. It gets a function
   * that tells other form directives and form controls to update their values.
   * In other words, that’s the handler function we want to call whenever
   * price changes through the view.
   */
  public registerOnChange(fn: Function) {
    this.propagateChange = fn;
  }

  /**
   * registerOnTouched(fn: any) Similar to registerOnChange(), this registers
   * a handler specifically for when a control receives a touch event.
   * We don’t need that in our custom control.
   */
  public registerOnTouched(fn: Function) {
    this.touchedChange = fn;
  }

  /********************************************************
   **END implementation of ControlValueAccessor interface**
   ********************************************************/
}
