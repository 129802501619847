import { Injectable } from '@angular/core';
import { Epic } from 'redux-observable';
import { from } from 'rxjs';

import { BaseEpic } from '../../base/epic.base';
import { AppState, STATE_TYPES } from '../../store.model';
import { ActionTransition, ActionType } from '../actions.model';
import { PortfolioAPIAction, PortfolioAPIActions } from '../portfolio/portfolio.actions';

@Injectable()
export class ProductAPIEpics extends BaseEpic {
  constructor(private portfolioActions: PortfolioAPIActions) {
    super();
  }

  public getStateType(): string {
    return STATE_TYPES.PRODUCT;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return this.createLoadSuccessProductEpic(filterIfNotLogged);
  }

  private createLoadSuccessProductEpic(ifNotLogged): Epic<PortfolioAPIAction, PortfolioAPIAction, AppState> {
    const actionType = this.getActionName(ActionType.MAP, ActionTransition.DATA);
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => from([
      this.portfolioActions.map(STATE_TYPES.PORTFOLIO, store.value['asset']['items'])
    ]), false);
  }
}
