<ng-container *ngIf="data">
  <div class="modal-container">
    <div class="modal-header">
      <div class='btn-container'>
        <button mat-button mat-dialog-close>
          <gor-icon icon='icon_fechar' width='14px' height='14px'></gor-icon>
        </button>
      </div>
      <div class="title-container">
        <h4 class='title-container__text' translate>error connection with</h4>
        <h4 class='title-container__provider'>{{data.providerName}}</h4>
      </div>
    </div>
    <div class='modal-body'>
      <span class="modal-body__text"> {{ 'error connection message with' | translate }}</span>
    </div>
    <div class="modal-footer">
      <gor-button btnType='green' (click)="close()">{{"Continue" | translate}}</gor-button>
    </div>
  </div>
</ng-container>
