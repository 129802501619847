// tslint:disable
import { Injector } from "@angular/core";
export class UtilsService {

  static injector: Injector;
  public static deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
      if (oldObj instanceof Date) { return new Date(oldObj.valueOf()); }
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
        if (oldObj.hasOwnProperty(i)) {
          newObj[i] = this.deepCopy(oldObj[i]);
        }
      }
    }
    return newObj;
  }

  public static deepCompare(...objects: any[]) {
    var utils = new UtilsService();
    return utils.deepCompare(...objects);
  }

  private debug = false;
  i: any;
  l: any;
  leftChain: any;
  rightChain: any;
  public deepCompare(...objects: any[]) {
    this.i = 0;
    this.l = 0;
    if (objects.length < 1) {
      return true; //Die silently? Don't know how to handle such case, please help...
      // throw "Need two or more arguments to compare";
    }

    for (this.i = 1, this.l = objects.length; this.i < this.l; this.i++) {

      this.leftChain = []; //Todo: this can be cached
      this.rightChain = [];

      if (!this.compare2Objects(objects[0], objects[this.i])) {
        if (this.debug) { console.debug('compare2Objects ', objects[0], objects[this.i]); }
        return false;
      }
    }

    return true;
  }

  public compare2Objects(x: any, y: any) {
    var p: any;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
      return true;
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if ((typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)) {
      if (false === (x.toString() === y.toString())) { if (this.debug) { console.debug('function', x, y); } return false; }
      return true;
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      if (this.debug) { console.debug('instanceof', x, y); }
      return false;
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      if (this.debug) { console.debug('isPrototypeOf', x, y); }
      return false;
    }

    if (x.constructor !== y.constructor) {
      if (this.debug) { console.debug('constructor', x, y); }
      return false;
    }

    if (x.prototype !== y.prototype) {
      if (this.debug) { console.debug('prototype', x, y); }
      return false;
    }

    // Check for infinitive linking loops
    if (this.leftChain.indexOf(x) > -1 || this.rightChain.indexOf(y) > -1) {
      if (this.debug) { console.debug('indexOf', x, y); }
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        if (this.debug) { console.debug('hasOwnProperty', x, y); }
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        if (this.debug) { console.debug('typeof', x[p], y[p]); }
        return false;
      }
    }

    for (p in x) {
      if (!x.hasOwnProperty(p)) { continue; }

      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        if (this.debug) { console.debug('hasOwnProperty2', x[p], y[p]); }
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        if (this.debug) { console.debug('typeof', x[p], y[p]); }
        return false;
      }

      switch (typeof (x[p])) {
        case 'object':
        case 'function':

          this.leftChain.push(x);
          this.rightChain.push(y);

          if (!this.compare2Objects(x[p], y[p])) {
            return false;
          }

          this.leftChain.pop();
          this.rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            if (this.debug) { console.debug('!== ', x[p], y[p]); }
            return false;
          }
          break;
      }
    }

    return true;
  }


  public static getParameters(url?: string): any {
    var params: any = {};

    let hash = url.split('#')[1];
    if (hash = hash || location.hash) {
      hash = hash.startsWith('#') ? hash.substring(1) : hash;
      params = UtilsService.createParameterArray(params, hash);
    }

    let search = location.search;
    if (search) {      search = search.startsWith('?') ? search.substring(1) : hash;
      params = { ...params, ...UtilsService.createParameterArray(params, search) };
    }

    return params;
  }

  private static createParameterArray(params: any, urldata: string) {
    try {
      let parts = urldata.split('&');
      for (var i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) { continue; }
        params[nv[0]] = nv[1] || true;
      }
      return params;
    } catch (e) { console.warn(e); }

  }
}
