import {
  animate,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { PositionModel } from '@gorila/widgets/allocation';

@Component({
  selector: 'progress-group-expanded',
  templateUrl: './progress-group-expanded.component.html',
  animations: [
    trigger(
      'progressBarInOut', [
        transition(':enter', [
          style({ transform: 'translateX(100%)' }),
          animate(450, style({ transform: 'translateX(0)' }))
        ]),
        transition(':leave', [
          style({ transform: 'translateX(0)' }),
          animate(450, style({ transform: 'translateX(100%)' }))
        ])
      ]
    )
  ]
})

export class ProgressGroupExpandedComponent implements OnChanges {
  @Input() public progressData: Array<any> = [];
  @Input() public fillGradientStyle = null;
  public data: Array<any> = [];
  public calculating = '';
  public title_started = false;

  public constructor(private positionModel: PositionModel) { }

  public ngOnChanges(data: SimpleChanges) {
    if (typeof data['progressData'] !== 'undefined') {
      if (!this.progressData) { return; }
      this.updateArray(this.progressData);
    }
  }

  private updateArray(data: Array<any>) {
    try {
      const out = [];
      for (const i in data) {
        if (i === '0') { continue; }
        const temp = this.prepareArray(data[i]);
        if (!temp) { continue; }
        out.push(temp);
      }
      this.data = out;
    } catch (e) {
      this.data = [];
      console.warn(e);
    }
  }

  public prepareArray(temp: any) {
    try {
      if (!temp['SecurityName']) {
        temp['SecurityName'] = temp['index'];
      }
      temp['title'] = temp['SecurityName'];
      temp['SecurityName'] = this.positionModel.prepareName(temp['SecurityName']);
      return temp;
    } catch (e) {
      console.warn(e);
      return null;
    }

  }
}
