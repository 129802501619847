<ng-container *ngFor="let button of buttons">
  <ng-container [ngSwitch]="button">
    <ng-container *ngSwitchCase="'add-pro'">
      <gor-button btnType="green" class="professional">Adicionar Profissional</gor-button>
    </ng-container>
    <ng-container *ngSwitchCase="'invite'">
      <gor-button btnType="green" class="invite" [routerLink]="['convidar/tipo-de-cliente']">
        {{ "Add client" | translate }}
      </gor-button>
    </ng-container>
    <ng-container *ngSwitchCase="'blotter'">
      <gor-blotter-button *ngIf="!brokerClientMode"></gor-blotter-button>
    </ng-container>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</ng-container>
