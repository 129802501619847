import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { clearState } from '@gorila/root-store/logout/logout.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { Key as AppInfoKey } from './app-info.state';
import { AppInfoEffects } from './effects/app-info.effects';
import * as fromAppInfo from './reducers/app-info.reducer';

const metaReducers = [clearState];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AppInfoKey, fromAppInfo.reducer, { metaReducers }),
    EffectsModule.forFeature([AppInfoEffects])
  ],
  declarations: []
})
export class AppInfoReduxModule { }
