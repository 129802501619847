import { StateItemList } from '../../store.model';
import { Product } from '../product/product.model';

/**
 * Interface for objects that represents a portfolio.
 *
 * @interface
 */
export interface Portfolio {
  /**
   * Unique identifier of a portfolio.
   *
   * @type {number}
   */
  id: number;

  /**
   * A list of products related to the portfolio, identified by an unique identifier.
   *
   * @type {Array<string>}
   * @see {Product}
   */
  products: Array<string>;
}

// tslint:disable-next-line:no-empty-interface
export interface PortfolioList extends StateItemList<Portfolio> { }

export const mapPortfolio = (position: any): Portfolio => ({
  id: position.FundId,
  products: [position.AssetClass]
});

export const insertProductOnPortfolio = (product: Product, portfolio: Portfolio): Portfolio => {
  if (portfolio.id !== +product.fundId) { return portfolio; }
  if (portfolio.products.indexOf(product.id) !== -1) { return portfolio; }
  const newPortfolio = portfolio;
  newPortfolio.products = [...portfolio.products, product.id];
  return newPortfolio;
};

export const mapProductToPortfolio = (product: Product): Portfolio => ({
  id: +product.fundId,
  products: [product.id]
});
