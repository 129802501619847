import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { CashFlowRoutePath } from './metadata';
import { environment } from '@env/environment';

export const CashFlowMenuData = {
  featureName: 'cashFlow',
  routePath: CashFlowRoutePath,
  icon: 'Nav/Advanced-Tools/Cash-Flow-White',
  text: 'Fluxo de Caixa'
};

export const CashFlowRoute: Route = {
  data: {
    title: 'Fluxo de Caixa',
    hideFilter: false
  },
  path: CashFlowRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/cash-flow-shell#CashFlowShellModule'
};
