import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';

import { StateType } from '../store.model';
import { Separator } from '../store.utils';
import { ActionTransition, actionTransitionNameFor, ActionType, actionTypeNameFor } from './actions.model';

const CLEAR = 'CLEAR';

export type GenericAction = FluxStandardAction<any, MetaData>;

// Flux-standard-action gives us stronger typing of our actions.
export interface MetaData {
  actionType?: ActionType;
  stateType: StateType;
  force?: boolean;
}

export const getReduxActionType = (actionType: ActionType, stateType: StateType, actionTransition?: ActionTransition) =>
  actionTypeNameFor(actionType) + Separator + stateType + (actionTransition ? Separator + actionTransitionNameFor(actionTransition) : '');

export const getTypeFor = (actionType: ActionType, stateType: StateType[], actionTransition: ActionTransition) =>
  actionTypeNameFor(actionType) + Separator + stateType.join(Separator) + Separator + actionTransitionNameFor(actionTransition);

export function clearAction(type: string, stateType: StateType) {
  return {
    type: CLEAR + Separator + type,
    meta: { actionType: ActionType.CLEAR, stateType },
    payload: null
  };
}

export function addDataAction(stateType: StateType, payload: any) {
  return genericDataAction(ActionType.ADD, stateType, payload);
}

export function addSucceededAction(stateType: StateType, payload: any) {
  return genericSucceededAction(ActionType.ADD, stateType, payload);
}

export function genericDataAction(actionType: ActionType, stateType: StateType, payload = null) {
  return {
    type: getReduxActionType(actionType, stateType, ActionTransition.DATA),
    meta: { actionType, stateType },
    payload
  };
}

export function genericFailedAction(actionType: ActionType, stateType: StateType, error) {
  return {
    type: getReduxActionType(actionType, stateType, ActionTransition.FAILED),
    meta: { actionType, stateType },
    payload: null,
    error
  };
}

export function genericStartedAction(actionType: ActionType, stateType: StateType, payload = null) {
  return {
    type: getReduxActionType(actionType, stateType, ActionTransition.STARTED),
    meta: { actionType, stateType },
    payload
  };
}

export function genericSucceededAction(actionType: ActionType, stateType: StateType, payload = null) {
  return {
    type: getReduxActionType(actionType, stateType, ActionTransition.SUCCEEDED),
    meta: { actionType, stateType },
    payload
  };
}

export function loadDataAction(stateType: StateType) {
  return genericDataAction(ActionType.LOAD, stateType);
}

export function loadSucceededAction(stateType: StateType, payload: any) {
  return genericSucceededAction(ActionType.LOAD, stateType, payload);
}

export function mapDataAction(stateType: StateType, payload: any) {
  return genericDataAction(ActionType.MAP, stateType, payload);
}

export function removeDataAction(stateType: StateType, payload: any) {
  return genericDataAction(ActionType.REMOVE, stateType, payload);
}

export function removeSucceededAction(stateType: StateType, payload: any) {
  return genericSucceededAction(ActionType.REMOVE, stateType, payload);
}

export function unmapDataAction(stateType: StateType, payload: any) {
  return genericDataAction(ActionType.UNMAP, stateType, payload);
}

export function updateDataAction(stateType: StateType, payload: any) {
  return genericDataAction(ActionType.UPDATE, stateType, payload);
}

export function updateSucceededAction(stateType: StateType, payload: any) {
  return genericSucceededAction(ActionType.UPDATE, stateType, payload);
}

@Injectable()
export class APIActions<T> {
  public static getTypeFor = getTypeFor;
  public static readonly CLEAR = CLEAR;
  protected readonly type: string;

  @dispatch()
  public dispatchLoad = (stateType: StateType): FluxStandardAction<T[], MetaData> =>
    loadDataAction(stateType)

  @dispatch()
  public dispatchAdd = (stateType: StateType, payload: any): FluxStandardAction<T[], MetaData> =>
    addDataAction(stateType, payload)

  @dispatch()
  public dispatchUpdate = (stateType: StateType, payload: any): FluxStandardAction<T[], MetaData> =>
    updateDataAction(stateType, payload)

  @dispatch()
  public dispatchRemove = (stateType: StateType, key: any): FluxStandardAction<any, MetaData> =>
    removeDataAction(stateType, key)

  public map = (stateType: StateType, payload: any): FluxStandardAction<T[], MetaData> =>
    mapDataAction(stateType, payload)

  public unmap = (stateType: StateType, payload: any | string): FluxStandardAction<T[], MetaData> =>
    unmapDataAction(stateType, payload)

  public started = (actionType: ActionType, stateType: StateType): FluxStandardAction<any, MetaData> =>
    genericStartedAction(actionType, stateType)

  public addSucceeded = (stateType: StateType, payload: T|T[]): FluxStandardAction<T, MetaData> =>
    addSucceededAction(stateType, payload)

  public loadSucceeded = (stateType: StateType, payload: T[]): FluxStandardAction<T[], MetaData> =>
    loadSucceededAction(stateType, payload)

  public removeSucceeded = (stateType: StateType, payload: any): FluxStandardAction<T[], MetaData> =>
    removeSucceededAction(stateType, payload)

  public updateSucceeded = (stateType: StateType, payload: T): FluxStandardAction<T, MetaData> =>
    updateSucceededAction(stateType, payload)

  public failed = (actionType: ActionType, stateType: StateType, error): FluxStandardAction<any, MetaData> =>
    genericFailedAction(actionType, stateType, error)

  @dispatch()
  public clear = (stateType: StateType, type: string = this.type): FluxStandardAction<T[], MetaData> => clearAction(type, stateType)

}
