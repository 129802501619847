import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalExpectationType } from '../user-account-details/user-account.model';

@Component({
  selector: 'connection-expectations',
  templateUrl: './connection-expectations.component.html',
  styleUrls: ['./connection-expectations.component.scss']
})
export class ConnectionExpectationsComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      providerName: string,
      text: any,
      enableClose: boolean,
      buttonColor: string,
      typeModal: ModalExpectationType
    },
    private dialogRef: MatDialogRef<ConnectionExpectationsComponent>
  ) { }

  public close() {
    this.dialogRef.close();
  }
}
