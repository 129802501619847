import { Route } from '@angular/router';
import { BlotterOutlet, BlotterBaseRoute } from '@gorila-bot/blotter';
import { ProxyComponent } from '@gorila/shared/components';

import { PermissionGuard } from '../../guards/permission.guard';

export const BlotterRoute: Route = {
  component: ProxyComponent,
  data: { permissions: [ 'ADVISOR', 'CLIENT' ] },
  canLoad: [PermissionGuard],
  loadChildren: '@gorila-bot/blotter#BlotterModule',
  outlet: BlotterOutlet,
  path: BlotterBaseRoute
};
