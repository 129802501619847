export interface SignupUser {
  /**
   * The advisor fund id of the user.
   */
  advisorFundId?: number;

  /**
   * The new user e-mail.
   */
  email: string;

  /**
   * The new user name.
   */
  name: string;

  /**
   * The new user password.
   */
  password: string;
}

/**
 * An error representation of errors on signup flow.
 */
export interface SignupError {
  /**
   * Flag that indicates an error, always true.
   */
  error: true;

  /**
   * A text string to discriminate the error.
   */
  text: string;
}

export enum USER_TYPE {
  ADVISOR = 'ADVISOR',
  INDIVIDUAL = 'INDIVIDUAL',
  BROKER = 'BROKER',
}

export interface SignUpUserData {
  username: string;
  password: string;
  name: string;
  type: USER_TYPE;
}
