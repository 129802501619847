<div
  class="menu-dropdown-container"
  (mouseenter)="handleMouseEvent('mouseEnter')"
  (mouseleave)="handleMouseEvent('mouseLeave')"
>
  <div class="header" [class.height-2x]="name.long">
    <span class="username" *ngIf="name.first || name.last">{{name.first}} {{name.last}}</span>
    <span class="email">{{email}}</span>
  </div>
  <div class="body">
    <ul class="menu">
      <li class="menu-item" *ngFor="let item of items">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {item:item}"></ng-container>
      </li>
    </ul>
  </div>
</div>
<div class="menu-dropdown-container-shadow" [class.height-2x]="name.long"></div>

<ng-template #itemTemplate let-item="item">
  <span class="ribbon"></span>
  <span class="text">
    <ng-container *ngIf="item.hasExternalLink;else routerLinkTemplate">
      <a [href]="item.link" target="_blank" translate (click)="handleMouseEvent('linkClick')">{{item.title | translate}}</a>
    </ng-container>
    <ng-template #routerLinkTemplate>
      <a [routerLink]="item.link" translate (click)="handleMouseEvent('linkClick')">{{item.title | translate}}</a>
    </ng-template>
  </span>
</ng-template>
