import { Action } from '@ngrx/store';

import { ITransferResponseItem, ITransferRequest, ITransferItem } from '../transfer.state';

export enum TransferActionTypes {
  LoadTransfers = '[Transfer] Load Transfers',
  LoadedTransfers = '[Transfer] Loaded Transfers',
  LoadedTransfersError = '[Transfer] Loaded Transfers Error',
  ClearTransfers = '[Transfer] Clear Transfers',
  RemoveTransfer = '[Transfer] Remove Transfer',
  RemovedTransfer = '[Transfer] Removed Transfer'
}

export class LoadTransfers implements Action {
  public readonly type = TransferActionTypes.LoadTransfers;
  constructor(public requestData: ITransferRequest) { }
}

export class LoadedTransfers implements Action {
  public readonly type = TransferActionTypes.LoadedTransfers;

  /**
   *
   * @param transfers data received from server
   * @param requestData this is needed to correct replace itens in the current state inside redux
   */
  constructor(public transfers: ITransferResponseItem, public requestData: ITransferRequest) {}
}

export class LoadedTransfersError implements Action {
  public readonly type = TransferActionTypes.LoadedTransfersError;
  constructor(public data: any) {}
}

export class ClearTransfers implements Action {
  public readonly type = TransferActionTypes.ClearTransfers;
}

export class RemoveTransfer implements Action {
  public readonly type = TransferActionTypes.RemoveTransfer;
  constructor(public id: ITransferItem['custodyTransferID']) { }
}

export class RemovedTransfer implements Action {
  public readonly type = TransferActionTypes.RemovedTransfer;
  constructor(public id: ITransferItem['custodyTransferID']) { }
}

export type TransferActions =
  LoadTransfers
  | LoadedTransfers
  | LoadedTransfersError
  | ClearTransfers
  | RemoveTransfer
  | RemovedTransfer;
