<div
  class="dropdown-box"
  (mouseenter)="handleMouseEvent(true)"
  (mouseleave)="handleMouseEvent(false)"
>
  <gor-dropdown-button *ngFor="let item of items"
    [item]="item"
    matTooltipClass="tools-menu-item"
    [matTooltip]="item.tooltip | translate"
  ></gor-dropdown-button>
</div>
