import { EventEmitter } from '@angular/core';

// tslint:disable-next-line:no-empty-interface
export interface EventType { }

export interface Event<T extends EventType> {
  type: T;
  payload?: any;
}

export class CustomEventEmitter<T> extends EventEmitter<Event<T>> {

}

export const CreateReduction = (type: string, payload: any, emitter?: CustomEventEmitter<any>): Event<any> => {
  const data: Event<any> = { type, payload };
  try {
    if (emitter) {
      emitter.emit(data);
    }
  } catch (e) { console.warn(e); }
  return data;
};

export const ReduceEvent = (object: any, event: Event<any>, reductionEmmiter?: CustomEventEmitter<any>) => {
  try {
    let method = `reduce_${event.type}`;
    if (typeof object[method] !== 'undefined') { return object[method](event.payload); }
    method = `reduce${event.type}`;
    if (typeof object[method] !== 'undefined') { return object[method](event.payload); }
    method = `reduce${event.type.charAt(0).toUpperCase() + event.type.slice(1)}`;
    if (typeof object[method] !== 'undefined') { return object[method](event.payload); }
    if (!reductionEmmiter) { return null; }
    return CreateReduction(event.type, event.payload, reductionEmmiter);
  } catch (e) { console.warn(e); }
};
