import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { AppConstants } from '@gorila/constants';
import { IOperation, IPosition, OperationSideEnum } from '@gorila-bot/gorila-front-models';
import { ITransferItem } from '@gorila/root-store/transfer/src/lib/transfer.state';

@Pipe({
  name: 'quantity',
  pure: true
})
export class QuantityPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) { }

  public transform(value: any, operation: IOperation | ITransferItem | IPosition, emptyValue: string): string {
    if (!value && emptyValue) { return emptyValue; }
    const productType = 'security' in operation
    ? this.getProductTypeFromSecurity(operation.security)
    : operation.securityType ? operation.securityType :  operation.productType;

    const _productType = productType && productType.split('_')[0];
    const decimals =
    _productType && _productType in AppConstants.Decimals
    ? AppConstants.Decimals[_productType].Quantity
    : AppConstants.Decimals.DEFAULT.Quantity;

    if ('side' in operation) {
      if (operation.side === OperationSideEnum.SELL) {
        const valueDecimal = this.decimalPipe.transform(value, `1.${decimals}-${decimals}`);
        return `-${valueDecimal}`;
      }
    }

    return this.decimalPipe.transform(value, `1.${decimals}-${decimals}`);

  }

  private getProductTypeFromSecurity(security: IOperation['security'] | IPosition['security']): string {
    return 'productType' in security ? security.productType : security.type;
  }

}
