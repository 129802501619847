import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productIndex',
  pure: true
})
export class ProductIndexPipe implements PipeTransform {

  public transform(operation: string): string {
    return (operation === 'PRE') ? 'pre-fixed' : 'postfixed';
  }
}
