import { ITrade } from '@gorila-bot/gorila-front-models';
import {
  append,
  compose,
  contains,
  dissoc,
  findIndex,
  forEachObjIndexed,
  indexBy,
  insert,
  keys,
  not,
  or,
  prop,
  propEq,
  remove,
  sortBy,
  update
} from 'ramda';
import { Action } from 'redux';

import { ReducerBase } from '../../base/reducer.base';
import { FLAGS, STATE_TYPES, StateItemList } from '../../store.model';
import {
  PositionAPIAction,
  PositionAPIActions,
  PositionLoadAPIAction,
  PositionRemoveAPIAction,
  PositionUpdateAPIAction
} from './position.actions';
import { composePositionIdFromTrade, INITIAL_STATE, Position, setPositionPnlFromServer } from './position.model';

const positionsToItems = positions => indexBy(prop('id'), sortBy(compose(prop('name') as any, prop('security')))(positions));

const failedReducer = (flag: string) =>
  (state: StateItemList<Position> = INITIAL_STATE, action: PositionAPIAction) =>
  ({ ...state, [flag]: false, error: action.error });

const startedReducer = (flag: string) =>
  (state: StateItemList<Position> = INITIAL_STATE, action: PositionAPIAction) =>
  ({ ...state,  [flag]: true });

function clearDataReducer(state: StateItemList<Position> = INITIAL_STATE, action: PositionLoadAPIAction) {
  return {
    ...state,
    items: {}
  };
}

function loadSuccessReducer(state: StateItemList<Position> = INITIAL_STATE, action: PositionLoadAPIAction) {
  const positions = (<PositionLoadAPIAction>action).payload.map(pos => {
    return setPositionPnlFromServer(pos, action.meta['profits']);
  });

  return {
    ...state,
    items: positionsToItems(positions),
    loading: false,
    error: null
  };
}

function mapDataReducer(state: StateItemList<Position> = INITIAL_STATE, action: any) {
  const trades = action.payload;
  const positionsIndexed = or(state.items, {});
  let positions: Position[] = keys(positionsIndexed).map(positionId => positionsIndexed[positionId]);

  if (positions.length < 1) { return state; }

  const mapTradeToPosition = (trade: ITrade) => {
    if (!trade.security || !trade.fundId || !trade.broker) {
      if (localStorage.getItem('reduxLog')) {
        console.warn('Invalid trade found: can\'t create position key from trade.', trade);
      }
      return;
    }

    const key = composePositionIdFromTrade(trade);
    const positionIndex = findIndex(propEq('id', key))(positions);
    const position: Position = positions[positionIndex];

    if (!position) { return; }

    if (position.trades.indexOf(trade.id) === -1) {
      position.trades = append(trade.id, position.trades);
      positions = update(positionIndex, position, positions);
    }
  };
  forEachObjIndexed(mapTradeToPosition, trades);
  return {
    ...state,
    items: positionsToItems(positions)
  };
}

function removeSuccessReducer(state: StateItemList<Position> = INITIAL_STATE, action: PositionRemoveAPIAction) {
  let items: any = or(state.items, {});
  const index = findIndex(propEq('id', action.payload))(items);
  items = index === -1 && dissoc(action.payload, items) || remove(index, 1, items);
  return {
    ...state,
    items,
    removing: false,
    error: null
  };
}

function updateSuccessReducer(state: StateItemList<Position> = INITIAL_STATE, action: PositionUpdateAPIAction) {
  let position: Position = action.payload;
  const positionsIndexed = or(state.items, {});
  let positions: Position[];

  if (!position) { return state; }

  position = setPositionPnlFromServer(position, action.meta['profits']);

  positions = keys(positionsIndexed).map(positionId => positionsIndexed[positionId]);
  const currIndex = findIndex(propEq('id', position.id))(positions);
  position.trades = currIndex > -1 ? positions[currIndex].trades : [];
  const upsert = currIndex > -1 ? update : insert;
  positions = upsert(currIndex, position, positions);

  return {
    ...state,
    items: positionsToItems(positions),
    updating: false,
    error: null,
  };
}

function unmapDataReducer(state: StateItemList<Position> = INITIAL_STATE, action: PositionRemoveAPIAction) {
  const positionsIndexed = or(state.items, {});
  let positions: Position[] = keys(positionsIndexed).map(positionId => positionsIndexed[positionId]);

  if (!positions || positions.length < 1) { return state; }

  const tradeId: string = action.payload;
  let tradeIndex = -1;
  let key = '';
  for (let i = 0; i < positions.length; i++) {
    const trades: string[] = positions[i].trades;
    if (not(contains(tradeId, trades))) { continue; }
    key = positions[i].id;
    for (let j = 0; j < trades.length; j++) {
      if (trades[j] !== tradeId) { continue; }
      tradeIndex = j;
    }
    break;
  }
  const positionIndex = findIndex(propEq('id', key))(positions);
  const position: Position = positions[positionIndex];
  if (!position) { return state; }

  position.trades = remove(tradeIndex, 1, position.trades);
  positions = update(positionIndex, position, positions) as Position[];
  return {
    ...state,
    items: positionsToItems(positions)
  };
}

export function positionReducer(state: StateItemList<Position> = INITIAL_STATE, a: Action) {
  const clearAction = PositionAPIActions.actionType.clear;
  const mapAction = PositionAPIActions.actionType.map;
  const unmapAction = PositionAPIActions.actionType.unmap;
  const updateAction = PositionAPIActions.actionType.update;
  const loadAction = PositionAPIActions.actionType.load;
  const removeAction = PositionAPIActions.actionType.remove;
  return ReducerBase({
    /***** CLEAR *****/
    [clearAction]: clearDataReducer,

    /***** MAP *****/
    [mapAction.data]: mapDataReducer,

    /***** UNMAP *****/
    [unmapAction.data]: unmapDataReducer,

    /***** LOAD *****/
    [loadAction.fail]: failedReducer(FLAGS.LOADING),
    [loadAction.start]: startedReducer(FLAGS.LOADING),
    [loadAction.success]: loadSuccessReducer,

    /***** REMOVE *****/
    [removeAction.fail]: failedReducer(FLAGS.REMOVING),
    [removeAction.start]: startedReducer(FLAGS.REMOVING),
    [removeAction.success]: removeSuccessReducer,

    /***** UPDATE *****/
    [updateAction.fail]: failedReducer(FLAGS.UPDATING),
    [updateAction.start]: startedReducer(FLAGS.UPDATING),
    [updateAction.success]: updateSuccessReducer
  }, state, a, STATE_TYPES.POSITION);
}
