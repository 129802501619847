import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@gorila/pages/auth';
import { UserDataState } from '@gorila-bot/user-data-store';
import { SocketEventService } from '@gorila-bot/gorila-base';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppShellRoutePath } from '../app-shell/metadata';
import { AuthGuard } from './auth.guard';

@Injectable()
export class LoggedOutGuard extends AuthGuard {
  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected socketEventService: SocketEventService,
    protected store: Store<UserDataState.State>
  ) {
    super(router, authService, socketEventService, store);
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticated().pipe(map(authenticated => {
      if (authenticated) {
        this.router.navigate([`/${AppShellRoutePath}`]);
      }
      return !authenticated;
    }));
  }

  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return false;
  }

  public canLoad(): boolean {
    return true;
  }
}
