<form class="form" *ngIf="form" [formGroup]="form">
  <div>
    <span for="email" class="form-label">{{ 'email' | translate }}</span>
    <div>
      <input matInput class="form-input" type="email" name="email" id="email" placeholder="Digite seu email de cadastro"
        formControlName="email">
    </div>
    <span *ngIf="!form.get('email').valid && form.get('email').touched" class="form-invalid">
      <i *ngIf="(form.get('email').errors?.required || form.get('email').errors?.email)">{{ 'Invalid email' | translate
        }}</i>
    </span>
  </div>
  <div *ngIf="isResetPassword" class="reset-container">
    <a class="reset-label" (click)="onClick(eventType.NAV_LOGIN)">{{ 'Back' | translate }}</a>
  </div>

  <div *ngIf="!!message.text" class="message-container">
    <span [class]="message.error ? 'error-message' : 'message'">{{ message.text | translate }}</span>
  </div>

  <div *ngIf="!message.text" class="button-container">
    <gor-base-button id="reset_password_submit" cssClass="btn-action btn-action--primary"
      [disabled]="!form.get('email').valid" (click)="onClick(eventType.EMAIL)" [title]="'Submit' | translate">
    </gor-base-button>
  </div>

  <div *ngIf="!!message.text" class="button-container">
    <gor-base-button cssClass="btn-action btn-action--primary" (click)="onClick(eventType.NAV_LOGIN)"
      [title]="'Return to login screen' | translate">
    </gor-base-button>
  </div>
</form>
