import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HeaderIconItem } from './model';

@Component({
  selector: 'gor-header-icons',
  templateUrl: './header-icons.component.html',
  styleUrls: ['./header-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderIconsComponent {
  @Input() public items: HeaderIconItem[] = [];
  @Input() public border = false;
  @Input() public size = '';
  @Input() public imgSize = '';

  @Output() public reduce = new EventEmitter<{ type: string, data: any }>();

  private lastEventIsMouseEnter: { [id: string]: boolean } = {};

  public trackByFn = (i, it) => it.id;

  public mouseEvent(type: string, data: any) {
    const currentEventIsMouseEnter = type === 'MouseEnterIcon';
    if (this.lastEventIsMouseEnter[data.id] === currentEventIsMouseEnter) {
      return;
    }

    if (!!this.lastEventIsMouseEnter[data.id]) {
      this.emitMouseMovement(type, data);
    } else {
      this.emitEvent(type, data);
    }
    this.lastEventIsMouseEnter[data.id] = currentEventIsMouseEnter;
  }

  private emitMouseMovement(type: string, data: any) {
    this.emitEvent(`${type}Start`, data);
    timer(500)
      .pipe(filter(() => !this.lastEventIsMouseEnter[data.id]))
      .subscribe(() => this.emitEvent(`${type}End`, data));
  }

  public emitEvent(type: string, data: any) {
    this.reduce.emit({ type, data });
  }
}
