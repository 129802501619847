<div id='progress-group' class="col-xs-12" *ngIf="simulation.length>0" [ngClass]="{'expanded':selected}">
  <div class="progress-group-cell" *ngFor="let cur of current">
    <div class="progress-group-header">
      <span class="progress-group-title">{{cur.title}}</span>
      <span class="progress-group-date">
        <!-- {{cur?.date}} -->
      </span>
    </div>
    <div class="progress-group-body">
      <progress-bar id="main-progress-bar" [waiting]="cur.waiting" [max]="cur.length" [progress]="cur.step" [filledBackground]="fillGradientStyle" nonFilledBackground="#d6d4d4"></progress-bar>
    </div>
  </div>

  <progress-group-expanded *ngIf="selected" [@progressGroupExpanded]="simulation.length>1" [progressData]="simulation" [fillGradientStyle]="fillGradientStyle"></progress-group-expanded>

  <div id="progress-more"
    *ngIf="simulation.length>1"
    [@progressGroup]="current.step < current.lenght">
    <show-more [moreText]="'more' | translate" [lessText]="'less' | translate" (clicked)="selected=$event"></show-more>
  </div>
</div>
