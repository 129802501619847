export class LogService {

  public static enabledReduxLog = !!localStorage.getItem('reduxLog');
  public static enabledLoginStatusLog = !!localStorage.getItem('loginStatusLog');
  public static enabledWsLog = !!localStorage.getItem('wsLog');

  public static reduxLog = !LogService.enabledReduxLog ? (...msgs) => {} : console.log;
  public static loginStatusLog = !LogService.enabledLoginStatusLog ? (...msgs) => {} : console.log;
  public static wsLog = !LogService.enabledWsLog ? (...msgs) => {} : console.log;

  public wsLog = LogService.wsLog;

  public construcotr() {}

}
