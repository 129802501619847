import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { Alert } from '../../model/alert';

@Component({
  selector: 'app-alert-group',
  templateUrl: './alert-group.component.html',
  styleUrls: ['./alert-group.component.scss']
})
export class AlertGroupComponent {
  @Input() public alerts: Array<Alert> = [];
  @Output() public remove = new EventEmitter();

  constructor() { }

  public removeItem(id: string) {
    this.remove.emit(id);
  }
}
