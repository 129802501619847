import { Route } from '@angular/router';
import { environment } from '@env/environment';

import { AdvisorFundGuard } from '../../../../guards/advisor-fund.guard';
import { DetailedPositionReportToolRoutePath } from './metadata';

export const DetailedPositionReportToolMenuData = {
  featureName: 'detailedPositionReport',
  routePath: DetailedPositionReportToolRoutePath,
  icon: 'Nav/Advanced-Tools/Report-White',
  text: 'TOOLS_DROPDOWN.DETAILED_POSITION_REPORT',
  dataTracking: 'Menu - Position Report',
};

export const DetailedPositionReportToolRoute: Route = {
  data: {
    title: 'Relatório de Posição Detalhada',
    hideFilter: true
  },
  path: DetailedPositionReportToolRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/generic-report#GenericReportModule'
};
