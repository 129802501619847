import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ToastService } from '@gorila-bot/toast';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { path } from 'ramda';
import { concat, from } from 'rxjs';
import { map, switchMap, publish, take, debounceTime, concatMap, catchError, filter } from 'rxjs/operators';

import {
  LoadedTransfers,
  LoadedTransfersError,
  LoadTransfers,
  RemovedTransfer,
  TransferActions,
  TransferActionTypes,
} from '../actions/transfer.actions';
import { TransferRequestService } from '../services/transfer-request.service';
import { selectEventById } from '../transfer.selectors';
import { ITransferResponseItem, ITransferResponse } from '../transfer.state';

@Injectable()
export class TransferEffects {

  @Effect()
  public loadTransfers$ = this.actions$.pipe(
    ofType(TransferActionTypes.LoadTransfers),
    // emit first value imediatally and debounce all others
    publish((observable$) => concat(
      observable$.pipe(take(1)),
      observable$.pipe(debounceTime(environment.features.portfolio.updateDebounceTime))
    )),
    switchMap((action: LoadTransfers) => this.service.doRequest(action.requestData).pipe(
      map((data: ITransferResponse) => {
        if (path(['error'], data)) {
          this.toastService.addRaw(
            'warning',
            this.translate.instant('TOAST.TRANSFER_HTTP_ERROR.SUMMARY'),
            { detail: this.translate.instant('TOAST.TRANSFER_HTTP_ERROR.DETAIL') }
          );
          return new LoadedTransfersError(path(['error'], data));
        }
        return new LoadedTransfers(data as ITransferResponseItem, action.requestData);
      })
    )),
  );

  @Effect()
  public removeTransfer$ = this.actions$.pipe(
    ofType(TransferActionTypes.RemoveTransfer),
    switchMap(({ id }) =>
      this.store$.pipe(
        select(selectEventById(id)),
        filter(transfer => !!transfer),
        concatMap(({ walletId }) =>
          this.service.deleteTransfer(id, walletId).pipe(
            map(() => new RemovedTransfer(id)),
            catchError((err) => {
              console.error(err);
              this.toastService.addRaw(
                'error',
                this.translate.instant('TOAST.TRANSFER_DELETE_HTTP_ERROR.SUMMARY'),
                { detail: this.translate.instant('TOAST.TRANSFER_DELETE_HTTP_ERROR.DETAIL') }
              );
              return from([null]);
            })
          )
        ),
      )
    )
  );

  constructor(
    private actions$: Actions<TransferActions>,
    private store$: Store<any>,
    private service: TransferRequestService,
    private translate: TranslateService,
    private toastService: ToastService
  ) {}

}
