import { IOperation } from '@gorila-bot/gorila-front-models';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { path, pick } from 'ramda';

import { initialState, Key, TradeState } from './trade.state';

export const selectFeature = state => state[Key];
export const selectTradeList = createSelector(
  selectFeature,
  (state: TradeState) => state && state.entities || initialState.entities
);
export const selectOneTrade = (tradeId) => createSelector(
  selectFeature,
  (state: TradeState): IOperation => path(['entities', tradeId], state)
);
export const selectFeatureLoading = createSelector(
  selectFeature,
  (state: TradeState) => state && state.loading || initialState.loading
);
export const selectTradesStatus = createSelector(
  selectFeature,
  (state: TradeState) => state && { error: state.error || initialState.error, loading: state.loading || initialState.loading }
);
export const selectTradesByIdList = idList => createSelector(
  selectFeature,
  (state: TradeState): {[s: string]: IOperation} => pick(idList, path(['entities'], state) || initialState.entities)
);
export const selectTradesCount = createSelector(
  selectTradeList,
  (state: Dictionary<IOperation>): number => Object.keys(state || {}).length
);
export const selectTradesIds = createSelector(
  selectFeature,
  (state: TradeState): string[] => path(['ids'], state) || []
);
export const selectCostsByTradeIdList = (tradeIds) => createSelector(
  selectFeature,
  (state: TradeState): { [parentOperationId: string]: IOperation[] } => pick(tradeIds, (state || initialState).costs)
);
