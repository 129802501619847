import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@gorila/core/module';
import { SharedModule } from '@gorila/shared/module';
import { SocketModule } from '@gorila/socket/module';
import { DirectivesModule } from '@gorilainvest/ui-toolkit/directives';

import { BookerService } from './services/booker.service';
import { TradeFormatterService } from './services/trade-formatter.service';
import { TradeService } from './services/trade.service';

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule, SocketModule, DirectivesModule],
  providers: [
    DatePipe,
    BookerService,
    TradeFormatterService,
    TradeService
  ]
})
export class TradeModule {}
