/*
 * For more info, check https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims#custom-claims
 * If you have access, check Auth0 rules.
 */
const NAMESPACE = 'https://gorila.com.br';

/**
 * Extract app_metadata from a decoded JWT token.
 *
 * @param {*} decodedToken
 * @return {object} a JS object representing the app_metadata, or an empty object if not found
 */
export const getAppMetadata = (decodedToken) => {
  return decodedToken.app_metadata || decodedToken[`${NAMESPACE}/app_metadata`] || {};
};

/**
 * Extract user_metadata from a decoded JWT token.
 *
 * @param {*} decodedToken
 * @return {object} a JS object representing the user_metadata, or an empty object if not found
 */
export const getUserMetadata = (decodedToken) => {
  return decodedToken.user_metadata || decodedToken[`${NAMESPACE}/user_metadata`] || {};
};
