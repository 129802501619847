import { STATE_TYPES } from '../../../store.model';
import { or } from 'ramda';
import { ProfitabilityAPIAction } from '../actions/profitability-base.actions';
import { ProfitabilityPnlActions } from '../actions/profitability-pnl.actions';
import { ProfitabilityGetKey } from '../profitability.model';
import { ReducerBase, MakeState } from '../../../base/reducer.base';
import { updateGroup, clearProfitability } from './profitability.reducer';

export const addPnl = (state: any, a: ProfitabilityAPIAction, extra) => ({ ...state });

export const loadedPnl = (state: any, a: ProfitabilityAPIAction, extra) => {
  const items = {};
  a.meta.profitabilityType.map(data => {
    const k = data.periodType;
    if (!items[extra.key]) { items[extra.key] = {}; }
    items[extra.key][k] = a.payload[k];
  });
  items['current'] = items[extra.key];
  return MakeState({ items: items });
};

export const beforeEachProfitability = (state: any, a: ProfitabilityAPIAction) => ({
  key: ProfitabilityGetKey(a.meta.profitabilityType[0]),
  items: or(state['items'], {})
});

export const checkState = (state: any, a: ProfitabilityAPIAction) => {
  if ([
    ProfitabilityPnlActions.actions.addProfitability,
    ProfitabilityPnlActions.actions.loadedProfitability,
    ProfitabilityPnlActions.actions.addMultipleProfitability,
    ProfitabilityPnlActions.actions.loadedMultiProfitability
  ].indexOf(a.type.toString()) !== -1) { return false; }
  if (!a.meta || !a.meta.profitabilityType) { return false; }
  return a.meta.profitabilityType instanceof Array;
};

export const profitabilityPnlReducer = (state: any = {}, a: ProfitabilityAPIAction) => {
  return ReducerBase({
    [ProfitabilityPnlActions.actions.addPnl]: addPnl,
    [ProfitabilityPnlActions.actions.loadedPnl]: loadedPnl,
    [ProfitabilityPnlActions.actions.updateGroup]: updateGroup,
    [ProfitabilityPnlActions.actions.clear]: clearProfitability
  }, state, a, STATE_TYPES.PROFITABILITY_PNL, {
      beforeEach: beforeEachProfitability,
      checkState: checkState
    });
};
