/* tslint:disable radix */

/* Angular modules */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  private item = Array;
  public transform(array: any[], filter: any, caseInsensitive: boolean): any {
    if (filter == null) { return array; }
    if (typeof array !== 'object') { return array; }
    const resultArray = new Array();
    for (const item of array) {
      this.item = item;
      if (this.needAdd(filter) === true) { resultArray.push(item); }
    }

    return resultArray;
  }

  private arrayFilter(filter: any) {
    for (const i in filter) {
      if (!filter.hasOwnProperty(i)) { continue; }

      if (false === this.stringFilter(filter[i])) {
        return false;
      }
    }
    return true;
  }

  private needAdd(filter: any) {
    if (typeof filter === 'string') {
      if (filter === '') { return true; }
      return this.stringFilter(filter);
    }
    return this.arrayFilter(filter);
  }

  private stringFilter(filter: string) {
    for (const i in this.item) {
      if (this.item[i] === filter) {
        return true;
      }
    }
    return false;
  }
}
