import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocketEventService } from '@gorila-bot/gorila-base';
import { SocketService } from '@gorila-bot/gorila-base';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    SocketService,
    SocketEventService
  ]
})
export class SocketModule { }
