import { Injectable, Inject, OnDestroy } from '@angular/core';
import { ApiService } from '@gorila-bot/gorila-base';
import { SERVICE_BASE_URL } from '@gorila-bot/gorila-base2';
import { Store, select } from '@ngrx/store';
import { UserDataSelectors } from '@gorila-bot/user-data-store';
import { distinctUntilChanged } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { IListOperationParams } from '../models/operation.model';
import { FundIdType } from '@gorila-bot/gorila-front-models';

@Injectable()
export class OperationTradeService implements OnDestroy {
  private fundId: FundIdType;

  constructor(
    private apiService: ApiService,
    private store$: Store<any>,
    @Inject(SERVICE_BASE_URL) private readonly baseUrl: string
  ) {
    this.init();
  }

  public ngOnDestroy() {}

  public getOperations(options: IListOperationParams, force?: boolean) {
    const params = `?fundId=${this.fundId}`
      + (!!options.minDate ? `&minDate=${options.minDate}` : '')
      + (!!options.maxDate ? `&maxDate=${options.maxDate}` : '')
      + (!!options.productType ? `&productType=${options.productType}` : '')
      + (!!options.securitySymbol ? `&securitySymbol=${options.securitySymbol}` : '')
      + (!!options.broker ? `&broker=${options.broker}` : '');
    const url = `${this.baseUrl}operations/trades${params}`;
    return this.apiService.getData(url, {}, false, null, force);
  }

  public deleteTrade(id: string, walletId: number) {
    const url = this.baseUrl + 'operations/' + id + '?walletId=' + walletId;
    return this.apiService.dropData(url);
  }

  private init() {
    this.store$.pipe(
      untilDestroyed(this),
      select(UserDataSelectors.selectFeatureCurrentFundId),
      distinctUntilChanged(),
    ).subscribe((fundId: FundIdType) => this.fundId = fundId);
  }
}
