import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';

@Component({
  selector: 'gor-blotter-button',
  templateUrl: './blotter-button.component.html',
  styleUrls: ['./blotter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlotterButtonComponent {
  public registerTradeEvent() {
    AnalyticsService.setEvent('startTrade', {});
  }
}
