import { AdvancedProfitabilityMenuData, AdvancedProfitabilityRoute } from './advanced-profitability';
import { CashFlowMenuData, CashFlowRoute } from './cash-flow';
import { DARFMenuData, DARFRoute } from './DARF';
import { GenericAssetMenuData, GenericAssetRoute } from './generic-asset';
import { PortfolioEventToolMenuData, PortfolioEventToolRoute } from './portfolio-events';
import { ReportToolMenuData, ReportToolRoute } from './report';
import { DetailedPositionReportToolRoute, DetailedPositionReportToolMenuData } from './reports';
import { TradeToolMenuData, TradeToolRoute } from './trade-tool';
import { ImportFilesMenuData, ImportFilesRoute } from './import-files';
import { ResearchHousesEventToolMenuData, ResearchHousesEventToolRoute } from './research-houses';

export const ToolsRoutes = [
  AdvancedProfitabilityRoute,
  CashFlowRoute,
  DARFRoute,
  GenericAssetRoute,
  PortfolioEventToolRoute,
  TradeToolRoute,
  ReportToolRoute,
  DetailedPositionReportToolRoute,
  ImportFilesRoute,
  ResearchHousesEventToolRoute
];

export const ToolsMenuData = [
  AdvancedProfitabilityMenuData,
  PortfolioEventToolMenuData,
  TradeToolMenuData,
  ReportToolMenuData,
  CashFlowMenuData,
  GenericAssetMenuData,
  DARFMenuData,
  DetailedPositionReportToolMenuData,
  ImportFilesMenuData,
  ResearchHousesEventToolMenuData
];
