import { Route } from '@angular/router';
import { HeaderButtons } from '@gorila/widgets/header/components/header-buttons/model';
import { HeaderMenuItems } from '@gorila/widgets/header/menu.items';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { OfficeToolRoutePath } from './metadata';

export const OfficeToolMenuData = {
  featureName: 'office',
  routePath: OfficeToolRoutePath,
  icon: 'Navigation/Main-Nav/Clients',
  text: 'TOOLS_DROPDOWN.OFFICE',
  dataTracking: 'Menu - Office Dashboard'
};

export const OfficeToolRoute: Route = {
  data: {
    title: 'Gestão de Escritórios',
    hideFilter: true,
    isB2BRoute: true,
    headerButtons: [HeaderButtons.ADD_PROFESSIONAL],
    headerIcons: [HeaderMenuItems.OFFICE, HeaderMenuItems.ADVISOR, HeaderMenuItems.HELP]
  },
  path: OfficeToolRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/office-dashboard#OfficeDashboardModule'
};
