import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES, StateType } from '../../store.model';
import { APIActions, clearAction, getReduxActionType, MetaData } from '../actions';
import { ActionTransition, ActionType } from '../actions.model';
import { Position } from './position.model';

const StateTypePosition = STATE_TYPES.POSITION;
const actionType = {
  map: {
    data: getReduxActionType(ActionType.MAP, StateTypePosition, ActionTransition.DATA)
  },
  unmap: {
    data: getReduxActionType(ActionType.UNMAP, StateTypePosition, ActionTransition.DATA)
  },
  load: {
    data: getReduxActionType(ActionType.LOAD, StateTypePosition, ActionTransition.DATA),
    start: getReduxActionType(ActionType.LOAD, StateTypePosition, ActionTransition.STARTED),
    success: getReduxActionType(ActionType.LOAD, StateTypePosition, ActionTransition.SUCCEEDED),
    fail: getReduxActionType(ActionType.LOAD, StateTypePosition, ActionTransition.FAILED)
  },
  update: {
    data: getReduxActionType(ActionType.UPDATE, StateTypePosition, ActionTransition.DATA),
    start: getReduxActionType(ActionType.UPDATE, StateTypePosition, ActionTransition.STARTED),
    success: getReduxActionType(ActionType.UPDATE, StateTypePosition, ActionTransition.SUCCEEDED),
    fail: getReduxActionType(ActionType.UPDATE, StateTypePosition, ActionTransition.FAILED)
  },
  remove: {
    data: getReduxActionType(ActionType.REMOVE, StateTypePosition, ActionTransition.DATA),
    start: getReduxActionType(ActionType.REMOVE, StateTypePosition, ActionTransition.STARTED),
    success: getReduxActionType(ActionType.REMOVE, StateTypePosition, ActionTransition.SUCCEEDED),
    fail: getReduxActionType(ActionType.REMOVE, StateTypePosition, ActionTransition.FAILED)
  },
  clear: getReduxActionType(ActionType.CLEAR, StateTypePosition)
};

export interface PositionMetaData extends MetaData {
  profits: any;
}

export function loadSucceededWithProfitsAction(_actionType: ActionType, stateType: StateType,
  payload: Position[], profits: any): PositionLoadAPIAction {
  return {
    type: actionType.load.success,
    meta: { actionType: _actionType, stateType, profits },
    payload
  };
}

export function updateSucceededWithProfitsAction(_actionType: ActionType, stateType: StateType,
  payload: Position, profits: any): PositionUpdateAPIAction {
  return {
    type: actionType.update.success,
    meta: { actionType: _actionType, stateType, profits },
    payload
  };
}

export type PositionAPIAction = PositionLoadAPIAction | PositionRemoveAPIAction | PositionUpdateAPIAction;
export type PositionLoadAPIAction = FluxStandardAction<Position[], PositionMetaData>;
export type PositionRemoveAPIAction = FluxStandardAction<string, MetaData>;
export type PositionUpdateAPIAction = FluxStandardAction<Position, PositionMetaData>;

@Injectable()
export class PositionAPIActions extends APIActions<Position> {
  public static actionType = actionType;

  protected readonly type = StateTypePosition;

  public succeededWithProfits = loadSucceededWithProfitsAction;

  public updateSucceededWithProfits = updateSucceededWithProfitsAction;

  @dispatch()
  public clear = (stateType: StateType): FluxStandardAction<Position[], MetaData> => clearAction(StateTypePosition, stateType)
}
