import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantityPrice'
})
export class QuantityPricePipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {}
  public transform(trade: any, args?: any): any {
    const price = this.currencyPipe.transform(trade.price, 'BRL', 'symbol');
    return `${trade.quantity} a ${price}`;
  }

}
