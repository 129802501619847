import { PositionBehavioralState, PositionColumns } from '@gorila/pages/store/wallet';
import { Parity } from '@gorila/shared';

export interface ReportState {
  components: {
    position: PositionBehavioralState;
  };
}

export const INITIAL_STATE: ReportState = {
  components: {
    position: {
      expandable: Parity.NONE,
      selectable: Parity.NONE,
      showActions: false,
      columnsToShow: [
        PositionColumns.Quantity,
        PositionColumns.PriceToday,
        PositionColumns.Total
      ],
    }
  }
};
