import { UserPlansBase, SubscriptionPlanIDs } from '@gorila-bot/billing-store';
import * as Colors from '@gorilainvest/styles/js/Colors';

export class UserPlans extends UserPlansBase {
  private gorilaRibbonInitColor = Colors.default.GorilaSecondary;
  private gorilaRibbonEndColor = Colors.default.GorilaPrimary;

  public getPlans() {
    return [
      {
        planId: SubscriptionPlanIDs.FREE,
        cardClass: 'no-shadow',
        colorInit: Colors.default.White,
        colorEnd: Colors.default.White,
        coloredTitle: false,
        title: 'Free',
        accessMessage: 'free plan access message',
        price: 0,
        period: '',
        features: [
          {
            name: 'Wallet',
            enabled: true
          },
          {
            name: 'Wallet events',
            enabled: true
          },
          {
            name: 'Advanced profitability',
            enabled: true
          },
          {
            name: 'Trade list',
            enabled: true
          },
          {
            name: 'CEI connection',
            enabled: true,
          },
          {
            name: 'Mobile App',
            enabled: true,
          },
          {
            name: 'Report',
            enabled: false
          },
          {
            name: 'Connect account',
            enabled: false
          },
        ],
        currentUserPlan: true
      },
      {
        planId: SubscriptionPlanIDs.GORILA_PREMIUM_MONTLHY,
        cardClass: '',
        colorInit: this.gorilaRibbonInitColor,
        colorEnd: this.gorilaRibbonEndColor,
        coloredTitle: true,
        title: 'Monthly Premium',
        accessMessage: 'premium plan access message',
        price: 14.9,
        period: '/month',
        features: [
          {
            name: 'Wallet',
            enabled: true
          },
          {
            name: 'Wallet events',
            enabled: true
          },
          {
            name: 'Advanced profitability',
            enabled: true
          },
          {
            name: 'Trade list',
            enabled: true
          },
          {
            name: 'CEI connection',
            enabled: true,
          },
          {
            name: 'Mobile App',
            enabled: true,
          },
          {
            name: 'Report',
            enabled: true
          },
          {
            name: 'Connect account',
            enabled: true
          },
        ],
        currentUserPlan: false,
        buttonText: 'Evolve to Premium'
      },
      {
        planId: SubscriptionPlanIDs.GORILA_PREMIUM_YEARLY,
        cardClass: '',
        colorInit: this.gorilaRibbonInitColor,
        colorEnd: this.gorilaRibbonEndColor,
        coloredTitle: true,
        title: 'Annual Premium',
        accessMessage: 'premium plan access message',
        price: 154.8,
        period: '/year',
        features: [
          {
            name: 'Wallet',
            enabled: true
          },
          {
            name: 'Wallet events',
            enabled: true
          },
          {
            name: 'Advanced profitability',
            enabled: true
          },
          {
            name: 'Trade list',
            enabled: true
          },
          {
            name: 'CEI connection',
            enabled: true,
          },
          {
            name: 'Mobile App',
            enabled: true,
          },
          {
            name: 'Report',
            enabled: true
          },
          {
            name: 'Connect account',
            enabled: true
          },
        ],
        currentUserPlan: false,
        buttonText: 'Evolve to Premium'
      }
    ];
  }
}
