import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';

export const MOCKED_URL = new InjectionToken<string>('MockedUrl');

@Injectable()
export class MustMockResolverService {

  constructor(@Inject(MOCKED_URL) @Optional() private _modkedUrl: string = 'mock') {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from([state.url.indexOf(this._modkedUrl) !== -1]);
  }

}
