import { Route } from '@angular/router';
import { AppShellComponent } from '@gorila/pages/app-shell';

import { AuthGuard } from '../guards/auth.guard';
import { MobileGuard } from '../guards/mobile.guard';
import { PermissionGuard } from '../guards/permission.guard';
import { RouteData } from '../route-data';
import { BlotterRoute } from './blotter';
import { DashboardRoute } from './dashboard';
import { ManagerRoutes } from './manager/export-group';
import { ACCOUNT_SETTINGS_PATH, AppShellFallbackRoutePath, AppShellRoutePath } from './metadata';
import { MobileWarningRoute } from './mobile-warning';
import { ToolsRoutes } from './tools';
import { UserRoutes } from './user';
import { WalletRoute } from './wallet';

export const AppShellRouteData: RouteData = {
  permissions: [ 'ADVISOR', 'BROKERCLIENT', 'CLIENT' ],
  title: 'Gorila'
};

export const AppShellRoute: Route = {
  component: AppShellComponent,
  data: AppShellRouteData,
  path: AppShellRoutePath,
  canActivate: [MobileGuard],
  canActivateChild: [AuthGuard, PermissionGuard],
  children: [
    ...ToolsRoutes,
    ...UserRoutes,
    ...ManagerRoutes,
    BlotterRoute,
    DashboardRoute,
    WalletRoute,
    MobileWarningRoute,
    {
      path: ACCOUNT_SETTINGS_PATH,
      loadChildren: '@gorila-bot/user-details-shell#UserDetailsFeatureShellModule',
      data: {
        hideFilter: true
      },
    },
    { path: '',   redirectTo: AppShellFallbackRoutePath, pathMatch: 'full' },
    { path: '**',   redirectTo: AppShellFallbackRoutePath, pathMatch: 'full' }
  ]
};
