import { IProfitabilityType } from '@gorila-bot/gorila-front-models';
import { AppConstants } from '@gorila/constants';
import * as moment from 'moment';

export function getId(_id: any): string {
  try {
    if (typeof _id['date'] !== 'undefined') {
      return moment.utc(_id['date']).format(AppConstants.Format.Date.American);
    }

    if (typeof _id['month'] !== 'undefined') {
      const month = getMonth(_id['month']);
      return `${_id['year']}-${month}`;
    }

    if (typeof _id['year'] !== 'undefined') {
      return _id['year'];
    }
  } catch (e) {
    console.warn(e);
  }
  return null;
}

function getMonth(month) {
  return (month < 10) ? `0${month}` : month;
}
export function transformProfit(profit) {
  try {
    const n = parseFloat(profit) * 100;
    return parseFloat(n.toFixed(2));
  } catch (e) {
    console.warn(e);
    return 0.00;
  }
}

export function ProfitabilityGetKey(profitabilityType: IProfitabilityType): string {
  let key = '';
  if (!profitabilityType) {
    return key;
  } else if (profitabilityType.id) {
    key = profitabilityType.id;
  } else if (profitabilityType.assetClass) {
    key = profitabilityType.assetClass;
  } else if (profitabilityType.productType) {
    key = profitabilityType.productType;
  } else if (profitabilityType.securityName) {
    key = profitabilityType.securityName;
  } else if (profitabilityType.brokerName) {
    key = profitabilityType.brokerName;
  }
  if (!key) { return ''; }
  key = key.replace(/g /, '_');
  return key;
}
