import { Component, Input } from '@angular/core';

export const ratio = 3.75;

@Component({
  selector: 'icon-message',
  templateUrl: './icon-message.component.html',
  styleUrls: ['./icon-message.component.scss']
})
export class IconMessageComponent {
  public _size = 30;
  public circleSize = 8;
  @Input('size') public set size (size: number) {
    this._size = (isNaN(size) || size < 1) ? 30 : size;
    this.circleSize = this._size / ratio;
  }
  @Input() public message: string;
  @Input() public hide: boolean;
  @Input() public icon: string;
  @Input() public centered = true;
}
