import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { clearState } from '@gorila/root-store/logout/logout.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PortfolioEffects } from './effects/portfolio.effects';
import * as fromUserData from './reducers/portfolio.reducer';
import { Key } from './portfolio.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(Key, fromUserData.reducer, { metaReducers: [clearState] }),
    EffectsModule.forFeature([PortfolioEffects])
  ]
})
export class UserPortfolioModule { }
