import { utc } from 'moment';
import { pipe } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

class LastDateClass {
    public static lastDate: string;
}

class LastStartEndDateClass {
    public static lastStartDate: string;
    public static lastEndDate: string;
}

/**
 * Filters same endDate emitted by GDP, also skip first occurrence.
 *
 * NOTE: use // @ts-ignore directive to avoid compilation erros!
 *
 * DO NOT:
 * ```
 * this.store$.pipe(
 *    select(UserDataSelectors.selectAdvisorMode),
 *    take(1),
 *    concatMap(isB2B => this.store$.pipe(
 *      select(FilterSelectors.getOneFilter('datePicker', isB2B)),
 *      untilDestroyed(this)
 *    )),
 *    filterEndDate(WalletComponent)
 *  )
 * ```
 *
 * DO:
 * ```
 * this.store$.pipe(
 *    select(UserDataSelectors.selectAdvisorMode),
 *    take(1),
 *    concatMap(isB2B => this.store$.pipe(
 *      select(FilterSelectors.getOneFilter('datePicker', isB2B)),
 *      untilDestroyed(this)
 *    )),
 *    // @ts-ignore
 *    filterEndDate(WalletComponent)
 *  )
 * ```
 *
 * @param STATIC_CLASS a class definition with public static member of type string called *lastDate*
 */
export function filterEndDate(STATIC_CLASS: typeof LastDateClass) {
    return pipe(
        filter(({ endDate }) => {
            if (utc(STATIC_CLASS.lastDate).isSame(endDate, 'day')) {
                return false;
            }
            return STATIC_CLASS.lastDate = endDate;
        }),
        skip(1)
    );
}

/**
 * Filters same start and end date emitted by GDP, also skip first occurrence.
 *
 * NOTE: use // @ts-ignore directive to avoid compilation erros!
 *
 * DO NOT:
 * ```
 * this.store$.pipe(
 *    select(UserDataSelectors.selectAdvisorMode),
 *    take(1),
 *    concatMap(isB2B => this.store$.pipe(
 *      select(FilterSelectors.getOneFilter('datePicker', isB2B)),
 *      untilDestroyed(this)
 *    )),
 *    filterStartEndDate(PortfolioEventsContainerComponent)
 *  )
 * ```
 *
 * DO:
 * ```
 * this.store$.pipe(
 *    select(UserDataSelectors.selectAdvisorMode),
 *    take(1),
 *    concatMap(isB2B => this.store$.pipe(
 *      select(FilterSelectors.getOneFilter('datePicker', isB2B)),
 *      untilDestroyed(this)
 *    )),
 *    // @ts-ignore
 *    filterStartEndDate(PortfolioEventsContainerComponent)
 *  )
 * ```
 *
 * @param STATIC_CLASS a class definition with public static member of type string called *lastStartDate* and *lastEndDate*
 */

export function filterStartEndDate(STATIC_CLASS: typeof LastStartEndDateClass) {
    return pipe(
        filter(({ startDate, endDate }) => {
            if (!startDate || !endDate) {
                return false;
            }
            const isSameStart = utc(STATIC_CLASS.lastStartDate).isSame(startDate, 'day');
            const isSameEnd = utc(STATIC_CLASS.lastEndDate).isSame(endDate, 'day');

            if (isSameStart && isSameEnd) {
                return false;
            }

            STATIC_CLASS.lastStartDate = startDate;
            STATIC_CLASS.lastEndDate = endDate;

            return true;
        })
    );
}
