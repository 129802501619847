import { IBenchmark, IBenchmarkType } from '@gorila-bot/gorila-front-models';

import { getId, transformProfit } from '../profitability/profitability.model';

export const mapBenchmark = (benchmark: IBenchmark): IBenchmark => {
  try {
    return {
      id: BenchmarkGetId(benchmark),
      profit: transformProfit(benchmark.profit),
      profitFactor: benchmark.profitFactor
    };
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export function BenchmarkGetType(benchmarkType: IBenchmarkType): string {
  return benchmarkType.benchmarkType;
}

export function BenchmarkGetKey(benchmarkType: IBenchmarkType): string {
  return benchmarkType.benchmarkName.replace(/g /, '_');
}

export function BenchmarkGetId(benchmark: IBenchmark): string {
  return getId(benchmark['_id']);
}
