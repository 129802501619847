export const ProductTypeColumns = {
  ProfitDaily: 'ProfitDaily',
  ProfitMonthly: 'ProfitMonthly',
  ProfitPeriod: 'ProfitPeriod',
  Total: 'Total'
};

export interface ProductTypeBehavioralState {
  columnsToShow?: string[];
  expandable: number;
  selectable: number;
  waitAnimationOnClose: number;
}
