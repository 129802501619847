import { LogService } from '@gorila/shared/services/log.service';
import { findIndex, forEachObjIndexed, indexBy, insert, length, prop, propEq, update } from 'ramda';
import { Action } from 'redux';

import { STATE_TYPES } from '../../store.model';
import { TypeReducer } from '../../store.utils';
import { ActionTransition, actionTransitionNameFor, ActionType, actionTypeNameFor } from '../actions.model';
import { SubProductAPIAction, SubProductAPIActions } from './subproduct.actions';
import { composeSubProductIdFromPosition, insertPositionOnSubProduct, mapPositionToSubProduct } from './subproduct.model';

export function subproductReducer(state = {}, a: Action) {
  try {
    const action = a as SubProductAPIAction;
    if (!action.meta || action.meta.stateType !== STATE_TYPES.SUBPRODUCT) {
      return state;
    }

    const caseAction = TypeReducer(actionTypeNameFor(action.meta.actionType), STATE_TYPES.SUBPRODUCT);
    if (action.meta.actionType === ActionType.CLEAR) {
      return {
        ...state,
        items: {}
      };
    } else if (action.meta.actionType === ActionType.MAP) {
      const positions = action.payload;
      let subProducts = [];
      const totalValue = {};
      const mapPositionToSubProducts = (position, positionIndex) => {
        const key = composeSubProductIdFromPosition(position);
        const subProductIndex = findIndex(propEq('id', key))(subProducts);
        let subProduct = subProducts[subProductIndex];

        if (!subProduct) {
          subProduct = mapPositionToSubProduct(position);
          subProducts = insert(length(subProducts), subProduct, subProducts);
        } else {
          subProduct = insertPositionOnSubProduct(position, subProduct);
          subProducts = update(subProductIndex, subProduct, subProducts);
        }

        if (totalValue[subProduct.assetClass.id]) {
          totalValue[subProduct.assetClass.id] += position.value;
        } else {
          totalValue[subProduct.assetClass.id] = position.value;
        }
      };
      forEachObjIndexed(mapPositionToSubProducts, positions);
      subProducts = subProducts.map(subProduct => {
        subProduct.percentual = subProduct.value / totalValue[subProduct.assetClass.id];
        return subProduct;
      });
      const byPercentual = (subProd1, subProd2) => subProd2.percentual - subProd1.percentual;
      return {
        ...state,
        items: indexBy(prop('id'), subProducts.sort(byPercentual))
      };
    }
    switch (action.type) {
      case caseAction(actionTransitionNameFor(ActionTransition.STARTED)):
        return {
          ...state,
          items: {},
          loading: true,
          error: null,
        };
      case caseAction(actionTransitionNameFor(ActionTransition.SUCCEEDED)):
        return {
          ...state,
          items: indexBy(prop('id'), action.payload),
          loading: false,
          error: null,
        };
      case caseAction(actionTransitionNameFor(ActionTransition.FAILED)):
        return {
          ...state,
          items: {},
          loading: false,
          error: action.error,
        };
      case caseAction(SubProductAPIActions.CLEAR):
        return {
          ...state,
          items: {},
          loading: false,
          error: action.error,
        };
    }
    if (action.meta.stateType === STATE_TYPES.SUBPRODUCT) {
      LogService.reduxLog(`@@@ NOT FILTERED STATE ${STATE_TYPES.SUBPRODUCT}`, action);
    }
  } catch (e) { console.warn(e); }
  return state;
}
