import { Route } from '@angular/router';

import { ResearchHousesGuard } from '../../../guards/research-houses.guard';
import { ResearchHousesEventToolRoutePath } from './metadata';

export const ResearchHousesEventToolMenuData = {
  featureName: 'researchHouses',
  routePath: ResearchHousesEventToolRoutePath,
  icon: 'Navigation/Sub-Nav/Research-Houses',
  text: 'TOOLS_DROPDOWN.RESEARCH_HOUSES',
  dataTracking: 'Menu - Research Houses'
};

export const ResearchHousesEventToolRoute: Route = {
  data: {
    title: 'Casa de research'
  },
  canLoad: [ResearchHousesGuard],
  path: ResearchHousesEventToolRoutePath,
  loadChildren: './pages/tools/research-houses/research-houses.module#ResearchHousesModule'
};
