import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { TradeToolRoutePath } from './metadata';
import { environment } from '@env/environment';

export const TradeToolMenuData = {
  featureName: 'tradeTool',
  routePath: TradeToolRoutePath,
  icon: 'Nav/Advanced-Tools/Transactions-List-White',
  text: 'TOOLS_DROPDOWN.TRADE_LIST',
  cancelNavigation: environment.features.tools.tradeTool.modal
};

export const TradeToolRoute: Route = {
  data: {
    title: 'Transações'
  },
  path: TradeToolRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: './pages/tools/trade-tool/trade-tool.module#TradeToolModule'
};
