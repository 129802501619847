import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderComponentEvent } from '../events.model';

import { HeaderDropdownItem, HeaderDropdownInput } from './model';

@Component({
  selector: 'gor-header-dropdown',
  templateUrl: './header-dropdown.component.html',
  styleUrls: ['./header-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderDropdownComponent {
  private _items: HeaderDropdownItem[] = [];
  @Input() public set items(inputs: HeaderDropdownInput[]) {
    this.buildItems(inputs);
  }
  public get items() {
    return this._items;
  }

  private _activeItem: string;
  @Input() public set activeItem (item: string) {
    this._activeItem = item;
    this.buildItems(this._items);
  }
  public get activeItem() {
    return this._activeItem;
  }

  @Output() public reduce = new EventEmitter<HeaderComponentEvent>();

  public handleMouseEvent(enter: boolean) {
    this.reduce.emit({ type: 'HeaderDropdownEvent', data: { enter } });
  }

  private buildItems(inputs: HeaderDropdownInput[]) {
    const items: HeaderDropdownItem[] = [];
    inputs.forEach(input => {
      const active = this.isActive(input);
      const tooltip = this.getTooltipFromInput(input);
      items.push({
        ...input,
        active,
        tooltip
      });
    });
    this._items = items;
  }

  private isActive(input: HeaderDropdownInput) {
    if (!input.link || !this._activeItem) {
      return false;
    }
    return input.link.endsWith(this._activeItem);
  }

  private getTooltipFromInput(input: HeaderDropdownInput) {
    return !input.link && !input.hideTooltip ? 'coming soon' : '';
  }
}
