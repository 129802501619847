<div class="grid-wrapper">
  <div class="left-image" [ngClass]="{'white-label':isWhiteLabel}"></div>
  <div class="header" *ngIf="!isWhiteLabel&&!isConnect">
    <div [ngSwitch]="pageRoute">
      <ng-container *ngSwitchCase="pathLogin">
        {{ 'Don\'t have a Gorila account? ' | translate }}
        <span class="signup" (click)="onFormEvent(eventType.NAV_SIGNUP)">{{ 'Create account' | translate }}</span>
      </ng-container>
      <!-- GorilaPRO signup shouldn't show any header -->
      <ng-container *ngSwitchCase="pathSignupPRO">
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'Already have a Gorila account? ' | translate }}
        <span class="login" (click)="onFormEvent(eventType.NAV_LOGIN)">{{ 'Sign In' | translate }}</span>
      </ng-container>
    </div>
  </div>

  <div class="form-container" [ngSwitch]="pageRoute" [ngClass]="{ connect: pathConnect==pageRoute }">
    <p *ngIf="isWhiteLabel" class="form-text">{{ formText | whiteLabel }}</p>
    <p *ngIf="!isWhiteLabel" class="form-text">{{ formText | translate:formTextParams }}</p>
    <p *ngIf="isLogin" class="form-hint">{{ formHint | translate }}</p>
    <ng-container *ngSwitchCase="pathConnect">
      <ng-container *ngTemplateOutlet="connectTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="pathSignup">
      <ng-container *ngTemplateOutlet="signupForm"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="pathSignupPRO">
      <ng-container *ngTemplateOutlet="signupForm"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="pathResetPassword">
      <ng-container *ngTemplateOutlet="resetPasswordForm"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="pathForgotPassword">
      <ng-container *ngTemplateOutlet="emailForm"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="pathEmail">
      <ng-container *ngTemplateOutlet="emailForm"></ng-container>
    </ng-container>
    <login-form *ngSwitchDefault [message]="message" [hideSocial]="hideSocial"
      [waitingAuthResponse]="waitingResponse$ | async" (loginEvent)="onFormEvent($event.type, $event.data)">
    </login-form>
  </div>
</div>

<ng-template #signupForm>
  <p class="form-hint">{{ formHintCreateAccount | translate }}</p>
  <signup-form [hideSocial]="hideSocial" [message]="message" [userType]="userType"
    [waitingAuthResponse]="waitingResponse$ | async" (signupSubmit)="onFormEvent(eventType.SIGNUP, $event)"
    (socialSignup)="onFormEvent($event === 'FACEBOOK' ? eventType.FACEBOOK : eventType.APPLE)"></signup-form>
</ng-template>

<ng-template #resetPasswordForm>
  <p class="form-hint">{{ formHintForgotPassword | translate }} <br /> {{ formMessageEmail }}</p>
  <reset-password-form
    (resetEvent)="onFormEvent($event.type, $event.data)"
    [waitingAuthResponse]="waitingResponse$ | async"
    [userEmail]="formMessageEmail"
    [userCode]="formResetCode"
    [message]="message"
    ></reset-password-form>
</ng-template>

<ng-template #emailForm>
  <p class="form-text">{{ formSubtitle | translate }}</p>
  <p class="form-hint">{{ formHintForgotPassword | translate }}</p>
  <email-form [message]="message" [isResetPassword]="pageRoute === pathResetPassword"
    (emailEvent)="onFormEvent($event.type, $event.data)"></email-form>
  <p class="form-facebook-message">{{ formMessageFacebook | translate }}</p>
  <a href="https://guia.gorila.com.br/hc/pt-br/requests/new" target="_blank" class="form-support-message">{{
    formMessageSupport |
    translate }}</a>
</ng-template>

<ng-template #connectTemplate>
  <connect (triggerNav)="onFormEvent($event)"></connect>
</ng-template>
