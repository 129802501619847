import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES, StateType } from '../../store.model';
import { APIActions, clearAction, MetaData } from '../actions';
import { Portfolio } from './portfolio.model';

export type PortfolioAPIAction = FluxStandardAction<Portfolio[], MetaData>;

@Injectable()
export class PortfolioAPIActions extends APIActions<Portfolio> {
  protected readonly type = STATE_TYPES['PORTFOLIO'];

  @dispatch()
  public clear = (stateType: StateType): FluxStandardAction<Portfolio[], MetaData> => clearAction(STATE_TYPES.PORTFOLIO, stateType)
}
