import { combineReducers } from 'redux';
import { routerReducer } from '@angular-redux/router';
import {
  benchmarkDailyReducer,
  benchmarkMonthlyReducer,
  benchmarkYearlyReducer
} from './api/benchmark/reducers/benchmark.reducer';
import { benchmarkPnlReducer } from './api/benchmark/reducers/benchmark-pnl.reducer';
import { blotterReducer } from './api/blotter/blotter.reducer';
import { portfolioReducer } from './api/portfolio/portfolio.reducer';
import { positionReducer } from './api/position/position.reducer';
import { productReducer } from './api/product/product.reducer';
import {
  profitabilityDailyReducer,
  profitabilityMonthlyReducer,
  profitabilityYearlyReducer
} from './api/profitability/reducers/profitability.reducer';
import { profitabilityPnlReducer } from './api/profitability/reducers/profitability-pnl.reducer';
import { subproductReducer } from './api/subproduct/subproduct.reducer';
import { routesReducer } from './api/routes/routes.reducer';
import { tradeReducer } from './api/trade/trade.reducer';
import { userReducer } from './api/user/user.reducer';
import {
  AppShellRoutePath,
  AuthNoLockRoutePathUrl,
  LoginRoutePathUrl,
  LogoutRoutePathUrl
} from '@gorila/core/router';

// Define the global store shape by combining our application's
// reducers together into a given structure.

const appReducer = combineReducers({
  benchmark: combineReducers({
    daily: benchmarkDailyReducer,
    monthly: benchmarkMonthlyReducer,
    yearly: benchmarkYearlyReducer,
    pnl: benchmarkPnlReducer
  }),
  blotter: blotterReducer,
  portfolio: portfolioReducer,
  position: positionReducer,
  product: productReducer,
  profitability: combineReducers({
    daily: profitabilityDailyReducer,
    monthly: profitabilityMonthlyReducer,
    yearly: profitabilityYearlyReducer,
    pnl: profitabilityPnlReducer
  }),
  routes: routesReducer,
  router: routerReducer,
  subproduct: subproductReducer,
  trade: tradeReducer,
  user: userReducer
});

export const rootReducer = (state, action) => {
  const goToLoginState = { router: AuthNoLockRoutePathUrl };
  if (action.type === 'USER_LOGOUT') {
    state = goToLoginState;
  } else if (action.type === '@angular-redux/router::UPDATE_LOCATION') {
    const previousRoute = state.router;
    const currentRoute = action.payload;

    if (!previousRoute && [LoginRoutePathUrl, LogoutRoutePathUrl].indexOf(action.payload) !== -1) {
      return goToLoginState;
    } else if (currentRoute === LoginRoutePathUrl && previousRoute !== AuthNoLockRoutePathUrl) {
      return goToLoginState;
    } else if (currentRoute === LogoutRoutePathUrl && previousRoute.indexOf(AppShellRoutePath) === -1) {
      return goToLoginState;
    }
  }
  return appReducer(state, action);
};
