import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Alert } from '../../model/alert';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss']
})
export class AlertContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public alertGroupName = '';
  @Input() public removeDuplicates = true;
  public alerts: Array<Alert> = [];

  constructor(private alertService: AlertService) {
  }

  public ngOnInit() {
    this.alertService.getObserver().pipe(untilDestroyed(this)).subscribe(
      (data: Array<Alert>) => this.alerts = this.getAlertsByGroup(data)
    );
  }

  public ngOnChanges(data: any) {
    if (typeof data['alertGroup'] !== 'undefined') {
      this.alerts = this.getAlertsByGroup(data);
    }
  }

  public ngOnDestroy() {}

  public removeItem(id: string) {
    this.alertService.removeAlert(id, this.removeDuplicates);
  }

  private getAlertsByGroup = (alerts: Array<Alert>): Array<Alert> => alerts.filter(alert => alert.group === this.alertGroupName);

}
