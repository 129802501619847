import {
  PositionBehavioralState,
  PositionColumns,
  AssetBehavioralState,
  ProductTypeBehavioralState,
  ProductTypeColumns,
  PortfolioBehavioralState
} from '@gorila/pages/store/wallet';
import { Parity } from '@gorila/shared';

export interface ProfitabilityState {
  components: {
    'asset': AssetBehavioralState;
    'portfolio': PortfolioBehavioralState;
    'position': PositionBehavioralState;
    'product-type': ProductTypeBehavioralState;
  };
}

export const INITIAL_STATE: ProfitabilityState = {
  components: {
    'asset': {
      expandable: Parity.MANY,
      selectable: Parity.ONE,
      animatePercentage: {}
    },
    'portfolio': {
      multiexpandable: true,
      multiselectable: false
    },
    'position': {
      expandable: Parity.NONE,
      columnsToShow: [
        PositionColumns.Broker,
        PositionColumns.Maturity,
        PositionColumns.Total
      ],
      selectable: Parity.ONE,
      showActions: false,
      descriptionLimit: 34

    },
    'product-type': {
      expandable: Parity.MANY,
      columnsToShow: [ ProductTypeColumns.Total ],
      selectable: Parity.ONE,
      waitAnimationOnClose: 250
    }
  }
};
