<gor-partner-header partnerLogoName="B3-Positive"
  [customTitleText]="'PARTNER.DISCONNECT.MODAL_TITLE'|translate" [showCloseButton]="true"></gor-partner-header>
<p class="disconnection__subtitle" translate>B3_PARTNER.DISCONNECTION.SUBTITLE</p>
<div class="disconnection__body">
  <ol class="disconnection__items">
    <!-- TODO Removing list-style none isn`t working -->
    <li *ngFor="let item of [1,2,3,4,5,6]" class="disconnection__item"
      [innerHtml]="item+'. '+(('B3_PARTNER.DISCONNECTION.LIST_ITEM_'+item)|translate)">
    </li>
  </ol>
  <div class="disconnection__footer-info">
    <gor-icon class="disconnection__item-icon" iconFolder="Platform/Information" icon="Information" width="15px" height="15px">
    </gor-icon>
    <div class="disconnection__footer-info-text">
    <p [innerHTML]="'B3_PARTNER.DISCONNECTION.TEXT_BOTTOM_1'|translate"></p>
    <p [innerHTML]="'B3_PARTNER.DISCONNECTION.TEXT_BOTTOM_2'|translate"></p>
    </div>
  </div>
</div>
<footer class="disconnection__footer" mat-dialog-actions>
  <a href="https://www.investidor.b3.com.br/" target="_blank">
    <gor-button btnType="green" mat-button>
      {{ 'B3_PARTNER.DISCONNECTION.BUTTON_TEXT' | translate }}
    </gor-button>
  </a>
</footer>