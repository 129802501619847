import { Injectable } from '@angular/core';
import { LogService } from '@gorila/shared/services/log.service';
import { BehaviorSubject ,  Observable } from 'rxjs';

import { LoginStatus } from './login-status';
import { LoginStatusEnum, LoginStatusString } from './login-status.enum';

@Injectable()
export class LoginStatusService {
  private static readonly defaultLoginData: LoginStatus = {
    code: LoginStatusEnum.Auth0NotLogged,
    previous: LoginStatusEnum.Auth0NotLogged,
    message: 'Auth0NotLogged',
    needLocker: 1
  };

  private _observer: BehaviorSubject<any> = null;
  private currentStatusCode = LoginStatusEnum.Auth0NotLogged;
  private messages = {};
  constructor() {
    this._observer = new BehaviorSubject(this.getDefaultValue());
    if (LogService.enabledLoginStatusLog) {
      this._observer.subscribe(data => LogService.loginStatusLog('$$$', {
        ...data,
        code: `${LoginStatusString[data.code]}(${data.code})`,
        previous: `${LoginStatusString[data.previous]}(${data.previous})`
      }));
    }
    this.init();
  }

  public updateStatus(code: LoginStatusEnum, options?: any, force?: boolean) {
    if (code === this.currentStatusCode && !force) { return; }
    const newStatus: LoginStatus = { code: code, previous: this.currentStatusCode, now: new Date().getTime() };

    newStatus['message'] = typeof this.messages[code] !== 'undefined' ? this.messages[code] : '';
    Object.keys(options || {}).map(option => newStatus[option] = options[option]);
    this.setCurrentValue(newStatus);
    this.currentStatusCode = code;
  }

  private getDefaultValue(): any {
    const loginStatusStorage = this.getLoginStatusFromStorage();
    const out = loginStatusStorage || LoginStatusService.defaultLoginData;
    if (out.code !== this.currentStatusCode) {
      this.setCurrentValue(out.code);
      this.currentStatusCode = out.code;
    }
    return out;
  }

  private isValidData(data: any): boolean {
    return LoginStatusEnum.Auth0NotLogged <= data['code'] && data['code'] <= LoginStatusEnum.UserLogoutStarted;
  }

  public getLoginStatusFromStorage(): LoginStatus {
    const temp = localStorage.getItem('login_observer');
    if (temp === '' || temp === null) {
      return null;
    }
    try {
      return JSON.parse(temp);
    } catch (e) {
      return null;
    }
  }

  protected init() {
    Object.keys(LoginStatusEnum).map(i => this.messages[LoginStatusEnum[i]] = i, this);
  }

  public getCurrentValue(): any {
    return this._observer.getValue();
  }

  public getObserver(): Observable<any> {
    return this._observer;
  }

  protected setCurrentValue(data: any) {
    if (!this.isValidData(data)) { return; }
    this._observer.next(data);
  }
}
