// tslint:disable forin
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Alert } from '../model/alert';
import { LoginStatusEnum } from '@gorila/core';
import { SubscriberMasterService } from '@gorila/core/utils';

@Injectable()
export class AlertService extends SubscriberMasterService {
  private subject: BehaviorSubject<Array<Alert>>;
  public alerts: Array<Alert> = [];
  private uniqueAlertsCounter: { [key: string]: number } = {};

  constructor() {
    super();
    this.subject = new BehaviorSubject(this.alerts);
  }

  protected init() { }

  public existsAlert(id: string): boolean {
    return this.alerts.some(alert => alert._id === id);
  }

  public addUniqueAlert(data: Alert) {
    const _id = data._id;
    if (typeof this.uniqueAlertsCounter[_id] === 'undefined') {
      this.uniqueAlertsCounter[_id] = 1;
    } else {
      this.uniqueAlertsCounter[_id] = this.uniqueAlertsCounter[_id] + 1;
    }
    if (this.existsAlert(_id)) { return; }
    this.addAlert(data);
  }

  public addAlert(data: Alert) {
    this.alerts.push(data);
    this.subject.next(this.alerts);
  }

  public removeAlert(id: string, removeDuplicates?: boolean): boolean {
    if (typeof this.uniqueAlertsCounter[id] !== 'undefined') {
      this.uniqueAlertsCounter[id] = (removeDuplicates) ? 0 : this.uniqueAlertsCounter[id] - 1;
      if (this.uniqueAlertsCounter[id] > 0) { return; }
      this.uniqueAlertsCounter[id] = undefined;
    }

    for (const i in this.alerts) {
      if (this.alerts[i]._id !== id) { continue; }
      this.alerts.splice(parseInt(i, 10), 1);
      this.subject.next(this.alerts);
      return true;
    }
    return false;
  }

  public removeAll(id: string): boolean {
    let removedSome = false;
    for (const i in this.alerts) {
      if (this.alerts[i]._id !== id) { continue; }
      this.alerts.splice(parseInt(i, 10), 1);
      this.subject.next(this.alerts);
      removedSome = true;
    }
    return removedSome;
  }

  public cleanup(loginStatusCode?: LoginStatusEnum) {
    this.alerts.splice(0, this.alerts.length);
    this.subject.next(this.alerts);
  }

  public getObserver(): BehaviorSubject<any> {
    return this.subject;
  }
}
