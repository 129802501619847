import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES } from '../../store.model';
import { MetaData } from '../actions';
import { ActionType } from '../actions.model';
import { Position, PositionType } from '../position';

export type BlotterPayloadType = Position;
export type BlotterRequestType = PositionType;
export type BlotterAPIAction = FluxStandardAction<any, MetaData>;
export type BlotterPositionSucceededAction = FluxStandardAction<BlotterPayloadType, MetaData>;

export const BlotterActionsData = {
  position: {
    data: 'LOAD_BLOTTER_POSITION_DATA',
    started: 'LOAD_BLOTTER_POSITION_STARTED',
    succeeded: 'LOAD_BLOTTER_POSITION_SUCCEEDED',
    failed: 'LOAD_BLOTTER_POSITION_FAILED'
  }
};

export const BlotterPositionDispatchData = (blotterRequestType: BlotterRequestType): BlotterAPIAction => ({
  type: BlotterActionsData.position.data,
  meta: { actionType: ActionType.LOAD, stateType: STATE_TYPES.BLOTTER },
  payload: blotterRequestType
});

export const BlotterPositionStarted = (): BlotterAPIAction => ({
  type: BlotterActionsData.position.started,
  meta: { actionType: ActionType.LOAD, stateType: STATE_TYPES.BLOTTER },
  payload: null
});

export const BlotterPositionSucceeded = (payload: BlotterPayloadType): BlotterPositionSucceededAction => ({
  type: BlotterActionsData.position.succeeded,
  meta: { actionType: ActionType.LOAD, stateType: STATE_TYPES.BLOTTER },
  payload
});

export const BlotterPositionFailed = (error: any): BlotterAPIAction => ({
  type: BlotterActionsData.position.failed,
  meta: { actionType: ActionType.LOAD, stateType: STATE_TYPES.BLOTTER },
  payload: null,
  error
});


@Injectable()
export class BlotterAPIActions {

  public static actions = BlotterActionsData;

  protected readonly type = STATE_TYPES['Blotter'];

  @dispatch()
  public dispatchData = BlotterPositionDispatchData;

  public started = BlotterPositionStarted;

  public succeeded = BlotterPositionSucceeded;

  public failed = BlotterPositionFailed;
}
