import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoginStatusEnum, LoginStatusService } from '../login-status';
import { UtilsService } from './utils.service';

@Injectable()
export abstract class SubscriberMasterService {
  protected subscriptions: { [s: string]: Subscription } = {};
  protected loginStatusService: LoginStatusService;
  constructor() {
    this.loginStatusService = UtilsService.injector.get(LoginStatusService);
    this.initLoginStatusObserver();
  }

  protected abstract cleanup(loginStatusCode?: LoginStatusEnum): void;
  protected abstract init(): void;

  protected clearSubscriptions() {
    try {
      /* tslint:disable-next-line */
      for (let i in this.subscriptions) {
        this._dettach(i);
      }
    } catch (e) { console.warn(e); }
  }


  protected clear(loginStatusCode?: LoginStatusEnum) {
    try {
      if (typeof this.cleanup !== 'undefined') {
        this.cleanup(loginStatusCode);
      }
      this.clearSubscriptions();
    } catch (e) { console.warn(e); }
  }

  public _attach(subscriptionName: string, obj: Subscription) {
    if (this.subscriptionExists(subscriptionName)) { return; }
    this.subscriptions[subscriptionName] = obj;
  }

  public _dettach(subscriptionName: string) {
    try {
      if (!this.subscriptionExists(subscriptionName)) { return; }
      this.subscriptions[subscriptionName].unsubscribe();
      this.subscriptions[subscriptionName] = null;
    } catch (e) { console.warn(e); }
  }

  private initLoginStatusObserver() {
    /* tslint:disable-next-line */
    var self = this;
    this.loginStatusService.getObserver().subscribe((status: LoginStatusService) => {
      try {
        status = self.loginStatusService.getCurrentValue();
        if (!status['code']) { return; }
        if (
          status['previous'] === LoginStatusEnum.UserLogged ||
          status['code'] === LoginStatusEnum.UserLogoutStarted
        ) {
          return self.clear(status['code']);
        }

        if (status['code'] !== LoginStatusEnum.UserLogged) { return; }

        if (typeof self.init !== 'undefined') {
          self.init();
        }
      } catch (e) {
        try {
          console.warn(e);
          self.clear();
        } catch (e) {
          console.warn(e);
        }
      }
    });
  }

  protected subscriptionExists(subscriptionName: string) {
    return (typeof this.subscriptions[subscriptionName] !== 'undefined' && this.subscriptions[subscriptionName] !== null);
  }
}
