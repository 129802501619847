import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserAccountModalsModule } from '@gorila/pages/user/user-account/modules/user-account-modals.module';
import { clearState } from '@gorila/root-store/logout/logout.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserAccountEffects } from './effects/user-account.effects';
import * as fromUserAccount from './reducers/user-account.reducer';
import { UserAccountEventListenerService } from './services/user-account-event-listener.service';
import { UserAccountRequestService } from './services/user-account-request.service';
import { Key } from './user-account.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(Key, fromUserAccount.reducer, { metaReducers: [clearState] }),
    EffectsModule.forFeature([UserAccountEffects]),
    UserAccountModalsModule
  ],
  providers: [ UserAccountEventListenerService, UserAccountRequestService ],
  declarations: []
})
export class UserAccountModule { }
