import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationStart, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { AppConstants } from '@gorila/constants';
import { LoginStatusEnum, LoginStatusService } from '@gorila/core';
import {
  AuthGorilaPROSignupRoutePath,
  AuthNoLockEmailRoutePath,
  AuthNoLockForgotPasswordRoutePath,
  AuthNoLockRoutePath,
  AuthNoLockSignupRoutePath,
  LoginRoutePath,
  AuthConnectRoutePath,
  MobileWarningRoutePath,
} from '@gorila/core/router';
import { WhiteLabelService } from '@gorila-bot/white-label';
import { SignupUserType } from '@gorila-bot/signup';
import { Store } from '@ngrx/store';
import * as auth0 from 'auth0-js';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { forEachObjIndexed } from 'ramda';
import { BehaviorSubject, timer } from 'rxjs';

import { URL_OAUTH_TOKEN_KEY, URL_STATE_KEY } from '../models/storage-keys.model';
import { AuthFormEvent, AuthUser } from '../models/authnolock.model';
import { AuthService } from '../services/auth.service';
import { Auth0Service } from '../services/auth0.service';
import { WhiteLabelPipe } from '@gorila-bot/white-label';
import { Platform } from '@angular/cdk/platform';
import { JwtHelperService } from '@auth0/angular-jwt';
import { getAppMetadata } from '@gorila/utils';


@Component({
  selector: 'auth0lock',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './auth0lock.component.html',
  styleUrls: ['./auth0lock.component.scss'],
  providers: [ WhiteLabelPipe, Auth0Service ]
})
export class Auth0LockComponent implements OnInit, OnDestroy {
  public readonly pathConnect = AuthConnectRoutePath;
  public readonly pathEmail = AuthNoLockEmailRoutePath;
  public readonly pathLogin = AuthNoLockRoutePath;
  public readonly pathSignup = AuthNoLockSignupRoutePath;
  public readonly pathSignupPRO = AuthGorilaPROSignupRoutePath;
  public readonly pathResetPassword = AuthNoLockForgotPasswordRoutePath;
  public readonly pathLoginRoutePath = LoginRoutePath;
  public readonly eventType = AuthFormEvent;
  public readonly homePage = AppConstants.HomePage;

  private isMobile: boolean;
  public selectedLockOption = '';
  public hideSocial = false;
  public isConnect = false;
  public isWhiteLabel = false;
  public formHint: string;
  public formText: string;
  public formTextParams = { broker: '' };
  public message = { text: '', error: false };
  public waitingResponse$ = new BehaviorSubject(false);
  private basePath: string;
  public pageRoute: string;
  public userType: SignupUserType;
  public formReady = 'login';
  private lockPathSignup = AuthNoLockSignupRoutePath;
  private subscriptionLockInputs;
  private subscriptionSelectedLock;
  // tslint:disable-next-line:max-line-length
  private regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public auth0 = new auth0.WebAuth({
    domain: environment.Auth0.domain,
    clientID: environment.Auth0.clientID,
    redirectUri: `${environment.Auth0.redirectUrl}entrando`,
    responseType: environment.Auth0.responseType,
    scope: environment.Auth0.scope,
    audience: 'https://gorilainvest.auth0.com/api/v2/'
  });

  private urlState: string;

  constructor(
    private loginStatusService: LoginStatusService,
    private authService: AuthService,
    private auth0Service: Auth0Service,
    private route: ActivatedRoute,
    private router: Router,
    protected store: Store<any>,
    private wlService: WhiteLabelService,
    private location: Location,
    public platform: Platform,
    private jwtHelper: JwtHelperService
  ) {
    this.isMobile = (this.platform.IOS || this.platform.ANDROID);
    router.events.pipe(untilDestroyed(this)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.onNavigationEnd(event.urlAfterRedirects.split('#')[0]);
      }
    });
  }
  public ngOnInit() {
    this.urlState = localStorage.getItem(URL_STATE_KEY);
    this.hideSocial =
      this.isWhiteLabel ||
      !!this.urlState ||
      this.pageRoute === this.pathSignupPRO;

    this.loginStatusService
      .getObserver()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const data = this.loginStatusService.getCurrentValue();
        if (
          [LoginStatusEnum.ConnectingWithServices].indexOf(data['code']) !== -1
        ) {
          this.redirectToLoadingRoute();
          return;
        }
      });
  }

  public onNavigationEnd(url: string) {
    const index = url.indexOf('?');
    const lastSepIndex = url.lastIndexOf('/');
    this.basePath = url.split('/').slice(0, -1).join('/') + '/';
    this.pageRoute =
      index === -1
        ? url.substring(lastSepIndex + 1)
        : url.substring(lastSepIndex + 1, index);
    this.userType = this.pageRoute === this.pathSignupPRO ? 'advisor' : 'client';
    this.isConnect = false;
    if (!localStorage.getItem('id_token')) {
      this.isWhiteLabel = this.wlService.isWhiteLabel();
      this.auth0Service.initLockControl( this.userType, this.pageRoute, this.isWhiteLabel );
      this.listenLockEvents();
      switch (this.pageRoute) {
        case AuthConnectRoutePath:
          const TOKEN = localStorage.getItem(URL_OAUTH_TOKEN_KEY);
          const TOKEN_INFO = this.jwtHelper.decodeToken(TOKEN);
          const appMetadata = getAppMetadata(TOKEN_INFO);
          this.isConnect = true;
          this.formHint = 'To complete the integration we need you to access your account or create a new account on Gorila.';
          this.formTextParams.broker = appMetadata.providerName;
          timer(1600).subscribe( () => this.selectedLockOption = 'login' );
          return (this.formText = 'Continue your integration between Gorilla and @broker.');
        case AuthGorilaPROSignupRoutePath:
          this.formReady = 'signup';
          this.lockPathSignup = AuthGorilaPROSignupRoutePath;
          return timer(1600).subscribe( () => this.selectedLockOption = 'signup' );
        case AuthNoLockSignupRoutePath:
          this.formReady = 'signup';
          return timer(1600).subscribe( () => this.selectedLockOption = 'signup' );
        case AuthNoLockForgotPasswordRoutePath:
          return timer(1600).subscribe( () => this.selectedLockOption = 'forgot' );
        case AuthNoLockEmailRoutePath:
          return timer(2500).subscribe( () => this.selectedLockOption = 'login' );
        default:
          timer(2500).subscribe( () => this.selectedLockOption = 'login' );
          return (this.formText = '');
      }
    } else {
      this.isWhiteLabel = this.wlService.isWhiteLabel();
      this.selectedLockOption = 'app-error';
      this.router.navigateByUrl('/app');
    }
  }

  private checkLoginReadyToGo(emailInput: HTMLElement, passwordInput: HTMLElement) {
    const emailVal = emailInput['value'];
    const passwordVal = passwordInput['value'];
    if (this.regexEmail.test(String(emailVal).toLowerCase()) && passwordVal.length > 1) {
      this.formReady = 'login';
    } else {
      this.formReady = '';
    }
  }

  private checkSignupReadyToGo(nameInput: HTMLElement, emailInput: HTMLElement, passwordInput: HTMLElement) {
    const nameVal = nameInput['value'];
    const emailVal = emailInput['value'];
    const passwordVal = passwordInput['value'];
    if (this.regexEmail.test(String(emailVal).toLowerCase()) && passwordVal.length > 1 && nameVal.length > 2) {
      this.formReady = 'signup';
    } else {
      this.formReady = '';
    }
  }

  private listenLockEvents() {
    const lockIntance = this.auth0Service.getLockInstance();
    lockIntance.on('signin ready', () => {
      this.location.replaceState(this.basePath + AuthNoLockRoutePath);
      if (this.subscriptionLockInputs) {
        this.subscriptionLockInputs.unsubscribe();
      }
      if (this.subscriptionSelectedLock) {
        this.subscriptionSelectedLock.unsubscribe();
      }
      this.subscriptionSelectedLock =  timer(350).subscribe( () => {
        this.selectedLockOption = 'login';
      });
      this.subscriptionLockInputs = timer(1500).subscribe( () => {
        const emailInput = <HTMLElement>window.document.querySelector('input[name="email"]');
        const passwordInput = <HTMLElement>window.document.querySelector('input[name="password"]');

        if (!emailInput || !passwordInput) {
          console.warn('Some inputs wasn\'t found:');
          console.table({
            emailInput: !!emailInput,
            passwordInput: !!passwordInput
          });
          return;
        }

        emailInput.addEventListener('keyup', () => this.checkLoginReadyToGo(emailInput, passwordInput) );
        passwordInput.addEventListener('keyup', () => this.checkLoginReadyToGo(emailInput, passwordInput) );
        emailInput.addEventListener('change', () => this.checkLoginReadyToGo(emailInput, passwordInput) );
        passwordInput.addEventListener('change', () => this.checkLoginReadyToGo(emailInput, passwordInput) );
        this.checkLoginReadyToGo(emailInput, passwordInput);
      });
    });
    lockIntance.on('forgot_password ready', () => {
      if (this.subscriptionLockInputs) {
        this.subscriptionSelectedLock.unsubscribe();
        this.subscriptionLockInputs.unsubscribe();
      }
      this.selectedLockOption = 'forgot';
      this.location.replaceState(this.basePath + AuthNoLockForgotPasswordRoutePath);
    });
    lockIntance.on('signup ready', () => {
      if (this.subscriptionLockInputs) {
        this.subscriptionSelectedLock.unsubscribe();
        this.subscriptionLockInputs.unsubscribe();
      }
      this.subscriptionLockInputs = timer(1200).subscribe( () => {
        const nameInput = <HTMLElement>window.document.querySelector('input[name="name"]');
        const emailInput = <HTMLElement>window.document.querySelector('input[name="email"]');
        const passwordInput = <HTMLElement>window.document.querySelector('input[name="password"]');

        if (!emailInput || !nameInput || !passwordInput) {
          console.warn('Some inputs wasn\'t found:');
          console.table({
            nameInput: !!nameInput,
            emailInput: !!emailInput,
            passwordInput: !!passwordInput
          });
          return;
        }

        nameInput.tabIndex = 1;
        emailInput.tabIndex = 2;
        passwordInput.tabIndex = 3;
        emailInput.addEventListener('keyup', () => this.checkSignupReadyToGo(nameInput, emailInput, passwordInput) );
        passwordInput.addEventListener('keyup', () => this.checkSignupReadyToGo(nameInput, emailInput, passwordInput) );
        nameInput.addEventListener('keyup', () => this.checkSignupReadyToGo(nameInput, emailInput, passwordInput) );
        emailInput.addEventListener('change', () => this.checkSignupReadyToGo(nameInput, emailInput, passwordInput) );
        passwordInput.addEventListener('change', () => this.checkSignupReadyToGo(nameInput, emailInput, passwordInput) );
        nameInput.addEventListener('change', () => this.checkSignupReadyToGo(nameInput, emailInput, passwordInput) );
      });
      this.selectedLockOption = 'signup';
      this.location.replaceState(this.basePath + this.lockPathSignup);
    });
  }

  public onFormEvent(type: AuthFormEvent, data?: AuthUser) {
    this.message = { text: null, error: false };
    switch (type) {
      case AuthFormEvent.NAV_LOGIN:
        this.pageRoute = AuthNoLockRoutePath;
        const selectorLogin = '.auth0-lock-tabs li:first-child > a';
        const loginBtn: HTMLElement = window.document.querySelector(selectorLogin) as HTMLElement;
        if (!loginBtn) {
          const backBtn: HTMLElement = window.document.querySelector('.auth0-lock-back-button') as HTMLElement;
          backBtn.click();
          return;
        }
        return loginBtn.click();
      case AuthFormEvent.NAV_RESET:
        return this.router.navigate([AuthNoLockForgotPasswordRoutePath], {
          relativeTo: this.route.parent
        });
      case AuthFormEvent.NAV_SIGNUP:
        this.pageRoute = AuthNoLockSignupRoutePath;
        const selectorSignup = '.auth0-lock-tabs li:nth-child(2) > a';
        let signUpBtn: HTMLElement = window.document.querySelector(selectorSignup) as HTMLElement;
        if (!signUpBtn) {
          const backBtn: HTMLElement = window.document.querySelector('.auth0-lock-back-button') as HTMLElement;
          backBtn.click();
          signUpBtn = window.document.querySelector(selectorSignup) as HTMLElement;
        }
        return signUpBtn.click();
    }
  }

  public ngOnDestroy() {}

  public continueOnAuth0(data?: { [key: string]: string }) {
    let url = `https://${environment.Auth0.domain}/continue?state=${this.urlState}`;
    if (data) {
      forEachObjIndexed((value, key) => (url += `&${key}=${value}`), data);
    }

    localStorage.removeItem(URL_STATE_KEY);
    window.location.href = url;
  }

  private redirectToLoadingRoute = () => {
    this.router.navigate([LoginRoutePath], { relativeTo: this.route.parent });
  }

  private removeIdToken = () => localStorage.removeItem('id_token');
}
