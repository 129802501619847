import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Validators, FormGroup } from '@angular/forms';
import { FieldDataObject, FormBaseComponent, FormCreatorService } from '@gorila-bot/forms';
import { AuthFormEvent, AuthFormEventData } from '../../models/authnolock.model';

@Component({
  selector: 'reset-password-form',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent extends FormBaseComponent implements OnDestroy, OnInit {
  public readonly eventType = AuthFormEvent;

  public form: FormGroup;
  public _waitingAuthResponse = false;
  public _btnText = 'Create new password';
  @Input() set waitingAuthResponse(waiting: boolean) {
    this._waitingAuthResponse = waiting;
    this._btnText = waiting ? 'Waiting' : 'Create new password';
  }

  @Input() public message = { text: '', error: false };

  @Input() public userEmail: string;
  @Input() public userCode: string;

  @Output() public resetEvent = new EventEmitter<AuthFormEventData>();

  public isRequestInvalid = false;

  public fields: FieldDataObject = {
    password: {
      value: '',
      required: true,
      disabled: false,
      type: 'password',
      validators: [Validators.required, Validators.minLength(6)]
    },
    confirmPassword: {
      value: '',
      required: true,
      disabled: false,
      type: 'password',
      validators: [Validators.required]
    }
  };

  constructor(
    protected formCreatorService: FormCreatorService
  ) {
    super(formCreatorService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.checkRequest();
    this.form.validator = this.passwordValidator;
  }

  private checkRequest() {
    this.message = { text: '', error: false };
    if (
      !this.userEmail || this.userEmail.indexOf('@') < 2 ||
      !this.userCode || (this.userCode || '').length < 3

    ) {
      this.isRequestInvalid = true;
    }
  }

  public ngOnDestroy() {
  }

  public getFields(): FieldDataObject {
    return this.fields;
  }

  public getName(): string {
    return 'ResetPasswordComponent';
  }

  public passwordValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('password');
    const confirmPassowordControl = formGroup.get('confirmPassword');

    if (!confirmPassowordControl || !confirmPassowordControl.value) {
      return null;
    }

    if (passwordControl.value !== confirmPassowordControl.value) {
      return { passwordMatch: true };
    }

    return null;
  }

  public onClick(type: AuthFormEvent) {
    const data = { email: this.userEmail, password: this.form.get('password').value };
    this.resetEvent.emit({ type, data });
  }
}
