import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utc',
  pure: true
})
export class UtcPipe extends DatePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) _locale: string) { super(_locale); }

  public transform(value: any, pattern: string = 'mediumDate'): string|null {
    try {
      if (!value || typeof value === 'boolean') { return super.transform(value, pattern); }

      const date = (!(value instanceof Date)) ? new Date(value) : value;
      return super.transform(new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      ), pattern);
    } catch (e) {
      console.warn(e);
      return super.transform(value, pattern);
    }
  }

}
