import { Route } from '@angular/router';

import { MobileWarningRoutePath } from './metadata';
import { RouteData } from '../../route-data';

export const MobileWarningRouteData: RouteData = {
  title: 'Dispositivo Móvel'
};

export const MobileWarningRoute: Route = {
  data: MobileWarningRouteData,
  path: MobileWarningRoutePath,
  loadChildren: './pages/mobile-warning/src/mobile-warning.module#MobileWarningModule'
};
