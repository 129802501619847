/* Third-party modules */
import { LoadingBarService } from '@ngx-loading-bar/core';
import { BehaviorSubject } from 'rxjs';

/* Own modules */
import { LoginStatusEnum } from '@gorila/core';
import { LoginStatusService } from '@gorila/core';
import { SubscriberMasterService } from '@gorila/core';
import { UtilsService } from '@gorila/core';

export abstract class UpdaterService extends SubscriberMasterService {
  protected _observer: BehaviorSubject<any[]> = new BehaviorSubject(new Array());
  protected _updateObserver: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _updating = false;
  private _init = false;

  constructor(
    protected loginStatusService: LoginStatusService,
    protected loadingBarService: LoadingBarService
  ) {
    super();
  }

  public abstract start(): void;

  protected init() {
    this._init = false;
    if (this._updating === true) { return; }
    this.updatingStart();
    try {
      this.start();
    } catch (e) { console.warn(e); }
  }

  public _update(data: any, force?: boolean) {
    const value = this._observer.getValue();
    if (!force && UtilsService.deepCompare(value, data)) {
      this.updatingStop();
      return;
    }
    const copy = UtilsService.deepCopy(data);
    this.updatingStop();
    this._observer.next(copy);
  }

  public deepCopy(oldObj: any) {
    let newObj = oldObj;
    if (oldObj && typeof oldObj === 'object') {
      newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
      for (const i in oldObj) {
        if (oldObj.hasOwnProperty(i)) {
          newObj[i] = this.deepCopy(oldObj[i]);
        }
      }
    }
    return newObj;
  }

  public getCurrentData() {
    return this._observer.value;
  }

  public getObserver(): BehaviorSubject<any> {
    return this._observer;
  }

  public getUpdateObserver(): BehaviorSubject<any> {
    return this._updateObserver;
  }

  protected updatingStart() {
    this._updating = true;
    this.loadingBarService.start();
    if (!this._updateObserver) { return; }
    this._updateObserver.next(true);
  }

  protected updatingStop() {
    this._updating = false;
    this.loadingBarService.complete();
    if (!this._updateObserver) { return; }
    this._updateObserver.next(false);
  }

  protected cleanup(loginStatusCode?: LoginStatusEnum) {
    this._init = false;
    this._updating = false;
    this.resetObservers();
  }

  private resetObservers() {
    this._observer.complete();
    this._observer = new BehaviorSubject(new Array());
    this._updateObserver.complete();
    this._updateObserver = new BehaviorSubject(false);
  }
}
