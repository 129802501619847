import { Inject, InjectionToken, Optional, Pipe, PipeTransform } from '@angular/core';

import { PortfolioEvent } from '@gorila/pages/tools/portfolio-events/src/store';

export type EventTypesMap = {
  ASSET_BONUS: string,
  ASSET_INPLIT: string,
  ASSET_NAME_CHANGE: string,
  ASSET_MERGER_AND_ACQUISITION: string,
  ASSET_RETURN_OF_CAPITAL: string,
  ASSET_SPINOFF: string,
  ASSET_SPLIT: string,
  ASSET_UNIT_SPLIT: string,
  CASH_AMORTIZATION: string,
  CASH_AMORTIZATION_TOTAL: string,
  CASH_AMORTIZATION_TOTAL_EARLY: string,
  CASH_BONUS: string,
  CASH_DIVIDEND: string,
  CASH_INCOME: string,
  CASH_INTEREST: string,
  CASH_JSCP: string,
  CASH_PREMIUM: string,
  CASH_RETURN_OF_CAPITAL: string,
  EXPIRATION: string,
  FORWARD_EXPIRATION: string,
  RECEIPT_EXCHANGE: string,
  ASSET_OPTION_EXERCISE: string,
};

export type SummaryTypeValue = {
  value: number,
  currency: string
};

export type SummaryType = {
  TOTAL: SummaryTypeValue,
  AVERAGE: SummaryTypeValue,
  DIVIDEND: SummaryTypeValue,
  JSCP: SummaryTypeValue,
  BONUS: SummaryTypeValue,
  AMORTIZATION: SummaryTypeValue,
  INCOME: SummaryTypeValue,
  MATURITIES: SummaryTypeValue,
  CASH_INTEREST: SummaryTypeValue
};

export const INITIAL_SUMMARY_VALUE: SummaryTypeValue = {
  value: 0,
  currency: '‒'
};

export const INITIAL_SUMMARY: SummaryType = {
  TOTAL: {...INITIAL_SUMMARY_VALUE},
  AVERAGE: {...INITIAL_SUMMARY_VALUE},
  DIVIDEND: {...INITIAL_SUMMARY_VALUE},
  JSCP: {...INITIAL_SUMMARY_VALUE},
  BONUS: {...INITIAL_SUMMARY_VALUE},
  AMORTIZATION: {...INITIAL_SUMMARY_VALUE},
  INCOME: {...INITIAL_SUMMARY_VALUE},
  MATURITIES: {...INITIAL_SUMMARY_VALUE},
  CASH_INTEREST: {...INITIAL_SUMMARY_VALUE}
};

export const EVENT_TYPES_MAP = new InjectionToken<EventTypesMap>('EVENT_TYPES_MAP');
export const defaultEventTypesMap: EventTypesMap = {
  ASSET_BONUS: 'Bônus em ações',
  ASSET_INPLIT: 'Grupamento',
  ASSET_NAME_CHANGE: 'Mudança de nome',
  ASSET_MERGER_AND_ACQUISITION: 'Fusão e aquisição',
  ASSET_RETURN_OF_CAPITAL: 'Restituição de capital em ações',
  ASSET_SPINOFF: 'Cisão',
  ASSET_SPLIT: 'Desdobramento',
  ASSET_UNIT_SPLIT : 'Desmembramento',
  CASH_AMORTIZATION: 'Amortização',
  CASH_AMORTIZATION_TOTAL: 'Vencimento',
  CASH_AMORTIZATION_TOTAL_EARLY: 'Resgate total antecipado',
  CASH_BONUS: 'Bônus em dinheiro',
  CASH_DIVIDEND: 'Dividendo',
  CASH_INCOME: 'Rendimento',
  CASH_INTEREST: 'Juros',
  CASH_JSCP: 'JSCP',
  CASH_PREMIUM: 'Prêmio',
  CASH_RETURN_OF_CAPITAL: 'Retorno de capital em dinheiro',
  EXPIRATION: 'Vencimento',
  FORWARD_EXPIRATION: 'Vencimento de termo',
  RECEIPT_EXCHANGE: 'AÇÕES SUBSCRITAS',
  ASSET_OPTION_EXERCISE: 'Exercício de opção'
};

export const buildEventTypeComparator = (_eventTypeMap = defaultEventTypesMap) =>
  (d: 'asc' | 'desc') => (et1: PortfolioEvent | string, et2: PortfolioEvent | string): number => {
    const _et1 = _eventTypeMap[typeof et1 === 'string' ? et1 : (<PortfolioEvent> et1).eventType];
    const _et2 = _eventTypeMap[typeof et2 === 'string' ? et2 : (<PortfolioEvent> et2).eventType];
    const cmp = (a, b) => a < b ? -1 : (a > b ? 1 : 0);
    return d === 'asc' ? cmp(_et1, _et2) : cmp(_et2, _et1);
  };

@Pipe({
  name: 'eventType',
  pure: true
})
export class EventTypePipe implements PipeTransform {
  constructor(
    @Inject(EVENT_TYPES_MAP) @Optional() private _eventTypeMap?: EventTypesMap
  ) {
    if (_eventTypeMap) { return; }
    this._eventTypeMap = defaultEventTypesMap;
  }

  public transform(value: PortfolioEvent | string, emptyValue = null): string {
    try {
      const eventType = typeof value === 'string' ? value : (<PortfolioEvent> value).eventType;
      return this._eventTypeMap[eventType] || (emptyValue === null ? value : emptyValue);
    } catch (e) {
      console.warn(e, value);
    }
  }

  public getEventTypeMap() {
    return this._eventTypeMap;
  }
}
