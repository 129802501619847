import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AppShellFiltersService, ClientType } from '../app-shell-filters.service';

@Component({
  selector: 'app-shell-filter',
  templateUrl: './app-shell-filter.component.html',
  styleUrls: ['./app-shell-filter.component.scss']
})
export class AppShellFilterComponent implements OnInit, OnDestroy {
  @Input() public hideFilter = false;

  public clientType = ClientType;
  public startDate$ = this.appFiltersContainerService.getStartDate();
  public endDate$ = this.appFiltersContainerService.getEndDate();
  public options$ = this.appFiltersContainerService.getOptions();
  public filterOptions$ = this.appFiltersContainerService.getFilterOptions();
  public minDate$ = this.appFiltersContainerService.getMinDate();
  public maxDate$ = this.appFiltersContainerService.getMaxDate();
  public filterServiceItems$ = this.appFiltersContainerService.getFilterServiceItems();
  public clientTypeClass = '';

  public constructor(
    public appFiltersContainerService: AppShellFiltersService,
  ) { }

  public ngOnInit() {
    this.appFiltersContainerService.clientType$.pipe(
      untilDestroyed(this)
    ).subscribe(clientType => this.clientTypeClass = clientType);
  }

  public ngOnDestroy() { }
}
