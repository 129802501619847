<div *ngFor="let item of items; trackBy: trackByFn"
  class="item"
  [id]="item.id"
  [ngClass]="{'active': item.active, 'border': border}"
  [style.width.px]="size"
  [style.height.px]="size"
  [attr.data-id]="item.dataID || ''"
  (click)="emitEvent('IconClicked', item)"
  (mouseenter)="mouseEvent('MouseEnterIcon', item)"
  (mouseleave)="mouseEvent('MouseLeaveIcon', item)"
>
  <div class="top"></div>
  <gor-icon
    iconFolder="Navigation/Main-Nav"
    [icon]="item.icon"
    [width]="item.size"
    [height]="item.size"
  ></gor-icon>
</div>
