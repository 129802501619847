import { Pipe, PipeTransform } from '@angular/core';
/*
 * Capitalize the first letter of the string
 * Takes a string as a value.
 * Usage:
 *  value | ucfirst
 * Example:
 *  // value.name = test abc
 *  {{ value.name | ucfirst  }}
 *  formats to: Test Abc
*/
@Pipe({
  name: 'ucfirst'
})
export class UcfirstPipe implements PipeTransform {
  public transform(value: string, ignore: { [s: string]: string }): string {
    if (!value) {
      return value;
    }
    return value.replace(/[a-zA-ZÀ-ž]\S*/g, function(txt) {
      if (ignore && ignore[txt.trim()]) {
        return ignore[txt.trim()];
      }
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
