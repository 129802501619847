import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

const defaultTitle = 'save';
const errorTitle = 'saveError';
const savedTitle = 'saved';
const savingTitle = 'saving';

export const SaveButtonTitles = {
  default: defaultTitle,
  error: errorTitle,
  saved: savedTitle,
  saving: savingTitle
};

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent implements OnChanges {
  @Input() public status = '';
  @Input() public enabled = true;
  @Output() public clicked = new EventEmitter();

  public _title = SaveButtonTitles.default;
  public isDisabled = false;
  public isSaving = false;

  constructor() {}

  public ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['status']) {
      if (this.status !== '' && !SaveButtonTitles[this.status]) {
        console.warn('invalid status', this.status);
      }
      this._title = SaveButtonTitles[this.status] || SaveButtonTitles.default;
      this.isSaving = this.status === 'saving';
    }

    this.isDisabled = ['error', 'saving'].indexOf(this.status) !== -1 || !this.enabled;
  }
}
