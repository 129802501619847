import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '@gorilainvest/ui-toolkit/icon';

import { HeaderIconsComponent } from './header-icons.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule
  ],
  declarations: [
    HeaderIconsComponent
  ],
  exports: [
    HeaderIconsComponent
  ]
})
export class HeaderIconsModule { }
