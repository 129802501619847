import { InjectionToken } from '@angular/core';

export const AUTH0_CLIENTID = new InjectionToken<string>(
  'Auth0ClientID'
);

export const AUTH0_DATABASE = new InjectionToken<string>(
  'Auth0Database'
);

export const AUTH0_DOMAIN = new InjectionToken<string>(
  'Auth0Domain'
);

export const SIGNUP_SERVICE_BASE_URL = new InjectionToken<string>(
  'SignupServiceBaseURL'
);

export const SERVICE_TERMS_URL_B2B = new InjectionToken<string>(
  'ServiceTermsURLB2B'
);

export const SERVICE_TERMS_URL_B2C = new InjectionToken<string>(
  'ServiceTermsURLB2C'
);
