import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Pallete } from '@gorila-bot/gorila-front-utils/dist/asset-class';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';
import { Event, EventType } from '@gorila/core/utils';
import { isSafari } from '@gorila-bot/gorila-front-utils';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import * as lang from '../../config/lang/pt-br.lang.json';
import { DonutChartService } from './donut-chart.service';

export interface ProfitChartData {
  id: string;
  title: string;
  value: number;
  color: string;
  percentValue: number;
  filterKey?: string;
}

export class MiddleBoxComponentEvent implements EventType {
  public static Reset = 'MIDDLE_RESET';
  public static Change = 'MIDDLE_CHANGE';
  public static BackLevel = 'MIDDLE_BACK_LEVEL';
}

@Component({
  selector: 'allocation-middle-box',
  templateUrl: './middle-box.component.html',
  styleUrls: ['./middle-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DonutChartService]
})
export class MiddleBoxComponent implements OnChanges, OnDestroy, OnInit {
  public _dataProvider = new Array<ProfitChartData>();
  public emptyData = false;
  @Input() public dataProvider: Array<ProfitChartData>;
  @Input() public label: string;
  @Input() public disableFilter: boolean;
  @Input() public isMobile = false;
  @Input() public loading: boolean;
  @Input() public selected = '';
  @Input() public selectedAsset: string;
  @Output() public changeLoader = new EventEmitter;
  @Output() public sliceClicked = new EventEmitter<Event<MiddleBoxComponentEvent>>();

  @ViewChild('chartElement', { static: true }) private chartElement: ElementRef<HTMLElement>;

  /**
   * set selected assets level as breadcrumb.
   *
   * @default []
   * @see MiddleBoxComponent
   */
  @Input() public set chartDataLevel(breadcrumb: ProfitChartData[]) {
    this.chart.breadcrumb = breadcrumb || [];
    this.showBackButton = this.chart.breadcrumb.length > 0;
  }

  public color = '';
  public hideDonut = false;
  public selectedAssetLabel = '';
  public parentAssetLabel = '';
  public showBackButton = false;

  public selectedAssetText: string;
  public dataProviderActive: Array<ProfitChartData> = [];
  public isSafari = false;
  public chartTitle = 'Patrimony';

  constructor(
    private chart: DonutChartService<ProfitChartData>,
    private translate: TranslateService
  ) { }

  public ngOnInit() {
    this.chart.create(this.chartElement.nativeElement);
    this.chart.setHitListener(this.sliceHitListener);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.loading || changes.dataProvider) {
      this.emptyData = this.dataProvider.length === 0;
      this.hideDonut = this.loading || this.emptyData;
    }

    if (this.emptyData) {
      return;
    }

    if (changes.chartDataLevel || changes.selectedAsset) {
      this.updateLabelOnChart();
    }

    if (changes.dataProvider) {
      this.setColorFromData();
      this.chart.updateData(this.dataProvider.map(d => ({ ...d, alpha: (this.selected === '' || d.id === this.selected) ? 1 : 0.3 })));
    }
  }

  private updateLabelOnChart() {
    const chartDataLevel = this.chart.breadcrumb;
    this.selectedAssetLabel = chartDataLevel.length > 0 ? chartDataLevel[chartDataLevel.length - 1].title : '';

    this.selectedAssetText = this.selectedAsset.split('asset-bg')[1];

    if (chartDataLevel.length > 1) {
      this.parentAssetLabel = this.translate.instant('of wallet');
    } else {
      this.parentAssetLabel = this.translate.instant('patrimony');
    }
  }

  public ngOnDestroy() {
    this.chart.destroy();
  }

  public goBackLevel() {
    this.chart.hide();
    this.createTimer$(this.chart.transitionDuration).subscribe(() => {
      this.sliceClicked.emit({ type: MiddleBoxComponentEvent.BackLevel });
    });
  }

  public emit(active?: { [s: string]: string }) {
    const { id } = active ? active : { id: null };
    if (this.disableFilter) {
      return;
    }

    // updating chart's loader faster
    this.changeLoader.emit(true);

    if (this.sliceClicked.observers.length === 0) {
      console.warn('[MiddleBoxComponent emit] No observer registered to "sliceClicked" emitter.');
    }

    if (id && id !== this.selected) {
      this.selected = id;
      // waiting for the chart loader to load before starting the dashboard update flow with the asset data:
      this.createTimer$(200).subscribe(() => this.sliceClicked.emit({ type: MiddleBoxComponentEvent.Change, payload: active }));
    } else {
      this.selected = '';
      // waiting for the chart loader to load before starting the dashboard update flow with the asset data:
      this.createTimer$(200).subscribe(() => this.sliceClicked.emit({ type: MiddleBoxComponentEvent.Reset }));
    }
  }

  private createTimer$(time: number): Observable<number> {
    return timer(time).pipe(untilDestroyed(this));
  }

  private setColorFromData() {
    this.color = this.dataProvider.length > 0 ? this.dataProvider[0].color : '';
  }

  private sliceHitListener = (e) => {
    this.createTimer$(1400).subscribe(() => {
      this.emit(e.target.dataItem.dataContext);
    });
  }

}
