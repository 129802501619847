import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepService } from '@gorila/shared/services/step.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'progress-container',
  templateUrl: './progress-container.component.html',
  styleUrls: ['./progress-container.component.scss']
})
export class ProgressContainerComponent implements OnDestroy, OnInit {

  /**
   * Progress data for progress-bar component
   *
   * you can test with this snippet data
   * https://bitbucket.org/snippets/tigredonorte/MbEEg8
   * (comment self.progressData = out; line when you want debug)
   *
   */
  public progressData: Array<any> = [];
  public constructor(private stepService: StepService) { }

  public ngOnInit() {
    this.listenStepService();
  }

  public ngOnDestroy() {}

  private listenStepService() {
    var self = this; // tslint:disable-line
    this.stepService.getObserver().pipe(untilDestroyed(this)).subscribe(
      (data: any) => {
        if (!data) { return; }
        const out = [];
        for (const i in data) {
          if (!data.hasOwnProperty(i)) { continue; }
          out.push(data[i]);
        }
        self.progressData = out;
      }
    );
  }

}
