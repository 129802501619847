import { Injectable } from '@angular/core';
import { ProfitabilityRequestService } from '@gorila-bot/profitability-store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { isEmpty, isNil, path } from 'ramda';
import { map, switchMap } from 'rxjs/operators';
import {
  LoadUserPortfolioDatesActions,
  LoadedUserPortfolioDatesActions,
  UserPortfolioActionTypes,
  UserPortfolioActions
} from '../actions/portfolio.actions';
import { LoadedUserPortfolioDatesPayload, action2Request, toLoadedPayload } from '../models/portfolio.model';

@Injectable()
export class PortfolioEffects {

  @Effect()
  public loadPortfolioDates$ = this.actions$.pipe(
    ofType(UserPortfolioActionTypes.LoadUserPortfolioDatesActions),
    switchMap((action: LoadUserPortfolioDatesActions) => this.service.doMultiRequests(action2Request(action.payload)).pipe(
      map((res: any): any => isEmpty(res) || isNil(res) ? [] : res.map(r => path(['Profit'], (r || [])[0]))),
      map((res: any): LoadedUserPortfolioDatesPayload => (res || []).reduce((pps, ps) => ({
        ...pps,
        ...(ps || []).map(p => p._id).reduce(toLoadedPayload, {})
      }), {})),
      map((payload: LoadedUserPortfolioDatesPayload): LoadedUserPortfolioDatesActions => new LoadedUserPortfolioDatesActions(payload))
    ))
  );

  constructor(
    private actions$: Actions<UserPortfolioActions>,
    private service: ProfitabilityRequestService
  ) {}

}
