<form class="form" *ngIf="form" [formGroup]="form">
  <div>
    <span for="email" class="form-label">{{ 'email' | translate }}</span>
    <div>
      <input matInput class="form-input" type="email" name="email" id="email" placeholder="seunome@exemplo.com"
        formControlName="email">
    </div>
    <span *ngIf="!form.get('email').valid && form.get('email').touched" class="form-invalid">
      <i *ngIf="(!!form.get('email').errors?.required || !!form.get('email').errors?.email)">{{ 'Invalid email' |
        translate }}</i>
    </span>
  </div>
  <div>
    <span class="form-label" for="password">{{ 'password' | translate }}</span>
    <div>
      <input matInput class="form-input" type="password" name="password" id="password"
        placeholder="{{ 'Input your password' | translate }}" formControlName="password">
    </div>
    <span *ngIf="!form.get('password').valid && form.get('password').touched" class="form-invalid">
      <i>{{ 'Invalid password' | translate }}</i>
    </span>
  </div>
  <div class="reset-container">
    <a class="reset-label" (click)="onClick(eventType.NAV_RESET)">{{ 'Forgot password' | translate }}</a>
  </div>

  <div class="message-container">
    <span [class]="message.text ? message.error ? 'error-message' : 'message' : 'hide-message'">{{ message.text |
      translate }}</span>
  </div>

  <div class="button-container button-submit">
    <gor-base-button id="signin_submit" cssClass="btn-action btn-action--primary" (click)="onClick(eventType.LOGIN)"
      [disabled]="!form.get('email').valid || !form.get('password').valid || _waitingAuthResponse"
      [title]="_btnText | translate">
      <gor-loading [stopLoading]="!_waitingAuthResponse"></gor-loading>
    </gor-base-button>
  </div>

  <div class="button-container" *ngIf="!hideSocial">
    <button id="social_submit" class="btn-action btn-facebook" (click)="onClick(eventType.FACEBOOK)"
      [disabled]="_waitingAuthResponse">
      <img src="/assets/img/facebook.svg">
      {{ 'Facebook Login' | translate }}
    </button>
    <button id="social_submit_apple" class="btn-action btn-apple" (click)="onClick(eventType.APPLE)"
      [disabled]="_waitingAuthResponse">
      <img src="/assets/img/apple.svg">
      {{ 'Apple Login' | translate }}
    </button>
  </div>

</form>
