import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IOperation, OperationTypeEnum, CostSubTypeEnum } from '@gorila-bot/gorila-front-models';
import { whereEq, path, mergeRight } from 'ramda';

import { TradeActionTypes } from '../actions/trade.actions';
import { initialState, TradeState } from '../trade.state';
import { IListOperationParams } from '../models/operation.model';

const adapter: EntityAdapter<IOperation> = createEntityAdapter<IOperation>({
  selectId: (trade) => trade.ids[0],
});

/*
 * receives a list of operation and separate trades from costs
 */
const separateCostAndTrade = (operations: IOperation[] = []) => {
  const trades = [];
  const costs = {};
  operations.forEach((operation: IOperation) => {
    // come-cotas is mapped as a trade to not change behaviour of wallet
    if (operation.type !== OperationTypeEnum.COST || operation.subType === CostSubTypeEnum.COME_COTAS) {
      trades.push(operation);
      return;
    }
    const parentId = operation.parentOperationId;
    costs[parentId] = costs[parentId] ? [...costs[parentId], operation] : [operation];
  });

  return { trades, costs };
};

export function reducer(state = initialState, action: any): TradeState {
  switch (action.type) {
    case TradeActionTypes.LoadTradeAction: {
      if (action.options.forceReload) {
        return adapter.removeAll(
          {
            ...state,
            error: null,
            loading: true
          }
        );
      }
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case TradeActionTypes._LoadTradeAction: {
      let newState = state;

      const params = path<IListOperationParams>(['data', 'options', 'params'], action) || {};
      // check if load action was called with filter params to remove the trades with the same param from store
      // to update them to avoid phantom trades when any is deleted
      if (!!params.securitySymbol) {
        // create a function to check trade security
        const spec = {
          symbol: params.securitySymbol,
        };
        // Product type is missing in POSITION_REMOVE, so ignores it if undefined
        if (!!params.productType) {
          // @ts-ignore
          spec.productType = params.productType;
        }
        const compareSecurity = whereEq(spec);
        // remove trades with same params of load trade action checks for broker and brokerName to work with
        // POSITION_UPDATE and POSITION_REMOVE events respectively
        newState = adapter.removeMany((trade: IOperation) =>
          [trade.broker, trade.brokerName].includes(params.broker)
          && compareSecurity(trade.security)
          , state);
      }

      const { trades, costs } = separateCostAndTrade(action.data.operations);
      return adapter.upsertMany(
        trades, {
        ...newState,
        costs: mergeRight(newState.costs, costs),
        error: action.error || initialState.error,
        loading: false
      });
    }
    case TradeActionTypes._AddTradeAction: {
      return adapter.upsertMany(
        action.data, {
        ...state,
        loading: false
      });
    }
    case TradeActionTypes._RemoveTradeAction: {
      return action.tradeId ? adapter.removeOne(
        action.tradeId, {
        ...state,
        loading: false
      }) : state;
    }
    case TradeActionTypes._SetTradeAction: {
      const { trades, costs } = separateCostAndTrade(action.data.operations);
      return adapter.addAll(
        trades, {
          ...state,
          costs,
          loading: false
        }
      );
    }

    default:
      return state;
  }
}
