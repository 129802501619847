import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cpf'
})
export class CpfPipe implements PipeTransform {

    public transform(cpf: string): string {
        if (cpf) {
            const value = cpf.toString().replace(/\D/g, '');

            let cpfFormatted = '';

            if (value.length > 9) {
                cpfFormatted = value.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{0,5})/, '$1.$2.$3-$4');
            } else if (value.length > 5) {
                cpfFormatted = value.replace(/^(\d{3})?(\d{3})?(\d{0,5})/, '$1.$2.$3');
            } else if (value.length > 2) {
                cpfFormatted = value.replace(/^(\d{3})?(\d{0,5})/, '$1.$2');
            } else {
              return cpf;
            }
            return cpfFormatted;
        }
        return '';

    }

}
