import { TranslateService } from '@ngx-translate/core';

function _constructor(locale, translate) {
  translate.addLangs([locale]);
  translate.setDefaultLang(locale);
  translate.use(locale);
}

export abstract class BasePage {
  constructor(locale: string, protected translate: TranslateService) {
    _constructor(locale, translate);
  }
}

export abstract class BasePageWithSusbcriptions {
  constructor(locale: string, protected translate: TranslateService) {
    _constructor(locale, translate);
  }
}
