import { Component, HostBinding, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { RouterWatcherService } from '@gorila/core';
import { BasePage } from '@gorila/core/utils';
import { Fund, UserDataSelectors } from '@gorila-bot/user-data-store';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { timeout, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { path } from 'ramda';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FeatureToggleService } from '@gorila-bot/gorila-toggle';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasePage implements OnDestroy {
  @HostBinding('@.disabled') public disableAnimations = environment.disableAnimations;

  public translationsLoaded = false;

  /**
   * AppComponent constructor.
   *
   * @param locale Locale defined on AppModule
   * @param loader LoadingBarService unique instance that will generate values for progress bar
   * @param translate TranslateService unique instance to load translations file
   * @param routerWatcherService Initialize our router watcherman, doesn't remove him
   */
  public constructor(
    private store: Store<any>,
    @Inject(LOCALE_ID) protected locale: string,
    public loader: LoadingBarService,
    protected translate: TranslateService,
    private routerWatcherService: RouterWatcherService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private featureToggleService: FeatureToggleService,
  ) {
    super(locale, translate);
    this.initFeatureToggle();
    this.routerWatcherService.subscribeRoute();
    this.language();
    this.registryIcons();
  }

  private language() {
    try {
      const updateTranslationsLoaded = (_) => this.translationsLoaded = true;
      this.translate
        .use(this.locale)
        .pipe(timeout(1000))
        .subscribe(updateTranslationsLoaded, updateTranslationsLoaded);
    } catch (e) {
      console.warn(e);
    }
  }

  /**
   * Base to gorila toggle work.
   */
  private initFeatureToggle(): void {
    combineLatest([
      this.store.pipe(untilDestroyed(this), select(UserDataSelectors.selectFeatureCurrentLoggedFundData)),
      this.store.pipe(untilDestroyed(this), select(UserDataSelectors.selectFeatureUserTier)),
    ]).pipe(
      filter(([ userData, _ ]: [Fund, number]) => !!userData.traderName),
    ).subscribe(([ userData, tier ]: [Fund, number]) => {
      this.featureToggleService.setUserData(
        userData.fundId,
        path([ 'traderEmail' ], userData),
        userData.clientType,
        tier,
      );
    });
  }

  private registryIcons() {
    this.iconRegistry.addSvgIcon('gorila-calendar',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/calendar.svg'));
  }

  /**
   * Angular Destroy lifecycle.
   */
  public ngOnDestroy() {

  }
}
