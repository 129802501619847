import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { HeaderComponentEvent } from '../../events.model';

@Component({
  selector: 'gor-user-menu-dropdown',
  templateUrl: './user-menu-dropdown.component.html',
  styleUrls: ['./user-menu-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuDropdownComponent {
  @HostBinding('class.advisor-menu') @Input() public advisorMode = false;

  private _email = '';
  @Input() public set email(email: string) {
    this._email = this.format(email || '', this.limits.email);
  }
  public get email() {
    return this._email;
  }

  @Input() public set username(username: string) {
    this.updateName(username || '');
  }

  @Input() public items = [];

  @Output() public reduce = new EventEmitter<HeaderComponentEvent>();

  public name = {
    first: '',
    last: '',
    long: false
  };

  private limits = {
    name: 22,
    email: 36
  };

  public handleMouseEvent(mouseEvent: 'mouseEnter' | 'mouseLeave' | 'linkClick') {
    this.reduce.emit({ type: 'UserMenuDropdownEvent', data: { mouseEvent } });
  }

  private format(word: string = '', limit: number = Number.MAX_SAFE_INTEGER) {
    return word.length > limit
      ? `${word.substring(0, limit)}...`
      : word;
  }

  private updateName(username: string = '') {
    let usernameParts = [];
    let firstName = '';
    let lastName = '';

    if (username.indexOf(' ') !== -1) {
      usernameParts = username.split(' ');
    } else {
      firstName = username;
    }

    if (usernameParts.length > 1) {
      firstName = usernameParts[0];
      lastName = usernameParts[usernameParts.length - 1];
    }

    firstName = this.format(firstName, this.limits.name);
    lastName = this.format(lastName, this.limits.name);

    const longName = usernameParts.length > 1
      && firstName.length + lastName.length > this.limits.name;

    this.name = {
      first: firstName,
      last: lastName,
      long: longName
    };
  }
}
