/**
 * Interface for objects that represents the side of a trade.
 *
 * @interface
 */
export interface Side {
  /**
   * The value that identify which side is.
   *
   * @type {number}
   */
  value: number;

  /**
   * The name of the side.
   *
   * @type {string}
   */
  name: string;
}

/**
 * Enum for two-state side values.
 *
 * @readonly
 * @enum {number}
 */
export enum SideEnum {
  BUY = 1,
  SELL
}

/**
 * Get a Side object based on a SideEnum input.
 *
 * @param  {SideEnum} sideEnum=SideEnum.BUY Says if is a buy or a sell.
 * @return {Side}                           A object representation of a side.
 */
export function getSideObject(sideEnum = SideEnum.BUY): Side {
  return (sideEnum === SideEnum.BUY) ? {
    name: 'BUY',
    value: 1
  } : {
    name: 'SELL',
    value: 2
  };
}
