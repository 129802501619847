import { Route } from '@angular/router';

import { UserAccountsRoutePath } from './metadata';
import { PermissionGuard } from '../../../guards/permission.guard';

export const UserAccountRoute: Route = {
  canLoad: [PermissionGuard],
  data: {
    permissions: [ 'CLIENT' ],
    title: 'Contas do usuário'
  },
  path: UserAccountsRoutePath,
  loadChildren: './pages/user/user-account/user-account.module#UserAccountModule'
};
