import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'connection-security',
  templateUrl: './connection-security.component.html',
  styleUrls: ['./connection-security.component.scss']
})
export class ConnectionSecurityComponent {

  constructor(
    private dialogRef: MatDialogRef<ConnectionSecurityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { urlGuia: string }
  ) { }

  public close() {
    this.dialogRef.close();
  }
}
