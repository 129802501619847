import { IPercentAllocation } from '@gorila-bot/gorila-front-models';
import { createSelector } from '@ngrx/store';
import { path } from 'ramda';

import { IAllocationPercentageState, initialState, Key } from './allocation-percentages.state';

const selectAllocationPercentagesState = state => path([Key], state);

export const selectAllocationPercentages = (fundId: string) => createSelector(
  selectAllocationPercentagesState,
  (state: IAllocationPercentageState) => (state && state.allocationData[fundId] || initialState.allocationData) as IPercentAllocation
);

export const selectAllAllocationData = createSelector(
  selectAllocationPercentagesState,
  (state: IAllocationPercentageState) => state && state.allocationData
);

export const selectAllocationPercentagesStatus = createSelector(
  selectAllocationPercentagesState,
  (state: IAllocationPercentageState) => state &&
    { error: state.error || initialState.error, loading: state.loading || initialState.loading }
);
