import { keys, values } from 'ramda';
import { Position } from '../store/api/position/position.model';
import { getZeroedPnl, getZeroedPnlData } from '../store/api/pnl.model';

type IndexedPositions = {[key: string]: Position};
type PositionsArray = Position[];

function _removeTrades(position: Position): Position {
  return {
    ...position,
    trades: []
  };
}

function _resetPnls(position: Position): Position {
  return {
    ...position,
    pnl: {
      ...getZeroedPnlData(),
      total: getZeroedPnl()
    }
  };
}

function _actOnArray(positions: PositionsArray, fn: (p: Position) => Position): PositionsArray {
  if (!positions) {
    return positions;
  }

  return positions.reduce((arr: PositionsArray, position: Position) => {
    arr.push(fn(position));
    return arr;
  }, []);
}

function _removeAllTradesFromPositionsArray(positions: PositionsArray): PositionsArray {
  return _actOnArray(positions, _removeTrades);
}

function _resetPnlsFromPositionsArray(positions: PositionsArray): PositionsArray {
  return _actOnArray(positions, _resetPnls);
}

function _actOnIndexed(positions: IndexedPositions, fn: (p: Position) => Position): IndexedPositions {
  if (!positions) {
    return positions;
  }

  return values(positions).reduce((obj: IndexedPositions, position: Position, index: number) => {
    if (!position) {
      obj[keys(positions)[index]] = position;
    } else {
      obj[position.id] = fn(position);
    }
    return obj;
  }, {});
}

function _removeAllTradesFromPositionsIndexed(positions: IndexedPositions): IndexedPositions {
  return _actOnIndexed(positions, _removeTrades);
}

function _resetPnlsFromPositionsIndexed(positions: IndexedPositions): IndexedPositions {
  return _actOnIndexed(positions, _resetPnls);
}

export function removeAllTradesFromPosition(position: Position): Position {
  return _removeTrades(position);
}

export function removeAllTradesFromPositions(positions: PositionsArray|IndexedPositions): PositionsArray|IndexedPositions {
  if (Array.isArray(positions)) {
    return _removeAllTradesFromPositionsArray(<PositionsArray> positions);
  } else {
    return _removeAllTradesFromPositionsIndexed(<IndexedPositions> positions);
  }
}

export function resetPnlsFromPositions(positions: PositionsArray|IndexedPositions): PositionsArray|IndexedPositions {
  if (Array.isArray(positions)) {
    return _resetPnlsFromPositionsArray(<PositionsArray> positions);
  } else {
    return _resetPnlsFromPositionsIndexed(<IndexedPositions> positions);
  }
}
