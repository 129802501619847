import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PremiumModule } from '@gorila-bot/premium';
import { ButtonModule } from '@gorilainvest/ui-toolkit/button';
import { DirectivesModule } from '@gorilainvest/ui-toolkit/directives';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { TranslateModule } from '@ngx-translate/core';

import { BlotterButtonComponent } from './blotter-button';
import { HeaderButtonsComponent } from './header-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DirectivesModule,
    IconModule,
    PremiumModule.forChild(),
    RouterModule,
    TranslateModule
  ],
  declarations: [
    BlotterButtonComponent,
    HeaderButtonsComponent
  ],
  exports: [
    BlotterButtonComponent,
    HeaderButtonsComponent
  ]
})
export class HeaderButtonsModule { }
