import * as moment from 'moment';

import { TransferActions, TransferActionTypes } from '../actions/transfer.actions';
import { initialState, ITransferResponseItem, ITransferState } from '../transfer.state';

export function reducer(state = initialState, action: TransferActions): ITransferState {
  switch (action.type) {
    case TransferActionTypes.LoadTransfers: {
      return {
        ...state,
        loading: true
      };
    }

    case TransferActionTypes.LoadedTransfers:
      const data: ITransferResponseItem = {
        assetEvents: action.transfers.assetEvents || [],
        trades: action.transfers.trades || [],
        transfers: action.transfers.transfers || [],
      };
      const { broker, productType, securityName } = action.requestData;
      const replacedEvents = (event): boolean => !(
        (!broker || event.broker === broker)
        && (!productType || !event.productType || event.productType === productType)
        && (!securityName || event.securityName === securityName)
      );
      return {
        ...state,
        isEmpty: !data.assetEvents.length && !(data.trades || []).length && !(data.transfers || []).length,
        trades: data.trades || [],
        events: [
          ...data.transfers.map(dt => ({
            ...dt,
            paymentCurrency: dt.paymentCurrency || 'BRL',
            eventType: dt.source === 'CEI'
              ? 'Extrato CEI'
              : dt.subType
                ? dt.subType
                : 'Custody Transfer',
          })),
          ...data.assetEvents.map(dt => ({
            ...dt,
            paymentCurrency: dt.paymentCurrency || 'BRL',
            eventType: dt.eventType,
            price: dt.unitValue,
            value: dt.totalValue,
            refDate: dt.refDate || dt.paymentDate
          })),
          ...state.events.filter(replacedEvents),
        ].sort((a, b) => moment(a.refDate).diff(b.refDate)),
        loading: false,
        error: false
      };

    case TransferActionTypes.LoadedTransfersError: {
      return {
        ...state,
        ...initialState,
        error: true
      };
    }

    case TransferActionTypes.ClearTransfers: {
      return {
        ...state,
        ...initialState
      };
    }

    case TransferActionTypes.RemovedTransfer: {
      const excludeById = item => item.custodyTransferID !== action.id;
      return action.id ? ({
        ...state,
        trades: state.trades.filter(excludeById),
        events: state.events.filter(excludeById)
      }) : state;
    }

    default:
      return state;
  }
}
