import { AppInfoActions, AppInfoActionTypes } from '../actions/app-info.actions';
import { getSize } from '../models/app-info.model';
import { initialState, AppInfoState } from '../app-info.state';


export function reducer(state = initialState, action: AppInfoActions): AppInfoState {
  switch (action.type) {
    case AppInfoActionTypes.SetScreenInformation: {
      return {
        ...state,
        screen: {
          mobile: action.width < 1200,
          size: getSize(action.width),
          isPortrait: action.height > action.width
        }
      };
    }

    default:
      return state;
  }
}
