import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiSimpleModalData } from './ui-simple-modal.data';

@Component({
  selector: 'ui-simple-modal',
  templateUrl: './ui-simple-modal.component.html',
  styleUrls: ['./ui-simple-modal.component.scss']
})
export class UiSimpleModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UiSimpleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UiSimpleModalData
  ) { }

  public ngOnInit() {
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
