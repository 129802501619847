export enum ActionType {
  ADD,
  CLEAR,
  FILTER,
  LOAD,
  MAP,
  NULL,
  REMOVE,
  RESET,
  UPDATE,
  UNMAP
}

export const actionTypeNameFor = (actionType: ActionType) => {
  switch (actionType) {
    case ActionType.ADD: return 'ADD';
    case ActionType.UPDATE: return 'UPDATE';
    case ActionType.MAP: return 'MAP';
    case ActionType.UNMAP: return 'UNMAP';
    case ActionType.LOAD: return 'LOAD';
    case ActionType.REMOVE: return 'REMOVE';
    case ActionType.CLEAR: return 'CLEAR';
    case ActionType.RESET: return 'RESET';
    case ActionType.FILTER: return 'FILTER';
  }
  return 'NULL';
};

export enum ActionTransition {
  DATA = 1,
  STARTED,
  SUCCEEDED,
  FAILED
}

export const actionTransitionNameFor = (actionTransition: ActionTransition) => {
  switch (actionTransition) {
    case ActionTransition.DATA: return 'DATA';
    case ActionTransition.STARTED: return 'STARTED';
    case ActionTransition.SUCCEEDED: return 'SUCCEEDED';
    case ActionTransition.FAILED: return 'FAILED';
  }
  return 'NULL';
};
