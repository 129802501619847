import { Component, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';

import { ReactiveComponent } from '../reactive/reactive.component';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent extends ReactiveComponent implements ControlValueAccessor, OnChanges {
  @Input() public array: Array<any>;
  @Input() public codField = '';
  @Input() public titleField = 'title';

  public getName() {
    return 'SelectorComponent';
  }

  protected getDefaultValue(): any {
    if (this.defaultValue !== '') { return this.defaultValue; }
    this.defaultValue = this.array[0];
    return this.defaultValue;
  }

  protected getOutput(object: any): any {
    try {
      if (object == null) { return this.getDefaultValue(); }
      if (typeof object['originalObject'] !== 'undefined') {
        return object['originalObject'];
      }
      return object;
    } catch (e) {
      try {
        console.error(e);
        return this.getDefaultValue();
      } catch (ex) { console.error(ex); }
    }
  }

  protected getValidationFn(inputs: any) {
    return (c: FormControl): any => {
      return null;
    };
  }

  protected onInputChange(inputs: any) { }

  protected transformToModel(object: any): any {
    return object;
  }
}
