/* Angular modules */
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/* Third-party modules */
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

/* Own modules */
import { AllocationConstants } from '@gorila/widgets/allocation/src/config/allocation.constants';
import { AppConstants } from '@gorila/constants';

@Pipe({
  name: 'position',
  pure: true
})
export class PositionPipe implements PipeTransform {
  public temp = [];

  private readonly CopyPaste = ['_id', 'IssuerName', 'FundId', 'Currency', 'BuyOrSell', 'Description', 'ProductTypeName'];
  private readonly Dynamics = ['IssuerName', 'SecurityGlobalType', 'SecurityType', 'SecurityName', 'SecurityUniqueName', 'title'];
  private readonly FilterDates = ['MaturityDate', 'Maturity', 'RefDate', 'RefCalcDate', 'SettlementDate'];
  private readonly FilterNumbers = ['MtmNotional', 'Notional', 'Quantity', 'Price'];
  private readonly Ignored = ['BrokerList', 'LastModifiedDate', 'RowNumber'];
  private readonly UncommonType = ['TREASURY', 'CORPORATE_BONDS', 'SAVINGS', 'OTHERS'];

  public constructor(
    private datePipe: DatePipe,
    private translate: TranslateService
  ) { }

  public transform(position: any) {
    let newPosition: any = {};

    try {

      newPosition = this.initNewPosition(position);

      for (const field in position) {
        if (!position.hasOwnProperty(field)) { continue; }
        if (field === '_id') { continue; }
        const value = position[field];

        if (this.Ignored.indexOf(field) !== -1) {
          continue;
        }

        if (this.FilterDates.indexOf(field) !== -1) {
          newPosition[field] = this.filterDate(value);
          continue;
        }

        if (typeof value === 'number') {
          newPosition[field] = this.fixNumber(field, value, newPosition['SecurityGlobalType']);
          continue;
        }

        if (this.CopyPaste.indexOf(field) !== -1) {
          newPosition[field] = value;
          continue;
        }

        if (field === 'Key' && !this.missedProperty(value)) {
          newPosition['_id'] = value;
        }

        if (isNaN(parseFloat(value))) {
          newPosition[field] = value;
        }
      }
    } catch (e) {
      console.warn(e);
      return {};
    }

    return newPosition;
  }

  private initNewPosition(position: any): any {
    this.temp = position;
    this.setFields();
    this.setIssuerName();
    this.setDinamicFields();
    this.setBuyOrSell();
    this.setDescription();
    this.setProductTypeName();

    const newPosition = {};
    for (const field in this.temp) {
      if (!this.temp.hasOwnProperty(field)) { continue; }

      if (this.Dynamics.indexOf(field) !== -1) {
        newPosition[field] = this.temp[field];
      }
    }
    this.temp = [];

    return newPosition;
  }

  private filterDate(candidate: Date | string): Date {
    try {
      if (typeof candidate === 'undefined' || candidate === null) {
        return null;
      }
      if (candidate instanceof Date) { return candidate; }
      if (typeof candidate === 'string') {
        const v = moment.utc(candidate).format(AppConstants.Format.Date.American);
        return new Date(v);
      }
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }

  private fixNumber(field: string, num: number, productType: string): number {
    if (this.FilterNumbers.indexOf(field) === -1) { return num; }

    if (field !== 'Price') { return +num.toFixed(AllocationConstants.decimals['DEFAULT']); }

    const types = Object.keys(AllocationConstants.decimals).filter((t) => t !== 'DEFAULT');
    for (const type of types) {
      if (type === productType) {
        return +num.toFixed(AllocationConstants.decimals[type]);
      }
    }

    return +num.toFixed(AllocationConstants.decimals['DEFAULT']);
  }

  private missedProperty(value: any) {
    if (typeof value === 'undefined' || value === null) {
      return true;
    }

    if (typeof value === 'string' && value === '') {
      return true;
    }

    return false;
  }

  private setFields() {
    try {
      const s = this.temp['SecurityType'].split('_');
      let temp = s[0];
      if (typeof s[1] !== 'undefined') { temp += '_' + s[1]; }
      this.temp['SecurityGlobalType'] = temp.replace('_LOCAL', '');
      if (this.temp['SecurityGlobalType'].indexOf('STOCK') !== -1) {
        this.temp['SecurityGlobalType'] = 'STOCK';
        return;
      }
      if (this.temp['SecurityGlobalType'].indexOf('FUNDQUOTE') !== -1) {
        this.temp['SecurityGlobalType'] = 'FUNDQUOTE';
        return;
      }
      if (AllocationConstants.PricedSecurities.indexOf(this.temp['SecurityGlobalType']) === -1) {
        this.temp['SecurityGlobalType'] = 'OTHERS';
        return;
      } else if (this.temp['SecurityGlobalType'] === 'STOCK_ETF') {
        this.temp['SecurityGlobalType'] = 'ETF';
        this.temp['SecurityType'] = 'ETF';
        return;
      }
      this.temp['SecurityName'].split('_');
      this.checkEmptySecurityType();
    } catch (e) { console.warn(e); }
  }

  private checkEmptySecurityType() {
    if (this.temp['SecurityType'] !== '') { return; }
    const e = this.temp['SecurityName'].split('_');
    if (e[0] !== '') {
      if (e[0] === 'LCI' || e[0] === 'LCA' || e[0] === 'CDB') {
        this.temp['SecurityType'] = 'CORPORATE_BONDS';
        this.temp['SecurityGlobalType'] = 'CORPORATE_BONDS';
      }
      return;
    }
  }

  private setIssuerName() {
    try {
      this.temp['SecurityUniqueName'] = this.temp['SecurityName'];
      if (this.temp['SecurityGlobalType'] === 'SAVINGS' || this.temp['SecurityGlobalType'] === 'CORPORATE_BONDS') {
        const s = this.temp['SecurityName'].split('_');
        const i = (this.temp['SecurityGlobalType'] === 'SAVINGS') ? 3 : 6;
        s.splice(s.length - i, i);
        if (this.temp['SecurityGlobalType'] === 'SAVINGS') { s.splice(0, 1); }
        if (this.temp['SecurityGlobalType'] === 'CORPORATE_BONDS') {
          this.temp['SecurityCategory'] = s[0] + ' ' + s[1];
          if (s[1] === 'POS' && !isNaN(s[2])) {
            s.splice(0, 3);
          } else { s.splice(0, 2); }
        }

        this.temp['IssuerName'] = s.join(' ');
        if (this.temp['SecurityGlobalType'] === 'SAVINGS') {
          this.temp['SecurityUniqueName'] = this.temp['SecurityType'] + ' ' + this.temp['IssuerName'];
        }
      } else if (this.temp['SecurityGlobalType'].indexOf('TREASURY') !== -1) {
        this.temp['IssuerName'] = this.translate.instant('TREASURY_ISSUER');
      } else {
        this.temp['IssuerName'] = '';
      }
      if (!this.temp['IssuerName']) { this.temp['IssuerName'] = ''; }
    } catch (e) { console.warn(e); }
  }

  private setDinamicFields() {
    try {
      if (this.UncommonType.indexOf(this.temp['SecurityGlobalType']) !== -1) {
        if (
          this.temp['SecurityGlobalType'] === 'CORPORATE_BONDS' ||
          this.temp['SecurityGlobalType'] === 'SAVINGS'
        ) {
          this.temp['title'] = this.temp['IssuerName'];
        } else if (this.temp['SecurityGlobalType'] === 'TREASURY') {
          this.treasuryName();
        } else if (this.temp['SecurityGlobalType'] === 'OTHERS') {
          this.othersName();
        } else {
          this.temp['title'] = this.temp['SecurityName'];
        }
      } else {
        this.temp['title'] = this.temp['SecurityName'];
      }
    } catch (e) { console.warn(e); }
  }

  private treasuryName() {
    try {

      if (!this.temp['SecurityType'] || !this.temp['MaturityDate']) {

        if (typeof this.temp['SecurityName'] === 'undefined') {
          console.error('Failure to discover title this security! No SecurityName, SecurityType and MaturityDate!');
          this.temp = null;
          return '';
        }

        const i = this.temp['SecurityName'].length - 1;
        if (!this.temp['MaturityDate']) {
          const str: string = this.temp['SecurityName'].substr(i - 7, i);
          this.temp['MaturityDate'] = moment.utc(str).format(AppConstants.Format.DateTime.American);
        }
        if (!this.temp['SecurityType']) {
          this.temp['SecurityType'] = this.temp['SecurityName'].substr(0, i - 7);
        }
      }

      const t1 = this.translate.instant(this.temp['SecurityType']);

      if (this.temp['MaturityDate'] === null) {
        console.warn('Treasury without maturity date found: ' + t1);
        this.temp['title'] = t1;
        return;
      }

      const t2 = this.datePipe.transform(this.temp['MaturityDate'], 'dd/MM/yyyy');
      this.temp['title'] = t1 + ' ' + t2;

    } catch (e) { this.temp['title'] = this.temp['SecurityName']; }
  }

  private othersName() {
    try {

      if (typeof this.temp['SecurityType'] === 'undefined' || typeof this.temp['MaturityDate'] === 'undefined') {

        if (typeof this.temp['SecurityName'] === 'undefined') {
          console.error('Failure to discover title this security! No SecurityName, SecurityType and MaturityDate!');
          this.temp = null;
          return '';
        }

        const i = this.temp['SecurityName'].length - 1;
        if (typeof this.temp['MaturityDate'] === 'undefined') {
          const str: string = this.temp['SecurityName'].substr(i - 7, i);
          const date = str.substr(0, 4) + '-' + str.substr(4, 2) + '-' + str.substr(6, 2) + 'T00:00:00';
          this.temp['MaturityDate'] = date;
        }
        if (typeof this.temp['SecurityType'] === 'undefined') {
          this.temp['SecurityType'] = this.temp['SecurityName'].substr(0, i - 7);
        }
      }

      const secNameArray: Array<string> = this.temp['SecurityName'].split('_');
      const t1 = this.translate.instant(secNameArray[0]);
      const t2 = this.getIssuerFromSecurity(secNameArray);
      this.temp['title'] = t1 + ' ' + t2;

    } catch (e) { this.temp['title'] = this.temp['SecurityName']; console.warn(e); }
  }

  private getIssuerFromSecurity(securityName: Array<string>): string {
    if (securityName.indexOf('ETF') !== -1) {
      return this.temp['BrokerName'];
    } else {
      return securityName.slice(2, securityName.length - 3).join(' ');
    }
  }

  private setBuyOrSell() {
    this.temp['BuyOrSell'] = this.temp['Quantity'] > 0 ? 'BUY' : 'SELL';
  }

  private setProductTypeName() {
    const securityGlobalType = this.temp['SecurityGlobalType'];

    let productTypeName = '';

    if (securityGlobalType.indexOf('CORPORATE_BONDS') !== -1) {
      productTypeName = 'CORPORATE_BONDS';
    } else if (securityGlobalType.indexOf('TREASURY') !== -1) {
      productTypeName = 'TREASURY';
    } else if (securityGlobalType.indexOf('FUNDQUOTE') !== -1) {
      productTypeName = 'FUNDQUOTE';
    } else if (securityGlobalType.indexOf('STOCK') !== -1) {
      productTypeName = 'STOCK';
    } else if (securityGlobalType === 'SAVINGS') {
      productTypeName = 'SAVINGS';
    } else {
      productTypeName = 'OTHERS';
    }

    this.temp['ProductTypeName'] = productTypeName;
  }

  private setDescription() {
    this.temp['Description'] =
      (['SAVINGS', 'TREASURY'].indexOf(this.temp['SecurityGlobalType']) !== -1)
        ? this.translate.instant(this.temp['SecurityType'])
        : this.temp['title'];
  }
}
