import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';

@Injectable()
export class RouterWatcherService {

  private dataWatcher: BehaviorSubject<any> = new BehaviorSubject({});
  private titleWatcher: BehaviorSubject<string> = new BehaviorSubject('');
  private navigation = {};

  constructor(
    private loader: LoadingBarService,
    private router: Router,
    private titleService: Title
  ) { }

  public getUrl(): string {
    return this.router.url;
  }

  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    const temp = commands.toString();
    if (this.navigation[temp]) { return; }
    this.navigation[temp] = true;
    const out = this.router.navigate(commands, extras);
    var self = this; // tslint:disable-line
    out.then(() => {
      self.removeNavigation(temp);
    }).catch((error) => {
      console.error('Error when navigating:', error);
      self.removeNavigation(temp);
    });
    return out;
  }

  private getTitle(state: any, parent: any) {
    try {
      const data = new Array();
      if (parent && parent.snapshot.data && parent.snapshot.data.title) {
        data.push(parent.snapshot.data.title);
      }

      if (state && parent) {
        data.push(... this.getTitle(state, state.firstChild(parent)));
      }
      return data;
    } catch (e) { console.warn(e); }
  }

  private getData(state: any, parent: any) {
    try {
      const data = new Array();
      if (parent && parent.snapshot.data) {
        data.push(parent.snapshot.data);
      }

      if (state && parent) {
        data.push(... this.getData(state, state.firstChild(parent)));
      }
      return data;
    } catch (e) { console.warn(e); }
  }

  private removeNavigation(temp: string) {
    try {
      if (typeof this.navigation[temp] !== 'undefined') {
        delete this.navigation[temp];
      }
    } catch (e) { console.warn(e); }

  }

  public subscribeRoute() {
    /*
     * Do not unsubscribe from this event.
     * I want listen all app urls users logged in or not
     */
    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        try {
          console.warn(event.url, event.error['message']);
        } catch (e) { console.warn(e); }
      }

      if (event instanceof NavigationStart) {
        this.loader.stop();
      }

      if (event instanceof NavigationEnd) {
        try {
          const temp = this.getTitle(this.router.routerState, this.router.routerState.root);
          const title = temp[temp.length - 1];
          this.titleWatcher.next(title);
          this.titleService.setTitle(title);

          const temp2 = this.getData(this.router.routerState, this.router.routerState.root);
          this.dataWatcher.next(temp2);

          AnalyticsService.setEvent('virtual_page_view', {'url' : 'https://app.gorila.com.br' + this.getUrl(), 'title': title});
        } catch (e) { console.warn(e); }
      }
    });
  }

  public watchData = (): Observable<any> => this.dataWatcher.asObservable();
  public watchTitle = (): Observable<string> => this.titleWatcher.asObservable();
}
