import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { AdvancedProfitabilityRoutePath } from './metadata';
import { environment } from '@env/environment';

export const AdvancedProfitabilityMenuData = {
  featureName: 'advancedProfitability',
  routePath: AdvancedProfitabilityRoutePath,
  icon: 'Nav/Advanced-Tools/Advanced-Profitability-White',
  text: 'TOOLS_DROPDOWN.ADVANCED_PROFITABILITY',
  cancelNavigation: environment.features.tools.advancedProfitability.modal

};

export const AdvancedProfitabilityRoute: Route = {
  data: {
    title: 'Rentabilidade Avançada'
  },
  path: AdvancedProfitabilityRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: './pages/tools/advanced-profitability/advanced-profitability.module#AdvancedProfitabilityModule'
};
