<ng-container *ngIf="data.partnerHeader; else noDefPartnerHeaderTmpl">
  <gor-partner-header [borderless]="data.partnerHeader.borderless" [customTitleText]="data.partnerHeader.customTitleText" [isBeta]="data.partnerHeader.isBeta"
    [partnerLogoFolder]="data.partnerHeader.partnerLogoFolder" [partnerLogoName]="data.partnerHeader.partnerLogoName"
    [partnerName]="data.partnerHeader.partnerName" [partnerType]="'_'+data.partnerHeader.partnerType" [helpLink]="data.partnerHeader.helpLink" [showCloseButton]="data.partnerHeader.showCloseButton">
  </gor-partner-header>
</ng-container>

<ng-template #noDefPartnerHeaderTmpl>
  <ng-container *ngIf="!data.loading">
    <gor-icon iconFolder="Platform" icon="Close" width="20px" height="20px" class="close-button" mat-dialog-close>
    </gor-icon>
  </ng-container>
  
  <!-- Image above title -->
  <ng-container *ngIf="data.topImage">
    <gor-icon [ngClass]="data.topImage.class" class="partner-modal__top-image" [width]="data.topImage.width"
      [height]="data.topImage.height" [iconFolder]="data.topImage.iconFolder" [icon]="data.topImage.icon"
      [ngStyle]="{'min-height.px': data.topImage.height, display: 'block'}">
    </gor-icon>
  </ng-container>
  
  <!-- The modal title -->
  <h6 class="partner-modal__title">
    {{ data.title | translate }}
  </h6>
  
  <!-- Image below title -->
  <ng-container *ngIf="data.bottomImage">
    <gor-icon [ngClass]="data.bottomImage.class" class="partner-modal__bottom-image" [width]="data.bottomImage.width"
      [height]="data.bottomImage.height" [iconFolder]="data.bottomImage.iconFolder" [icon]="data.bottomImage.icon"
      [ngStyle]="{'min-height.px': data.bottomImage.height, display: 'block'}">
    </gor-icon>
  </ng-container>
  
  <!-- Provider info -->
  <ng-container *ngIf="data.provider">
    <div class="partner-modal__provider" [ngClass]="data.provider.class">
      <h6 class="partner-modal__provider-title" [innerHtml]="(data.provider.title | translate)|gorKeepHtml"></h6>
      <h2 class="partner-modal__provider-name" translate>{{data.provider.name}}</h2>
    </div>
  </ng-container>
</ng-template>

  
  <!-- The modal subtitle -->
  <h6 class="partner-modal__subtitle" *ngIf="data.subtitle" [ngClass]="data.type"  [innerHtml]="(data.subtitle | translate)|gorKeepHtml">
    {{ data.subtitle | translate }}
  </h6>

<!-- HTML Content -->
<ng-container *ngIf="data.htmlContent">
  <div class="partner-modal__body" [innerHtml]="data.htmlContent"></div>

  <!-- Image below HTML content -->
  <ng-container *ngIf="data.bodyImage">
    <gor-icon [ngClass]="data.bodyImage.class" class="partner-modal__body-image" [width]="data.bodyImage.width"
      [height]="data.bodyImage.height" [iconFolder]="data.bodyImage.iconFolder" [icon]="data.bodyImage.icon"
      [ngStyle]="{'height.px': data.bodyImage.height, display: 'block'}">
    </gor-icon>
  </ng-container>
</ng-container>

<div class="partner-modal__footer" [class.loading]="data.loading">
  <!-- Loading -->
  <ng-container *ngIf="data.loading; else modalAction">
    <gor-loading class="partner-modal__loading" [stopLoading]="false" [message]="''" [size]="38" [thickness]="4">
    </gor-loading>
  </ng-container>

  <!-- Action buttons -->
  <ng-template #modalAction>
    <ng-container *ngIf="data.buttons">
      <div class="partner-modal__buttons">
        <ng-container *ngFor="let button of data.buttons">
          <ng-container *ngTemplateOutlet="button.link ? linkTmpl : buttonTmpl; context: {$implicit:button}"></ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #buttonTmpl let-button>
    <gor-button class="partner-modal__button" [btnType]="button.main?'green':'gray'" (click)="close(button.id)">
      {{ button.text | translate }}
    </gor-button>
  </ng-template>

  <ng-template #linkTmpl let-button>
    <a [href]="button.link" target="_blank">
      <gor-button class="partner-modal__button" [btnType]="button.main?'green':'gray'">
        {{ button.text | translate }}
      </gor-button>
    </a>
  </ng-template>

  <!-- Footer HTML Content -->
  <ng-container *ngIf="data.footerDisclaimer">
    <div class="partner-modal__disclaimer" [innerHtml]="data.footerDisclaimer|gorKeepHtml"></div>
  </ng-container>

  <ng-container *ngIf="data.showSecurityDisclaimer">
    <security-disclaimer [urlGuia]="data.partnerHeader?.helpLink"></security-disclaimer>
  </ng-container>
</div>