import { IAllocationPercentagesRequestModel } from '@gorila-bot/gorila-front-models';
import { AllocationAssetsInfo } from '@gorila-bot/allocation-assets-group';
import { Action } from '@ngrx/store';

export const ACTION_TAG = '[AllocationPercentages]';

export enum AllocationPercentagesActionTypes {
  AllocationPercentagesClearAction = '[AllocationPercentages] Clear Allocation Percentages',
  AllocationPercentagesLoadAction = '[AllocationPercentages] Load Allocation Percentages',
  _AllocationPercentagesLoadAction = '[AllocationPercentages] Load Allocation Percentages END',
  AllocationPercentagesReportLoad = '[AllocationPercentages] Load Allocation Percentages Report',
  _AllocationPercentagesReportLoad = '[AllocationPercentages] Load Allocation Percentages Report END',
}

export class AllocationPercentagesClear implements Action {
  public readonly type = AllocationPercentagesActionTypes.AllocationPercentagesClearAction;

  public constructor() { }
}

export class AllocationPercentagesLoad implements Action {
  public readonly type = AllocationPercentagesActionTypes.AllocationPercentagesLoadAction;

  public constructor(public payload: IAllocationPercentagesRequestModel) { }
}

// tslint:disable:class-name
export class _AllocationPercentagesLoad implements Action {
  public readonly type = AllocationPercentagesActionTypes._AllocationPercentagesLoadAction;

  constructor(public data: any, public error?: any) { }
}

export class AllocationPercentagesReportLoad implements Action {
  public readonly type = AllocationPercentagesActionTypes.AllocationPercentagesReportLoad;

  public constructor(public payload: Array<{ date: string; label: string }>) { }
}

export class _AllocationPercentagesReportLoad implements Action {
  public readonly type = AllocationPercentagesActionTypes._AllocationPercentagesReportLoad;

  constructor(public data: { currentGrossEquity: number; percentages: AllocationAssetsInfo }, public error?: any) { }
}

export type AllocationPercentagesActions = AllocationPercentagesLoad;
export type _AllocationPercentagesActions = _AllocationPercentagesLoad;
export type AllocationPercentagesReportActions = AllocationPercentagesReportLoad;
export type _AllocationPercentagesReportActions = _AllocationPercentagesReportLoad;
export type ClearAllocationPercentagesActions = AllocationPercentagesClear;
