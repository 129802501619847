import { Injectable } from '@angular/core';
import { CardConfig, cardsConfig } from '@gorila-bot/empty-portfolio';
import { UserAccountList, UserAccountSelectors } from '@gorila/root-store/user-account';
import { select, Store } from '@ngrx/store';
import { isEmpty } from 'ramda';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class CardsService {
  public cardsConfigLocal: CardConfig[] = [{
    id: 'blotter',
    iconPath: 'Platform/Add',
    title: 'EMPTY_PORTFOLIO.CARDS.BLOTTER.TITLE',
    text: 'EMPTY_PORTFOLIO.CARDS.BLOTTER.TEXT',
    route: ['app', { outlets: { transacoes: ['transacao', 'comprar'] } }],
  }, {
    id: 'connect',
    iconPath: 'Nav/Connected-Accounts',
    title: 'EMPTY_PORTFOLIO.CARDS.CONNECT.TITLE',
    text: 'EMPTY_PORTFOLIO.CARDS.CONNECT.TEXT',
    route: ['app', 'area-do-usuario', 'contas'],
  }, {
    id: 'brokerage-note',
    iconPath: 'Platform/Upload',
    title: 'EMPTY_PORTFOLIO.CARDS.BROKERAGE_NOTE.TITLE',
    text: 'EMPTY_PORTFOLIO.CARDS.BROKERAGE_NOTE.TEXT',
    route: ['app', 'ferramentas', 'importar-arquivos', 'modelo', 'nota-de-corretagem'],
  }];

  constructor(
    private store: Store<any>,
  ) { }

  private getAccounts = (): Observable<UserAccountList> =>
    this.store.pipe(
      select(UserAccountSelectors.selectFeatureAccounts)
    )

  /**
   * Return a Card Config
   */
  public getCardsConfig(): Observable<CardConfig[]> {
    return this.getAccounts().pipe(
      filter(data => !isEmpty(data)),
      map(data => cardsConfig.map(cardConfig => cardConfig.id === 'connect' ?
          { ...cardConfig, text: `EMPTY_PORTFOLIO.CARDS.CONNECT.TEXT_${data.filter(x => x.providerId !== 666) ? 'B3' : 'CEI'}` }
          : cardConfig)),
      );
  }
}
