<div class="hide-icon-container"
gorTippy
[tippyOptions]="{
  content: tippyContentForDisplayMode[displayMode]
}"
>
  <img *ngIf="hide; else show" class="hide-icon" src="/assets/img/visibility-hide.svg" (click)="onClick()">
</div>

<ng-template #show>
  <img class="hide-icon" src="/assets/img/visibility-show.svg" (click)="onClick()">
</ng-template>
