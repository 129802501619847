import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { HeaderComponentEvent } from '../events.model';

@Component({
  selector: 'gor-header-hide',
  templateUrl: './header-hide.component.html',
  styleUrls: ['./header-hide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderHideComponent {
  private _hide = false;
  @Input() public set hide(hide: boolean) {
    if (!!hide !== this._hide) {
      this._hide = !!hide;
      this.updateDisplayMode();
    }
  }
  public get hide() {
    return this._hide;
  }

  @Output() public reduce = new EventEmitter<HeaderComponentEvent>();

  public displayMode: 'show' | 'hide' = 'show';
  public tippyContentForDisplayMode = {
    hide: this.translate.instant('HEADER.HIDE.SHOW'),
    show: this.translate.instant('HEADER.HIDE.HIDE')
  };

  public constructor(private translate: TranslateService) { }

  private updateDisplayMode() {
    this.displayMode = this._hide ? 'hide' : 'show';
  }

  public onClick() {
    this._hide = !this._hide;
    this.updateDisplayMode();
    this.reduce.emit({ type: 'HideChange', data: this._hide });
  }
}
