import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '@env/environment';

class SentryErrorHandler implements ErrorHandler {
  public handleError(error) {
    try {
      Sentry.captureException(error.originalError || error.error || error);
    } catch (e) {
      console.error('Sentry error:', e);
      console.error(error);
    }
  }
}

/**
 * TODO after angular update, add the follow to AppModule in place of SentryErrorHandler:
 *
 * useValue: Sentry.createErrorHandler({
 *      showDialog: true,
 * }),
 *
 * Also, import Sentry from @sentry/angular
 */
export function getErrorHandler(): ErrorHandler {
  if (!environment.production) {
    return new ErrorHandler();
  }

  return new SentryErrorHandler();
}
