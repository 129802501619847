// tslint:disable:trailing whitespace
// tslint:disable:max-line-length
import { environment } from '@env/environment';

const isProd = environment.production;

export const BitcoinTrade = { // Bitcoin Trade
  id: 607,
  logo: '',
  enabled: true,
  description:
    'Informe o ID e Segredo da Chave de API criada no Mercado Bitcoin e aceite os termos para conectar as contas.',
  terms:
    `<b>(i)</b> autorizo a plataforma Gorila a acessar minha conta na corretora Bitcoin Trade (
      <a href="https://bitcointrade.com.br/">https://bitcointrade.com.br/</a>) usando dados de acesso informados por mim;
      e <b>(ii)</b> autorizo expressamente o Gorila, na qualidade de mandatário, a acessar meus dados financeiros disponibilizados na
      Bitcoin Trade.`,
  urlGuia: 'https://guia.gorila.com.br/hc/pt-br/articles/360053199192-Conectar-Contas-BitcoinTrade',
  fields: {
    key: 'Segredo de API'
  }
};

export const B3 = { // B3
  id: 666,
  logo: 'B3-Positive',
  enabled: true,
  preConnect: true,
  fullName: 'B3',
  description:
    ``,
  terms: 'Autorizo a plataforma Gorila a acessar os meus dados financeiros, do CPF por mim informado, através da API disponibilizada pela B3, que mantém o histórico de movimentações e posições de ativos na(s) conta(s) das corretoras nas quais opero.',
  urlGuia: 'https://gorila.com.br/guia/integracoes/conectar-gorila-cei',
  // tslint:disable-next-line
  urlB3Authorize: 'https://b3investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=88525b52-a2e4-4e61-9a50-4400e4db349e&nonce=defaultNonce&scope=openid&response_type=code&prompt=login&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br',
  urlB3Deauthorize: 'https://www.investidor.b3.com.br/',
  availableIntegrationType: [{
    type: 'FOCUS_ON_POSITION',
    description: 'B3_POSITION',
    prosCons: [
      { text: 'B3_POSITION_DESCRIPTION' }
    ]
  }, {
    type: 'FOCUS_ON_TRADE',
    description: 'B3_TRADE',
    prosCons: [
      { text: 'B3_TRADE_DESCRIPTION' }
    ]
  }],
  fields: {
    login: true
  },
  dataStepped: true
};

export const CEI = { // CEI
  id: 0,
  logo: '',
  enabled: true,
  fullName: 'CEI - Canal Eletrônico do Investidor',
  posConnect: {
    title: 'active connection with',
    buttonLabel: 'Understood',
    textBody1: 'posConnectCEITextBody1',
    footer: 'deleteManualTradesFooterHint'
  },
  expectations: {
    title: 'What to expect from connecting to',
    showModalBeforeConnect: true,
    buttonLabel: 'Understood',
    list: [
      { icon: 'Investments', text: 'expectationsCEIInvestments' },
      { icon: 'Calendar', text: 'expectationsCEICalendar' },
      { icon: 'Clock', text: 'expectationsCEIClock' },
      { iconFolder: 'Status', icon: 'sync', text: 'expectationsCEISync' },
    ]
  },
  description:
    `Conecte sua conta do CEI ao Gorila e sincronize a compra e venda de Ações, Opções,
      FIIs e Tesouro Direto dos últimos 18 meses automaticamente.`,
  terms:
    `<b>(i)</b> autorizo a plataforma Gorila a acessar o CEI - Canal Eletrônico do Investidor
      (<a href="https://cei.b3.com.br/">https://cei.b3.com.br</a>) usando o CPF e a respectiva senha de acesso informada por mim;
      e <b>(ii)</b> autorizo expressamente o Gorila, na qualidade de mandatário, a acessar meus dados financeiros disponibilizados no
      sistema do CEI que mantém o histórico de movimentações de ativos na(s) conta(s) das corretoras nas quais opero.`,
  // tslint:disable-next-line
  urlGuia: 'https://gorila.com.br/guia/integracoes/conectar-gorila-cei',
  // tslint:disable-next-line
  availableIntegrationType: [{
    type: 'FOCUS_ON_POSITION',
    description: 'CEI_POSITION',
    prosCons: [
      { text: 'CEI_POSITION_PROS_1' },
      { text: 'CEI_POSITION_PROS_2' },
      { text: 'CEI_POSITION_CONS_1' }
    ]
  }, {
    type: 'FOCUS_ON_TRADE',
    description: 'CEI_TRADE',
    prosCons: [
      { text: 'CEI_TRADE_PROS_1' },
      { text: 'CEI_TRADE_CONS_1' }
    ]
  }],
  fields: {
    login: true,
    password: false
  },
};

export const Genial = { // Genial
  id: 628,
  logo: 'Genial-Positive',
  enabled: true,
  integrationType: {
    connection: isProd ? 'GenialInv' : 'GenialDev',
    hasToAwaitData: false,
  },
  fullName: 'Genial Investimentos',
  posConnect: {
    textBody1: 'posConnectGenialTextBody1',
    textBody2: 'posConnectDeleteDuplicatedManualTrades',
  },
  preConnect: {
    title: 'connect with',
    buttonLabel: 'Next',
    textBody1: 'To connect the partner account',
    list: [
      { icon: 'Calendar', text: 'expectationsGenialCalendar' },
      { icon: 'Attention', text: 'expectationsGenialAttetion' },
    ]
  },
  expectations: {
    title: 'What to expect from connecting to',
    showModalBeforeConnect: false,
    buttonLabel: 'Understood',
    list: [
      { icon: 'Investments', text: 'expectationsGenialInvestments' },
      { icon: 'Calendar', text: 'expectationsGenialCalendar' },
      { icon: 'Clock', text: 'expectationsGenialClock' },
      { icon: 'Attention', text: 'expectationsGenialAttetion' },
      { iconFolder: 'Status', icon: 'sync', text: 'expectationsGenialSync' },
    ]
  },
  description:
    `Conecte sua conta da Genial ao Gorila e sincronize as compras e vendas de seus investimentos automaticamente.<br>
      Após aceitar os termos, você será redirecionado para a página da corretora Genial Investimentos para autorizar a conexão.
      `,
  terms:
    `<b>(i)</b> autorizo a plataforma Gorila a acessar minha conta na corretora Genial (
      <a href="https://www.genialinvestimentos.com.br/">https://www.genialinvestimentos.com.br/</a>) usando dados de acesso
      informados por mim; e <b>(ii)</b> autorizo expressamente o Gorila, na qualidade de mandatário, a acessar meus dados
      financeiros disponibilizados na Genial.`,
  urlGuia: 'https://guia.gorila.com.br/hc/pt-br/articles/360053199332-Conectar-Contas-Genial',
  fields: {}
};

export const MercadoBitcoin = { // MB
  id: 608,
  logo: 'MercadoBitcoin-Positive',
  enabled: true,
  preConnect: true,
  posConnect: {
    textBody1: 'posConnectMercadoBitcoinTextBody1',
  },
  description: `
    <span style="display:block;text-align:left"><b>Para conectar à conta é preciso gerar uma Chave de API no site do Mercado Bitcoin e ter salvo o Identificador (ID) e o Segredo da API.</b></span>
    <span style="margin-top:8px;display:block;text-align:left;color:#f0712c"><b>Atenção:</b> por motivos de segurança, pedimos para selecionar que a chave tenha acesso de <b>“Somente leitura”</b>.</span>
  `,
  terms:
    `<b>(i)</b> autorizo a plataforma Gorila a acessar minha conta na corretora Mercado Bitcoin (
      <a href="https://www.mercadobitcoin.com.br/">https://www.mercadobitcoin.com.br/</a>) usando dados de acesso informados por mim;
      e <b>(ii)</b> autorizo expressamente o Gorila, na qualidade de mandatário, a acessar meus dados financeiros disponibilizados na
      Mercado Bitcoin.`,
  urlGuia: 'https://guia.gorila.com.br/hc/pt-br/articles/360053199372-Conectar-Contas-Mercado-Bitcoin',
  fields: {
    key_id: 'Identificador (ID)',
    key: 'Segredo de API'
  }
};

export const Orama = { // Orama
  id: 363,
  logo: '',
  enabled: true,
  integrationType: {
    connection: isProd ? 'OramaProd' : 'OramaDev',
    hasToAwaitData: true,
    hideSuccessModal: true,
  },
  fullName: 'Órama',
  posConnect: {
    textBody1: 'posConnectOramaTextBody1',
    textBody2: 'posConnectDeleteDuplicatedManualTrades',
  },
  expectations: {
    title: 'What to expect from connecting to',
    showModalBeforeConnect: false,
    buttonLabel: 'Understood',
    list: [
      { icon: 'Investments', text: 'expectationsOramaInvestments' },
      { icon: 'Clock', text: 'expectationsOramaClock' },
      { iconFolder: 'Status', icon: 'sync', text: 'expectationsOramaSync' },
    ]
  },
  description:
    `Conecte sua conta da Órama ao Gorila e sincronize as compras e vendas de seus investimentos automaticamente.<br>
    Após aceitar os termos, você será redirecionado para a página da corretora Órama para autorizar a conexão.
    `,
  terms:
    `<b>(i)</b> autorizo a plataforma Gorila a acessar minha conta na corretora Órama (
    <a href="https://www.orama.com.vc/">https://www.orama.com.vc/</a>) usando dados de acesso
    informados por mim; e <b>(ii)</b> autorizo expressamente o Gorila, na qualidade de mandatário, a acessar meus dados
    financeiros disponibilizados na Órama.`,
  urlGuia: 'https://guia.gorila.com.br/hc/pt-br/articles/4404189412251-Conectar-Contas-%C3%93rama',
  fields: {}
};

export const XP = { // XP
  id: 226,
  logo: '',
  enabled: true,
  fullName: 'XP Investimentos',
  posConnect: {
    title: 'active connection with',
    buttonLabel: 'Understood',
    textBody1: 'posConnectCrediGoXPTextBody1',
    footer: 'deleteManualTradesFooterHint'
  },
  expectations: {
    title: 'What to expect from connecting to',
    showModalBeforeConnect: true,
    buttonLabel: 'Understood',
    list: [
      { icon: 'Investments', text: 'expectationsCrediGoXPInvestments' },
      { icon: 'Clock', text: 'expectationsCrediGoXPClock' },
      { iconFolder: 'Status', icon: 'sync', text: 'expectationsCrediGoXPSync' },
    ]
  },
  accountSync: {
    title: 'syncing account with',
    textBody1: 'accountXPSync',
    textBody2: 'useAppWhileCrediGoXPSync',
    buttonLabel: 'Understood',
  },
  description:
    'Ativos de renda variável Ações, IPOs, Fundos imobiliários, ETFs, BDRs e Tesouro direto não serão importados. Complete sua carteira com esses ativos conectando sua conta da B3 no Gorila também.',
  terms:
    `<b>(i)</b> autorizo a plataforma Gorila a acessar minha conta na corretora XP Investimentos (
    <a href="https://xpi.com.br/">https://xpi.com.br/</a>) usando dados de acesso informados por mim;
    e <b>(ii)</b> autorizo expressamente o Gorila, na qualidade de mandatário, a acessar meus dados financeiros disponibilizados na
    XP Investimentos.`,
  fields: {}
};

export const FreeProviders = ['CEI', 'Genial'];
export const ProvidersWithoutSyncAfterConnection = [Genial.id, Orama.id];

// this stuff SHOULD COME FROM BACKEND -.-
export const UserAccountData = {
  '0': CEI,
  '226': XP,
  '363': Orama,
  '607': BitcoinTrade,
  '608': MercadoBitcoin,
  '609': { // FOXBIT
    logo: '',
    enabled: true,
    description:
      'Conecte sua conta da FOXBIT ao Gorila e sincronize a compra e venda das suas criptomoedas automaticamente.',
    terms:
      `<b>(i)</b> autorizo a plataforma Gorila a acessar minha conta na corretora FOXBIT (
      <a href="https://foxbit.com.br/">https://foxbit.com.br/</a>) usando dados de acesso informados por mim;
      e <b>(ii)</b> autorizo expressamente o Gorila, na qualidade de mandatário, a acessar meus dados financeiros disponibilizados na
      FOXBIT.`,
    urlGuia: 'https://guia.gorila.com.br/hc/pt-br/articles/360053692451-Conectar-Contas-FoxBit',
    fields: {
      login: 'ID do usuário',
      password: 'Senha',
      key: 'Chave de acesso',
      key_id: 'Chave secreta'
    }
  },
  '620': Genial,
  '628': Genial,
  '666': B3
};
