import { AppConstants } from '@gorila/constants';
import { LoadableState } from '@gorila/root-store/common/loadable-state';
import { IProfitabilityType } from '@gorila-bot/gorila-front-models';

type DatesType = { minDate: string, maxDate: string };

export interface UserPortfolioData extends LoadableState {
  dates: {
    [securities: string]: DatesType;

    'CURRENCY'?: DatesType;
    'FIXED INCOME'?: DatesType;
    'MULTIMARKET'?: DatesType;
    'STOCKS'?: DatesType;

    'PORTFOLIO'?: DatesType;
  };
}

export type LoadUserPortfolioDatesPayload = {
  asset?: boolean;
  product?: boolean;
  security?: boolean;
};
export type LoadedUserPortfolioDatesPayload = {
  [securities: string]: DatesType;

  'CURRENCY'?: DatesType;
  'FIXED INCOME'?: DatesType;
  'MULTIMARKET'?: DatesType;
  'STOCKS'?: DatesType;

  'PORTFOLIO'?: DatesType;
};

export const getEmptyPortfolioData = (): UserPortfolioData => ({
  dates: {
    portfolio: null
  },
  loading: false
});

export const action2Request = (payload: LoadUserPortfolioDatesPayload): IProfitabilityType[] => {
  if (!payload) { return []; }

  const defaulParam = { reduceProfitMaxPoints: 1, minDate: AppConstants.Blotter.minBookDate };
  const reqParams: IProfitabilityType[] = [defaulParam];

  if (payload.asset) {
    reqParams.push({ ...defaulParam, groupByAssetClass: true });
  }

  if (payload.product) {
    reqParams.push({ ...defaulParam, groupByProductType: true });
  }

  if (payload.security) {
    reqParams.push({ ...defaulParam, groupBySecurity: true });
  }

  return reqParams;
};

export const toLoadedPayload = (pp, p_id) => ({
  ...pp,
  [p_id.productType || p_id.assetClass || 'PORTFOLIO']: {
    maxDate: p_id.maxDate,
    minDate: p_id.minDate
  }
});
