import { IPercentAllocation } from '@gorila-bot/gorila-front-models';
import { AllocationAssetsInfo } from '@gorila-bot/allocation-assets-group';

export const Key = 'allocation-percentages';

export interface IAllocationPercentageState {
  error: any;
  loading: boolean;
  allocationData: { [fundId: string]: IPercentAllocation; };
}

export const initialState: IAllocationPercentageState = {
  error: null,
  loading: false,
  allocationData: {}
};

export interface IAllocationPercentagesReportState {
  error: any;
  currentGrossEquity: number;
  loading: boolean;
  percentages: AllocationAssetsInfo;
}

export const initialReportState: IAllocationPercentagesReportState = {
  error: null,
  currentGrossEquity: 0,
  loading: false,
  percentages: []
};
