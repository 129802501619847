import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { GenericAssetRoutePath } from './metadata';
import { environment } from '@env/environment';

export const GenericAssetMenuData = {
  featureName: 'genericAsset',
  routePath: GenericAssetRoutePath,
  icon: 'Product/Generic-Asset-White',
  text: 'Ativo Genérico',
  cancelNavigation: environment.features.tools.genericAsset.modal
};

export const GenericAssetRoute: Route = {
  data: {
    title: 'Ativo Genérico'
  },
  path: GenericAssetRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/generic-asset-shell#GenericAssetShellModule'
};
