import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@gorila-bot/gorila-base';
import { FundIdType, FundRequestService } from '@gorila-bot/user-data-store';
import { Store } from '@ngrx/store';
import { from, Observable, zip } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

@Injectable()
export abstract class RequestServiceBase<T, S = any[]|any> extends FundRequestService {

  public serviceBaseUrl;

  constructor(
    protected apiService: ApiService,
    protected store: Store<any>
  ) {
    super(store);
  }

  private urls: Array<any> = new Array();
  public abstract getParams(params: T, requestType?: string): T;
  public abstract getBaseUrl(fundId: FundIdType, requestType?: string): string;

  public getItem(params: T): Observable<any> {
    return this.getFundId().pipe(concatMap(fundId => {
      params = this.getParams(params);
      const baseUrl = this.getBaseUrl(fundId, 'get');
      const url = this.createUrl(baseUrl, params);
      return this.makeRequest(url);
    }));
  }

  public doRequest(params: T, filter?: string): Observable<S> {
    return this.getFundId().pipe(concatMap(fundId => {
      try {
        params = this.getParams(params);
        const baseUrl = this.getBaseUrl(fundId);
        const url = this.createUrl(baseUrl, params, filter);
        return this.makeRequest(url);
      } catch (e) { console.warn(e); }
    }));
  }

    protected createUrl(url: string, params: any, filter?: string): string {
      let concat = '?';
      for (const i in params) {
        if (typeof params[i] === 'undefined' || params[i] === null || params[i] === '') {
          continue;
        }
        url += concat + i + '=' + params[i];
        concat = '&';
      }
      if (filter) {
        return `${url}&${filter}`;
      }
      return url;
    }

    protected makeRequest(url: string): Observable<S> {
      let loadingBar = false;
      if (this.urls.indexOf(url) === -1) {
        this.urls.push(url);
        loadingBar = true;
      }

      const header = (url.indexOf('monthly') !== -1 || url.indexOf('annually') !== -1)
        ? {Authorization: `Bearer ${localStorage.getItem('id_token')}`} : this.getHeader();
      return this.apiService
        .getData((this.serviceBaseUrl ? this.serviceBaseUrl : environment.serviceBaseUrl) + url, header, loadingBar, null, true)
        .pipe(catchError((e: any) => {
          console.warn(e);
          return from([{ error: JSON.stringify(e) }]);
        })) as Observable<S>;
    }

  public deleteCache(params: T) {
    this.getFundId().pipe(tap(fundId => {
      params = this.getParams(params);
      const baseUrl = this.getBaseUrl(fundId);
      const url = this.createUrl(baseUrl, params);
      return this.apiService.deleteCache(url);
    }));
  }

  public doMultiRequests(itemType: any[]): Observable<any[]> {
    const observables: Array<Observable<any>> = [];
    itemType.forEach((item, key) => {
      observables.push(this.doRequest(item));
    });
    return zip(...observables);
  }

  public getHeader = () => ({});
}
