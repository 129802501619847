import { Pipe, PipeTransform } from '@angular/core';
import { breakString } from '@gorila-bot/gorila-front-utils';

@Pipe({
  name: 'stringBreak'
})
export class StringBreakPipe implements PipeTransform {

  public transform(input: string, limit = 40): string {
    return breakString(input, limit);
  }

}
