import { Injectable } from '@angular/core';
import { AppConstants } from '@gorila/constants';
import { AllocationPercentagesActions } from '@gorila/root-store/allocation-percentages';
import { PortfolioEventsAction } from '@gorila/pages/tools/portfolio-events';
import { TradeActions } from '@gorila/root-store/trade';
import { IListOperationParams } from '@gorila/root-store/trade/src/models/operation.model';
import { ITransferRequest } from '@gorila/root-store/transfer/src/lib/transfer.state';
import { IPositionServer } from '@gorila-bot/gorila-front-models';
import { ProfitabilityActions } from '@gorila-bot/profitability-store';
import { SocketEventService } from '@gorila-bot/gorila-base';
import { TransferActions } from '@gorila-bot/transfer-store';
import { Store } from '@ngrx/store';
import { values } from 'ramda';
import { interval, merge, Subject } from 'rxjs';
import { debounceTime, delay, filter, take, tap } from 'rxjs/operators';
import { DarfFacade } from '@gorila-bot/darf-store';

import * as PositionActions from '../actions/position.actions';
import { getPositionServerKey } from '../reducers/position.reducer';
import { utc } from 'moment';
import { LoadPortfolioEventsOptions } from '@gorila/pages/tools/portfolio-events/src/actions/portfolio-events.actions';

type PositionEvent = {
  Position: IPositionServer,
  Status: string
};
@Injectable()
export class PositionEventListenerService {
  private maxDate: string;
  private minDate: string;
  private muted = false;
  private notifier$: Subject<any>;
  private refDate = utc();
  private started = false;
  /**
   * Indicates if we will just update data if maxDate is before refDate.
   */
  private updateAllByRefDate = false;

  public constructor(
    private socketEventService: SocketEventService,
    private darfFacade: DarfFacade,
    private store$: Store<any>
  ) { }

  public configure(notifier$: Subject<any>) {
    this.notifier$ = notifier$;
  }

  public listenPositionEvents() {
    try {
      if (this.started) {
        return;
      }
      this.started = true;
      let toUpdate = {};
      const onMuteProp = () => !this.muted;
      const eventStatusFinish = (event: PositionEvent): boolean =>
        event && event.Status === 'FINISH';
      const withoutPortfolioEventsFn = (position: IPositionServer): boolean =>
        position.SecurityType && (
          AppConstants.productSubTypes.withoutPortfolioEvents[position.SecurityType]
          || position.SecurityType.startsWith('FUNDQUOTE')
        );
      merge(
        this.socketEventService.getObserver<PositionEvent>('POSITION_UPDATE').pipe(
          filter(onMuteProp),
          filter(eventStatusFinish),
          tap((event) => event && (toUpdate[getPositionServerKey(event.Position)] = event.Position)),
          delay(50),
          tap((event) => this.positionUpdate(event))
        ),
        this.socketEventService.getObserver<PositionEvent>('POSITION_REMOVE').pipe(
          filter(onMuteProp),
          filter(eventStatusFinish),
          tap((event) => event && (toUpdate[event.Position.Key] = event.Position)),
          delay(50),
          tap((event) => this.positionRemove(event))
        )
      ).pipe(debounceTime(1000)).subscribe({
        next: () => {
          const positions = values(toUpdate);
          const shouldCallPortfolioEvents = !positions.some(withoutPortfolioEventsFn);

          if (this.isMaxDateAfterRefDate()) {
            this.updateAllData({
              maxDate: this.maxDate,
              minDate: this.minDate,
              shouldCallPortfolioEvents
            });
            this.darfFacade.handlePositionsChange(positions);
            return;
          }

          toUpdate = {};
          if (positions.length > 10) {
            this.updateAllData({
              maxDate: this.maxDate,
              minDate: this.minDate,
              shouldCallPortfolioEvents
            });
          } else {
            this.darfFacade.handlePositionsChange(positions);
            interval(100).pipe(take(positions.length)).subscribe({
              next: () => {
                const position: IPositionServer = positions.pop();
                let broker = position.Broker;
                let security = position.SecurityName;

                // if security doesn't exist in position, is a position from
                // POSITION_REMOVE event with only the Key field
                if (!security) {
                  // SECURITY - FUNDID - BROKER
                  [security, , broker] = position.Key.split('-');
                }
                const params = {
                  broker,
                  productType: position.ProductType
                };
                if (position.ProductType !== 'CASH') {
                  this.updatePositionExplanation({ ...params, securityName: security });
                }
                this.updateTradeOperations({ ...params, securitySymbol: security });
              },
              complete: () => {
                this.updatePortfolioEvents({ forceLoad: shouldCallPortfolioEvents });
              }
            });
          }
        }
      });
      this.socketEventService
        .getObserver('PORTFOLIO_UPDATE')
        .pipe(filter(eventStatusFinish), debounceTime(1000))
        .subscribe({ next: this.portfolioUpdate.bind(this) });
      this.socketEventService
        .getObserver('PORTFOLIO_INVALIDATE')
        .pipe(filter(eventStatusFinish))
        .subscribe({ next: this.refreshPortfolio.bind(this) });
    } catch (e) {
      console.warn(e);
    }
  }

  public mute() {
    this.muted = true;
  }

  public setDates(minDate: string, maxDate: string) {
    this.minDate = minDate;
    this.maxDate = maxDate;
  }

  public unmute() {
    this.muted = false;
    this.refreshPortfolio();
  }

  private isMaxDateAfterRefDate() {
    return this.updateAllByRefDate ? this.maxDate && this.refDate.isAfter(this.maxDate, 'd') : false;
  }

  private positionUpdate(data: any) {
    if (!data.Position) {
      console.warn(`variable Position doesn't exists`, data);
      return;
    }

    this.notifier$.next({ type: 'POSITION_UPDATE', event: data });

    if (this.isMaxDateAfterRefDate()) {
      return;
    }

    this.store$.dispatch(new PositionActions.UpdatePosition(data.Position, data.FundId));
  }

  private positionRemove(data: any) {
    if (!(data.Position || {}).Key) {
      console.warn(`variable Key doesn't exists`, data);
      return;
    }

    this.notifier$.next({ type: 'POSITION_REMOVE', event: data });

    if (this.isMaxDateAfterRefDate()) {
      return;
    }
    this.store$.dispatch(new PositionActions.RemovePosition(data.Position.Key));
  }

  private portfolioUpdate() {
    try {
      this.store$.dispatch(new PositionActions.PositionLoad({}));
      this.store$.dispatch(new ProfitabilityActions.UpdatedPosition({}));
      this.updateAllocationPercentages();
    } catch (e) {
      console.warn(e);
    }
  }

  public refreshPortfolio() {
    try {
      this.updateTradeOperations({}, true);
      this.updatePortfolioEvents({ forceLoad: true });
      this.store$.dispatch(new PositionActions.PositionLoad({}));
      this.store$.dispatch(new ProfitabilityActions.UpdatedPosition({}));
      this.updateAllocationPercentages();
    } catch (e) {
      console.warn(e);
    }
  }

  private updateAllData({ maxDate, minDate, shouldCallPortfolioEvents }: {
    maxDate: string;
    minDate: string;
    shouldCallPortfolioEvents: boolean;
  }) {
    this.updatePortfolioEvents({ forceLoad: shouldCallPortfolioEvents });
    // How we can have transfers too, we don't check if product type can have
    // some event that change position
    this.updatePositionExplanation({ maxDate, minDate });
    this.updateTradeOperations({ maxDate, minDate });
    this.darfFacade.resetDarfData();
  }

  private updateAllocationPercentages() {
    this.store$.dispatch(new AllocationPercentagesActions.AllocationPercentagesLoad({}));
  }

  private updatePositionExplanation(params: ITransferRequest = {}) {
    this.store$.dispatch(new TransferActions.LoadTransfers(params));
  }

  private updatePortfolioEvents(params: LoadPortfolioEventsOptions) {
    this.store$.dispatch(new PortfolioEventsAction.PortfolioEventsLoad(params));
  }

  private updateTradeOperations(params: IListOperationParams = {}, forceUpdate = false) {
    this.store$.dispatch(new TradeActions.LoadTrade({ forceUpdate, params }));
  }
}
