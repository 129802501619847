import { Pipe, PipeTransform } from '@angular/core';
import { IOperation, TradeSourceEnum } from '@gorila-bot/gorila-front-models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'operationProvider',
    pure: true
})
export class OperationProviderPipe implements PipeTransform {
    public constructor(private translate: TranslateService) { }

    private static readonly PROVIDERS_MAP = {
        BITCOINTRADE: TradeSourceEnum.BROKER,
        B3: TradeSourceEnum.EXCHANGE,
        CEI: TradeSourceEnum.EXCHANGE,
        CREDIGO: TradeSourceEnum.CRAWLER,
        GENIAL: TradeSourceEnum.BROKER,
        FOXBIT: TradeSourceEnum.BROKER,
        MANUAL: TradeSourceEnum.MANUAL,
        MERCADOBITCOIN: TradeSourceEnum.BROKER,
        ORAMA: TradeSourceEnum.BROKER,
        RBDTVM: TradeSourceEnum.BROKER
    };
    public transform(value: IOperation | string, tooltip: boolean = false, defaultValueKey = TradeSourceEnum.UNKNOWN): string {
        try {
            const provider = typeof value === 'string' ? value : value.provider;
            const isUnknown = provider === 'UNKNOWN';
            const isManual = typeof value === 'object' && value.isUserCreated && !isUnknown;
            let pTranslateKey = OperationProviderPipe.PROVIDERS_MAP[provider] || defaultValueKey;

            if (isManual) {
              pTranslateKey = 'M';
            }

            const translateKey = `${tooltip ? 'TOOLTIP.' : ''}TRADE_SOURCE.${pTranslateKey}`;

            const arraySource = ['B3', 'CEI'];
            return arraySource.includes(provider) && !isManual
            ? `${this.translate.instant(translateKey)} (${provider})`
            : this.translate.instant(translateKey);

        } catch (e) {
            console.error(e, value);
            return '';
        }
    }
}
