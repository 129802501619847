import { or } from 'ramda';

import { BaseState, MakeState, ReducerBase } from '../../../base/reducer.base';
import { STATE_TYPES } from '../../../store.model';
import { BenchmarkAPIAction, BenchmarkAPIActions } from '../benchmark.actions';
import { BenchmarkGetType } from '../benchmark.model';

export const addBenchmark = (state: BaseState, a: BenchmarkAPIAction, extra) => {
  if (typeof extra.items[extra.key] === 'undefined') {
    extra.items[extra.key] = [];
  }
  return MakeState({ items: extra.items });
};

export const loadedBenchmark = (state: BaseState, a: BenchmarkAPIAction, extra) => {
  extra.items[extra.key] = a.payload;
  return MakeState({ items: extra.items });
};

export const loadError = (state: BaseState, a: BenchmarkAPIAction, extra) => {
  if (typeof extra.items[extra.key] !== 'undefined') {
    delete extra.items[extra.key];
  }
  return MakeState({ items: extra.items, loading: false, error: a.error });
};

export const clearBenchmark = (state: BaseState, a: BenchmarkAPIAction, extra) => {
  return MakeState({ items: {} });
};

export const beforeEachBenchmark = (state: BaseState, a: BenchmarkAPIAction) => ({
  key: BenchmarkGetType(a.meta.benchmarkType),
  items: or(state['items'], {})
});

/**
 * BASE METHODS
 */
export const checkState = (stateName: string) => (state: BaseState, a: BenchmarkAPIAction) => {
  if ([
    BenchmarkAPIActions.actions.addPnl,
    BenchmarkAPIActions.actions.loadedPnl
  ].indexOf(a.type.toString()) !== -1) {
    return false;
  }
  return !!a.meta && !!a.meta.benchmarkType && a.meta.benchmarkType.periodType === stateName;
};

export const BenchmarkReducer = (checkStateFn) => (state: BaseState = {}, a: BenchmarkAPIAction) => {
  return ReducerBase({
    [BenchmarkAPIActions.actions.addBenchmark]: addBenchmark,
    [BenchmarkAPIActions.actions.loadedBenchmark]: loadedBenchmark,
    [BenchmarkAPIActions.actions.addPnl]: addBenchmark,
    [BenchmarkAPIActions.actions.clear]: clearBenchmark
  }, state, a, STATE_TYPES.BENCHMARK, {
      beforeEach: beforeEachBenchmark,
      checkState: checkStateFn
    });
};

export const benchmarkDailyReducer = BenchmarkReducer(checkState('DAILY'));
export const benchmarkMonthlyReducer = BenchmarkReducer(checkState('MONTHLY'));
export const benchmarkYearlyReducer = BenchmarkReducer(checkState('YEARLY'));
