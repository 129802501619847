import { MatDialogConfig } from '@angular/material';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IntegrationType, UserAccountModel } from '@gorila/root-store/user-account/src/models/user-account.model';

import { UserAccountData } from '../../user-account.data';

export type ModalExpectationType = 'preConnect' | 'posConnect' | 'expectations' | 'accountSync';
export interface ExpectationsList {
  icon: string;
  text: string;
}
export interface Expectations {
  title: string;
  textBody1?: string;
  textBody2?: string;
  showModalBeforeConnect?: boolean;
  buttonLabel: string;
  list?: ExpectationsList[];
  footer?: string;
}
export interface DetailsIntegrationType {
  connection: string;
  hasToAwaitData: boolean;
  hideSuccessModal?: boolean;
}
export interface UserAccountDetailsModel {
  signUpFields: any;
  title: string;
  integrationType?: DetailsIntegrationType;
  fullName?: string;
  description: string;
  expectations?: Expectations;
  preConnect?: Expectations;
  posConnect?: Expectations;
  accountSync?: Expectations;
  fields: {
    login: boolean;
    password: boolean;
    key: boolean;
    key_id: boolean;
  };
  terms?: string;
}

// TODO - move to gorila-front-models
export enum CEIIntegrationType {
  POSITION = 'FOCUS_ON_POSITION',
  TRADE = 'FOCUS_ON_TRADE'
}

export interface UserAccountDialogData {
  title: string;
  description: string;
  urlGuia: string;
  terms: string;
  fields: {
    login: string;
    password: string;
    key: string;
    key_id: string;
  };
  signUpFields: string[];
  userAccountData: UserAccountModel;
  integrationType: IntegrationType;
  iframeUrl: SafeResourceUrl | null;
  dataStepped: boolean;
  partnerLogoName?: string;
}

export const buildModalConfig = (
  provider: UserAccountModel,
  iframeUrl?: SafeResourceUrl
): MatDialogConfig<UserAccountDialogData> => {
  const extra = UserAccountData[provider.providerId] || { description: '', steps: [], extra: {} };
  return {
    height: `${provider.providerId === 666 ? '670px' : 444 + extra.fields.length * 50 + 'px'}`,
    width: '510px',
    panelClass: 'no-padding-dialog',
    data: {
      title: provider.providerName,
      description: extra.description,
      urlGuia: extra.urlGuia,
      terms: extra.terms,
      fields: extra.fields,
      signUpFields: provider.signUpFields,
      userAccountData: provider,
      integrationType: provider.integrationType,
      dataStepped: extra.dataStepped,
      iframeUrl,
      partnerLogoName: extra.logo
    }
  };
};
