import { ActionTypes } from './dashboard.actions';

export function clearDashboardState(reducer) {
  return function (state, action) {

    if (action.type === ActionTypes.DASHBOARD_CLEAR) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
