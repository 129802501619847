import { IBenchmark, IBenchmarkType, IProfitability, IProfitabilityType } from '@gorila-bot/gorila-front-models';
import { AppConstants } from '@gorila/constants';
import { BenchmarkAPIActions, ProfitabilityPnlActions } from '@gorila/store';
import { ToggleData } from '@gorilainvest/ui-toolkit/slide-toggle';
import * as moment from 'moment';
import { mapObjIndexed, path } from 'ramda';

export namespace Pnl {
  export interface Type {
    id: string;
    nominal: number;
    percentual: number;
    grossup?: number;
    net?: number;
    color?: string;
  }

  export class Model {
    public lastYear: string;
    public lastMonth: string;
    public lastDay: string;
    public today: string;

    constructor() {
      const DateFormat = AppConstants.Format.Date;
      this.today = moment.utc().format(DateFormat.American);
      this.lastYear = moment.utc().subtract(1, 'years').endOf('year').format(DateFormat.American);
      this.lastMonth = moment.utc().subtract(1, 'months').endOf('month').format(DateFormat.American);
      this.lastDay = moment.utc().subtract(1, 'days').format(DateFormat.American);
    }

    public setPNLProfitability(profitabilityActions: ProfitabilityPnlActions, assetClass?: string) {
      try {
        if (!assetClass) { assetClass = ''; }
        const obj: IProfitabilityType = { assetClass: assetClass, maxDate: this.today };
        profitabilityActions.addPnl([
          { ...obj, periodType: 'DAILY', minDate: this.lastDay },
          { ...obj, periodType: 'MONTHLY', minDate: this.lastMonth },
          { ...obj, periodType: 'YEARLY', minDate: this.lastYear }
        ]);
      } catch (e) { console.warn(e); }
    }

    public convertProfitabilityInPNL(data: { [profit: string]: Array<IProfitability> }): (obj: any) => { [key: string]: Type } {
      return mapObjIndexed(
        (profit: IProfitability[], i: string) => this.mapItem(i, path([`${profit.length - 1}`], profit) || profit, false)
      );
    }

    public mapItem = (id, data, displayNetProfit: boolean) => ({
      id,
      nominal: +path([displayNetProfit ? 'netTotalPnl' : 'totalPnl'], data) || 0,
      percentual: path(['profitFactor'], data) ? data['profitFactor'].toFixed(4) - 1 : 0,
      grossup: path(['grossupProfitFactor'], data) ? data['grossupProfitFactor'].toFixed(4) - 1 : 0,
      net: path(['netProfitFactor'], data) ? data['netProfitFactor'].toFixed(4) - 1 : 0,
    })

    public mapAccumulatedItem = (id, data, displayNetProfit: boolean, ignoreNominal = false) => ({
      id,
      nominal: (ignoreNominal) ? null : path([displayNetProfit ? 'netAccumulatedPnl' : 'accumulatedPnl'], data) || 0,
      percentual: path(['profit'], data) ? data['profit'].toFixed(4) / 100 : 0,
      grossup: path(['grossupProfit'], data) ? data['grossupProfit'].toFixed(4) / 100 : 0,
      net: path(['netProfit'], data) ? data['netProfit'].toFixed(4) / 100 : 0,
    })

    public setPNLBenchmark(benchmarkActions: BenchmarkAPIActions, options: ToggleData) {
      try {
        const obj: IBenchmarkType = {
          benchmarkType: options.cod,
          benchmarkName: options.title,
          ratio: null,
          spread: null,
          outputFrequency: ''
        };
        benchmarkActions.addPnl([
          { ...obj, outputFrequency: 'DAILY', startDate: this.lastDay, endDate: this.today  },
          { ...obj, outputFrequency: 'MONTHLY', startDate: this.lastMonth, endDate: this.today },
          { ...obj, outputFrequency: 'YEARLY', startDate: this.lastYear, endDate: this.today }
        ]);
      } catch (e) { console.warn(e); }
    }

    public updateBenchmarks(data: { [benchmark: string]: Array<IBenchmark> }) {
      const benchmarks: Type[] = [];
      try {
        for (const period in data) {
          if (!data[period]) { continue; }
          const item = data[period];
          let percent = 0;
          if (item.length > 0) {
            const k = item.length - 1;
            percent = item[k].profitFactor - 1;
          }
          benchmarks.push({
            id: period,
            nominal: null,
            percentual: percent
          });
        }
      } catch (e) { console.warn(e); }
      return benchmarks;
    }

  }
}
