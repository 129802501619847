import { ManagerRoute } from '.';
import { ComparatorToolMenuData, ComparatorToolRoute } from './comparator';
import { FeeToolMenuData, FeeToolRoute } from './fee';
import { AdvisorInviteRoute } from './invite';
import { OfficeToolRoute } from './office';
import { B2BReportsMenuData } from './reports';
import { ImportSpreadsheetRoute } from '../tools/import-files';
import { ImportFilesMenuData, ImportFilesRoute } from '../tools/import-files';

export const ManagerRoutes = [
  ManagerRoute,
  ComparatorToolRoute,
  FeeToolRoute,
  AdvisorInviteRoute,
  OfficeToolRoute,
  ImportSpreadsheetRoute,
];

export const B2BToolsMenuData = [
  ComparatorToolMenuData,
  FeeToolMenuData,
  B2BReportsMenuData,
];
