import { UserAccountList, UserAccountModel } from './models/user-account.model';

export const Key = 'user-account';

export interface State {
  accounts: UserAccountList;
  currentAccount: UserAccountModel;
}

const initialAccounts = [];
const initialCurrentAccount = null;

export const initialState: State = {
  accounts: initialAccounts,
  currentAccount: initialCurrentAccount
};
