import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { ITrade } from '@gorila-bot/gorila-front-models';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES, StateType } from '../../store.model';
import { APIActions, clearAction, MetaData, getReduxActionType } from '../actions';
import { ActionType, ActionTransition } from '../actions.model';

export type TradeAPIAction = TradeAddAPIAction | TradeLoadAPIAction | TradeRemoveAPIAction;
export type TradeAddAPIAction = FluxStandardAction<ITrade|ITrade[], MetaData>;
export type TradeLoadAPIAction = FluxStandardAction<ITrade[], MetaData>;
export type TradeRemoveAPIAction = FluxStandardAction<string|string[], MetaData>;

const StateTypeTrade = STATE_TYPES.TRADE;
const actionType = {
  load: {
    data: getReduxActionType(ActionType.LOAD, StateTypeTrade, ActionTransition.DATA),
    start: getReduxActionType(ActionType.LOAD, StateTypeTrade, ActionTransition.STARTED),
    success: getReduxActionType(ActionType.LOAD, StateTypeTrade, ActionTransition.SUCCEEDED),
    fail: getReduxActionType(ActionType.LOAD, StateTypeTrade, ActionTransition.FAILED)
  },
  add: {
    data: getReduxActionType(ActionType.ADD, StateTypeTrade, ActionTransition.DATA),
    start: getReduxActionType(ActionType.ADD, StateTypeTrade, ActionTransition.STARTED),
    success: getReduxActionType(ActionType.ADD, StateTypeTrade, ActionTransition.SUCCEEDED),
    fail: getReduxActionType(ActionType.ADD, StateTypeTrade, ActionTransition.FAILED)
  },
  remove: {
    data: getReduxActionType(ActionType.REMOVE, StateTypeTrade, ActionTransition.DATA),
    start: getReduxActionType(ActionType.REMOVE, StateTypeTrade, ActionTransition.STARTED),
    success: getReduxActionType(ActionType.REMOVE, StateTypeTrade, ActionTransition.SUCCEEDED),
    fail: getReduxActionType(ActionType.REMOVE, StateTypeTrade, ActionTransition.FAILED)
  },
  clear: getReduxActionType(ActionType.CLEAR, StateTypeTrade)
};

@Injectable()
export class TradeAPIActions extends APIActions<ITrade> {
  public static actionType = actionType;

  protected readonly type = StateTypeTrade;

  @dispatch()
  public clear = (stateType: StateType): FluxStandardAction<ITrade[], MetaData> => clearAction(StateTypeTrade, stateType)
}
