import { environment } from '@env/environment';
import { BILLING_PLAN_ROUTE_PATH } from '@gorila-bot/billing-shell';
import { PERSONAL_PROFILE_ROUTE_PATH, PERSONAL_ACCOUNT_ROUTE_PATH } from '@gorila-bot/personal-shell';
import { PERSONAL_AREA_ROUTE_PATH } from '@gorila-bot/user-details-shell/routes';
import {
  ACCOUNT_SETTINGS_PATH,
  DashboardRoutePath,
  LogoutRoutePath,
  ManagerRoutePath,
  ToolsRoutePath,
  UserAccountsRoutePath,
  WalletRoutePath,
  OfficeToolRoutePath
} from '@gorila/core/router';
import { TranslateService } from '@ngx-translate/core';
import { routesWithHeaderIcons } from 'src/app/core/router/app-routes.config';

import { HeaderMenuItemInputs } from './header-menu/header-menu-item.inputs';
import { HeaderMenuItems } from './menu.items';

export const helpLink = 'chat';

const removeCanShow = (dt) => {
  if (!!dt['canShow']) {
    delete dt['canShow'];
  }
  return dt;
};

export const getMenuItems = (config): HeaderMenuItemInputs[] => {
  let iconsFromRoute = null;
  routesWithHeaderIcons.forEach(r => {
    if (config.route.indexOf(r.path) !== -1) {
      iconsFromRoute = r.headerIcons;
    }
  });
  const defaultItems = [
    {
      id: HeaderMenuItems.OFFICE,
      icon: 'Office-Dashboard',
      link: OfficeToolRoutePath,
      canShow: (): boolean => !!config.advisor && !!environment.features.tools.office.menu,
      size: '38px',
      tippyOptions: {
        content: config.translate.instant('Office')
      }
    },
    {
      id: HeaderMenuItems.ADVISOR,
      icon: 'Clients',
      link: ManagerRoutePath,
      canShow: (): boolean => !!config.advisor,
      size: '38px',
    },
    {
      id: HeaderMenuItems.DASHBOARD,
      icon: 'Dashboard',
      link: DashboardRoutePath,
      canShow: (): boolean => !!config.client,
      size: '36px',
      tippyOptions: {
        content: config.translate.instant('Dashboard')
      }
    },
    {
      id: HeaderMenuItems.WALLET,
      icon: 'Wallet',
      link: WalletRoutePath,
      canShow: (): boolean => !!config.client,
      size: '38px',
      tippyOptions: {
        content: config.translate.instant('Wallet')
      }
    },
    {
      id: HeaderMenuItems.TOOLS,
      icon: 'Advanced-Tools',
      link: ToolsRoutePath,
      disableRouting: true,
      tippyOptions: {
        html: '#tools_dropdown',
        theme: 'gorila-white',
        hideOnClick: false,
      },
      hasDropdown: true,
      canShow: (): boolean => true,
      size: '38px'
    },
    {
      id: HeaderMenuItems.ACCOUNTS,
      icon: 'Connected-Accounts',
      link: UserAccountsRoutePath,
      canShow: (): boolean => !config.advisor && !config.brokerClient,
      size: '38px',
      tippyOptions: {
        content: config.translate.instant('Connect accounts')
      }
    },
    {
      id: HeaderMenuItems.HELP,
      icon: 'Help',
      link: helpLink,
      disableRouting: true,
      canShow: (): boolean => environment.features.helpMenu,
      size: '36px',
      tippyOptions: {
        content: config.translate.instant('Help')
      }
    }
  ].filter(dt => !!dt.canShow()).map(removeCanShow);
  return iconsFromRoute
    ? defaultItems.filter(dt => iconsFromRoute.indexOf(dt.id) !== -1)
    : defaultItems;
};

export const getUserMenuDropdown = (
  translate: TranslateService,
  advisorMode: boolean,
  brokerClientMode: boolean
): HeaderMenuItemInputs[] => [
  {
    id: 'faq',
    title: 'Guia Gorila (FAQ)',
    icon: 'help',
    link: 'https://guia.gorila.com.br/hc/pt-br',
    canShow: (): boolean => !advisorMode
  },
  {
    id: 'account-settings',
    title: translate.instant('Account Settings'),
    link: `${ACCOUNT_SETTINGS_PATH}/${PERSONAL_AREA_ROUTE_PATH}/${
        advisorMode ? PERSONAL_PROFILE_ROUTE_PATH : PERSONAL_ACCOUNT_ROUTE_PATH
    }`,
    canShow: (): boolean => !brokerClientMode,
  },
  {
    id: 'plans',
    title: translate.instant('plans'),
    link: `${ACCOUNT_SETTINGS_PATH}/${BILLING_PLAN_ROUTE_PATH}`,
    canShow: (): boolean => advisorMode
  },
  {
    id: 'tutorial',
    title: translate.instant('tutorial'),
    icon: 'idea',
    link: '/tutorial',
    canShow: (): boolean => environment.features.tutorial && !advisorMode && !brokerClientMode
  },
  {
    id: 'exit',
    title: translate.instant('exit'),
    icon: 'logout',
    link: `../${LogoutRoutePath}`,
    canShow: (): boolean => true
  }
].filter(dt => !!dt.canShow()).map(removeCanShow);
