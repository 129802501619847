<div class="progress-group-cell" *ngFor="let p of data">
  <div class="progress-group-header">
    <span class="progress-group-title">{{p.title}}</span>
    <span class="progress-group-date">
      <!-- {{p?.date}} -->
    </span>
  </div>
  <div class="progress-group-body">
      <progress-bar
        [filledBackground]="fillGradientStyle"
        nonFilledBackground="#d6d4d4"
        [id]="p.id"
        [@progressBarInOut]="p.step < p.length"
        [max]="p.length"
        [progress]="p.step"
        [waiting]="p.waiting">
  </progress-bar>
  </div>
</div>
