import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataActions, UserDataState } from '@gorila-bot/user-data-store';
import { WhiteLabelService } from '@gorila-bot/white-label';
import { LoginStatusEnum, LoginStatusService } from '@gorila/core';
import { AuthNoLockRoutePath, AuthRoutePath, LogoutRoutePath } from '@gorila/core/router';
import { LoginService } from '@gorila/shared';
import { Store } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay, filter, map, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnDestroy, OnInit {
  public message = '';
  public hasError = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private loginService: LoginService,
    private loginStatusService: LoginStatusService,
    private store: Store<UserDataState.State>,
    private router: Router,
    private wlService: WhiteLabelService
  ) { }

  public ngOnInit() {
    this.routeListener();
    this.loginListener();
  }

  public ngOnDestroy() {}

  private routeListener() {
    this.route.url.pipe(
      untilDestroyed(this),
      map(segments => segments.map(segment => segment.path)),
      filter(segments => segments.indexOf(LogoutRoutePath) !== -1),
      tap(() => {
        this.authService.logout();
        this.loginService.logout();
        this.store.dispatch(new UserDataActions.UserLogout());
      }),
      delay(1000)
      ).subscribe(() => {
        this.router.navigate([AuthRoutePath], { relativeTo: this.route });
    });
  }

  private loginListener() {
    this.loginStatusService.getObserver().pipe(untilDestroyed(this)).subscribe(
      () => {
        const data = this.loginStatusService.getCurrentValue();
        if ([LoginStatusEnum.Error, LoginStatusEnum.Auth0NotLogged].indexOf(data['code']) !== -1) {
          return this.redirectToLoginRoute();
        }
        this.setMessages(data);
        this.hasError = [LoginStatusEnum.Error].indexOf(data['code']) !== -1;
      },
      (error) => {
        console.warn(error);
      }
    );
  }

  private setMessages(data: any) {
    try {
      if (!!data['message']) {
        this.wlService.translate$(data['message']).subscribe(d => (this.message = d));
      }
    } catch (e) {
      console.warn(e);
    }
  }
  private redirectToLoginRoute = () => this.router.navigate([AuthNoLockRoutePath], { relativeTo: this.route });
}
