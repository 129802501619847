import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TransferEffects } from './effects/transfer.effects';
import * as fromTransfer from './reducers/transfer.reducer';
import { TransferRequestService } from './services/transfer-request.service';
import { clearTransferState, Key } from './transfer.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(Key, fromTransfer.reducer, { metaReducers: [clearTransferState] }),
    EffectsModule.forFeature([TransferEffects])
  ],
  providers: [
    TransferRequestService
  ]
})
export class TransferStoreModule { }
