/* tslint:disable radix */
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@gorila-bot/gorila-base';
import { LoginStatusEnum, LoginStatusService, UtilsService } from '@gorila/core';
import { LoginService, UpdaterService } from '@gorila/shared';
import { LoadingBarService } from '@ngx-loading-bar/core';

import { PositionModel } from '../model/position.model';

@Injectable()
export class PositionService extends UpdaterService {
  private url = environment.serviceBaseUrl;
  private ProductTypeName = '';
  private lastProductType = 'none';
  private positionsArray: Array<any> = new Array();
  private savings: Array<any> = new Array();
  /*
   * this variable is true when positions array is updated by server,
   * false if positions array is filtered. this can be used by components
   */
  private loaded = false;
  private _isUpdated = true;
  public constructor(
    protected loginStatusService: LoginStatusService,
    protected loadingBarService: LoadingBarService,
    protected apiService: ApiService,
    private loginService: LoginService,
    private positionModel: PositionModel
  ) { super(loginStatusService, loadingBarService); }

  public start(loadingBar?: boolean): void {
    if (!loadingBar) { loadingBar = false; } else { this.updatingStart(); }

    const ProductTypeName = this.ProductTypeName;
    if (this.lastProductType !== ProductTypeName) { loadingBar = true; }
    this.lastProductType = ProductTypeName;

    if (this.subscriptionExists('position_list')) { return; }
    const url = this.getPortfolioUrl();
    // tslint:disable-next-line
    var self = this;
    this._attach('position_list', this.apiService.getData(url, {}, loadingBar, null, true).subscribe(
      (data: Array<any>) => self.initPositions(data),
      (error: any) => {
        console.warn(error);
        self.loaded = true;
        self.updatingStop();
      }
    ));
  }

  private getPortfolioUrl(excludeProductType?: boolean) {
    const fundId = this.loginService.getFundID();
    const url = `${this.url}portfolio/${fundId}/positions?`;
    return (excludeProductType) ? url : `${url}productType=`;
  }

  private initPositions(data: Array<any>) {
    try {
      this.savings = new Array();
      this.positionsArray = data.filter(position => {
        if (this.isValid(position)) { return true; }
        console.warn('### INVALID ### THIS POSITION IS INVALID!', position);
        return false;
      }).map(position => {
        if (position['SecurityGlobalType'] === 'SAVINGS') {
          this.savings.push(UtilsService.deepCopy(position));
        }
        return this.positionModel.preparePosition(position);
      }).sort(this.sortPositions);
      this.applyFilter(true, true);
    } catch (e) { console.warn(e); }
  }

  private isValid = (position) => position.AssetClass && position.AssetClass !== '';
  private sortPositions = (a, b) => b['RowNumber'] - a['RowNumber'];

  private applyFilter(isUpdated: boolean, sumValues?: boolean) {
    this._isUpdated = isUpdated;
    this.apiService.updateCache('position_list', this.positionsArray);
    const temp = this.positionModel.groupItens([...this.positionsArray], sumValues);
    this.loaded = true;
    return this._update(temp);
  }

  public isUpdated() {
    return this._isUpdated;
  }

  public getProductType() {
    return this.ProductTypeName;
  }

  public clear(loginStatusCode?: LoginStatusEnum) {
    try {
      super.clear(loginStatusCode);
      this.positionsArray = new Array();
      this.savings = new Array();
      this.ProductTypeName = '';
      this.lastProductType = 'none';
      this._isUpdated = true;
      this.loaded = false;
      this._update([], true);
    } catch (e) { console.warn(e); }
  }

}
