import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LogoutRoutePathUrl } from '@gorila/core/router';
import { ToastService } from '@gorila-bot/toast';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const SERVICES_TOAST_BLACKLIST = [
  'benchmark',
  'positionExplanation',
  'sentry',
  'subscriptions/profile',
  'calendar/working-days'
];

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    @Optional() private dialogRef: MatDialog,
    private router: Router,
    private toastService: ToastService,
    private translate: TranslateService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError ( (error: HttpErrorResponse) => {
          if (error.status !== 401) {
            return this.sendErrorToToast(error);
          }
          // continue data flow for password error on login page
          if (
            error.url.indexOf('user/login/password') > 0 ||
            error.url.indexOf('user/password') > 0 &&
            error.status === 401
          ) {
            return next.handle(request);
          }
          // closing all dialogs
          if (!!this.dialogRef) {
            this.dialogRef.closeAll();
          }
          // forcing logout
          this.router.navigateByUrl(LogoutRoutePathUrl);
        })
      );
  }

  private sendErrorToToast(error: HttpErrorResponse) {
    const blocked = SERVICES_TOAST_BLACKLIST.find(service => error.url.indexOf(service) !== -1);
    if (!blocked && error.status >= 500) {
      this.toastService.addRaw('error', this.translate.instant('TOAST.DEFAULT_HTTP_ERROR'));
    }
    return throwError(error);
  }
}
