import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'show-more',
  templateUrl: './show-more.component.html'
})
export class ShowMoreComponent {
  @Input() public moreText = 'more';
  @Input() public lessText = 'less';
  @Output() public clicked = new EventEmitter();

  public selected = false;
  public hover = false;

  public click() {
    this.selected = !this.selected;
    this.clicked.emit(this.selected);
  }
}
