<div class="icons-container">
  <ng-container *ngIf="helpLink">
    <a class="help-icon" [href]="helpLink" target="_blank">
      <gor-icon class="help-icon" iconFolder="Navigation/Main-Nav" icon="Help" width="24px" height="24px">
      </gor-icon>
    </a>
  </ng-container>
  <ng-container *ngIf="step">
    <span class="steps">
 {{('B3_PARTNER.HAVE_ACCOUNT.4_STEPS' | translate: { step: step })}}
    </span>
  </ng-container>
  <ng-container *ngIf="showCloseButton">
    <gor-icon class="close-icon" iconFolder="Platform" icon="Close" width="24px" height="24px" mat-dialog-close="close">
    </gor-icon>
  </ng-container>
</div>
<header class="partner-header">
  <h2 class="partner-header__title">
    {{ titleText | translate }}
  </h2>
  <main class="partner-header__main" [class.partner-header__main--borderless]="borderless">
    <ng-container *ngIf="partnerLogoName; else partnerNameText">
      <gor-icon class="partner-header__logo" [iconFolder]="partnerLogoFolder" [icon]="partnerLogoName" height="81px"></gor-icon>
    </ng-container>
    <span *ngIf="isBeta" class="beta">BETA</span>
  </main>
  <ng-container *ngIf="partnerType">
    <h6 class="partner-header__type">{{ partnerType | translate }}</h6>
  </ng-container>
</header>

<ng-template #partnerNameText>
  <span class="partner-header__name">
    {{ partnerName }}
  </span>
</ng-template>
