import { Routes } from '@angular/router';

import { AppShellRoute } from './app-shell';
import { AppShellRoutePath } from './app-shell/metadata';
import { AuthCallbackRoutes, AuthRoutes } from './auth';
import { AuthNoLockRoutePath } from './auth/metadata';
import { AuthGuard } from './guards/auth.guard';
import { InviteRoute } from './invite';

export const routes: Routes = [
  ...AuthRoutes,
  ...AuthCallbackRoutes,
  InviteRoute,
  AppShellRoute,
  {
    path: 'm',
    canActivateChild: [AuthGuard],
    loadChildren: './pages/mobile-pages/mobile-pages.module#MobilePagesModule'
  },
  { path: '', redirectTo: AuthNoLockRoutePath, pathMatch: 'full' },
  { path: '**', redirectTo: AppShellRoutePath, pathMatch: 'full' }
];

export const AppRoutes: Routes = [
  { path: 'advisor/:advisor', children: routes },
  { path: 'partner/:partner/advisor/:advisor', children: routes },
  { path: 'partner/:partner', children: routes },
  ...routes
];

export const routesWithHeaderButtons = [];
export const routesWithHeaderIcons = [];

AppShellRoute.children
  .filter(r => r.data && r.data)
  .forEach(r => {
    if (r.data.headerButtons) {
      routesWithHeaderButtons.push({ path: r.path, headerButtons: r.data.headerButtons });
    }
    if (r.data.headerIcons) {
      routesWithHeaderIcons.push({ path: r.path, headerIcons: r.data.headerIcons });
    }
  });
