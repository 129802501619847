export const AuthRoutePath = 'entrar';
export const AuthRoutePathUrl = '/entrar';
export const AuthConnectRoutePath = 'conectar-contas';
export const AuthConnectRoutePathUrl = '/conectar-contas';
export const AuthNoLockEmailRoutePath = 'email';
export const AuthNoLockEmailRoutePathUrl = '/email';
export const AuthNoLockForgotPasswordRoutePath = 'recuperar-senha';
export const AuthNoLockForgotPasswordRoutePathUrl = '/recuperar-senha';
export const AuthNoLockResetPasswordRoutePath = 'resetar-senha';
export const AuthNoLockResetPasswordRoutePathUrl = '/resetar-senha';
export const AuthNoLockRoutePath = 'login';
export const AuthNoLockRoutePathUrl = '/login';
export const AuthNoLockSignupRoutePath = 'cadastro';
export const AuthNoLockSignupRoutePathUrl = '/cadastro';
export const AuthGorilaPROSignupRoutePath = 'cadastro-gorila-pro';
export const AuthGorilaPROSignupRoutePathUrl = '/cadastro-gorila-pro';
export const LoginRoutePath = 'entrando';
export const LoginRoutePathUrl = '/entrando';
export const LogoutRoutePath = 'saindo';
export const LogoutRoutePathUrl = '/saindo';
