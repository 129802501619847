import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../guards/advisor-fund.guard';
import { RouteData } from '../../route-data';
import { ManagerRoutePath } from './metadata';

export const ManagerRouteData: RouteData = {
  title: 'Manager',
  saveDatePicker: true
};

export const ManagerRoute: Route = {
  data: ManagerRouteData,
  path: ManagerRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: './pages/manager/src/manager.module#ManagerModule'
};
