<div class="header-menu">
  <div class="align-right">
    <gor-header-buttons
      [displayB2BButtons]="advisorMode && !advisorAsClient"
      [brokerClientMode]="brokerClientMode"
      [routerEvent]="routerEvent"
    ></gor-header-buttons>
    <gor-header-icons
      class="top-menu"
      size="65"
      imgSize="40"
      [border]="true"
      [items]="_menuItems"
      (reduce)="reduce($event)"
    ></gor-header-icons>
    <div class="user-menu-wrapper">
      <gor-user-menu
        [active]="activeRoutes.usermenu === (activeDropdown | async)"
        [name]="user?.name"
        (reduce)="reduce($event)"
      ></gor-user-menu>
    </div>
  </div>
</div>
<gor-user-menu-dropdown
  *ngIf="activeRoutes.usermenu === (activeDropdown | async)"
  [items]="_userMenuDropdown"
  [advisorMode]="advisorMode"
  [email]="user?.email"
  [username]="user?.name"
  (reduce)="reduce($event)"
></gor-user-menu-dropdown>
<progress-container></progress-container>
<app-alert-container alertGroupName="simulation"></app-alert-container>
<gor-header-dropdown
  *ngIf="(displayToolsDropdown$ | async)"
  id="tools_dropdown"
  [activeItem]="activeTool"
  [items]="tools"
  (reduce)="reduce($event)"
></gor-header-dropdown>
