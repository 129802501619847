import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AllocationAssetsModule } from '@gorila-bot/allocation-assets';
import { AllocationAssetsGroupModule } from '@gorila-bot/allocation-assets-group';
import { SharedModule } from '@gorila/shared/module';
import { SocketModule } from '@gorila/socket/module';
import { GroCardFieldModule } from '@gorila/ui/gro-card-field';
import { UiIconModule } from '@gorila/ui/ui-icon';
import { ArrowVariationModule } from '@gorilainvest/ui-toolkit/arrow-variation';
import { CurrencyDisplayModule } from '@gorilainvest/ui-toolkit/currency-display';
import { LoadingModule } from '@gorilainvest/ui-toolkit/loading';
import { TranslateModule } from '@ngx-translate/core';

import { AllocationComponent } from './allocation/allocation.component';
import { LeftBoxComponent } from './allocation/left-box/left-box.component';
import { PnlComponent } from './allocation/left-box/variation/pnl/pnl.component';
import { VariationButtonComponent } from './allocation/left-box/variation/variation-button/variation-button.component';
import { VariationComponent } from './allocation/left-box/variation/variation.component';
import { MiddleBoxComponent } from './allocation/middle-box/middle-box.component';
import { RightBoxComponent } from './allocation/right-box/right-box.component';
import { TotalPnlComponent } from './allocation/total-pnl/total-pnl.component';
import { PositionModel } from './model/position.model';
import { PositionService } from './services/position.service';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { DirectivesModule } from '@gorilainvest/ui-toolkit/directives';

@NgModule({
  imports: [
    AmChartsModule,
    ArrowVariationModule,
    CurrencyDisplayModule,
    GroCardFieldModule,
    SharedModule,
    SocketModule,
    TranslateModule.forChild(),
    AllocationAssetsModule,
    AllocationAssetsGroupModule,
    UiIconModule,
    DirectivesModule,
    LoadingModule,
    IconModule
  ],
  declarations: [
    AllocationComponent,
    VariationComponent,
    PnlComponent,
    LeftBoxComponent,
    MiddleBoxComponent,
    RightBoxComponent,
    TotalPnlComponent,
    VariationButtonComponent
  ],
  exports: [AllocationComponent, VariationButtonComponent],
  providers: [DatePipe, PositionService, PositionModel]

})
export class AllocationModule { }
