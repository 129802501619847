import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UserMenuDropdownComponent } from './user-menu-dropdown/index';
import { UserMenuComponent } from './user-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    UserMenuComponent,
    UserMenuDropdownComponent
  ],
  exports: [
    UserMenuComponent,
    UserMenuDropdownComponent
  ]
})
export class UserMenuModule { }
