import { ElementRef, Renderer2 } from '@angular/core';

export interface Style {
  name: string;
  value: string;
}

export class DomManipulatorModel {
  private debug = false;
  public constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  public getStyleProperty(query: string, property: string): string {
    try {
      const element = this.getElement(query);
      if (!element) {
        if (this.debug) { console.warn('element ' + query + ' not founded'); }
        return '';
      }
      return this.getStyle(element, property);
    } catch (e) { console.warn(e); }
  }

  public setElementRef(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  public setElementRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  public setStyle(query: string, property: Style | Array<Style>) {
    try {
      const element = this.getElement(query);
      if (!element) {
        if (this.debug) { console.warn('element ' + query + ' not founded'); }
        return;
      }

      if (typeof property['length'] === 'undefined') {
        property = property as Style;
        this.renderer.setStyle(element, property.name, property.value);

        return;
      }

      for (const i in property) {
        if (!property[i]) { continue; }
        this.renderer.setStyle(element, property[i].name, property[i].value);
      }
    } catch (e) { console.warn(e); }
  }

  public removeStyle(query: string, property: Array<string>) {
    try {
      const element = this.getElement(query);
      if (!element) { return; }
      for (const i in property) {
        if (!property[i]) { continue; }
        this.renderer.removeStyle(element, property[i]);
      }
    } catch (e) { console.warn(e); }
  }

  public addClass(query: string, classname: string) {
    try {
      const element = this.getElement(query);
      if (!element) { return; }
      this.renderer.addClass(element, classname);
    } catch (e) { console.warn(e); }
  }

  public removeClass(classname: string) {
    try {
      const element = this.getElement('.' + classname);
      if (!element) { return; }
      this.renderer.removeClass(element, classname);
    } catch (e) { console.warn(e); }
  }

  public toggleClass(query: string, classname: string) {
    try {
      const element = this.getElement(query);
      if (!element) { return; }
      const methodName = element.className.indexOf(classname) === -1 ? 'addClass' : 'removeClass';
      this.renderer[methodName](element, classname);
    } catch (e) { console.warn(e); }
  }

  private getElement(query: string): Element {
    const element = this.elementRef.nativeElement.querySelector(query);
    if (!element && this.debug) {
      console.warn('element ' + query + ' not founded');
    }
    return element;
  }

  // Gist from: https://gist.github.com/guilhermejcgois/e014b2afac753325326691b4be28993c
  private getStyle(el: Element, styleProp: string): string {
    let value;
    const defaultView = el.ownerDocument.defaultView;
    // W3C standard way:
    if (defaultView && defaultView.getComputedStyle) {
      // sanitize property name to css notation (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } else if (el['currentStyle']) { // IE
      // sanitize property name to camelCase
      styleProp = styleProp.replace(/\-(\w)/g, function (str, letter) {
        return letter.toUpperCase();
      });
      value = el['currentStyle'][styleProp];
      return value;
    }

    return '';
  }

}
