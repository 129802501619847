import { Pipe, PipeTransform } from '@angular/core';
import { BigCurrencyPipe, SymbolDisplay } from '@gorilainvest/ui-toolkit/pipes';

import { AppConstants } from '@gorila/constants';

@Pipe({
  name: 'price',
  pure: true
})
export class PricePipe implements PipeTransform {
  constructor(private currencyPipe: BigCurrencyPipe) { }

  public transform(value: number|string, productType: string, currencyCode = 'USD', symbolDisplay: SymbolDisplay): string {
    const _productType = productType && productType.split('_')[0];
    const decimals =
      _productType && _productType in AppConstants.Decimals
      ? AppConstants.Decimals[_productType].Price
      : AppConstants.Decimals.DEFAULT.Price;

    if (productType === 'GENERIC') {
      return this.currencyPipe.transform(value, currencyCode, symbolDisplay, '1.2-5');
    }

    return this.currencyPipe.transform(value, currencyCode, symbolDisplay, `1.${decimals}-${decimals}`);

  }
}
