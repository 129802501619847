import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'side'
})
export class SidePipe implements PipeTransform {
  public readonly Buy = 1;
  public readonly BuyNotSavings = 'Compra';
  public readonly BuySavings = 'Aplicação';
  public readonly Sell = 2;
  public readonly SellNotSavings = 'Venda';
  public readonly SellSavings = 'Resgate';

  public transform(value: number, productType: string): string {
    try {
      if (productType === 'SAVINGS') {
        if (value === this.Buy) { return this.BuySavings; }
        if (value === this.Sell) { return this.SellSavings; }
      } else {
        if (value === this.Buy) { return this.BuyNotSavings; }
        if (value === this.Sell) { return this.SellNotSavings; }
      }
      return value.toString();
    } catch (e) {
      console.warn(e);
      return value.toString();
    }
  }
}
