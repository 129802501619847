/**
 * Interface for objects that represents pnl information (nominal and percentual).
 *
 * @interface
 */
export interface Pnl {
  id?: string;

  /**
   * PNL nominal (decimal information).
   *
   * @type {number}
   */
  nominal: number;

  /**
   * PNL percentual (relative information).
   *
   * @type {number}
   */
  percentual: number;
}

/**
 * Interface for objects that represents pnl data.
 *
 * @interface
 */
export interface PnlData {
  /**
   * PNL information for daily period.
   *
   * @type {Pnl}
   */
  daily: Pnl;

  /**
   * PNL information for monthly period.
   *
   * @type {Pnl}
   */
  monthly: Pnl;

  /**
   * PNL information for yearly period.
   *
   * @type {Pnl}
   */
  yearly: Pnl;

  /**
   * PNL information for total period (from the begin date to today/end date).
   *
   * @type {Pnl}
   */
  total?: Pnl;
}
export const PnlDataProperties = ['daily', 'monthly', 'yearly', 'total'];

export const getZeroedPnl = (): Pnl => ({
  nominal: 0,
  percentual: 0
});
export const getZeroedPnlData = (withTotal = false): PnlData => ({
  daily: getZeroedPnl(),
  monthly: getZeroedPnl(),
  yearly: getZeroedPnl(),
  total: withTotal ? getZeroedPnl() : null
});

export const fromServer = profit => profit ? {
  nominal: profit['totalPnl'] || 0,
  percentual: profit['profitFactor'] ? profit['profitFactor'] - 1 : 0
} : getZeroedPnl();

export const profitFromServer = fromServer;

export const mergePnl = (pnlA: Pnl, pnlB: Pnl): Pnl => ({
  nominal: pnlA.nominal + pnlB.nominal,
  percentual: pnlA.percentual * pnlB.percentual
});

export const mergePnlData = (pnlDataA: PnlData, pnlDataB: PnlData): PnlData => ({
  daily: mergePnl(pnlDataA.daily, pnlDataB.daily),
  monthly: mergePnl(pnlDataA.monthly, pnlDataB.monthly),
  yearly: mergePnl(pnlDataA.yearly, pnlDataB.yearly),
  total: mergePnl(pnlDataA.total, pnlDataB.total)
});

export const unmergePnl = (pnlA: Pnl, pnlB: Pnl): Pnl => ({
  nominal: pnlA.nominal - pnlB.nominal,
  percentual: pnlA.percentual / pnlB.percentual
});

export const unmergePnlData = (pnlDataA: PnlData, pnlDataB: PnlData): PnlData => ({
  daily: unmergePnl(pnlDataA.daily, pnlDataB.daily),
  monthly: unmergePnl(pnlDataA.monthly, pnlDataB.monthly),
  yearly: unmergePnl(pnlDataA.yearly, pnlDataB.yearly),
  total: unmergePnl(pnlDataA.total, pnlDataB.total)
});
