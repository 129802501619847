import {
  PositionBehavioralState,
  PositionColumns,
  AssetBehavioralState,
  ProductTypeBehavioralState,
  ProductTypeColumns,
  PortfolioBehavioralState
} from '@gorila/pages/store/wallet';
import { Parity } from '@gorila/shared';

export interface ProfitabilityState {
  components: {
    position: PositionBehavioralState;
    product: AssetBehavioralState;
    subproduct: ProductTypeBehavioralState;
    portfolio: PortfolioBehavioralState;
  };
}

export const INITIAL_STATE: ProfitabilityState = {
  components: {
    position: {
      expandable: Parity.NONE,
      columnsToShow: [
        PositionColumns.Broker,
        PositionColumns.Maturity,
        PositionColumns.Total
      ],
      selectable: Parity.ONE,
      showActions: false
    },
    product: {
      expandable: Parity.MANY,
      selectable: Parity.ONE,
      animatePercentage: {}
    },
    subproduct: {
      expandable: Parity.MANY,
      columnsToShow: [ ProductTypeColumns.Total ],
      selectable: Parity.ONE,
      waitAnimationOnClose: 250
    },
    portfolio: {
      multiexpandable: true,
      multiselectable: false
    }
  }
};
