import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'disconnection',
  templateUrl: './disconnection.component.html',
  styleUrls: ['./disconnection.component.scss']
})
export class DisconnectionComponent {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: { guideLink: string },
  ) { }
}
