import { Component, Input, Output } from '@angular/core';
import { CreateReduction, CustomEventEmitter } from '@gorila/core/utils';

@Component({
  selector: 'close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss']
})
export class CloseButtonComponent {

  @Input() public routerLink = null;
  @Input() public size = '17px';
  @Input() public color = null;
  @Output() public clicked: CustomEventEmitter<boolean> = new CustomEventEmitter();
  constructor() { }

  public reduce = () => CreateReduction('close_button_click', null, this.clicked);
}
