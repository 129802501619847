import { LogService } from '@gorila/shared/services/log.service';

import { GenericAction } from '../api/actions';

export type ReducerBaseFunction = (state: any, action: GenericAction, parameters?: any) => any;
export type ReducerFunction = (state: any, action: GenericAction, extra?: any) => any;
export type ReducerForActionMap = {[key: string]: ReducerFunction};

export function ReducerBase(
  obj: ReducerForActionMap,
  state: any,
  action: any,
  stateType: string,
  functions?: ReducerBaseFunctions
) {
  try {
    if (!action.meta || !action.type || action.meta.stateType !== stateType) {
      return state;
    }

    const parameters = (functions && functions['parameters']) ? functions['parameters'] : {};
    const checkState = (functions && functions['checkState']) ? functions['checkState'](state, action, parameters) : true;
    if (!checkState) {
      return state;
    }

    if (!obj[action.type]) {
      if (action.meta.stateType === stateType) {
        LogService.reduxLog(`@@@ NOT FILTERED STATE ${stateType} `, action, obj);
      }
      return state;
    }

    const before = (functions && functions['beforeEach']) ? functions['beforeEach'](state, action, parameters) : null;
    const newState = obj[action.type](state, action, before);
    if (!newState) {
      return state;
    }
    return { ...state, ...newState };
  } catch (e) { console.warn(e); }
  return state;
}

export function MakeState(state): BaseState {
  return {
    items: {},
    loading: false,
    error: null,
    ...state
  };
}

export interface BaseState {
  items?: any;
  loading?: boolean;
  error?: string;
}

export interface ReducerBaseFunctions {
  beforeEach?: ReducerBaseFunction;
  checkState?: ReducerBaseFunction;
  parameters?: ReducerBaseFunction;
}
