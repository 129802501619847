import { ITrade, OperationSideEnum, OperationSourceEnum } from '@gorila-bot/gorila-front-models';

export const Key = 'transfer';

export function clearTransferState(reducer) {
  return (state, action) => reducer(action.type !== '[App] logout' ? state : undefined, action);
}

export interface ITransferRequest {
  minDate?: string;
  maxDate?: string;
  transfers?: boolean;
  portfolioEvents?: boolean;
  trades?: boolean;
  broker?: string;
  productType?: string;
  securityName?: string;
}

export interface ITransferItem {
  broker: string;
  custodyTransferID: string;
  paymentCurrency?: string;
  price: number;
  productType?: string;
  quantity: number;
  refDate: string;
  securityName: string;
  securityDescription: string;
  source: OperationSourceEnum;
  type: string;
  subType?: string;
  securityType?: string;
  value: number;
  walletId: number;
  side: OperationSideEnum;
}

export interface IAssetEventsItem {
  eventType: string;
  broker: string;
  brokerName: string;
  paymentCurrency?: string;
  paymentDate?: string;
  productType?: string;
  unitValue?: number;
  totalValue?: number;
  quantity: number;
  refDate?: string;
  securityName: string;
  securityDescription?: string;
  value?: number;
}

export interface ITransferResponseItem {
  transfers: ITransferItem[];
  assetEvents: IAssetEventsItem[];
  trades: ITrade[];
}

export interface ITransferResponseError {
  error: string;
}

export type ITransferResponse = ITransferResponseError | ITransferResponseItem;

export interface ITransferStateItem {
  eventType: string;
  source?: OperationSourceEnum;
  brokerName?: string;
  paymentCurrency: string;
  price?: number;
  quantity: number;
  refDate?: string;
  securityName: string;
  securityDescription?: string;
  value?: number;
  custodyTransferID?: string;
  destinyBrokerName?: string;
  walletId?: number;
}

export type ITransferState = {
  loading: boolean;
  error: boolean;
  trades: ITrade[];
  events: ITransferStateItem[];
  isEmpty: boolean;
};

export const INITIAL_STATE: ITransferState = {
  loading: false,
  error: false,
  trades: [],
  events: [],
  isEmpty: true,
};

export const initialState: ITransferState = INITIAL_STATE;
