import { Action } from '@ngrx/store';
import { LoadUserPortfolioDatesPayload, LoadedUserPortfolioDatesPayload } from '../models/portfolio.model';

export const ACTION_TAG = '[User.Portfolio]';

export enum UserPortfolioActionTypes {
  LoadUserPortfolioDatesActions = '[User.Portfolio] Load User Portfolio Dates',
  LoadedUserPortfolioDatesActions = '[User.Portfolio] Loaded User Portfolio Dates'
}

export class LoadUserPortfolioDatesActions implements Action {
  public readonly type = UserPortfolioActionTypes.LoadUserPortfolioDatesActions;

  constructor(public payload: LoadUserPortfolioDatesPayload) {}
}

export class LoadedUserPortfolioDatesActions implements Action {
  public readonly type = UserPortfolioActionTypes.LoadedUserPortfolioDatesActions;

  constructor(public payload: LoadedUserPortfolioDatesPayload) {}
}

export type UserPortfolioActions = LoadUserPortfolioDatesActions | LoadedUserPortfolioDatesActions;
