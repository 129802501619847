import { Injectable } from '@angular/core';
import { Epic } from 'redux-observable';
import { from } from 'rxjs';

import { BaseEpic } from '../../base/epic.base';
import { AppState, STATE_TYPES } from '../../store.model';
import { ActionTransition, ActionType } from '../actions.model';
import { ProductAPIAction, ProductAPIActions } from '../product/product.actions';

@Injectable()
export class SubProductAPIEpics extends BaseEpic {
  constructor(private productActions: ProductAPIActions) {
    super();
  }

  public getStateType(): string {
    return STATE_TYPES.SUBPRODUCT;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return this.createLoadSuccessSubProductEpic(filterIfNotLogged);
  }

  private createLoadSuccessSubProductEpic(ifNotLogged): Epic<ProductAPIAction, ProductAPIAction, AppState> {
    const actionType = this.getActionName(ActionType.MAP, ActionTransition.DATA);
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) =>
      from([this.productActions.map(STATE_TYPES.PRODUCT, store.value['subproduct']['items'])]), false);
  }

}
