import { Action } from '@ngrx/store';

export enum AppInfoActionTypes {
  SetScreenInformation = '[AppInfo] Set Screen Information'
}

export class SetAppSize implements Action {
  public readonly type = AppInfoActionTypes.SetScreenInformation;

  /**
   *
   * @param size width screen (in pixels)
   */
  constructor(public width: number, public height: number) { }
}

export type AppInfoActions = SetAppSize;
