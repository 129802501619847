import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'toggle-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonGroupComponent implements OnChanges, OnInit {
  @Input() public label: string;
  @Input() public buttons: Array<{}> = new Array();
  @Input() public active = '';
  @Input() public blockToggle = false;
  @Input() public labelStyle = {};
  @Input() public buttonStyle = {};
  @Input() public activeButtonStyle = {};
  @Input() public round = false;
  @Input() public showDefaultVariation = true;
  @Input() public showTooltip = true;
  @Output() public changes = new EventEmitter();

  public _activeButtonStyle: any;

  constructor() { }

  public ngOnInit() {
    this._activeButtonStyle = Object.assign({}, this.buttonStyle);
    // tslint:disable-next-line
    for (const prop in this.activeButtonStyle) {
      this._activeButtonStyle[prop] = this.activeButtonStyle[prop];
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (typeof changes['active'] === 'undefined') {
      return;
    }

    if (changes['active']['currentValue'] === '') {
      this.updateButtonActive(changes['active']['currentValue']);
    }
  }

  public activeChanged($event: any) {
    for (const i in $event) {
      if (!$event.hasOwnProperty(i)) {
        continue;
      }
      if (i === this.active && this.blockToggle) { return; }
      this.active = ($event[i] === false) ? '' : i;
    }
    this.changes.emit(this.active);
  }

  private updateButtonActive(active = this.active) {
    this.buttons.map(el => el['active'] = active);
  }
}

@Component({
  selector: 'toggle-button-group-form',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ButtonGroupFormComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ButtonGroupFormComponent), multi: true }
  ]
})

export class ButtonGroupFormComponent extends ButtonGroupComponent implements ControlValueAccessor {
  public propagateChange: Function;
  public touchedChange: Function;

  constructor() {
    super();

    this.propagateChange = () => { };
    this.touchedChange = () => { };
  }

  public writeValue(value: any) {
    this.active = value || this.buttons && this.buttons['id'] || '';
  }

  /**
   * registerOnChange(fn: any) is a method that registers a handler that should
   * be called when something in the view has changed. It gets a function
   * that tells other form directives and form controls to update their values.
   * In other words, that’s the handler function we want to call whenever
   * price changes through the view.
   */
  public registerOnChange(fn: Function) {
    this.propagateChange = fn;
  }

  /**
   * registerOnTouched(fn: any) Similar to registerOnChange(), this registers
   * a handler specifically for when a control receives a touch event.
   * We don’t need that in our custom control.
   */
  public registerOnTouched(fn: Function) {
    this.touchedChange = fn;
  }
}
