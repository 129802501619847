import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { clearState } from '@gorila/root-store/logout/logout.state';
import { StoreModule } from '@ngrx/store';

import { reducer } from '@gorila/root-store/routes/src/reducers/routes.reducer';
import { Key, initialState } from '@gorila/root-store/routes/src/routes.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(Key, reducer, { metaReducers: [clearState], initialState }),
  ],
  declarations: []
})
export class RoutesReduxModule { }
