/**
 * Interface for objects that represents a asset class.
 *
 * @interface
 */
export interface AssetClass {
  /**
   * The identifier of the assset class.
   *
   * @type {string}
   */
  id: string;

  /**
   * The name of the asset class used to be displayed.
   *
   * @type {string}
   */
  name: string;
}

export const fromServer = (assetClass: string): AssetClass => ({
  id: assetClass,
  name: assetClass
});

export const isValid = (assetClass: AssetClass) => assetClass && assetClass.id;
