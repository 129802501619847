import { keyframes, trigger, state, style, animate, transition } from '@angular/animations';
export const flippEffect = trigger('flipState', [
  state('active', style({})),
  state('inactive', style({})),
  transition('inactive => active', [
    animate('900ms', keyframes([
      style({ transform: 'rotateY(0deg)', offset: 0 }),
      style({ transform: 'rotateY(90deg)', offset: 0.4999 }),
      style({ transform: 'rotateY(-90deg)', offset: 0.5 }),
      style({ transform: 'rotateY(0deg)', offset: 1 })
    ]))
  ]),
  transition('active => inactive', [
    animate('900ms', keyframes([
      style({ transform: 'rotateY(0deg)', offset: 0 }),
      style({ transform: 'rotateY(-90deg)', offset: 0.4999 }),
      style({ transform: 'rotateY(90deg)', offset: 0.5 }),
      style({ transform: 'rotateY(0deg)', offset: 1 })
    ]))
  ]),
]);
