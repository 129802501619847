import { HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { path } from 'ramda';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map, take } from 'rxjs/operators';

import { UtilsService } from './utils.service';
import { AppConstants } from '@gorila/constants';

export abstract class BaseBehaviorComponent<T> {
  @HostBinding('attr.route') public route: string;

  public state: T;
  protected _router: Router ;
  protected updateState = (state: T) => { this.state = state; };

  public abstract getSelector(): string;

  constructor(protected store$: Store<any>) {
    this._router = UtilsService.injector.get<Router>(Router);
    this.getState().subscribe(this.updateState);
  }

  protected getState(selector = this.getSelector()): Observable<any> {
    return from([this._router.url]).pipe(
      take(1),
      map((route: string) => route.split('//')[0].replace('(', '').replace(')', '')),
      concatMap((route: string) => {
        let pathRoute = route;
        if (AppConstants.WhiteLabelURLMarkers.indexOf(pathRoute.substring(0, 9)) !== -1) {
          const routeParts = pathRoute.split('/');
          pathRoute = pathRoute.replace(routeParts[0] + '/' + routeParts[1] + '/', '');
        }
        pathRoute = pathRoute.replace('primeiro-acesso/', '').replace('tutorial/', '');
        const pathArray = [
          'routes',
          ...pathRoute.split('/').splice(1),
          'components',
          selector
        ];
        this.route = pathRoute;
        return this.store$.pipe(select(data => path(pathArray, data)));
      }),
      map((state: any) => ({
        ...state,
        columnsToShow: (<any[]> path(['columnsToShow'], state) || []).reduce((prev, curr) => ({ ...prev, [curr]: true }), {})
      })),
      catchError(e => {
        console.warn(e);
        return from([{}]);
      })
    );
  }

}
