import {
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnChanges, OnInit {
  @Input() public cssClass = 'btn btn-sm';
  @Input() public title = '';
  @Input() public activeName = '';
  @Input() public id = '';
  @Input() public blockToggle = false;
  @Input() public style = {};
  @Input() public activeStyle = {};
  @Input() public roundClasses = {};
  @Output() public isActive = new EventEmitter();

  public active = false;
  public class: any;
  public _style: any;

  constructor() {}

  public ngOnInit() {
    this.active = this.activeName == this.id; // tslint:disable-line
    this.class = { 'active': this.active };
    this.class[this.cssClass] = true;
    this._style = (this.active) ? this.activeStyle : this.style;
  }

  public ngOnChanges(data) {
    try {
      if (typeof data['activeName'] !== 'undefined') {
        if (data['activeName']['currentValue'] === data['activeName']['previousValue']) {
          this.active = !this.active;
        } else if (data['activeName']['currentValue'] == this.id) { // tslint:disable-line
          this.active = true;
        } else {
          this.active = false;
        }
        this.class = this.getClass();
        this._style = (this.active) ? this.activeStyle : this.style;
      }
    } catch (e) { console.warn(e); }
  }

  public getClass() {
    const out = {
      'active': this.active
    };
    out[this.cssClass] = true;
    return out;
  }

  public toggleActive() {
    if (this.active && this.blockToggle) { return; }
    this.active = !this.active;

    const out = {};
    out[this.id] = this.active;
    this.class = this.getClass();

    this.isActive.emit(out);
  }
}
