<ng-container *ngIf="data">
  <div class="modal-container" [class.pre-connect]="data.typeModal === 'preConnect'" [class.pos-connect]="data.typeModal === 'posConnect'">
    <div class="modal-header" [class.account-sync]="data.typeModal === 'accountSync'">
      <div class='btn-container'>
        <button mat-button mat-dialog-close *ngIf="data.enableClose">
          <gor-icon [iconFolder]="'Platform'" [icon]="'Close'" [height]="'20px'" [width]="'20px'"></gor-icon>
        </button>
      </div>
      <div class="title-container">
        <h4 class='title-container__text' translate>{{ data.text?.title }}</h4>
        <h4 class='title-container__provider'>{{ data.providerName }}</h4>
      </div>
    </div>
    <div class='modal-body'>
      <span class="modal-body__text" *ngIf="data.text?.body1" translate>{{ data.text?.body1 }}</span>
      <span class="modal-body__text" *ngIf="data.text?.body2" translate>{{ data.text?.body2 }}</span>
      <ng-container *ngIf="data.text?.list" >
        <ul>
          <li *ngFor="let item of data.text?.list" [ngClass]="'icon-'+item.icon" [innerHTML]="item.text|translate|keepHtml"></li>
        </ul>
      </ng-container>
    </div>
    <div class="modal-footer" mat-dialog-actions>
      <gor-button [btnType]="data.buttonColor" mat-button [mat-dialog-close]="true">
        {{ data.text?.button | translate }}
      </gor-button>
    </div>
    <div *ngIf="!!data.text?.footer" class="modal-footer__text">
      {{ data.text.footer | translate }}
      <gor-icon iconFolder="Platform" icon="Settings" height="10px" width="10px"></gor-icon>
      .
    </div>
  </div>
</ng-container>
