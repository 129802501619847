import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { LoginStatusEnum, LoginStatusService, SubscriberMasterService } from '@gorila/core';
import { SocketEventService } from '@gorila-bot/gorila-base';
import { AlertService } from '@gorila/widgets/alert';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class StepService extends SubscriberMasterService implements OnDestroy {
  protected _observer: BehaviorSubject<any> = null;
  private simulating: {} = {};
  private queue: Array<{}> = [];

  constructor(
    private alertService: AlertService,
    private socketEventService: SocketEventService,
    protected loginStatusService: LoginStatusService,
    private translate: TranslateService
  ) {
    super();
    this.init();
  }

  public ngOnDestroy() {}

  public init() {
    if (!this.socketEventService) {
      return;
    }
    if (!this.subscriptionExists('POSITION_HISTORY')) {
      this._attach(
        'POSITION_HISTORY',
        this.socketEventService.getObserver('POSITION_HISTORY').subscribe((data: any) => this.checkEvent(data))
      );
    }

    if (this._observer == null) {
      this._observer = new BehaviorSubject(this.simulating);
    }
  }

  public getObserver(): BehaviorSubject<any> {
    this.init();
    return this._observer;
  }

  private checkEvent(data: any) {
    try {
      if (data == null || !data || !data['SecurityDescription']) {
        console.log('Step service: incomplete event data: or its null, or doesnt have data property.', data);
        return;
      }
      const index = data['SecurityDescription'];
      switch (data['Type']) {
        case 'POSITION_HISTORY:START':
          this.start(index, data);
          break;
        case 'POSITION_HISTORY:STEP':
          this.step(index, data);
          break;

        case 'POSITION_HISTORY:WAIT':
          this.wait(index, data);
          break;

        case 'POSITION_HISTORY:FINISH':
          this.finish(index, data);
          break;

        case 'POSITION_HISTORY:ERROR':
          this.error(index, data);
          break;
      }
      this.updateObserver();
    } catch (e) {
      console.warn(e, data);
    }
  }

  private start(index: any, data: any) {
    // start loading bar if current security is not progress
    if (!this.hasIndexInSimulation(index)) {
      if (false === this.add2simulation(index, data)) {
        console.error('not updated in start');
      }
      return;
    }

    // if this security is waiting starts it
    if (this.isSimulationWaiting(index)) {
      const len = this.getLength(data);
      if (len === -1) {
        return this.dropFromSimulation(index);
      }
      this.updateSimulationProperty(index, 'waiting', false);
      this.updateSimulationProperty(index, 'length', len);
      return;
    }

    // if this security is not in queue, add it in queue
    const val = this.findInQueue(index);
    if (val === -1) {
      this.addToQueue(index, data);
      return;
    }

    // if its in queue, send from queue from simulating
    if (!this.add2simulation(index, this.queue[val]['data'])) {
      console.log(this.queue[val], 'not added in simulation', this.queue.length, Object['assign']([], this.queue));
    }
  }

  private step(index: any, data: any) {
    if (!this.hasIndexInSimulation(index)) {
      this.manageQueueInStep(index, data);
    }

    this.updateLength(index, data);
    this.incrementProperty(index, 'step');
  }

  private updateLength(index: any, data: any) {
    const len = this.getDefaultLen(data);
    if (!this.hasSimulationProperty(index, 'length')) {
      return this.updateSimulationProperty(index, 'length', len);
    }

    const step = this.getSimulationProperty(index, 'step');
    const currentLen = this.getSimulationProperty(index, 'length');
    if (step === 0 || step === 100) {
      return this.updateSimulationProperty(index, 'length', len);
    }

    if (currentLen !== null && currentLen - step !== len) {
      return this.updateSimulationProperty(index, 'length', step + len);
    }
  }

  private manageQueueInStep(index, data) {
    const val = this.findInQueue(index);
    if (val !== -1) {
      if (typeof this.queue[val] === 'undefined' || typeof this.queue[val]['data'] === 'undefined') {
        console.warn(this.queue[val], 'val problem');
        return false;
      }
      if (!this.add2simulation(index, this.queue[val]['data'])) {
        console.warn('not added in simulation', this.queue.length, Object['assign']([], this.queue)[val]);
        return false;
      }
      return true;
    }

    if (!this.add2simulation(index, data)) {
      console.warn('not added in simulation', data);
      return false;
    }
    return true;
  }

  private findInQueue(index: number) {
    let val = -1;
    for (const i in this.queue) {
      if (this.queue[i]['index'] !== index) {
        continue;
      }
      val = parseInt(i, 10);
      break;
    }
    return val;
  }

  private wait(index: any, data: any) {
    const len = this.getDefaultLen(data);
    const id = 'it_' + Math.round(Math.random() * 10000000);
    if (!this.hasIndexInSimulation(index)) {
      this.addToSimulating(index, id, len, 0, true);
    } else {
      this.addToQueue(index, { id: id, length: len, step: 0, waiting: true, index: index });
    }
  }

  private getLength(data: any) {
    if (!data) {
      console.error(data);
      return -1;
    }
    if (typeof data['data'] !== 'undefined') {
      if (typeof data['data']['Length'] !== 'undefined') {
        return data['data']['Length'];
      }
      if (typeof data['data']['length'] !== 'undefined') {
        return data['data']['length'];
      }
    }
    if (typeof data['Length'] !== 'undefined') {
      return data['Length'];
    }
    if (typeof data['length'] !== 'undefined') {
      return data['length'];
    }
    console.error('length not defined!', data);
    return -1;
  }

  private finish(index: any, data: any) {
    const val = this.findInQueue(index);
    if (!this.hasIndexInSimulation(index)) {
      if (val !== -1) {
        this.add2simulation(this.queue[val]['index'], this.queue[val]['data']);
      }
      return;
    }

    if (val !== -1) {
      this.markWaitingTimer(val, true);
    }
    this.updateSimulationProperty(index, 'length', 100);
    this.updateSimulationProperty(index, 'step', 100);
    this.updateObserver();
    timer(2000).pipe(take(1), untilDestroyed(this)).subscribe((t) => {
      this.onRemoveSimulationItem(index);
    });
  }

  private onRemoveSimulationItem(index: any) {
    try {
      this.dropFromSimulation(index);
      this.updateObserver();
      const val = this.findInQueue(index);
      if (val !== -1) {
        this.add2simulation(this.queue[val]['index'], this.queue[val]['data']);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  private error(index: any, data: any) {
    try {
      if (environment.features.alertSimulationError) {
        this.alertService.addUniqueAlert({
          _id: 'simulation_error',
          message: `${this.translate.instant('calculationError')}`,
          type: 'error',
          group: 'simulation'
        });
      } else {
        console.warn(`${this.translate.instant('calculationError')}`);
      }
      this.dropFromSimulation(index);
      this.updateObserver();
    } catch (e) {
      console.warn(e);
    }
  }

  private getDefaultLen(data?: number) {
    const len = this.getLength(data);
    return len < 1 ? 100 : len;
  }

  private add2simulation(index: any, data: any) {
    try {
      this.removeFromQueue(index);
      const len = this.getLength(data);
      if (len === -1) {
        return false;
      }
      const id = 'it_' + Math.round(Math.random() * 10000000);
      this.addToSimulating(index, id, len, 0, false);
      return true;
    } catch (e) {
      console.warn(e);
    }
  }

  private updateObserver() {
    try {
      this._observer.next(this.simulating);
    } catch (e) {
      console.warn(e);
    }
  }

  private incrementProperty(index, property) {
    if (!this.hasSimulationProperty(index, property)) {
      return false;
    }
    this.simulating[index][property]++;
    return true;
  }

  private hasSimulationProperty(index, property) {
    if (!this.hasIndexInSimulation(index)) {
      return false;
    }
    return typeof this.simulating[index][property] !== 'undefined';
  }

  private getSimulationProperty(index, property) {
    if (!this.hasSimulationProperty(index, property)) {
      return null;
    }
    return this.simulating[index][property];
  }

  private updateSimulationProperty(index, property, value) {
    if (!this.hasIndexInSimulation(index)) {
      return;
    }
    this.simulating[index][property] = value;
  }

  private dropFromSimulation(index) {
    try {
      if (!this.hasIndexInSimulation(index)) {
        return;
      }
      delete this.simulating[index];
    } catch (e) {
      console.warn(e);
    }
  }

  private isSimulationWaiting(index) {
    return this.simulating[index]['waiting'];
  }

  private hasIndexInSimulation(index) {
    return typeof this.simulating[index] !== 'undefined';
  }

  private addToSimulating(index, id, len, step, waiting) {
    if (!isNaN(index)) {
      return;
    }
    if (typeof this.simulating[index] !== 'undefined') {
      delete this.simulating[index];
    }
    this.simulating[index] = { id: id, length: len, step: step, waiting: waiting, index: index };
  }

  private markWaitingTimer(val: number, waiting: boolean) {
    this.queue[val]['data']['waitingTimer'] = waiting;
  }

  private addToQueue(index: any, data: any) {
    if (typeof data['length'] === 'undefined') {
      data['length'] = 100;
    }
    if (typeof data['step'] === 'undefined') {
      data['step'] = 0;
    }
    if (typeof data['waiting'] === 'undefined') {
      data['waiting'] = true;
    }
    if (typeof data['index'] === 'undefined') {
      data['index'] = index;
    }
    if (typeof data['waitingTimer'] === 'undefined') {
      data['waitingTimer'] = false;
    }
    if (data['step'] > 0) {
      data['waiting'] = false;
    }
    this.queue.push({ index: index, data: data });
  }

  private removeFromQueue(index) {
    try {
      for (const i in this.queue) {
        if (this.queue[i]['index'] !== index) {
          continue;
        }
        this.queue.splice(parseInt(i, 10), 1);
        break;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  protected cleanup(loginStatusCode?: LoginStatusEnum) {
    this.simulating = {};
    this.queue = [];
    this.init();
    this._observer.next(null);
  }
}
