import { either, flatten, is, isEmpty, isNil, pathSatisfies } from 'ramda';
import { Epic, ofType } from 'redux-observable';
import { filter ,  mergeMap } from 'rxjs/operators';

import { ActionTransition, actionTransitionNameFor, ActionType, actionTypeNameFor } from '../api/actions.model';
import { AppState, stateTypeToStoreTreePath, TreePath } from '../store.model';
import { Separator, TypeReducer } from '../store.utils';

export abstract class BaseEpic {
  public abstract getStateType(): string;
  public abstract createEpic(filterIfNotLogged: (..._) => true);

  public notAlreadyFetched = (treePath: TreePath, state: AppState): boolean => {
    const path: string[] = is(Array)(treePath) ? flatten(treePath as string[]) : [treePath as string];
    return pathSatisfies(either(isEmpty, isNil), [...path, 'items'], state);
  }

  public actionIsForCorrectStateType = (action) => action.meta.stateType === this.getStateType();

  protected createBaseEpic(actionType: string, ifNotLogged, mapThings, checkAlreadyFetched = true, filterFn?: any): Epic<any, any, any> {
    try {
      return (action$, store) => action$.pipe(
        ofType(actionType),
        filter((action) => this.actionIsForCorrectStateType(action)),
        filter(() => !checkAlreadyFetched || this.notAlreadyFetched(stateTypeToStoreTreePath(this.getStateType()), store.value)),
        filter((action) => filterFn ? filterFn(action, store) : true),
        mergeMap(action => {
          try {
            return mapThings(action, store);
          } catch (e) { console.warn(e); }
          return {};
        })
      );
    } catch (e) { console.warn(e); }
  }

  protected getActionName(actionType: ActionType, actionTransition: ActionTransition, extra?: string) {
    let stateType = this.getStateType();
    if (extra) {
      stateType += Separator + extra;
    }
    return TypeReducer(actionTypeNameFor(actionType), stateType)(actionTransitionNameFor(actionTransition));
  }

}
