import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { AfterViewInit, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BaseDataSourceService } from '@gorila-bot/gorila-base';
import { equals } from 'ramda';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export abstract class ChartBase<T> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() public dataSource: BaseDataSourceService<T>;
  public dataProvider: T[] = [];
  public divID = '';
  public chart: any;
  private started = false;
  private subscription: Subscription = null;
  constructor() {
    this.divID = this.getDivId();
    this.subscription = null;
    am4core.options.commercialLicense = true;
  }

  public getDivId() {
    return 'chart_' + Math.ceil(Math.random() * 10000000);
  }

  public abstract getOptions(): any;
  public abstract onDataChange(chartData: T[]): T[];
  public abstract setAdapters(): any;

  private listenDataSource() {
    if (!this.dataSource || this.subscription !== null) {
      return;
    }
    this.subscription = this.dataSource.connect()
      .pipe(distinctUntilChanged((a, b) => equals(a, b))).subscribe((data: T[]) => {
      if (!data || !data.length) {
        return;
      }
      this.dataProvider = this.onDataChange(data);
      if (typeof this.dataProvider[0] === 'undefined') {
        console.warn('Falha ao gerar gráfico, pois array enviado possui itens indefinidos', this.dataProvider);
        return;
      }
      this.genChart();
    });
  }

  public ngOnInit() {
    this.listenDataSource();
  }

  public ngAfterViewInit() {
    this.started = true;
    if (!this.chart) {
      this.genChart();
    }
  }

  public ngOnChanges(data: any) {
    try {
      if (data['dataSource']) {
        this.unsubscribe();
        this.listenDataSource();
      }
      this.genChart();
    } catch (e) {
      console.warn(e);
    }
  }

  private genChart() {
    if (!this.started) {
      return;
    }
    const options = this.getOptions();
    const data = this.dataProvider ? [...this.dataProvider] : [];
    return !this.chart ? this.createChart(data, options) : this.updateChart(data, options);
  }

  protected createChart(dataProvider: Array<any>, options?: any) {
    try {
      if (!options) {
        options = {};
      }
      if (!dataProvider || !dataProvider.length) {
        return;
      }
      options['data'] = [...dataProvider];
      this.chart = am4core.createFromConfig(options, this.getChartDiv(), am4charts.XYChart);
      this.setLoaderColor(options.series[options.series.length - 1].fill);
      this.setAdapters();
    } catch (e) {
      console.warn(e);
    }
  }

  protected updateChart(dataProvider?: Array<T>, chartOptions?: any) {
    if (!this.chart || (!dataProvider && !chartOptions)) {
      return;
    }

    const series = this.getGraphSeries(chartOptions.series);
    this.setLoaderColor(chartOptions.series[chartOptions.series.length - 1].fill);
    this.chart.series.setAll(series);
    this.setAdapters();
    this.chart.data = dataProvider;
    return;
  }

  private setLoaderColor(color: string) {
    this.chart.preloader.label.stroke = color;
    this.chart.preloader.progressSlice.fill = color;
    this.chart.preloader.progressSlice.fillOpacity = 0.7;
  }

  private getGraphSeries(options: any) {
    const out = [];
    options.forEach(option => {
      const serie = new am4charts.LineSeries();
      serie.id = option.id;
      serie.name = option.name;
      serie.dataFields = option.dataFields;
      serie.fill = option.fill;
      serie.stroke = option.stroke;
      serie.strokeWidth = option.strokeWidth;
      serie.fillOpacity = option.fillOpacity;
      out.push(serie);
    });
    return out;
  }

  public ngOnDestroy() {
    this.unsubscribe();
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = null;
  }

  public getChartDiv() {
    return this.divID;
  }
}
