import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule, ChangeDetectorRef } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { environment } from '@env/environment';
import { LOGO_STRATEGY } from '@gorila-bot/logo';
import { WhiteLabelLogoStrategy, ADVISOR_LOGO_URL } from '@gorila-bot/white-label';

import { LoginStatusService } from './login-status/login-status.service';
import { AdvisorFundGuard } from './router/guards/advisor-fund.guard';
import { ResearchHousesGuard } from './router/guards/research-houses.guard';
import { AuthGuard } from './router/guards/auth.guard';
import { FeatureGuard } from './router/guards/feature-guard';
import { LoggedOutGuard } from './router/guards/logged-out.guard';
import { PermissionGuard } from './router/guards/permission.guard';
import { RouterWatcherService } from './router/router-watcher.service';
import { UtilsService } from './utils';

import { GorilaUrlSerializer } from './router/url-serializer';
import { MobileGuard } from './router/guards/mobile.guard';
import { TipsService } from '@gorila/widgets/tips/services/tips.service';

@NgModule({
  imports: [CommonModule],
})
export class CoreModule {
  constructor(private injector: Injector) {
    UtilsService.injector = this.injector;
  }

  private static mwp = {
    ngModule: CoreModule,
    providers: [
      LoginStatusService,
      RouterWatcherService,
      AdvisorFundGuard,
      ResearchHousesGuard,
      AuthGuard,
      TipsService,
      FeatureGuard,
      PermissionGuard,
      UtilsService,
      LoggedOutGuard,
      MobileGuard,
      {
        provide: ADVISOR_LOGO_URL,
        useValue: `https://gorila-pro.s3.us-west-2.amazonaws.com/logo/${ environment.production === true ? 'prod' : 'dev' }/`
      },
      { provide: LOGO_STRATEGY, useClass: WhiteLabelLogoStrategy },
      {
        provide: UrlSerializer,
        useClass: GorilaUrlSerializer,
      },
    ],
  };

  public static forChild(): ModuleWithProviders {
    return CoreModule.mwp;
  }

  public static forRoot(): ModuleWithProviders {
    return CoreModule.mwp;
  }
}
