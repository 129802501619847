import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES } from '../../store.model';
import { Separator } from '../../store.utils';
import { APIActions, getReduxActionType, MetaData } from '../actions';
import { ActionTransition, ActionType } from '../actions.model';
import { User } from './user.model';

export type UserLogoutAPIAction = FluxStandardAction<User, MetaData>;
export type UserSetDataAPIAction = FluxStandardAction<User, MetaData>;
export type UserSaveDataAPIAction = FluxStandardAction<User, MetaData>;
export type UserSavedDataAPIAction = FluxStandardAction<User, MetaData>;
export type UserLoadDataAPIAction = FluxStandardAction<User, MetaData>;
export type UserLoadUserDataAPIAction = FluxStandardAction<User, MetaData>;
export type UserLoadedUserDataAPIAction = FluxStandardAction<User, MetaData>;
export type UserLoadedUserDataErrorAPIAction = FluxStandardAction<User, MetaData>;

const stateType = STATE_TYPES['USER'];
const actionType = {
  logout: stateType + Separator + 'LOGOUT',
  setUserData: getReduxActionType(ActionType.ADD, stateType, ActionTransition.DATA),
  loadUserData: getReduxActionType(ActionType.LOAD, stateType, ActionTransition.STARTED),
  loadedUserData: getReduxActionType(ActionType.LOAD, stateType, ActionTransition.SUCCEEDED),
  loadedUserDataError: getReduxActionType(ActionType.LOAD, stateType, ActionTransition.FAILED),
  saveUserData: getReduxActionType(ActionType.UPDATE, stateType, ActionTransition.STARTED),
  savedData: getReduxActionType(ActionType.UPDATE, stateType, ActionTransition.SUCCEEDED),
};

export function logout(): UserLogoutAPIAction {
  return {
    type: actionType.logout,
    meta: { actionType: ActionType.ADD, stateType: stateType },
    payload: null
  };
}

export function setUserData(userData: User): UserLogoutAPIAction {
  return {
    type: actionType.setUserData,
    meta: { actionType: ActionType.ADD, stateType: stateType },
    payload: userData
  };
}

export function saveUserData(data: any): UserSaveDataAPIAction {
  return {
    type: actionType.saveUserData,
    meta: { actionType: ActionType.UPDATE, stateType: stateType },
    payload: data
  };
}

export function savedData(data, action): UserSavedDataAPIAction {
  return {
    type: actionType.savedData,
    meta: { actionType: ActionType.UPDATE, stateType: stateType },
    payload: data
  };
}

export function loadUserData(): UserLoadUserDataAPIAction {
  return {
    type: actionType.loadUserData,
    meta: { actionType: ActionType.LOAD, stateType: stateType },
    payload: null
  };
}

export function loadedUserData(data): UserLoadedUserDataAPIAction {
  return {
    type: actionType.loadedUserData,
    meta: { actionType: ActionType.LOAD, stateType: stateType },
    payload: data
  };
}

export function loadedUserDataError(): UserLoadedUserDataErrorAPIAction {
  return {
    type: actionType.loadedUserDataError,
    meta: { actionType: ActionType.LOAD, stateType: stateType },
    payload: null,
    error: true
  };
}

export type UserAPIAction = FluxStandardAction<User[], MetaData>;

@Injectable()
export class UserAPIActions extends APIActions<User> {

  public static actionType = actionType;
  protected readonly type = STATE_TYPES['USER'];
  @dispatch()
  public logout = (): UserLogoutAPIAction  => logout()

  @dispatch()
  public setUserData = (userData: User): UserSetDataAPIAction => setUserData(userData)

  @dispatch()
  public saveUserData = (userData: any): UserSaveDataAPIAction => saveUserData(userData)

  @dispatch()
  public loadUserData = (): UserLoadDataAPIAction => loadUserData()

  public loadedUserData = (data): UserLoadedUserDataAPIAction => loadedUserData(data);
  public loadedUserDataError = (): UserLoadedUserDataErrorAPIAction => loadedUserDataError();
  public savedData = (data, action): UserSavedDataAPIAction => savedData(data, action);
}
