import { UserAccountModel } from '@gorila/root-store/user-account';
import { TranslateService } from '@ngx-translate/core';
import { Genial, Orama } from '../../user-account.data';
import { PartnerModalData } from './partner-modal.model';

export const PartnerRedirectAwaitModalDataFn = (translate: TranslateService): PartnerModalData => ({
  title: 'PARTNER.REDIRECT_MODAL.TITLE',
  type: 'info',
  loading: true,
  footerDisclaimer: `
    <span>${translate.instant('PARTNER.REDIRECT_MODAL.MESSAGE')}</span>
  `
});

export const B3SynchronizingModalDataFn = (translate: TranslateService): PartnerModalData => ({
  title: 'B3_PARTNER.CONNECTING.MODAL_TITLE',
  type: 'info',
  bottomImage: {
    height: 32,
    icon: 'Sync',
    iconFolder: 'Platform/Actions',
    width: 32,
    class: 'color-filter--gorila-primary'
  },
  provider: {
    name: 'B3',
    title: 'B3_PARTNER.CONNECTING.PROVIDER_TITLE'
  },
  htmlContent: `
    <span>${translate.instant('B3_PARTNER.CONNECTING.MESSAGE_A')}</span><br/>
    <span style="max-width:90%;display:block;margin:0 auto;margin-top:14px">${translate.instant('B3_PARTNER.CONNECTING.MESSAGE_B')}</span>
  `,
  buttons: [{
    main: true,
    text: 'B3_PARTNER.CONNECTING.BUTTON_TEXT'
  }]
});

export const B3SynchronizedModalDataFn = (translate: TranslateService): PartnerModalData => ({
  title: 'B3_PARTNER.CONNECTED.MODAL_TITLE',
  type: 'success',
  bottomImage: {
    height: 32,
    icon: 'success',
    iconFolder: 'Status',
    width: 32
  },
  provider: {
    name: 'B3',
    title: 'B3_PARTNER.CONNECTED.PROVIDER_TITLE'
  },
  htmlContent: `
    <span>${translate.instant('B3_PARTNER.CONNECTED.MESSAGE_A')}</span><br/>
    <span>${translate.instant('B3_PARTNER.CONNECTED.MESSAGE_B')}</span>
  `,
  buttons: [{
    id: 'wallet',
    main: true,
    text: 'B3_PARTNER.CONNECTED.BUTTON_TEXT'
  }],
  footerDisclaimer: `
    <span>
    ${translate.instant('B3_PARTNER.CONNECTED.FOOTER_DISCLAIMER_A')}
    <img style="height: 13px" src="https://gorila-images.s3.amazonaws.com/app/Platform/Actions/Settings.svg" />
    ${translate.instant('B3_PARTNER.CONNECTED.FOOTER_DISCLAIMER_B')}
    </span>
  `
});

export const B3CEIDisconnectDisclaimerModalDataFn = (translate: TranslateService): PartnerModalData => ({
  title: 'B3_PARTNER.CEI_DISCONNECT_DISCLAIMER.MODAL_TITLE',
  htmlContent: `
    <div style="margin: 0 20px">
      <span>${translate.instant('B3_PARTNER.CEI_DISCONNECT_DISCLAIMER.MESSAGE_A')}</span>
      <br>
      <div style="margin-top: 8px">${translate.instant('B3_PARTNER.CEI_DISCONNECT_DISCLAIMER.MESSAGE_B')}</div>
    </div>
  `,
  buttons: [{
    id: 'continue',
    main: true,
    text: 'B3_PARTNER.CEI_DISCONNECT_DISCLAIMER.BUTTON_TEXT'
  }]
});

export const B3ConnectErrorModalDataFn = (translate: TranslateService): PartnerModalData => ({
  title: 'B3_PARTNER.CONNECT_ERROR.MODAL_TITLE',
  type: 'error',
  bottomImage: {
    height: 32,
    icon: 'error',
    iconFolder: 'Status',
    width: 32
  },
  provider: {
    name: 'B3',
    title: 'B3_PARTNER.CONNECT_ERROR.PROVIDER_TITLE'
  },
  htmlContent: `
    <span>${translate.instant('B3_PARTNER.CONNECT_ERROR.MESSAGE')}</span>
  `,
  buttons: [{
    id: 'try_again',
    main: true,
    text: 'B3_PARTNER.CONNECT_ERROR.BUTTON_TEXT'
  }]
});

export const B3CPFInUseModalDataFn = (translate: TranslateService): PartnerModalData => ({
  title: 'B3_PARTNER.CPF_IN_USE.MODAL_TITLE',
  type: 'info',
  htmlContent: `
    <span>${translate.instant('B3_PARTNER.CPF_IN_USE.MESSAGE')}</span>
  `,
  buttons: [{
    id: 'continue',
    main: true,
    text: 'B3_PARTNER.CPF_IN_USE.BUTTON_TEXT'
  }]
});

export const B3SyncErrorModalDataFn = (translate: TranslateService): PartnerModalData => ({
  title: 'B3_PARTNER.SYNC_ERROR.MODAL_TITLE',
  type: 'error',
  bottomImage: {
    height: 32,
    icon: 'error',
    iconFolder: 'Status',
    width: 32
  },
  provider: {
    name: 'B3',
    title: 'B3_PARTNER.SYNC_ERROR.PROVIDER_TITLE'
  },
  htmlContent: `
    <span style="max-width:90%;display:block;margin:0 auto">${translate.instant('B3_PARTNER.SYNC_ERROR.MESSAGE')}</span>
  `,
  buttons: [{
    id: 'okay',
    main: true,
    text: 'B3_PARTNER.SYNC_ERROR.BUTTON_TEXT'
  }]
});

export const CEIDisclaimer = (translate: TranslateService): PartnerModalData => ({
  type: 'alert',
  invertProviderColor: true,
  noPadding: true,
  title: '',
  provider: {
    name: 'CEI_PARTNER.DISCLAIMER.TITLE',
    title: ''
  },
  buttons: [{
    main: true,
    text: 'CEI_PARTNER.DISCLAIMER.BUTTON_TEXT'
  }],
  htmlContent: `
  <div style="margin: 0 30px">
    <p>${translate.instant('CEI_PARTNER.DISCLAIMER.TEXT_1')}</p>
    <p><strong>${translate.instant('CEI_PARTNER.DISCLAIMER.TEXT_2')}</strong></p>
    <ul style="color:#f0712c;text-align:left;margin:10px auto;width: fit-content;">
      <li>${translate.instant('CEI_PARTNER.DISCLAIMER.TEXT_3')}</li>
      <li>${translate.instant('CEI_PARTNER.DISCLAIMER.TEXT_4')}</li>
    </ul>
    <p>${translate.instant('CEI_PARTNER.DISCLAIMER.TEXT_5')}</p>
    <p>
      ${translate.instant('CEI_PARTNER.DISCLAIMER.TEXT_6_A')}
      <img style="height: 18px" src="https://gorila-images.s3.amazonaws.com/app/Navigation/Main-Nav/Help.svg" />
      ${translate.instant('CEI_PARTNER.DISCLAIMER.TEXT_6_B')}
    </p>
  </div>
  `
});

export const DisconnectModalDataFn = (
  translate: TranslateService,
  partner: UserAccountModel,
  partnerLogoName?: string
): PartnerModalData => ({
  title: '',
  noPadding: true,
  partnerHeader: {
    borderless: true,
    helpLink: '',
    customTitleText: translate.instant('PARTNER.DISCONNECT.MODAL_TITLE'),
    isBeta: partner.isBeta,
    partnerLogoFolder: 'Partner/Logo/Broker',
    partnerLogoName,
    partnerName: partner.providerName,
    partnerType: partner.providerType,
    showCloseButton: true,
  },
  htmlContent: `
    <span>${translate.instant('PARTNER.DISCONNECT.MESSAGE_1')}</span><br/>
    <span>
      ${translate.instant(
        Number(partner.providerId) === Orama.id || Number(partner.providerId) === Genial.id
          ? 'PARTNER.DISCONNECT.MESSAGE_2' : 'PARTNER.DISCONNECT.MESSAGE_2_WITHOUT_DELETE'
      )}
    </span>
  `,
  buttons: [
    {
      id: 'cancel',
      text: translate.instant('PARTNER.DISCONNECT.BUTTON_NOK_TEXT')
    },
    {
      id: 'drop_all',
      main: true,
      text: translate.instant('PARTNER.DISCONNECT.BUTTON_OK_TEXT')
    }
  ]
});

export const DeleteTransactionsData = (translate: TranslateService, partner: UserAccountModel): PartnerModalData => ({
  title: 'DELETE_TRANSACTION.CONFIRM.TITLE',
  type: 'info',
  provider: {
    name: partner.providerName + '?',
    title: 'DELETE_TRANSACTION.CONFIRM.SUBTITLE',
    class: 'primary'
  },
  htmlContent: `
    <span style="color:#434343">${translate.instant('DELETE_TRANSACTION.CONFIRM.TEXT')}</span><br/>
    <span style="max-width:90%;display:block;margin:0 auto;margin-top:14px">
      ${translate.instant('DELETE_TRANSACTION.CONFIRM.TEXT_HELP')}
    </span>
  `,
  buttons: [{
    text: 'DELETE_TRANSACTION.CONFIRM.CONFIRM',
    id: 'confirm'
  }]
});

export const DeleteTransactionsSuccessData = (translate: TranslateService, partner: UserAccountModel): PartnerModalData => ({
  title: 'DELETE_TRANSACTION.SUCCESS.TITLE',
  type: 'success',
  bottomImage: {
    height: 32,
    icon: 'success',
    iconFolder: 'Status',
    width: 32
  },
  subtitle: 'DELETE_TRANSACTION.SUCCESS.SUBTITLE',
  buttons: [{
    text: 'DELETE_TRANSACTION.SUCCESS.BUTTON',
    id: 'wallet'
  }]
});

export const DeleteTransactionsErrorData = (translate: TranslateService, partner: UserAccountModel): PartnerModalData => ({
  title: 'DELETE_TRANSACTION.ERROR.TITLE',
  type: 'error',
  bottomImage: {
    height: 32,
    icon: 'error',
    iconFolder: 'Status',
    width: 32
  },
  subtitle: 'DELETE_TRANSACTION.ERROR.SUBTITLE',
  htmlContent: `
    <span style="color:#434343">${translate.instant('DELETE_TRANSACTION.ERROR.TEXT')}</span><br/>
  `,
  buttons: [{
    text: 'DELETE_TRANSACTION.ERROR.BUTTON',
    id: 'retry'
  }]
});

export const DeleteTransactionsLoaderData = (translate: TranslateService, partner: UserAccountModel): PartnerModalData => ({
  title: translate.instant('DELETE_TRANSACTION.LOADER.TITLE', { providerName: partner.providerName }),
  type: 'info',
  loading: true
});

export const MBAPIInstructions = (translate: TranslateService, partner: UserAccountModel): PartnerModalData => ({
  title: '',
  noPadding: true,
  partnerHeader: {
    borderless: false,
    helpLink: 'https://guia.gorila.com.br/hc/pt-br/articles/360053199372-Conectar-Contas-Mercado-Bitcoin',
    customTitleText: '',
    isBeta: partner.isBeta,
    partnerLogoFolder: 'Partner/Logo/Broker',
    partnerLogoName: 'MercadoBitcoin-Positive',
    partnerName: partner.providerName,
    partnerType: partner.providerType,
    showCloseButton: true,
  },
  htmlContent: `
    <span style="margin:0 54px;display:block;text-align:left">${translate.instant('MB_PARTNER.API_TIPS.DESCRIPTION')}</span>
    <span style="margin:8px 54px 0;display:block;text-align:left;color:#f0712c">${translate.instant('MB_PARTNER.API_TIPS.ALERT')}</span>
  `,
  bodyImage: {
    height: 203,
    icon: 'MercadoBitcoin',
    iconFolder: 'Partner/Image',
    width: 300
  },
  buttons: [
    {
      id: 'open-tab',
      text: 'MB_PARTNER.API_TIPS.BUTTON_OPEN_TAB'
    },
    {
      id: 'continue',
      text: 'MB_PARTNER.API_TIPS.BUTTON_CONTINUE',
      main: true
    }
  ],
  showSecurityDisclaimer: true
});


export const SyncErrorModalDataFn = (
  { providerName, htmlContent, helpLink }: {
    providerName: string,
    htmlContent: string,
    helpLink?: string
  }): PartnerModalData => ({
    title: 'PARTNER.SYNC_ERROR.MODAL_TITLE',
    type: 'error',
    bottomImage: {
      height: 32,
      icon: 'error',
      iconFolder: 'Status',
      width: 32
    },
    provider: {
      name: providerName,
      title: 'PARTNER.SYNC_ERROR.PROVIDER_TITLE'
    },
    htmlContent,
    buttons: [{
      id: helpLink ? 'help' : 'okay',
      main: true,
      text: 'PARTNER.SYNC_ERROR.BUTTON_TEXT',
      link: helpLink
    }]
  });
