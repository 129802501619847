import { Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { SubscriptionsManager } from '@gorila-bot/gorila-base';
import { UtilsService } from '@gorila/core/utils';

export abstract class ReactiveComponent
  implements ControlValueAccessor, OnChanges, OnDestroy, OnInit {
  @Input() public inputname: string;
  @Input() public cssClass = 'form-control';
  @Input() public placeholder = '';
  @Input() public readonly: string;
  @Input() public disabled = false;
  @Input() public options: Object;
  @Input() public _data: any;

  protected _name = '';
  protected logEnabled = false;
  protected defaultValue: any;
  protected abstract transformToModel(arg: any): any;
  protected abstract getOutput(arg: any): any;
  protected abstract getDefaultValue(): any;
  protected abstract getValidationFn(changes: any);
  protected abstract onInputChange(changes: any);

  get data(): any {
    try {
      return this.getOutput(this._data);
    } catch (e) {
      try {
        return this.getDefaultValue();
      } catch (ex) {
        return '';
      }
    }
  }

  set data(val: any) {
    try {
      if (this.logEnabled) { console.log(this.constructor['name'], 'data', val); }
      if (typeof val === 'undefined' || val === null) { val = this.getDefaultValue(); }
      this._data = this.transformToModel(val);
      if (this.logEnabled) {
        console.log(this.constructor['name'], 'val:', val);
        console.log(this.constructor['name'], 'model:', this._data);
        console.log(this.constructor['name'], 'propagating:', this.getOutput(val));
      }
      this.propagateChange(this.getOutput(val));
    } catch (e) {
      console.error(this.constructor['name'], 'ReactiveComponent EXCEPTION!', e);
      try {
        this._data = this.transformToModel(this.getDefaultValue());
        this.propagateChange(this.getOutput(this._data));
      } catch (ex) { console.error(this.constructor['name'], ex); }
    }
  }

  public touchedChange: any = () => { };
  public propagateChange: any = () => { };
  public validateFn: any = () => { };

  public validate(c: FormControl) {
    try {
      return this.validateFn(c);
    } catch (e) { console.warn(this.constructor['name'], e); }
  }

  public focusEvent(event: any) {
    try {
      this.touchedChange();
    } catch (e) { console.warn(this.constructor['name'], e); }
  }

  public isDisabled() {
    try {
      return this.disabled;
    } catch (e) { console.warn(e); }
  }

  public ngOnInit() {
    try {
      this.touchedChange();
      const val = this.getValidationFn({});
      if (val !== null) { this.validateFn = val; }
    } catch (e) { console.warn(this.constructor['name'], e); }
  }

  public updateData(value: any) {
    try {
      if (!value) {
        this.data = this.getDefaultValue();
        return;
      }
      if (this.logEnabled) { console.log(this.constructor['name'], 'updateData', value); }
      this.data = value;
    } catch (e) {
      try {
        console.warn(this.constructor['name'], e);
        this.data = this.getDefaultValue();
      } catch (ex) {
        console.warn(this.constructor['name'], ex);
        this.data = null;
      }
    }
  }

  /***********************************************
   **BEGIN implementation of OnChanges interface**
   ***********************************************/
  public ngOnChanges(inputs: any) {
    try {
      this.onInputChange(inputs);
      const val = this.getValidationFn(inputs);
      if (val !== null) { this.validateFn = val; }
    } catch (e) { console.warn(this.constructor['name'], e); }
  }


  /**********************************************************
   **BEGIN implementation of ControlValueAccessor interface**
   **********************************************************/

  /**
   writeValue(obj: any) is the method that writes a new value from the form model
   * into the view or (if needed) DOM property. This is where we want to update our
   * counterValue model, as that’s the thing that is used in the view.
   */
  public writeValue(value: any) {
    try {
      if (UtilsService.deepCompare(value, this.defaultValue)) { return; }
      if (this.logEnabled) {
        console.log(
          this.constructor['name'],
          'writeValue', value,
          '_data', this._data);
      }
      this.updateData(value);
    } catch (e) { console.warn(this.constructor['name'], e); }
  }

  /**
   * registerOnChange(fn: any) is a method that registers a handler that should
   * be called when something in the view has changed. It gets a function
   * that tells other form directives and form controls to update their values.
   * In other words, that’s the handler function we want to call whenever
   * price changes through the view.
   */
  public registerOnChange(fn: Function) {
    try {
      this.propagateChange = fn;
      if (this.logEnabled) {
        console.log(this.constructor['name'], 'registerOnChange', this.data);
      }
      this.updateData(this._data);
    } catch (e) { console.warn(this.constructor['name'], e); }
  }

  /**
   * registerOnTouched(fn: any) Similiar to registerOnChange(), this registers
   * a handler specifically for when a control receives a touch event.
   * We don’t need that in our custom control.
   */
  public registerOnTouched(fn: Function) {
    try {
      this.touchedChange = fn;
    } catch (e) { console.warn(this.constructor['name'], e); }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /********************************************************
   **END implementation of ControlValueAccessor interface**
   ********************************************************/

  public ngOnDestroy() {
    SubscriptionsManager.detach(this._name);
  }
}
