// tslint:disable:class-name
import {
  IPosition,
  IPositionList,
  IPositionServer,
  IPositionRequestModel,
  IProfitabilityGroup,
  FundIdType
} from '@gorila-bot/gorila-front-models';
import { Action } from '@ngrx/store';

export enum PositionActionTypes {
  PositionLoad = '[Position] Load Positions',
  _PositionLoad = '[Position] Load Positions END',
  _PositionProfitabilityLoad = '[Position] Load Positions Profitability END',
  MapTrades = '[Position] Map Trades In Positions',
  _MapTrades = '[Position] Map Trades In Positions END',
  UpdatePosition = '[Position] Update Position',
  _UpdatePosition = '[Position] Update Position END',
  RemovePosition = '[Position] Remove Position',
}

export class PositionLoad implements Action {
  public readonly type = PositionActionTypes.PositionLoad;
  public payload: IPositionRequestModel;
  constructor(payload: IPositionRequestModel) {
    this.payload = payload;
  }
}

export class _PositionLoad implements Action {
  public readonly type = PositionActionTypes._PositionLoad;
  public payload: {
    positionsServer: IPositionServer[];
    positions: IPositionList;
  };
  constructor(positionsServer: IPositionServer[], positions: IPositionList, public error?: any) {
    this.payload = { positionsServer, positions };
  }
}

export class _PositionProfitabilityLoad implements Action {
  public readonly type = PositionActionTypes._PositionProfitabilityLoad;
  public payload: IProfitabilityGroup;
  constructor(profit: IProfitabilityGroup, public error?: any) {
    this.payload = profit;
  }
}

export class MapTrades implements Action {
  public readonly type = PositionActionTypes.MapTrades;
  public payload = null;
  constructor() {}
}

export class _MapTrades implements Action {
  public readonly type = PositionActionTypes._MapTrades;
  public payload = { trades: null, positions: null };
  constructor(payload) {
    this.payload = payload;
  }
}

export class UpdatePosition implements Action {
  public readonly type = PositionActionTypes.UpdatePosition;
  constructor(public payload: IPositionServer, public fundId: FundIdType) {}
}

export class _UpdatePosition implements Action {
  public readonly type = PositionActionTypes._UpdatePosition;
  public payload: { positionServer: IPositionServer, position: IPosition };
  constructor(positionServer, position) {
    this.payload = { positionServer, position };
  }
}

export class RemovePosition implements Action {
  public readonly type = PositionActionTypes.RemovePosition;
  constructor(public payload: string) {}
}

export type PositionActions =
  | PositionLoad
  | _PositionLoad
  | _PositionProfitabilityLoad
  | MapTrades
  | _MapTrades
  | UpdatePosition
  | _UpdatePosition
  | RemovePosition;

// tslint:enable:class-name
