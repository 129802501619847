import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { FundIdType } from '@gorila-bot/user-data-store';
import { RequestServiceBase } from '@gorila/store';

import { ITransferRequest, ITransferResponseItem } from '../transfer.state';

@Injectable()
export class TransferRequestService extends RequestServiceBase<ITransferRequest, ITransferResponseItem | {error: string}> {
  public getParams(params: ITransferRequest): ITransferRequest {
    return params;
  }

  public getBaseUrl(fundId: FundIdType): string {
    return `wallet/${fundId}/positionExplanation`;
  }

  public deleteTransfer(id: string, walletId: number) {
    const url = environment.serviceBaseUrl + 'operations/' + id + '?walletId=' + walletId;
    return this.apiService.dropData(url);
  }
}
