export const AllocationConstants = {
  decimals: {
    FUNDQUOTE: 8,
    DEFAULT: 2
  },
  PricedSecurities: [
    'TREASURY', 'TREASURY_LOCAL', 'CORPORATE_BONDS', 'SAVINGS', 'STOCK',
    'STOCK_LOCAL', 'STOCK_SHARE', 'FUNDS', 'FUNDQUOTE'
  ],
};
