import { Injectable } from '@angular/core';
import { LoginStatusService } from '@gorila/core';
import { DaoService, TableFilterPipe } from '@gorila/shared';
import { SocketEventService } from '@gorila-bot/gorila-base';
import { ITradeServer } from '@gorila-bot/gorila-front-models';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { path } from 'ramda';
import { take } from 'rxjs/operators';

import { TradeConfig } from '../config/trade.config';
import { TradePipe } from '@gorila/pages/wallet/pipes';
import { BookerService } from './booker.service';
import { TradeFormatterService } from './trade-formatter.service';

@Injectable()
export class TradeService extends DaoService<ITradeServer> {
  public static requested = false;
  public selectedType: Array<string> = new Array();
  private tableFilterPipe: TableFilterPipe = null;

  constructor(
    protected loginStatusService: LoginStatusService,
    protected loadingBarService: LoadingBarService,
    private bookerService: BookerService,
    private socketEventService: SocketEventService,
    private tradeFormatService: TradeFormatterService,
    private tradePipe: TradePipe
  ) {
    super(loginStatusService, loadingBarService);
    this.tableFilterPipe = new TableFilterPipe();
    this.init();
  }

  public cleanup() {
    this.selectedType = new Array();
    TradeService.requested = false;
  }

  public start() {
    if (!this.bookerService) {
      return;
    }
    this.bookerService
      .getTrades(true)
      .pipe(take(1))
      .subscribe(
        (data: any) => {
          try {
            const value = this.tradeFormatService.prepareTradeList(data, true);
            this.updateData(value);
          } catch (e) {
            console.warn(e);
          }
        },
        (error: any) => {
          console.warn(error);
        }
      );
  }

  public setSelected(response: any) {
    this.selectedType = this.getSelectedType(response);
    this.updateData();
  }

  protected applyFilter(data: Array<ITradeServer>): Array<ITradeServer> {
    return this.tableFilterPipe.transform(data, this.selectedType, false);
  }

  protected formatItem(item: ITradeServer): any {
    return this.tradePipe.transform(item);
  }

  protected getIdName(): string {
    return 'Id';
  }

  protected init() {
    this.start();
    this.listenSocket();
    super.init();
  }

  protected isValidItem(item: ITradeServer): boolean {
    if (!item) {
      return false;
    }
    if (this.get(item[this.getIdName()])) {
      return false;
    }
    return true;
  }

  private getSelectedType(response: any): Array<string> {
    const selectedType = new Array();
    if (response.securityType !== '' && response.securityType !== null) {
      if (response.securityType.indexOf('_LOCAL') !== -1) {
        response.securityType = response.securityType.replace('_LOCAL', '');
      }
      selectedType.push(response.securityType);
    }

    if (
      response.group !== '' &&
      response.group !== 'securityType' &&
      response[response.group] !== '' &&
      response[response.group] !== null
    ) {
      selectedType.push(response[response.group]);
    }

    if (response.securityName !== '' && response.securityName !== null) {
      selectedType.push(response.securityName);
    }
    return selectedType;
  }

  private listenSocket() {
    if (!TradeConfig.Feature.Socket) {
      return;
    }
    var self = this; // tslint:disable-line
    this.socketEventService.getObserver('TRADE_ADD').subscribe((data) => {
      try {
        if (!data) {
          return;
        }
        self.add(path(['data', 'Trade'], data));
      } catch (e) {
        console.warn(e);
      }
    });
    this.socketEventService.getObserver('TRADE_REMOVE').subscribe((data) => {
      try {
        if (!data) {
          return;
        }
        self.remove(path(['data', 'Trade', 'TradeId'], data));
      } catch (e) {
        console.warn(e);
      }
    });
  }
}
