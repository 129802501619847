<ng-container *ngIf="data">
  <div class="modal-container">
    <div class="modal-header">
      <div class="title-container">
        <h4 class='title-container__text' translate>active connection with</h4>
        <h4 class='title-container__provider'>{{data.providerName}}</h4>
      </div>
    </div>
    <div class='modal-body'>
      <ng-container *ngIf="data.forceLogout; else noLogoutBodyTmpl">
        <span class="modal-body__text" translate>Just one step to take advantage of integration</span>
      </ng-container>
      <ng-template #noLogoutBodyTmpl>
        <span class="modal-body__text" [innerHtml]="this.getNoLogoutBodyMsg(data.providerName) | gorKeepHtml"></span>
      </ng-template>
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="data.forceLogout; else noLogoutFooterTmpl">
        <gor-button btnType='green' (click)="setLogout()">{{"Go to Login" | translate}}</gor-button>
      </ng-container>
      <ng-template #noLogoutFooterTmpl>
        <gor-button btnType='green' (click)="close()">{{"OK" | translate}}</gor-button>
      </ng-template>
    </div>
  </div>
</ng-container>
