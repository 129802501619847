import { Injectable } from '@angular/core';
import { ApiService } from '@gorila-bot/gorila-base';
import { FundIdType, IAllocationPercentagesRequestModel } from '@gorila-bot/gorila-front-models';
import { RequestServiceBase } from '@gorila/store';
import { Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';

@Injectable()
export class AllocationPercentagesRequestService extends RequestServiceBase<IAllocationPercentagesRequestModel> {

  public constructor (protected apiService: ApiService, protected store: Store<any>) {
    super(apiService, store);
  }

  public getBaseUrl(fundId: FundIdType) {
    return `portfolio/${fundId}/allocation`;
  }

  public getParams(params: IAllocationPercentagesRequestModel): IAllocationPercentagesRequestModel {
    return params;
  }

  public doMultiRequests(itemType: any[]): Observable<any[]> {
    const observables: Array<Observable<any>> = [];
    itemType.forEach(item => {
      const params = { refDate: item.date, assetClassOnly: true };
      observables.push(this.doRequest(params));
    });
    return zip(...observables);
  }
}
