import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@gorila-bot/gorila-base';
import { FundIdType } from '@gorila-bot/user-data-store';
import { RequestServiceBase } from '@gorila/store';
import { Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';

import { UserAccountRequestModel } from '../models/user-account-request.model';
import { CheckAuthorizationPayload } from '../models/user-account.model';

@Injectable({
  providedIn: 'root'
})
export class UserAccountRequestService extends RequestServiceBase<UserAccountRequestModel> {

  constructor(
    protected apiService: ApiService,
    protected store: Store<any>
  ) {
    super(apiService, store);
  }

  public getParams(params: UserAccountRequestModel): UserAccountRequestModel {
    return params;
  }

  public getBaseUrl(fundId: FundIdType): string {
    return `providersync/status_list?fundId=${fundId}`;
  }

  public activate(providerId: number) {
    return this.getFundId().pipe(concatMap(fundId => {
      const url = `providersync/activate?fundId=${fundId}&providerId=${providerId}`;
      return this.apiService.doPost(environment.serviceBaseUrl + url, {}, {providerId});
    }));
  }

  public checkAuthorization(data: CheckAuthorizationPayload) {
    return this.getFundId().pipe(concatMap(fundId => {
      const providerId = data.providerId;
      delete data.providerId;
      const url = `providersync/check_authorization?fundId=${fundId}&providerId=${providerId}`;
      return this.apiService.doPost(environment.serviceBaseUrl + url, {}, data);
    }));
  }

  public connect(data: any) {
    return this.getFundId().pipe(concatMap(fundId => {
      const providerId = data.providerId;
      delete data.providerId;
      const url = `providersync/sign_up?fundId=${fundId}&providerId=${providerId}`;
      return this.apiService.doPost(environment.serviceBaseUrl + url, {}, data);
    }));
  }

  public deactivate(providerId: number) {
    return this.getFundId().pipe(concatMap(fundId => {
      const url = `providersync/deactivate?fundId=${fundId}&providerId=${providerId}`;
      return this.apiService.doPost(environment.serviceBaseUrl + url, {}, {providerId});
    }));
  }

  public disconnect(providerId: number) {
    return this.getFundId().pipe(concatMap(fundId => {
      const url = `providersync/sign_off?fundId=${fundId}&providerId=${providerId}`;
      return this.apiService.doPost(environment.serviceBaseUrl + url, {}, {providerId});
    }));
  }

  public deleteManualTradeOfProvider(fundId: FundIdType, providerId: number) {
    const url = `providersync/manual_trades?fundId=${fundId}&providerId=${providerId}`;
    return this.apiService.dropData(environment.serviceBaseUrl + url);
  }

  public deleteManualTradesWithProviderDuplicates(fundId: FundIdType, providerId: number) {
    const url = `providersync/manual_provider_duplicate_trades?fundId=${fundId}&providerId=${providerId}`;
    return this.apiService.dropData(environment.serviceBaseUrl + url);
  }

  public deleteManualTrade(fundId: FundIdType) {
    const url = `trader/portfolio?fundId=${fundId}`;
    return this.apiService.dropData(environment.serviceBaseUrl + url);
  }

  public deleteSyncedTransactions(fundId: FundIdType, providerId: number) {
    const url = `connections/${fundId}/provider/${providerId}/clean-wallet`;
    return this.apiService.dropData(environment.serviceBaseUrl + url);
  }
}
