import { IOperation } from '@gorila-bot/gorila-front-models';
import { EntityState } from '@ngrx/entity';

export const Key = 'trade';

export interface TradeState extends EntityState<IOperation> {
  costs: { [parentOperaionId: string]: IOperation[] };
  loading?: boolean;
  error?: any;
}

export const initialState: TradeState = {
  ids: [],
  entities: {},
  costs: {},
  loading: false,
  error: null
};
