import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FilterDefinition } from '@gorila-bot/filter-bar';
import { FilterActions, FilterSelectors } from '@gorila-bot/filter-container/store';
import { AvailableDates, DATE_FORMAT } from '@gorila-bot/gorila-base2';
import { IOperation, IPosition, IPositionIndexed, IPositionList } from '@gorila-bot/gorila-front-models';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';
import { ProfitabilityActions, ProfitabilitySelectors } from '@gorila-bot/profitability-store';
import { getDefaultFilterOptions, RangeDatepickerOption } from '@gorila-bot/range-datepicker';
import { AppConstants } from '@gorila/constants';
import { Event, ReduceEvent } from '@gorila/core/utils';
import { PositionDescriptionPipe } from '@gorila/pages/wallet/pipes';
import { NgrxAPIService } from '@gorila/root-store/ngrx-api.service';
import { PositionActions, PositionSelectors } from '@gorila/root-store/position';
import { selectPositions, selectPositionsLoading } from '@gorila/root-store/position/src/position.selectors';
import { selectTradeList } from '@gorila/root-store/trade/src/trade.selectors';
import { UserService } from '@gorila/shared/services/user.service';
import { getRouteData } from '@gorila/utils/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { equals, isEmpty, path, values } from 'ramda';
import { BehaviorSubject, combineLatest, Subscription, timer } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, map, skip, take } from 'rxjs/operators';

import { brokerFactory, sliderFactory, walletCategoryFactory } from './filters/b2b';

export type SelectedDate = {
  minDate: string;
  maxDate: string;
};

export type SelectedDateAlt = {
  startDate: string;
  endDate: string;
};

export enum ClientType {
  B2C = 'B2C',
  B2B = 'B2B',
  B2B2C = 'B2B2C'
}

export type ClientsAvaliable =
  | ClientType.B2C
  | ClientType.B2B
  | ClientType.B2B2C;

export const selectedPeriodKey = 'selectedPeriod';
export const selectedPeriodLabelKey = 'selectedPeriodLabel';
export const selectedPeriodIsTodayKey = 'selectedPeriodIsToday';
export const showTooltipDatepickerKey = 'showTooltipDatepicker';

@Injectable()
export class AppShellFiltersService implements OnDestroy {

  public clientType$: BehaviorSubject<ClientsAvaliable> = new BehaviorSubject(null);
  private filterServiceItems$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public filterServiceDefinitions$: BehaviorSubject<FilterDefinition> = new BehaviorSubject({});
  public filterOptions$: BehaviorSubject<RangeDatepickerOption[]> = new BehaviorSubject(null);

  private todayDate = moment.utc().format(this.dateFormat.American);
  private selectedRange: { startDate: string; endDate: string } = null;
  /**
   * The start date selected by the user.
   */
  private filterStartDate$: BehaviorSubject<string> = new BehaviorSubject(this.getPeriodSelected().startDate);
  /**
   * The end date selected by the user.
   */
  private filterEndDate$: BehaviorSubject<string> = new BehaviorSubject(this.getPeriodSelected().endDate || this.todayDate);
  private options$: BehaviorSubject<any> = new BehaviorSubject({ showDateAsDisabled: true });
  private firstTimeTracking = true;

  /**
   * The minimum date that the user can select.
   */
  private filterMinDate$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  /**
   * The maximum date that the user can select.
   */
  private filterMaxDate$: BehaviorSubject<string> = new BehaviorSubject(this.filterEndDate$.getValue());

  private emptyWallet = true;
  private savedDates: {
    minDate: string;
    maxDate: string;
    startDate: string;
    endDate: string;
    [selectedPeriodKey]: string;
  };

  constructor(
    private ngrxApi: NgrxAPIService,
    private store$: Store<any>,
    @Inject(DATE_FORMAT) private dateFormat: AvailableDates,
    protected descriptionPipe: PositionDescriptionPipe,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router
  ) {
    this.updateInfoBasedOnClientType();
    this.listenerFilters();
    this.setOptionsEnableTooltip();
    this.listenRouter();
    this.listenSocket();
  }

  private getB2BInitialDate = () => this.getPeriodSelected().startDate || moment.utc().subtract(1, 'M').format(this.dateFormat.American);

  public getFilterServiceItems = () => this.filterServiceItems$.asObservable();
  public getStartDate = () => this.filterStartDate$.asObservable();
  public getEndDate = () => this.filterEndDate$.asObservable();
  public getOptions = () => this.options$.asObservable();
  public getFilterOptions = () => this.filterOptions$.asObservable();
  public getMinDate = () => this.filterMinDate$.asObservable();
  public getMaxDate = () => this.filterMaxDate$.asObservable();
  public transformToMinMaxDate = (data: { startDate: string; endDate: string }) => ({ minDate: data.startDate, maxDate: data.endDate });

  private listenRouter() {
    this.router.events.pipe(
      untilDestroyed(this),
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
    ).subscribe((event) => {
      const { saveDatePicker } = getRouteData(this.router.routerState.root);

      if (!saveDatePicker) {
        return;
      }

      if (event instanceof NavigationStart) {
        const selectedPeriod = localStorage.getItem(selectedPeriodKey);

        this.savedDates = {
          endDate: this.filterEndDate$.value,
          startDate: this.filterStartDate$.value,
          minDate: this.filterMinDate$.value,
          maxDate: this.filterMaxDate$.value,
          [selectedPeriodKey]: `${this.filterStartDate$.value}_${this.filterEndDate$.value}`
        };

        return;
      }

      if (!this.savedDates) {
        return;
      }

      this.reset();
      this.store$.dispatch(FilterActions.update({ b2b: true, title: 'datePicker', filter: this.savedDates }));
      localStorage.setItem(selectedPeriodKey, this.savedDates[selectedPeriodKey]);
      this.setPeriodSelected(this.savedDates.startDate, this.savedDates.endDate);
      this.reset(true);
    }, console.error);
  }

  private listenSocket() {
    this.ngrxApi.getNotifications('POSITION_UPDATE').pipe(
      untilDestroyed(this),
      filter(() => this.selectedRange && moment(this.selectedRange.endDate).isBefore(moment(), 'd'))
    ).subscribe(() => {
      this.filterEndDate$.next(this.selectedRange.endDate = this.todayDate);
      this.store$.dispatch(FilterActions.update({
        b2b: this.clientType$.value === ClientType.B2B,
        title: 'datePicker',
        filter: this.selectedRange
      }));
    });
  }

  public setOptionsEnableTooltip() {
    const showTooltipDatepicker = localStorage.getItem(showTooltipDatepickerKey);
    const showedTooltipToday = moment(showTooltipDatepicker).isSame(this.todayDate, 'day');

    this.options$.next({
      ...this.options$.value,
      enableTooltip: !showedTooltipToday && this.clientType$.value !== ClientType.B2B
    });

    localStorage.setItem(showTooltipDatepickerKey, this.todayDate);
  }

  private updateInfoBasedOnClientType() {
    let positionSubscription: Subscription = null;
    let tradeSubscription: Subscription = null;
    const unsubFn = sub => {
      if (sub === null) {
        return;
      }
      sub.unsubscribe();
      sub = null;
    };
    this.userService
      .getUserType()
      .subscribe(clientType => {
        if (clientType !== ClientType.B2B) {
          // minDate, startdate, endDate controller >>>
          this.loaderProfitability();
          this.selectProfitabilityObservable();
          positionSubscription = this.listenPositionsChange();
          tradeSubscription = this.listenTradesChange();
          // minDate, startdate, endDate controller <<<
        } else {
          unsubFn(positionSubscription);
          unsubFn(tradeSubscription);
          this.updateDefaultPeriod(this.getB2BInitialDate());
        }
        this.clientType$.next(clientType);
        this.updateFilters();
      });
  }

  private listenerFilters() {
    this.store$.pipe(
      untilDestroyed(this),
      select(FilterSelectors.getAllFilters),
      distinctUntilChanged((a, b) => equals(a, b))
    ).subscribe(dt => {
      const selectedDate = dt.datePicker;
      if (selectedDate && this.filterStartDate$.getValue() != null && !equals(this.selectedRange, selectedDate)) {
        this.trackingFilterDate(selectedDate);
        this.setPeriodSelected(selectedDate.startDate, selectedDate.endDate);
        if (this.clientType$.value !== ClientType.B2B && (!this.selectedRange || this.selectedRange.endDate !== selectedDate.endDate)) {
          this.updateAllocation(selectedDate);
        }
        this.selectedRange = selectedDate;
        this.filterEndDate$.next(selectedDate.endDate);
      }
    });

    combineLatest([
      this.store$.pipe(
        select(PositionSelectors.selectPositions),
        skip(1),
        debounceTime(150),
      ),
      this.userService.getUserType()
    ])
      .pipe(filter(([_, clientType]) => clientType !== ClientType.B2B))
      .subscribe(data => {
        this.options$.next({
          ...this.options$.value,
          showDateAsDisabled: isEmpty(data)
        });

      });
  }

  private updateAllocation(range: SelectedDateAlt) {
    this.store$.dispatch(new PositionActions.PositionLoad({ minDate: range.endDate, maxDate: range.endDate }));
  }

  public reduceFilter(event: Event<any>) {
    return ReduceEvent(this, event);
  }

  private updateFilters = () => (this.clientType$.value === ClientType.B2B)
    ? this.b2bFilters()
    : this.b2cFilters()

  private b2bFilters = () => {
    this.removeWalletPeriodFromFilterOptions();
    this.setFilter({
      brokerName: brokerFactory(),
      products: walletCategoryFactory(this.descriptionPipe, this.translate),
      patrimony: sliderFactory('clientPatrimony', 'Wealth'),
      profitability: sliderFactory('profit', 'profitability', { pipe: 'Percent' })
    });
    this.options$.next({ showDateAsDisabled: false });
  }

  private b2cFilters = () => this.setFilter({});

  private setFilter = (value) => {
    if (equals(this.filterServiceDefinitions$.value, value)) {
      return;
    }
    this.filterServiceDefinitions$.next(value);
  }

  public updateB2bData = (b2bData) => {
    this.filterServiceItems$.next(b2bData);
  }

  public updateB2cData = (b2cData) => {
    this.filterServiceItems$.next(b2cData);
  }

  private setPeriodSelected(minDate: string, maxDate: string) {
    if (!this.filterOptions$.getValue() || !moment(minDate).isValid() || !moment(maxDate).isValid()) {
      return;
    }
    this.ngrxApi.setDates(minDate, maxDate);
    let selectedPeriodLabel = 'CUSTOM';
    this.filterOptions$.getValue().forEach((option) => {
      const { startDate, endDate } = option.range;
      if (
        moment(startDate, AppConstants.Format.Date.Brazilian).isSame(moment(minDate), 'day')
        && moment(endDate, AppConstants.Format.Date.Brazilian).isSame(moment(maxDate), 'day')
      ) {
        selectedPeriodLabel = option.label.split('.').pop();
      }
    });
    localStorage.setItem(selectedPeriodIsTodayKey, moment(maxDate).isSame(new Date(), 'day') ? '1' : '0');
    localStorage.setItem(selectedPeriodLabelKey, selectedPeriodLabel);
    localStorage.setItem(selectedPeriodKey, minDate + '_' + maxDate);
  }

  private getPeriodSelected() {
    let periodSelectedRange = { startDate: null, endDate: null };
    const selectedPeriodIsToday = localStorage.getItem(selectedPeriodIsTodayKey) === '1' ? true : false;
    const selectedPeriodLabel = localStorage.getItem(selectedPeriodLabelKey);
    const selectedPeriod = localStorage.getItem(selectedPeriodKey);
    const range = selectedPeriod ? selectedPeriod.split('_') : [null, null];

    if (selectedPeriodIsToday) {
      range[1] = moment.utc().format(this.dateFormat.American);
    }

    if (!selectedPeriodLabel || !this.filterOptions$.getValue()) {
      return periodSelectedRange;
    }
    if (selectedPeriodLabel && selectedPeriodLabel !== 'CUSTOM') {
      this.filterOptions$.getValue().forEach((option) => {
        const label = option.label.split('.').pop();
        if (label === selectedPeriodLabel) {
          const startDate = moment(option.range.startDate, AppConstants.Format.Date.Brazilian);
          const endDate = moment(option.range.endDate, AppConstants.Format.Date.Brazilian);
          periodSelectedRange = {
            startDate: startDate.isValid() ? startDate.format(this.dateFormat.American) : periodSelectedRange.startDate,
            endDate: endDate.isValid() ? endDate.format(this.dateFormat.American) : periodSelectedRange.endDate
          };
        }
      });
      return periodSelectedRange;
    }
    /**
     * remove later, fix equal selected dates
     */
    if (moment(range[0]).isSame(moment(range[1]), 'day')) {
      range[1] = moment.utc().format(this.dateFormat.American);
    }

    periodSelectedRange = { startDate: range[0], endDate: range[1] };
    return periodSelectedRange;
  }

  private trackingFilterDate(date: SelectedDateAlt) {
    if (this.firstTimeTracking) {
      this.firstTimeTracking = false;
      return;
    }

    const { startDate, endDate } = date;
    const trackDate = {
      clientType: this.clientType$.value,
      startDate,
      endDate,
      selectedDays: moment(endDate).diff(startDate, 'days') + 1,
      lastDayIsToday: moment(endDate).isSame(new Date(), 'day')
    };

    AnalyticsService.setEvent('Filter_Date_Change', {
      Click: 'Filter_Date',
      ...trackDate
    });
  }

  private updateDatePicker() {
    const oldestTradeDate = moment.utc(this.filterMinDate$.getValue()).format(AppConstants.Format.Date.American);
    const { startDate, endDate } = this.selectedRange;
    this.filterEndDate$.next(moment(oldestTradeDate).isSameOrAfter(endDate) ? this.todayDate : endDate);

    /**
     * fix without timer on filter-datepicker later
     */
    timer(500).subscribe(() => this.filterStartDate$.next(moment(startDate).isSameOrBefore(oldestTradeDate) ? oldestTradeDate : startDate));

    this.updateFilterMinDate(oldestTradeDate);
    this.setPeriodSelected(this.filterStartDate$.getValue(), this.filterEndDate$.getValue());
    this.updateOptions(oldestTradeDate);
  }

  /**
   * check if trade changes wallet period to change datepicker if necessary
   */
  private handleTradeListChange(tradeList: IOperation[]) {
    const oldestTrade = tradeList.reduce(
      (acc, cur) => moment(cur.transactDate).isBefore(acc.transactDate) ? cur : acc,
      { transactDate: undefined }
    );
    this.updateFilterMinDate(oldestTrade.transactDate);
    let selectedRange;
    // handle empty wallet (selectedRange is undefined)
    if (!this.selectedRange) {
      selectedRange = {
        startDate: oldestTrade.transactDate,
        endDate: moment().format('YYYY-MM-DD')
      };
      this.store$.dispatch(FilterActions.update({ b2b: false, title: 'datePicker', filter: selectedRange }));
      // handle change in wallet period
    } else {
      return;
    }

    this.updateFilterMinDate(oldestTrade.transactDate);
    this.selectedRange = selectedRange;
  }

  private listenPositionsChange() {
    let positionsCount = 0;
    return this.store$.pipe(
      untilDestroyed(this),
      select(selectPositionsLoading),
      filter(loading => !loading),
      concatMap(() => this.store$.pipe(
        select(selectPositions),
        take(1)
      )),
      map(values),
      filter((positions: IPositionList) => (positions || []).length !== positionsCount)
    )
    .subscribe((positions: IPositionList) => {
      const emptyPositions = (positionsCount = positions.length) === 0;
      if (this.emptyWallet === true && !emptyPositions) {
        this.loaderProfitability();
      } else if (!this.emptyWallet && emptyPositions) {
        this.reset();
      }
      this.emptyWallet = emptyPositions;
    });
  }

  private listenTradesChange() {
    return this.store$.pipe(
      select(selectTradeList),
      filter((trades: { [key: string]: IOperation }) => !!trades && !isEmpty(trades)),
      distinctUntilChanged((a, b) => equals(a, b)),
      untilDestroyed(this)
    ).subscribe((trades: { [key: string]: IOperation }) => {
      this.handleTradeListChange(values(trades));
      this.updateDatePicker();
    });
  }

  private loaderProfitability() {
    const payload = {
      'all-DAILY-minDate': {
        assetClass: '',
        id: 'all-DAILY-minDate',
        maxDate: moment.utc(this.filterEndDate$.getValue()).format(AppConstants.Format.Date.American),
        minDate: moment.utc(AppConstants.Blotter.minBookDate).format(AppConstants.Format.Date.American),
        periodType: 'DAILY',
        reduceProfitMaxPoints: 1
      }
    };
    this.store$.dispatch(new ProfitabilityActions.LoadProfitability(payload));
  }

  private reset(restore?: boolean) {
    const savedDates = restore && this.savedDates ? this.savedDates : {
      startDate: null,
      endDate: null,
      minDate: null,
      maxDate: null
    };
    this.filterStartDate$.next(savedDates.startDate);
    this.filterEndDate$.next(savedDates.endDate);
    this.filterMinDate$.next(savedDates.minDate);
    this.filterMaxDate$.next(savedDates.maxDate);
    this.options$.next({ showDateAsDisabled: true });
  }

  private selectProfitabilityObservable = () => this.store$.pipe(
    select(ProfitabilitySelectors.selectProfitabilityFeature),
    map(data => path(['entities', 'all-DAILY-minDate', 'data', '0', '_id', 'minDate'], data)),
    filter(data => !!data),
    take(1),
    distinctUntilChanged((a, b) => equals(a, b)),
    untilDestroyed(this),
  ).subscribe((data: string) => {
    this.filterMinDate$.next(moment.utc(data).format(this.dateFormat.American));
    this.updateFilterMinDate(data);
    const minDate = this.filterMinDate$.getValue();
    const maxDate = this.filterMaxDate$.getValue();
    this.updateOptions(minDate);
    const periodSelected = this.getPeriodSelected();
    this.updateDefaultPeriod(
      periodSelected.startDate || minDate,
      periodSelected.endDate || maxDate
    );
  })

  private updateDefaultPeriod(minDate, maxDate = this.filterEndDate$.getValue()) {
    if (this.filterStartDate$.getValue()) {
      return;
    }
    this.filterStartDate$.next(moment.utc(minDate).format(this.dateFormat.American));
    this.filterEndDate$.next(moment.utc(maxDate).format(this.dateFormat.American));
  }

  private updateFilterMinDate(candidateDate: string) {
    const candidateMoment = moment.utc(candidateDate);
    if (candidateMoment.isSameOrAfter(this.filterMinDate$.getValue())) {
      return;
    }

    this.filterMinDate$.next(candidateMoment.format('YYYY-MM-DD'));
  }

  private updateOptions(oldestWalletDate) {
    const filterOptions = getDefaultFilterOptions(AppConstants.Format.Date.Brazilian);

    const startWallet = new Date(oldestWalletDate);
    const startLastYear = moment().subtract(1, 'year').startOf('year').format(AppConstants.Format.Date.Brazilian);

    if (moment(startWallet).isSameOrBefore(startLastYear)) {
      filterOptions.push(
        {
          label: 'GOR_RANGE_DATEPICKER.DEFAULT_OPTIONS.LAST_12_MONTHS',
          range: {
            startDate: moment().subtract(1, 'year').startOf('year').format(AppConstants.Format.Date.Brazilian),
            endDate: moment().subtract(1, 'year').endOf('year').format(AppConstants.Format.Date.Brazilian),
          }
        },
      );
    }

    filterOptions.push(
      {
        label: 'GOR_RANGE_DATEPICKER.DEFAULT_OPTIONS.WALLET_PERIOD',
        range: {
          startDate: moment(oldestWalletDate).format(AppConstants.Format.Date.Brazilian),
          endDate: moment().format(AppConstants.Format.Date.Brazilian),
        }
      }
    );

    this.filterOptions$.next(filterOptions);
  }

  private removeWalletPeriodFromFilterOptions(): void {
    const filterOptions = getDefaultFilterOptions(AppConstants.Format.Date.Brazilian)
            .filter( (periodOption) => periodOption.label !== 'GOR_RANGE_DATEPICKER.DEFAULT_OPTIONS.WALLET_PERIOD');
    this.filterOptions$.next(filterOptions);
    this.filterMinDate$.next(moment.utc('2010-01-04').format(this.dateFormat.American));
  }

  public ngOnDestroy() { }
}
