import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataFormatterModule } from '@gorilainvest/ui-toolkit/data-formatter';
import { GroCardFieldTitleModule } from '@gorila/ui/gro-card-field-title';

import { GroCardFieldComponent } from './gro-card-field/gro-card-field.component';

@NgModule({
  declarations: [GroCardFieldComponent],
  exports: [GroCardFieldComponent],
  imports: [
    CommonModule,
    DataFormatterModule,
    GroCardFieldTitleModule
  ]
})
export class GroCardFieldModule { }
