import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@gorilainvest/ui-toolkit/directives';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownButtonComponent } from './dropdown-button/index';
import { HeaderDropdownComponent } from './header-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    IconModule,
    MatTooltipModule,
    TranslateModule
  ],
  declarations: [
    DropdownButtonComponent,
    HeaderDropdownComponent
  ],
  exports: [
    DropdownButtonComponent,
    HeaderDropdownComponent
  ]
})
export class HeaderDropdownModule { }
