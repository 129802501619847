import { Pipe, PipeTransform } from '@angular/core';
import { IOperation } from '@gorila-bot/gorila-front-models';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'tradeDescription',
  pure: true
})
export class TradeDescriptionPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  public transform(trade: IOperation): string {
    if (!trade || !trade.security) { return ''; }
    // securities for cash does not have display name so it requires a fallback to symbol to not break filter
    let securityDisplayName = trade.security.displayName || trade.security.symbol;
    try {
      switch (trade.security.productType) {
        case 'SAVINGS':
          const savings = this.translate.instant('SAVINGS');
          return `${savings} ${trade.brokerName}`;
        case 'TREASURY':
          const productSubType = this.translate.instant(trade.security.productType);
          const maturityDate = moment.utc(securityDisplayName.substring(securityDisplayName.length - 8), 'YYYYMMDD').format('DD/MM/YYYY');
          return `${productSubType} ${maturityDate}`;
        case 'CRYPTOCURRENCY':
          return this.translate.instant(securityDisplayName);
      }
      if (trade.security.productType === 'CORPORATE_BONDS_DEBENTURE') {
        return securityDisplayName;
      }
    } catch (e) {
      console.warn(`Error while retrieving description from trade: ${e}`);
    }
    if (securityDisplayName.length > 40) {
      securityDisplayName = securityDisplayName.substring(0, 41) + '...';
    }

    return securityDisplayName.replace(/_/g, ' ');
  }
}
