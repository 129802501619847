import { Injectable } from '@angular/core';
import { combineEpics, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseEpic } from '../../base/epic.base';
import { AppState, STATE_TYPES } from '../../store.model';
import { Auth0UserService } from './auth0-user.service';
import { UserAPIAction, UserAPIActions } from './user.actions';

const userStorageName = '__user_data';

const updateStorageUserData = (store) => {
  const user = store.value['user']['data'];
  try {
    localStorage.setItem(userStorageName, JSON.stringify(user));
  } catch (e) { console.warn(e); }
  return user;
};

@Injectable()
export class UserAPIEpics extends BaseEpic {
  constructor(
    private action: UserAPIActions,
    private service: Auth0UserService
  ) {
    super();
  }

  public getStateType(): string {
    return STATE_TYPES.USER;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return combineEpics(
      this.createSaveUserEpic(filterIfNotLogged),
      this.createSetUserDataEpic(filterIfNotLogged),
      this.createSavedDataEpic(filterIfNotLogged),
      this.createLoadUserDataEpic(filterIfNotLogged)
    );
  }

  private createSaveUserEpic(ifNotLogged): Epic<UserAPIAction, UserAPIAction, AppState> {
    const actionType = UserAPIActions.actionType.saveUserData;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const user = store.value['user'];
      const user_id = user['data']['user_id'];
      return this.service.saveData(user_id, action.payload).pipe(
        map(data => this.action.savedData(data, action))
      );
    }, true, (action, store) => !!(store.value['user'] && store.value['user']['data']));
  }

  private createSetUserDataEpic(ifNotLogged): Epic<UserAPIAction, UserAPIAction, AppState> {
    const actionType = UserAPIActions.actionType.setUserData;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const user = updateStorageUserData(store);
      localStorage.setItem('id_token', user['token']);
      return [];
    });
  }

  private createSavedDataEpic(ifNotLogged): Epic<UserAPIAction, UserAPIAction, AppState> {
    const actionType = UserAPIActions.actionType.savedData;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      updateStorageUserData(store);
      return [];
    });
  }

  private createLoadUserDataEpic(ifNotLogged): Epic<UserAPIAction, UserAPIAction, AppState> {
    const actionType = UserAPIActions.actionType.loadUserData;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const item = localStorage.getItem(userStorageName);
      const parsed = JSON.parse(item);
      if (!item || !parsed) {
        return from([this.action.loadedUserDataError()]);
      }
      return from([this.action.loadedUserData(parsed)]);
    });
  }

}
