import { BaseState, MakeState, ReducerBase } from '../../base/reducer.base';
import { STATE_TYPES } from '../../store.model';
import { UserAPIAction, UserAPIActions } from './user.actions';

const removeCircularStructure = payload => {
  if (payload['user_metadata'] && payload['user_metadata']['data']) {
    if (payload['user_metadata']['data']['user_metadata']) {
      delete payload['user_metadata']['data']['user_metadata'];
    }
  }
  return payload;
};

export const getUserData = (state: BaseState, payload) => {
  payload = removeCircularStructure(payload);
  /*
   * For more info, check https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims#custom-claims
   * If you have access, check Auth0 rules.
   */
  const namespace = 'https://gorila.com.br/';
  const appMetadata =
    payload['app_metadata'] || payload[`${namespace}app_metadata`];
  const userMetadata =
    payload['user_metadata'] || payload[`${namespace}user_metadata`];
  return {
    name:
      userMetadata && userMetadata['name']
        ? userMetadata['name']
        : payload['name'],
    token: payload['token'],
    picture: payload['picture'],
    created_at: payload['created_at'],
    email: payload['email'],
    email_verified: payload['email_verified']
      ? payload['email_verified']
      : null,
    first_time: payload['first_time'] ? payload['first_time'] : null,
    user_id: payload['user_id'] ? payload['user_id'] : null,
    app_metadata: appMetadata ? appMetadata : null,
    data: userMetadata || payload['data']
  };
};

export const logout = (state: BaseState, a: UserAPIAction, extra) => {
  return {};
};

export const loadUserData = (state: BaseState, a: UserAPIAction, extra) => {
  return MakeState({ loading: true });
};

export const loadedUserData = (state: BaseState, a: UserAPIAction, extra) => {
  const user = getUserData(state, a.payload);
  return MakeState({ data: user, loading: false });
};

export const loadedUserDataError = (
  state: BaseState,
  a: UserAPIAction,
  extra
) => {
  return MakeState({ error: true });
};

export const saveUserData = (state: BaseState, a: UserAPIAction, extra) => {
  return state;
};

export const setUserData = (state: BaseState, a: UserAPIAction, extra) => {
  const user = getUserData(state, a.payload);
  return MakeState({ data: user });
};

export const savedData = (state: BaseState, a: UserAPIAction, extra) => {
  a.payload = removeCircularStructure(a.payload);
  for (const i in a.payload) {
    if (!a.payload[i]) {
      continue;
    }
    state['data'][i] = a.payload[i];
  }
  if (a.payload['user_metadata']) {
    for (const i in a.payload['user_metadata']) {
      if (!a.payload['user_metadata'][i]) {
        continue;
      }
      state['data'][i] = a.payload['user_metadata'][i];
    }
  }
  return MakeState({ data: state['data'] });
};

export const userReducer = (state: BaseState = {}, a: UserAPIAction) => {
  return ReducerBase(
    {
      [UserAPIActions.actionType.logout]: logout,
      [UserAPIActions.actionType.setUserData]: setUserData,
      [UserAPIActions.actionType.savedData]: savedData,
      [UserAPIActions.actionType.saveUserData]: saveUserData,
      [UserAPIActions.actionType.loadUserData]: loadUserData,
      [UserAPIActions.actionType.loadedUserData]: loadedUserData,
      [UserAPIActions.actionType.loadedUserDataError]: loadedUserDataError
    },
    state,
    a,
    STATE_TYPES.USER
  );
};
