import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent {
  @Input() public label: string;
  @Input() public labelFor: string;
  @Input() public labelClass: string;
  @Input() public form: FormGroup;
  @Input() public inputControl: AbstractControl;
  @Input() public hide = false;
  @Input() public translateParams: {};
}
