import { Inject, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GorilaPaginatorIntl extends MatPaginatorIntl {

  public itemsPerPageLabel: string;
  public nextPageLabel: string;
  public previousPageLabel: string;
  public lastPageLabel: string;
  public firstPageLabel: string;
  public zeroOfLabel: string;
  public ofLabel: string;
  private toTranslate = [
    'Items per page',
    'Next page',
    'Previous page',
    'Last page',
    'First page',
    '0 of ',
    ' of '
  ];
  private translate: TranslateService;

  constructor(
    @Inject(TranslateService) translate
  ) {
    super();
    this.translate = translate;
    this.translate.get(this.toTranslate).subscribe(
      translations => {
        this.itemsPerPageLabel = translations['Items per page'];
        this.nextPageLabel = translations['Next page'];
        this.previousPageLabel = translations['Previous page'];
        this.lastPageLabel = translations['Last page'];
        this.firstPageLabel = translations['First page'];
        this.zeroOfLabel = translations['0 of '];
        this.ofLabel = translations[' of '];
      }
    );
  }

  public getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return this.zeroOfLabel + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + this.ofLabel + length;
  };

}
