import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-progress-bar',
  templateUrl: './ui-progress-bar.component.html',
  styleUrls: ['./ui-progress-bar.component.scss']
})
export class UiProgressBarComponent {
  @Input() public animate = false;
  constructor() { }
}
