import { UserPortfolioActions, UserPortfolioActionTypes } from '../actions/portfolio.actions';
import { initialState, State } from '../portfolio.state';

export function reducer(state = initialState, action: UserPortfolioActions): State {
  switch (action.type) {
    case UserPortfolioActionTypes.LoadUserPortfolioDatesActions:
      return !action.payload ? initialState : {
        ...initialState,
        loading: true
      };

    case UserPortfolioActionTypes.LoadedUserPortfolioDatesActions: {
      return {
        dates: action.payload || initialState.dates,
        loading: false
      };
    }

    default:
      return state;
  }
}
