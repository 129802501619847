import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';

import { STATE_TYPES } from '../../../store.model';
import {
  addMultipleProfitability,
  addPnl,
  addProfitability,
  loadedMultiProfitability,
  loadedPnl,
  loadedProfitability,
  loadError,
  profitabilityActionsData,
  ProfitabilityActionsDataType,
  ProfitabilityBaseActions,
  setCurrent,
  updateGroup,
  updateProfitability
} from './profitability-base.actions';

const stateType = STATE_TYPES['PROFITABILITY_MONTHLY'];

@Injectable()
export class ProfitabilityMonthlyActions extends ProfitabilityBaseActions {

  public static actions: ProfitabilityActionsDataType = profitabilityActionsData(stateType);
  protected readonly type = stateType;

  @dispatch()
  public addMultipleProfitability = addMultipleProfitability(stateType, this, ProfitabilityMonthlyActions.actions);

  @dispatch()
  public addProfitability = addProfitability(stateType, this, ProfitabilityMonthlyActions.actions);

  @dispatch()
  public updateProfitability = updateProfitability(stateType, ProfitabilityMonthlyActions.actions);

  @dispatch()
  public setCurrent = setCurrent(stateType, ProfitabilityMonthlyActions.actions);

  public loadedMultiProfitability = loadedMultiProfitability(ProfitabilityMonthlyActions.actions);
  public loadedProfitability = loadedProfitability(ProfitabilityMonthlyActions.actions);

  public loadError = loadError(ProfitabilityMonthlyActions.actions);

  @dispatch()
  public addPnl = addPnl(stateType, ProfitabilityMonthlyActions.actions);

  @dispatch()
  public updateGroup = updateGroup(stateType, ProfitabilityMonthlyActions.actions);

  public loadedPnl = loadedPnl(ProfitabilityMonthlyActions.actions);

}
