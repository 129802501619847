import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Params, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { Fund, FundIdType, UserDataSelectors, UserDataState } from '@gorila-bot/user-data-store';
import { AuthService } from '@gorila/pages/auth';
import { SocketEventService } from '@gorila-bot/gorila-base';
import { isValidFundId } from '@gorila-bot/gorila-front-utils';
import { Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthNoLockRoutePath } from '../auth/metadata';

@Injectable()
export class AuthGuard implements CanActivateChild, CanLoad {
  private lastRoute = null;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected socketEventService: SocketEventService,
    protected store: Store<UserDataState.State>
  ) {}

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin();
  }

  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url, next.queryParams);
  }

  protected authenticated(): Observable<boolean> {
    const ws = this.socketEventService.wsActivity.getValue() || environment.unstableSocket;
    const isLogged$ = this.store.select(UserDataSelectors.selectFeatureCurrentFundData).pipe(
      map((data: Fund): FundIdType => data.fundId),
      map(
        (fundId: FundIdType): boolean =>
          ws && this.authService.authenticated() && isValidFundId(fundId)
      )
    );
    return iif(() => environment.enableMockedData, of(true), isLogged$);
  }

  private checkLogin(url?: string, queryParams?: Params): Observable<boolean> {
    this.saveRouteWhenMockData(url);
    return this.authenticated().pipe(
      tap(isLogged => {
        if (isLogged) {
          return;
        }
        localStorage.setItem('lastroute', url);
        const splittedUrl = url.split('/app/');
        const commands = splittedUrl.length === 2 ? [splittedUrl[0], AuthNoLockRoutePath] : [AuthNoLockRoutePath];
        this.router.navigate(commands, { queryParams });
      })
    );
  }

  private saveRouteWhenMockData(url) {
    if (!environment.enableMockedData) {
      return;
    }
    if (!this.lastRoute) {
      this.lastRoute = url;
      localStorage.setItem('lastroute', url);
    }
  }
}
