import { ITrade } from '@gorila-bot/gorila-front-models';
import {
  assoc,
  dissoc,
  findIndex,
  indexBy,
  is,
  merge,
  or,
  prop,
  propEq,
  remove as removeFn
} from 'ramda';

import {
  TradeAPIAction,
  TradeAPIActions,
  TradeRemoveAPIAction
} from './trade.actions';
import { INITIAL_STATE} from './trade.model';

import {ReducerBase} from '../../base/reducer.base';
import {FLAGS, STATE_TYPES, StateItemList} from '../../store.model';

const failedReducer = (flag: string) =>
  (state: StateItemList<ITrade> = INITIAL_STATE, action: TradeAPIAction) =>
  ({ ...state, [flag]: false, error: action.error });

const startedReducer = (flag: string) =>
  (state: StateItemList<ITrade> = INITIAL_STATE, action: TradeAPIAction) =>
  ({ ...state, [flag]: true });

function clearDataReducer(state: StateItemList<ITrade> = INITIAL_STATE, action: TradeAPIAction) {
  return {
    ...state,
    items: {}
  };
}

function addSuccessReducer(state: StateItemList<ITrade> = INITIAL_STATE, action: TradeAPIAction) {
  const addItem = (trades, newTrade) => assoc(newTrade['id'], newTrade, trades);
  const items = or(state.items, {});
  const payload = action.payload;

  return {
    ...state,
    items: is(Array, payload) ? (payload as ITrade[]).reduce(addItem, items) : addItem(items, payload),
    [FLAGS.ADDING]: false
  };
}

function loadSuccessReducer(state: StateItemList<ITrade> = INITIAL_STATE, action: TradeAPIAction) {
  return {
    ...state,
    items: merge(state.items, indexBy(prop('id'), action.payload as ITrade[])),
    [FLAGS.LOADING]: false
  };
}

function removeSuccessReducer(state: StateItemList<ITrade> = INITIAL_STATE, action: TradeRemoveAPIAction) {
  const dissocOrRemove = ([trades, id, index]) => index === -1 && dissoc(id, trades) || removeFn(index, 1, trades);
  const byIndex = (tradeList, id) => [tradeList, id, findIndex(propEq('id', id))(tradeList)];
  const stateItems = or(state.items, {});
  const payload = action.payload;
  const removeItem = (items, id) => dissocOrRemove(byIndex(items, id) as [any, any, any]);

  return {
    items: is(Array, payload) ? (payload as string[]).reduce(removeItem, stateItems) : removeItem(stateItems, payload),
    [FLAGS.REMOVING]: false
  };
}

export function tradeReducer(state: StateItemList<ITrade> = INITIAL_STATE, a: TradeAPIAction) {
  const add = TradeAPIActions.actionType.add;
  const load = TradeAPIActions.actionType.load;
  const remove = TradeAPIActions.actionType.remove;
  const clearAction = TradeAPIActions.actionType.clear;

  return ReducerBase({
    /***** CLEAR *****/
    [clearAction]: clearDataReducer,

    /***** ADD *****/
    [add.fail]: failedReducer(FLAGS.ADDING),
    [add.start]: startedReducer(FLAGS.ADDING),
    [add.success]: addSuccessReducer,

    /***** LOAD *****/
    [load.fail]: failedReducer(FLAGS.LOADING),
    [load.start]: startedReducer(FLAGS.LOADING),
    [load.success]: loadSuccessReducer,

    /***** REMOVE *****/
    [remove.fail]: failedReducer(FLAGS.REMOVING),
    [remove.start]: startedReducer(FLAGS.REMOVING),
    [remove.success]: removeSuccessReducer
  }, state, a, STATE_TYPES.TRADE);
}
