import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LogoModule } from '@gorila-bot/logo';
import { PremiumModule } from '@gorila-bot/premium';
import { SharedModule } from '@gorila/shared/module';
import { UiIconModule } from '@gorila/ui/ui-icon';
import { AlertModule } from '@gorila/widgets/alert/alert.module';
import { ButtonModule } from '@gorilainvest/ui-toolkit/button';
import { DirectivesModule } from '@gorilainvest/ui-toolkit/directives';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponentsModule } from './components';
import { ChatService } from './header-menu/chat/chat.service';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    CommonModule,
    AlertModule,
    LogoModule,
    MatTooltipModule,
    RouterModule,
    SharedModule,
    TranslateModule.forChild(),
    UiIconModule,
    DirectivesModule,
    HeaderComponentsModule,
    PremiumModule.forChild(),
    ButtonModule,
    IconModule
  ],
  declarations: [
    HeaderComponent,
    HeaderMenuComponent
  ],
  exports: [HeaderComponent],
  providers: [ChatService]
})
export class HeaderModule { }
