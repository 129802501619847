import { sanitizeBackDate } from '@gorila-bot/gorila-front-utils';

import { FLAGS, StateItemList } from '../../store.model';
import { AssetClass, fromServer as assetFromServer } from '../asset-class.model';
import { fromServer as pnlFromServer, PnlData, PnlDataProperties } from '../pnl.model';
import { Security } from '../security.model';

/**
 * Interface for objects that represents a position.
 *
 * @interface
 */
export interface Position {
  /**
   * Unique identifier of a position.
   *
   * @type {string}
   */
  id: string;

  /**
   * Describes the position itself.
   *
   * @type {string}
   */
  description: string;

  /**
   * The broker that have custody the position;
   *
   * type {string}
   */
  brokerName: string;

  /**
   * The issuer that sponsor the position;
   *
   * type {string}
   */
  issuerName: string;

  /**
   * The maturity date of the position.
   *
   * @type {string}
   */
  maturity: string;

  /**
   * The date of the last price. (?)
   *
   * @type {string}
   */
  priceDate: string;

  /**
   * How many unit have in the position. (?)
   *
   * @type {number}
   */
  quantity: number;

  /**
   * The value of the position.
   *
   * @type {number}
   */
  value: number;

  /**
   * Describes the pnl information (nominal and percentual) total for the
   * prodyuct and for given periods:
   * - daily
   * - monthly
   * - yearly
   *
   * @type {PnlData}
   * @see {PnlData}
   */
  pnl: PnlData;

  /**
   * A list of trades related to the positon, identified by an unique identifier.
   *
   * @type {Array<string>}
   * @see {ITrade}
   */
  trades: Array<string>;

  /**
   * Indicates the current price for the position relative to today.
   *
   * @type {number}
   * @optional
   */
  currentPrice?: number;

  /**
   * The fund id that owns the position.
   *
   * @type {string}
   */
  fundId: string;

  /**
   * The security information of the position.
   *
   * @type {Security}
   * @see {Security}
   */
  security: Security;

  /**
   * The asset class of the position.
   *
   * @type {AssetClass}
   * @see {AssetClass}
   */
  assetClass: AssetClass;
}
export type Positions = Array<Position>;

export const composePositionId = position => `${position.SecurityName}-${position.FundId}-${position.BrokerName}`;
export const composePositionIdFromTrade = trade => `${trade.security}-${trade.fundId}-${trade.broker.name}`;
export const positionOwnProfit = (position, profit) =>
  profit['_id'] && position.id === `${profit['_id']['security']}-${position.fundId}-${profit['_id']['broker']}`;
export const setPositionPnlFromServer = (position: Position, profits = []): Position => {
  const pnl: PnlData = { ...position.pnl };
  PnlDataProperties.forEach(pnlDataProperty => {
    let profit = { nominal: 0, percentual: 0 };

    if (!profits[pnlDataProperty]) {
      pnl[pnlDataProperty] = profit;
      return;
    }

    for (let i = profits[pnlDataProperty].length - 1; i >= 0; i--) {
      if (positionOwnProfit(position, profits[pnlDataProperty][i])) {
        profit = profits[pnlDataProperty][i];
        break;
      }
    }
    pnl[pnlDataProperty] = pnlFromServer(profit);
  });
  return { ...position, pnl };
};

export const fromServer = (position): Position => ({
  id: composePositionId(position),
  description: `${position.SecurityDescription}`,
  brokerName: position.BrokerName || '-',
  issuerName: position.IssuerName || '-',
  maturity: sanitizeBackDate(position.MaturityDate) as string,
  priceDate: sanitizeBackDate(position.RefCalcDate) as string,
  quantity: position.Quantity || 0,
  value: position.MtmNotional || 0,
  pnl: {
    daily: { nominal: 0, percentual: 0 },
    monthly: { nominal: 0, percentual: 0 },
    yearly: { nominal: 0, percentual: 0 },
    total: { nominal: 0, percentual: 0 }
  },
  trades: [],
  currentPrice: position.Price || position.PriceClose,
  fundId: position.FundId,
  security: {
    name: position.SecurityName,
    type: position.SecurityType
  },
  assetClass: assetFromServer(position.AssetClass)
});

export const INITIAL_STATE: StateItemList<Position> = {
  items: {},
  [FLAGS.ADDING]: false,
  [FLAGS.LOADING]: false,
  [FLAGS.REMOVING]: false,
  [FLAGS.UPDATING]: false,
  error: null
};
