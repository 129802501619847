import { Injectable } from '@angular/core';
import { AppShellRoutePath } from '@gorila/core/router';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { BenchmarkAPIEpics } from './api/benchmark/benchmark.epics';
import { BlotterAPIEpics } from './api/blotter/blotter.epics';
import { PositionAPIEpics } from './api/position/position.epics';
import { ProductAPIEpics } from './api/product/product.epics';
import { ProfitabilityDailyEpics } from './api/profitability/epics/profitability-daily.epic';
import { ProfitabilityMonthlyEpics } from './api/profitability/epics/profitability-monthly.epic';
import { ProfitabilityPNLEpics } from './api/profitability/epics/profitability-pnl.epic';
import { ProfitabilityYearlyEpics } from './api/profitability/epics/profitability-yearly.epic';
import { SubProductAPIEpics } from './api/subproduct/subproduct.epics';
import { TradeAPIEpics } from './api/trade/trade.epics';
import { UserAPIEpics } from './api/user/user.epics';

@Injectable()
export class RootEpics {
  private epicMiddleware;

  constructor(
    private benchmarkEpics: BenchmarkAPIEpics,
    private blotterEpics: BlotterAPIEpics,
    private positionEpics: PositionAPIEpics,
    private productEpics: ProductAPIEpics,
    private profitabilityPNLEpics: ProfitabilityPNLEpics,
    private profitabilityDailyEpics: ProfitabilityDailyEpics,
    private profitabilityMonthlyEpics: ProfitabilityMonthlyEpics,
    private profitabilityYearlyEpics: ProfitabilityYearlyEpics,
    private subproductEpics: SubProductAPIEpics,
    private tradeEpics: TradeAPIEpics,
    private userEpics: UserAPIEpics
  ) {}

  public createEpics() {
    return this.epicMiddleware = createEpicMiddleware();
  }

  public run() {
    const filterIfNotLogged = state => state.router.indexOf(AppShellRoutePath) !== -1;
    this.epicMiddleware.run(combineEpics(
      this.benchmarkEpics.createEpic(filterIfNotLogged),
      this.blotterEpics.createEpic(filterIfNotLogged),
      this.positionEpics.createEpic(filterIfNotLogged),
      this.productEpics.createEpic(filterIfNotLogged),
      this.profitabilityPNLEpics.createEpic(filterIfNotLogged),
      this.profitabilityDailyEpics.createEpic(filterIfNotLogged),
      this.profitabilityMonthlyEpics.createEpic(filterIfNotLogged),
      this.profitabilityYearlyEpics.createEpic(filterIfNotLogged),
      this.subproductEpics.createEpic(filterIfNotLogged),
      this.tradeEpics.createEpic(filterIfNotLogged),
      this.userEpics.createEpic(filterIfNotLogged)
    ));
  }
}
