import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

@Component({
  selector: 'app-completer-result',
  templateUrl: './completer-result.component.html',
  styleUrls: ['./completer-result.component.scss']
})
export class CompleterResultComponent implements OnChanges {
  @Input() public value: string | Object = null;
  @Input() public editable = true;
  @Output() public reset: EventEmitter<any> = new EventEmitter();

  public click(): void {
    this.reset.emit();
  }

  public ngOnChanges(inputs: any) {
    try {
      if (!inputs['value']) { return; }
      if (this.value != null && typeof this.value !== 'string' && typeof this.value['title'] !== 'undefined') {
        this.value = this.value['title'];
      }
    } catch (e) { console.error(e); }
  }
}
