import { IPosition, IPositionIndexed, IPositionList } from '@gorila-bot/gorila-front-models';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { initialState, Key, PositionState } from './position.state';
import { path, pick } from 'ramda';

type IState = { [Key]: PositionState };

export const selectFeature = (state: IState) => state[Key];
export const selectPositions = createSelector<IState, PositionState, IPositionIndexed>(
  selectFeature,
  (state: PositionState) => path(['positions'], state) || initialState.positions
);
export const selectPositionsByIdList = idList => createSelector<IState, PositionState, IPositionIndexed, IPositionIndexed>(
  selectFeature,
  (state) => path(['positions'], state) || initialState.positions,
  (positions) => !idList || !positions ? null : pick(idList, positions.positions)
);

export const selectOnePosition = (positionId) => createSelector(
  selectFeature,
  (state: PositionState) => ({...path<IPosition>(['positions', positionId], state)})
);
export const selectPositionsStatus = createSelector(
  selectFeature,
  (state: PositionState) => state && { error: state.error || initialState.error, loading: state.loading || initialState.loading }
);
export const selectPositionsLoading = createSelector(
  selectFeature,
  (state: PositionState) => state && state.loading || initialState.loading
);
export const selectPositionsCount = createSelector(
  selectPositions,
  (state: Dictionary<IPosition>): number => Object.keys(state || {}).length
);
