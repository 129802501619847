import { Component, HostBinding, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { PartnerModalData } from './partner-modal.model';
@Component({
  selector: 'gor-partner-modal',
  templateUrl: './partner-modal.component.html',
  styleUrls: ['./partner-modal.component.scss'],
})
export class PartnerModalComponent {
  @HostBinding('class.alert') public alert = false;
  @HostBinding('class.error') public error = false;
  @HostBinding('class.info') public info = false;
  @HostBinding('class.success') public success = false;
  @HostBinding('class.no-padding') public noPadding = false;
  @HostBinding('class.invert-provider-color') public invertProviderColor = false;

  public isMobile$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PartnerModalData,
    private dialogRef: MatDialogRef<PartnerModalComponent>,
    private sanitizer: DomSanitizer
  ) {
    this.invertProviderColor = data.invertProviderColor;
    this.noPadding = data.noPadding;
    this.alert = data.type === 'alert';
    this.error = data.type === 'error';
    this.info = data.type === 'info';
    this.success = data.type === 'success';
    if (data.htmlContent) {
      data.htmlContent = this.sanitizer.bypassSecurityTrustHtml(String(data.htmlContent));
    }
  }

  public close(id?: string) {
    if (id === 'open-tab') {
      window.open(this.data.partnerHeader.helpLink);
      return;
    }
    this.dialogRef.close(id);
  }
}
