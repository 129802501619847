import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IBenchmark, IBenchmarkType } from '@gorila-bot/gorila-front-models';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES } from '../../store.model';
import { Separator } from '../../store.utils';
import { APIActions, MetaData } from '../actions';
import { ActionTransition, ActionType } from '../actions.model';

export interface BenchmarkMetaData extends MetaData { benchmarkType: any; }

export type BenchmarkAPIAction = FluxStandardAction<IBenchmark[], BenchmarkMetaData>;

@Injectable()
export class BenchmarkAPIActions extends APIActions<IBenchmark> {
  public static readonly PNL = 'PNL';
  public static actions = {
    addBenchmark: BenchmarkAPIActions.getTypeFor(ActionType.ADD, [STATE_TYPES.BENCHMARK], ActionTransition.DATA),
    loadedBenchmark: BenchmarkAPIActions.getTypeFor(ActionType.LOAD, [STATE_TYPES.BENCHMARK], ActionTransition.SUCCEEDED),
    loadError: BenchmarkAPIActions.getTypeFor(ActionType.LOAD, [STATE_TYPES.BENCHMARK], ActionTransition.FAILED),
    addPnl: BenchmarkAPIActions.getTypeFor(
      ActionType.ADD, [STATE_TYPES.BENCHMARK, BenchmarkAPIActions.PNL], ActionTransition.DATA
    ),
    loadedPnl: BenchmarkAPIActions.getTypeFor(
      ActionType.LOAD, [STATE_TYPES.BENCHMARK, BenchmarkAPIActions.PNL], ActionTransition.SUCCEEDED
    ),
    clear: STATE_TYPES.BENCHMARK + Separator + APIActions.CLEAR
  };

  protected readonly type = STATE_TYPES['BENCHMARK'];

  @dispatch()
  public addBenchmark = (bType: IBenchmarkType): FluxStandardAction<{ [s: string]: IBenchmark[] }, BenchmarkMetaData> => ({
    type: BenchmarkAPIActions.actions.addBenchmark,
    meta: { actionType: ActionType.ADD, stateType: this.type, benchmarkType: bType },
    payload: null
  })

  public loadedBenchmark = (meta: BenchmarkMetaData, payload: IBenchmark[]): FluxStandardAction<IBenchmark[], MetaData> => ({
    type: BenchmarkAPIActions.actions.loadedBenchmark,
    meta: meta,
    payload
  })

  public loadError = (meta: BenchmarkMetaData, error: any): FluxStandardAction<IBenchmark[], MetaData> => ({
    type: BenchmarkAPIActions.actions.loadError,
    meta: meta,
    payload: null,
    error
  })

  @dispatch()
  public addPnl = (benchmarkTypes: IBenchmarkType[]): FluxStandardAction<{ [s: string]: IBenchmark[] }, BenchmarkMetaData> => ({
    type: BenchmarkAPIActions.actions.addPnl,
    meta: { actionType: ActionType.ADD, stateType: this.type, benchmarkType: benchmarkTypes },
    payload: null
  })

  public loadedPnl = (meta: BenchmarkMetaData, payload: IBenchmark[]): FluxStandardAction<IBenchmark[], BenchmarkMetaData> => ({
    type: BenchmarkAPIActions.actions.loadedPnl,
    meta: meta,
    payload
  })
}
