import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LogoutRoutePathUrl } from '@gorila/core/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'partner-connection-enabled',
  templateUrl: 'partner-connection-enabled.html',
  styleUrls: ['partner-connection-enabled.scss'],
})
export class PartnerConnectionEnabledComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { providerName: string; forceLogout?: boolean },
    private dialogRef: MatDialogRef<PartnerConnectionEnabledComponent>,
    private router: Router,
    private translate: TranslateService,
  ) {
    if (!!this.dialogRef.afterClosed) {
      this.dialogRef
        .afterClosed()
        .pipe(
          take(1),
          filter(() => data.forceLogout)
        )
        .subscribe(() => this.exit());
    }
  }

  public close() {
    this.dialogRef.close();
  }

  private exit() {
    this.router.navigateByUrl(LogoutRoutePathUrl);
  }

  public setLogout() {
    this.close();
    this.exit();
  }

  /**
   * Get modal body message by provider name.
   * @param providerName Provider name value, retrieved from auth token.
   * @returns Translated text.
   */
  public getNoLogoutBodyMsg(providerName: string): string {
    const messagesPerProvider = {
      'Órama': 'Connection with @provider is active and awaiting data for one day'
    };

    if (!(providerName in messagesPerProvider)) {
      return this.translate.instant('Connection is active, and we are syncing your accounts');
    }

    return this.translate.instant(
      messagesPerProvider[providerName],
      { provider: providerName }
    );
  }
}
