import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { OperationProviderPipe } from '../pipes/operation-provider.pipe';
import { PositionDescriptionPipe } from '../pipes/position-description.pipe';
import { PositionPipe } from '../pipes/position.pipe';
import { PricePipe } from '../pipes/price.pipe';
import { QuantityPipe } from '../pipes/quantity.pipe';
import { QuantityPricePipe } from '../pipes/quantity-price.pipe';
import { SidePipe } from '../pipes/side.pipe';
import { TradePipe } from '../pipes/trade.pipe';
import { TradeDescriptionPipe } from '../pipes/trade-description.pipe';

@NgModule({
  declarations: [
    OperationProviderPipe,
    PositionDescriptionPipe,
    PositionPipe,
    PricePipe,
    QuantityPipe,
    QuantityPricePipe,
    SidePipe,
    TradeDescriptionPipe,
    TradePipe
  ],
  providers: [
    DecimalPipe,
    PercentPipe,
    OperationProviderPipe,
    PositionDescriptionPipe,
    PositionPipe,
    PricePipe,
    QuantityPipe,
    QuantityPricePipe,
    SidePipe,
    TradeDescriptionPipe,
    TradePipe
  ],
  exports: [
    OperationProviderPipe,
    PositionDescriptionPipe,
    PositionPipe,
    PricePipe,
    QuantityPipe,
    QuantityPricePipe,
    SidePipe,
    TradeDescriptionPipe,
    TradePipe
  ],
  imports: [
    CommonModule
  ]
})
export class WalletPipesModule { }
