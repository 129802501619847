/* Own modules */
import { UpdaterService } from './updater.service';

export abstract class DaoService<T> extends UpdaterService {

  private dataArray: Array<any> = new Array();
  protected abstract isValidItem(item: T): boolean;
  protected abstract formatItem(item: T): any;
  protected abstract getIdName(): string;
  protected abstract applyFilter?(data: Array<T>): Array<T>;

  public list() {
    return this.getCurrentData();
  }

  public get(id: any): T {
    const n = this.findKey(id);
    if (n === null) { return null; }
    return this.dataArray[n];
  }

  public add(item: T): boolean {
    try {
      if (!this.isValidItem(item)) { return false; }
      item = this.formatItem(item);
      this.dataArray.unshift(item);
      this.updateData(this.dataArray);
      return true;
    } catch (e) { console.warn(e); }
  }

  public edit(id: string, item: T): boolean {
    if (!this.isValidItem(item)) { return false; }
    const n = this.findKey(id);
    if (n === null) { return false; }
    this.dataArray[n] = item;
    return true;
  }

  public remove(id: string): boolean {
    const n = this.findKey(id);
    if (n === null) { return false; }
    this.dataArray.splice(parseInt(n, 10), 1);
    this.updateData(this.dataArray);
    return true;
  }

  protected findKey(id: string): string {
    const key = this.getIdName();
    for (const n in this.dataArray) {
      /* tslint:disable:triple-equals */
      if (this.dataArray[n][key] != id) { continue; }
      /* tslint:enable:triple-equals */
      return n;
    }
    console.warn('id ' + id + ' not found!', this.dataArray);
    return null;
  }

  protected updateData(dataArray?: Array<T>) {
    try {
      if (dataArray) { this.dataArray = dataArray; }
      let data = this.applyFilter(this.dataArray);
      if (data === null) { data = this.dataArray; }
      this._update(data);
    } catch (e) { console.warn(e); }
  }

  protected getDefaultValue(): Array<T> { return []; }

  protected isValidData(data: Array<T>) { return (data) ? true : false; }
}
