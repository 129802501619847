import { Injectable } from '@angular/core';

import { TradePipe } from '@gorila/pages/wallet/pipes';

@Injectable()
export class TradeFormatterService {

  public constructor(private pipe: TradePipe) { }

  public prepareTradeList(data: any, format?: boolean): Array<any> {
    const out = new Array();
    try {
      data = (typeof data['Accounts'] === 'undefined') ? new Array() : data['Accounts'];
      for (const i in data) {
        if (!data.hasOwnProperty(i)) { continue; }
        for (const j in data[i]['Trades']) {
          if (!data[i]['Trades'].hasOwnProperty(j)) { continue; }
          if (format === true) {
            data[i]['Trades'][j] = this.pipe.transform(data[i]['Trades'][j]);
          }
          out.push(data[i]['Trades'][j]);
        }
      }
    } catch (e) { console.warn(e); }
    return this.sort(out);
  }

  private sort(out: Array<any>) {
    try {
      out.sort(function (a, b) {
        try {
          return b['Id'] - a['Id'];
        } catch (e) { console.warn(e); }
        return 0;
      });
    } catch (e) { console.warn(e); }
    return out;
  }

}
