<a
  id="buy-button"
  [routerLink]="[{ outlets: { transacoes: ['transacao', 'comprar'] } }]"
  (click)="registerTradeEvent()"
>
  <div
    class="product-circle btn-buy"
    gorTippy
    [tippyOptions]="{content: 'register buy' | translate, placement: 'bottom'}"
  >
    <gor-icon
      icon="Add-White"
      iconFolder="Platform"
      width="20px"
      height="20px"></gor-icon>
  </div>
</a>
<a
  id="sell-button"
  [routerLink]="[{ outlets: { transacoes: ['transacao', 'vender'] } }]"
  (click)="registerTradeEvent()"
>
  <div
    class="product-circle btn-sell"
    gorTippy
    [tippyOptions]="{content: 'register sell' | translate, placement: 'bottom'}"
  >
    <gor-icon
      icon="Less-White"
      iconFolder="Platform"
      width="20px"
      height="20px"></gor-icon>
  </div>
</a>
