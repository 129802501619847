import { Legend } from '@gorila-bot/allocation-assets';
import { isNil, values } from 'ramda';

import { ProfitChartData } from '../allocation/middle-box/middle-box.component';

export namespace AllocationPosition {
  export type Data = { id: string, name: string, value: number };
  export class Model {
    public getAbsoluteValuesChartData(data: Array<Data>): Array<{ id: string, value: number }> {
      const temp: { [s: string]: {id: string, value: number} } = {};
      values(data).map((line: Data) => {
        const id = line.id;
        if (isNil(temp[id])) {
          temp[id] = {
            id,
            value: 0
          };
        }
        temp[id].value += line.value;
      });
      return values(temp).sort((a, b) => b.value - a.value);
    }

    public getLegends(data: Array<ProfitChartData>): Array<Legend> {
      return data.map((line: ProfitChartData) => ({
        color: line.color,
        id: line.id,
        label: line.title
      }));
    }

    public getTotal = (data: Array<Data>): number => data.reduce((sum: number, d: Data) => sum + d.value, 0);
  }
}
