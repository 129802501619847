import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { PortfolioEventToolRoutePath } from './metadata';
import { environment } from '@env/environment';

export const PortfolioEventToolMenuData = {
  featureName: 'portfolioEvent',
  routePath: PortfolioEventToolRoutePath,
  icon: 'Nav/Advanced-Tools/Corporate-Events-White',
  text: 'TOOLS_DROPDOWN.PORTFOLIO_EVENTS',
  cancelNavigation: environment.features.tools.portfolioEvent.modal,
};

export const PortfolioEventToolRoute: Route = {
  data: {
    title: 'Eventos da Carteira'
  },
  canActivate: [AdvisorFundGuard],
  path: PortfolioEventToolRoutePath,
  loadChildren: './pages/tools/portfolio-events/src/portfolio-events.module#PortfolioEventsModule'
};
