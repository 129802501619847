import {
  animate,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PositionModel } from '@gorila/widgets/allocation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'progress-group',
  templateUrl: './progress-group.component.html',
  styleUrls: ['./progress-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'progressGroupExpanded', [
        transition(':enter', [
          style({ height: 0 }),
          animate(300, style({ height: '*' }))
        ]),
        transition(':leave', [
          style({ height: '*' }),
          animate(300, style({ height: 0 }))
        ])
      ]
    ),
    trigger(
      'progressGroup', [
        transition(':enter', [
          style({ transform: 'translateX(100%)' }),
          animate(450, style({ transform: 'translateX(0)' }))
        ]),
        transition(':leave', [
          style({ transform: 'translateX(0)' }),
          animate(450, style({ transform: 'translateX(100%)' }))
        ])
      ]
    )
  ]
})
export class ProgressGroupComponent implements OnChanges {
  @Input() public progressData: Array<any> = new Array();
  public fillGradientStyle = null;
  public preffix = false;
  public selected = false;
  public simulation = [];
  public current: Array<any> = null;

  constructor(
    private positionModel: PositionModel,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    this.current = new Array();
    this.fillGradientStyle = this.sanitizer.bypassSecurityTrustStyle('linear-gradient(to left, #3366FF 0%, #3366FF 100% ');
  }

  public ngOnChanges(data: SimpleChanges) {
    if (typeof data['progressData'] !== 'undefined') {
      this.changeSimulationArray(this.progressData);
    }
  }

  private changeSimulationArray(data: Array<any>) {
    try {
      if (data.length < 1) { return this.clearArray(); }
      if (data.length === 1) {
        this.selected = false;
      }
      this.updateArray(data);
    } catch (e) { console.warn(e); }
  }

  private clearArray() {
    this.current = new Array();
    this.simulation = [];
  }

  private updateArray(data: Array<any>) {
    this.simulation = data;
    data[0]['title'] = this.positionModel.prepareName(data[0]['index']);
    this.updateCurrent(data[0]);
    if (this.preffix) { this.setPrefix(data[0]['index']); }
  }

  private updateCurrent(data: any) {
    try {
      if (this.current.length === 0) {
        this.current.push(data);
        return;
      }

      for (const i in this.simulation) {
        if (this.simulation[i]['title'] === this.current[0]['title']) {
          this.current[0] = data;
          return;
        }
      }

      this.current = new Array();
      this.current.push(data);
    } catch (e) {
      this.current = new Array();
      this.current.push(data);
      console.warn(e);
    }

  }

  private setPrefix(text: string) {
    this.translate.get('CALCULATING').subscribe(
      (data: string) => {
        this.current[0]['title'] = data + ' ' + this.positionModel.prepareName(text);
      }
    );
  }

}
