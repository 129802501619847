import { FormControl } from '@angular/forms';
import { invalidCPF, invalidCNPJ } from '@gorila-bot/gorila-front-utils/dist/cpf-cnpj-validators';
import { unmaskNumber } from '@gorila/utils/unmaskNumber';

/**
 * Validates login information to be used with CEI synchronization service.
 * @param loginControl a FormControl where the user enters the login information.
 */
export function validateCEI(loginControl: FormControl) {
  const login: string = unmaskNumber(loginControl.value);

  if (isNaN(Number(login))) {
    return { numbersOnly: true };
  }
  switch (login.length) {
    case 11: {
      // CPF
      return invalidCPF(login) ? { invalidCPF: true } : null;
    }
    case 14: {
      // CNPJ
      return invalidCNPJ(login) ? { invalidCNPJ: true } : null;
    }
    default: {
      return { invalidCEILogin: true };
    }
  }


}
