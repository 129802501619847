import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { FeeToolRoutePath } from './metadata';

export const FeeToolMenuData = {
  featureName: 'fee',
  routePath: FeeToolRoutePath,
  icon: 'Nav/Advanced-Tools/Admin-Fee',
  text: 'TOOLS_DROPDOWN.FEE',
  dataTracking: 'Menu - Admin Fee'
};

export const FeeToolRoute: Route = {
  data: {
    title: 'Taxas Aplicadas',
    isB2BRoute: true,
    hideFilter: true
  },
  path: FeeToolRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/fee-shell#FeeShellModule'
};
