/* tslint:disable:class-name */
import { IOperation } from '@gorila-bot/gorila-front-models';
import { Action } from '@ngrx/store';
import { IListOperationParams } from '../models/operation.model';

export enum TradeActionTypes {
  LoadTradeAction = '[ITrade] LoadTrade',
  AddTradeAction = '[ITrade] AddTrade',
  RemoveTradeAction = '[ITrade] RemoveTrade',
  _LoadTradeAction = '[ITrade] LoadTrade END',
  _AddTradeAction = '[ITrade] AddTrade END',
  _RemoveTradeAction = '[ITrade] RemoveTrade END',
  _SetTradeAction = '[ITrade] SetTrade END',
}

export interface LoadTradeOptions {
  forceReload?: boolean;
  forceUpdate?: boolean;
  params?: IListOperationParams;
}

export class LoadTrade implements Action {
  public readonly type = TradeActionTypes.LoadTradeAction;
  constructor(public options: LoadTradeOptions) { }
}
export class _LoadTrade implements Action {
  public readonly type = TradeActionTypes._LoadTradeAction;
  constructor(public data: any, public error?: any) { }
}

export class AddTrade implements Action {
  public readonly type = TradeActionTypes.AddTradeAction;
  constructor(public data: any) {}
}
export class _AddTrade implements Action {
  public readonly type = TradeActionTypes._AddTradeAction;
  constructor(public data: any) {}
}

export class RemoveTrade implements Action {
  public readonly type = TradeActionTypes.RemoveTradeAction;
  constructor(public tradeId: IOperation['ids'][0], public walletId: IOperation['walletId']) {}
}
export class _RemoveTrade implements Action {
  public readonly type = TradeActionTypes._RemoveTradeAction;
  constructor(public tradeId: IOperation['ids'][0]) {}
}

export class _SetTrade implements Action {
  public readonly type = TradeActionTypes._SetTradeAction;
  constructor(public data: { operations: IOperation[] }) {}
}

export type TradeActions =
  | LoadTrade
  | AddTrade
  | RemoveTrade;

export type _TradeActions =
  | _LoadTrade
  | _AddTrade
  | _RemoveTrade
  | _SetTrade;
