import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ApiService, CacheService, HttpClientService } from '@gorila-bot/gorila-base';
import { SocketModule } from '@gorila/socket/module';
import { BaseButtonModule } from '@gorila-bot/base-button';
import { AlertModule } from '@gorila/widgets/alert/alert.module';
import { DirectivesModule } from '@gorilainvest/ui-toolkit/directives';
import { LoadingModule } from '@gorilainvest/ui-toolkit/loading';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CloseButtonComponent } from './components/close-button/close-button.component';
import { CompleterResultComponent } from './components/completer-result/completer-result.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { IconMessageComponent } from './components/icon-message/icon-message.component';
import { InputErrorHandlerComponent } from './components/input-error-handler/input-error-handler.component';
import { ProgressBarComponent } from './components/progress/progress-bar/progress-bar.component';
import { ProgressContainerComponent } from './components/progress/progress-container/progress-container.component';
import {
  ProgressGroupExpandedComponent,
} from './components/progress/progress-group-expanded/progress-group-expanded.component';
import { ProgressGroupComponent } from './components/progress/progress-group/progress-group.component';
import { ShowMoreComponent } from './components/progress/show-more/show-more.component';
import { ProxyComponent } from './components/proxy/proxy.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SaveButtonComponent } from './components/save-button/save-button.component';
import { SelectorComponent } from './components/selector/selector.component';
import { ButtonGroupComponent, ButtonGroupFormComponent } from './components/toggle/button-group/button-group.component';
import { ButtonComponent } from './components/toggle/button/button.component';
import { SharedPipesModule } from './pipes/pipes.module';
import { MOCKED_URL, MustMockResolverService } from './resolvers/must-mock-resolver.service';
import { CssChangerService } from './services/css-changer.service';
import { LoginService } from './services/login.service';
import { StepService } from './services/step.service';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { UserService } from './services/user.service';

@NgModule({
  imports: [
    AlertModule,
    AmChartsModule,
    CommonModule,
    BaseButtonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedPipesModule,
    SocketModule,
    TranslateModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    DirectivesModule,
    LoadingModule,
    IconModule
  ],
  declarations: [
    ButtonComponent,
    ButtonGroupComponent,
    ButtonGroupFormComponent,
    CompleterResultComponent,
    FormGroupComponent,
    IconMessageComponent,
    InputErrorHandlerComponent,
    ProgressBarComponent,
    ProgressContainerComponent,
    ProgressGroupComponent,
    ProgressGroupExpandedComponent,
    ProxyComponent,
    RadioGroupComponent,
    SaveButtonComponent,
    SelectorComponent,
    ShowMoreComponent,
    BreadcrumbComponent,
    CloseButtonComponent,
  ],
  exports: [
    ButtonComponent,
    ButtonGroupComponent,
    ButtonGroupFormComponent,
    CommonModule,
    CompleterResultComponent,
    FormsModule,
    FormGroupComponent,
    IconMessageComponent,
    InputErrorHandlerComponent,
    ProgressBarComponent,
    ProgressContainerComponent,
    ProgressGroupComponent,
    ProgressGroupExpandedComponent,
    ProxyComponent,
    RadioGroupComponent,
    ReactiveFormsModule,
    SaveButtonComponent,
    SelectorComponent,
    ShowMoreComponent,
    BreadcrumbComponent,
    CloseButtonComponent,
  ],
  providers: [
    ApiService,
    CacheService,
    HttpClientService,
    CssChangerService,
    LoginService,
    RouterModule,
    StepService,
    UserService,
    MustMockResolverService,
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: MOCKED_URL, useValue: 'primeiro-acesso' }
  ]
})
export class SharedModule { }
