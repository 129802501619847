// tslint:disable:deprecation
// Headers is deprecated
import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { ApiService } from '@gorila-bot/gorila-base';
import { LoginStatusEnum } from '@gorila/core';
import { SubscriberMasterService } from '@gorila/core/utils';
import { isValidFundId } from '@gorila-bot/gorila-front-utils';
import { Observable } from 'rxjs';
import { getAppMetadata } from '@gorila/utils';

@Injectable()
export class LoginService extends SubscriberMasterService {

  private nodeUrl = environment.serviceBaseUrl;
  private FundID = 0;


  constructor(
    private apiService: ApiService,
    private jwt: JwtHelperService,
  ) {
    super();
  }

  protected init() { }

  public linkAuth0Account(targetUserIdToken: string, sourceUserIdToken?: string): Observable<any> {
    const token = localStorage.getItem('id_token') || sourceUserIdToken;
    const sourceDecoded = this.jwt.decodeToken(token);
    const targetDecoded = this.jwt.decodeToken(targetUserIdToken);
    const { sub: auth0Id } = sourceDecoded;

    const {
      fundId,
    } = getAppMetadata(sourceDecoded);

    const {
      providerId,
    } = getAppMetadata(targetDecoded);

    return this.apiService.doPost(`${this.nodeUrl}providersync/broker_connection`, this.getAuthHeader(token).toJSON(), {
      fundId,
      providerId,
      auth0Id,
      providerUserToken: targetUserIdToken,
    });
  }

  public logout(): void {
    try {
      localStorage.clear();
      sessionStorage.clear();
    } catch (e) { console.warn(e); }
  }

  public loginUser(): Observable<any> {
    const url = this.nodeUrl + 'user/login';
    return this.apiService.doPost(url, {});
  }

  public getUserData(): Observable<any> {
    const url = this.nodeUrl + 'user/me';
    return this.apiService.getData(url, {});
  }

  public getSubscriptionData(): Observable<any> {
    const url = this.nodeUrl + 'premium/subscription';
    return this.apiService.getData(url, {});
  }

  public setFundId(fundId: number) {
    if (!isValidFundId(fundId)) {
      return false;
    }
    this.FundID = fundId;
    localStorage.setItem('FundID', `${fundId}`);
    return true;
  }

  public createUser(name: string): Observable<any> {
    const url = this.nodeUrl + 'user';
    return this.apiService.doPost(url, {}, { name });
  }

  public getFundID(): number {
    if (isValidFundId(this.FundID)) { return this.FundID; }
    const FundID = localStorage.getItem('FundID');
    if (isValidFundId(FundID)) {
      this.FundID = +FundID;
      return this.FundID;
    }
    return 0;
  }

  public resetFundID() {
    this.FundID = 0;
  }

  // This is used for SecurityService that uses CompleterSerice form ng2-completer
  // So, we need to look at our fork before updating this, ASAP before ng6 upgrade
  //
  // TODO - check if this method is necessary after SecurityService was removed
  public getAuthHeader(id_token?: string): Headers {
    const token = localStorage.getItem('id_token') || id_token;
    return new Headers({ 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token });
  }

  protected cleanup(loginStatusCode?: LoginStatusEnum) {
    this.nodeUrl = environment.serviceBaseUrl;
    if (loginStatusCode && loginStatusCode['previous'] === LoginStatusEnum.UserLogged) {
      this.resetFundID();
    }
  }

}
