import { BlotterState } from './api/blotter';
import { ROUTES_INITIAL_STATE, RoutesState } from './api/routes';

export type TreePath = string | string[];
export type StateType = string;

export const STATE_TYPES = {
  BENCHMARK: 'BENCHMARK',
  BLOTTER: 'BLOTTER',
  BROKER: 'BROKER',
  ISSUER: 'ISSUER',
  LOGIN: 'LOGIN',
  PAGES: 'PAGES',
  PORTFOLIO: 'PORTFOLIO',
  POSITION: 'POSITION',
  PRODUCT: 'PRODUCT',
  PROFITABILITY_MONTHLY: 'PROFITABILITY_MONTHLY',
  PROFITABILITY_DAILY: 'PROFITABILITY_DAILY',
  PROFITABILITY_YEARLY: 'PROFITABILITY_YEARLY',
  PROFITABILITY_PNL: 'PROFITABILITY_PNL',
  SUBPRODUCT: 'SUBPRODUCT',
  TRADE: 'TRADE',
  USER: 'USER'
};

export const stateTypeToStoreTreePath = (stateType: StateType): TreePath =>
  (stateType.indexOf('_') !== -1)
    ? stateType.split('_').map(s => s.toLowerCase())
    : stateType.toLowerCase();

export const FLAGS = {
  ADDING: 'adding',
  LOADING: 'loading',
  REMOVING: 'removing',
  UPDATING: 'updating'
};

export interface StateItemList<T> {
  items: {[key: string]: T};
  adding?: boolean;
  loading?: boolean;
  removing?: boolean;
  updating?: boolean;
  error: any;
}

export interface AppState {
  [stateType: string]: StateItemList<any> | any;
  blotter?: BlotterState;
  routes?: RoutesState;
  feedback?: any;
}

export const INITIAL_STATE = {
  routes: ROUTES_INITIAL_STATE
};
