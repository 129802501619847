import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@gorila-bot/gorila-base';
import { IBenchmarkType } from '@gorila-bot/gorila-front-models';
import { AppConstants } from '@gorila/constants';
import { FundIdType } from '@gorila-bot/user-data-store';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { RequestServiceBase } from '../../base/request-service.base';

@Injectable()
export class BenchmarkRequestService extends RequestServiceBase<IBenchmarkType> {

  public nodeUrl = environment.serviceBaseUrl;
  constructor(
    protected apiService: ApiService,
    protected store: Store<any>
  ) {
    super(apiService, store);
  }

  public getBaseUrl(fundId: FundIdType): string {
    return `benchmark`;
  }

  public getParams = (benchmarkType: IBenchmarkType): IBenchmarkType => ({
    ...benchmarkType,
    startDate: benchmarkType.startDate || moment.utc().subtract(1, 'years').format(AppConstants.Format.Date.American)
  })

}
