import { Injectable } from '@angular/core';
import { IBenchmarkType } from '@gorila-bot/gorila-front-models';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { BaseEpic } from '../../base/epic.base';
import { AppState, STATE_TYPES } from '../../store.model';
import { BenchmarkRequestService } from './benchmark-request.service';
import { BenchmarkAPIAction, BenchmarkAPIActions } from './benchmark.actions';
import { mapBenchmark } from './benchmark.model';

@Injectable()
export class BenchmarkAPIEpics extends BaseEpic {
  constructor(
    private service: BenchmarkRequestService,
    private benchmarkActions: BenchmarkAPIActions
  ) {
    super();
  }

  public getStateType(): string {
    return STATE_TYPES.BENCHMARK;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return combineEpics<Action, any, AppState, any>(
      this.createLoadBenchmarkEpicDaily(filterIfNotLogged),
      this.createLoadBenchmarkEpicMonthly(filterIfNotLogged),
      this.createLoadBenchmarkEpicYearly(filterIfNotLogged),
      this.createLoadPNLEpic(filterIfNotLogged)
    );
  }

  private createLoadBenchmarkEpicDaily(ifNotLogged): Epic<BenchmarkAPIAction, BenchmarkAPIAction, AppState> {
    return this.createLoadBenchmarkEpic(ifNotLogged, 'DAILY');
  }

  private createLoadBenchmarkEpicMonthly(ifNotLogged): Epic<BenchmarkAPIAction, BenchmarkAPIAction, AppState> {
    return this.createLoadBenchmarkEpic(ifNotLogged, 'MONTHLY');
  }

  private createLoadBenchmarkEpicYearly(ifNotLogged): Epic<BenchmarkAPIAction, BenchmarkAPIAction, AppState> {
    return this.createLoadBenchmarkEpic(ifNotLogged, 'YEARLY');
  }

  private createLoadBenchmarkEpic = (ifNotLogged, ctype): Epic<BenchmarkAPIAction, BenchmarkAPIAction, AppState> => {
    const actionType = BenchmarkAPIActions.actions.addBenchmark;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const current: IBenchmarkType = action.meta.benchmarkType;
      const metaData = { benchmarkType: current, stateType: this.getStateType() };
      return this.service.doRequest(current).pipe(
        filter(() => ifNotLogged(store.value)),
        map(data => this.benchmarkActions.loadedBenchmark(metaData, data.map(mapBenchmark))),
        catchError(response => from([
          this.benchmarkActions.loadError(
            metaData,
            { status: '' + response }
          )
        ]))
      );
    },
    false,
    (action, store) => action.meta.benchmarkType.periodType === ctype);
  }

  private createLoadPNLEpic(ifNotLogged): Epic<BenchmarkAPIAction, BenchmarkAPIAction, AppState> {
    const actionType = BenchmarkAPIActions.actions.addPnl;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const current: IBenchmarkType[] = action.meta.benchmarkType;
      return this.service.doMultiRequests(current).pipe(
        filter(() => ifNotLogged(store.value)),
        map(data => this.benchmarkActions.loadedPnl({ benchmarkType: current, stateType: this.getStateType() }, data)),
        catchError(response => from([
          this.benchmarkActions.loadError(
            { benchmarkType: current, stateType: this.getStateType() },
            { status: '' + response }
          )
        ]))
      );
    });
  }

}
