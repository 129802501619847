import { or } from 'ramda';

import { BaseState, MakeState, ReducerBase } from '../../../base/reducer.base';
import { STATE_TYPES } from '../../../store.model';
import { ProfitabilityAPIAction } from '../actions/profitability-base.actions';
import { ProfitabilityDailyActions } from '../actions/profitability-daily.actions';
import { ProfitabilityMonthlyActions } from '../actions/profitability-monthly.actions';
import { ProfitabilityYearlyActions } from '../actions/profitability-yearly.actions';
import { ProfitabilityGetKey } from '../profitability.model';

export const filterByStateName = (profitabilityArr: any, stateName: string) => {
  const out = [];
  for (const i in profitabilityArr) {
    if (profitabilityArr[i].periodType === stateName) {
      out.push(profitabilityArr[i]);
    }
  }
  return out;
};

export const addProfitability = (state: BaseState, a: ProfitabilityAPIAction, extra) => {
  if (typeof extra.items[extra.key] === 'undefined') {
    extra.items[extra.key] = [];
  }
  return MakeState({ items: extra.items, loading: true });
};

export const loadedProfitability = (state: BaseState, a: ProfitabilityAPIAction, extra) => {
  extra.items[extra.key] = a.payload;
  extra.items['current'] = extra.items[extra.key];
  return MakeState({ items: extra.items, loading: false });
};

export const addMultipleProfitability = (state: BaseState, a: ProfitabilityAPIAction, extra) => {
  return MakeState({ loading: true });
};

export const loadedMultiProfitability = (state: BaseState, a: ProfitabilityAPIAction, extra) => {
  for (const i in a.meta.profitabilityType) {
    if (!a.meta.profitabilityType[i] || !a.payload[i]) { continue; }
    const key = ProfitabilityGetKey(a.meta.profitabilityType[i]);
    extra.items[key] = a.payload[i];
  }
  return MakeState({ items: extra.items, loading: false });
};

export const setCurrent = (state: BaseState, a: ProfitabilityAPIAction, extra) => {
  return state;
};

export const updateGroup = (state: BaseState, a: ProfitabilityAPIAction, extra) => {
  const types: any[] = filterByStateName(a.meta.profitabilityType.profit, extra.params.type);
  const group = types[0].assetClass;
  const items = extra.items;
  if (typeof items[group] !== 'undefined') {
    delete items[group];
  }
  if (typeof items[''] !== 'undefined') {
    delete items[''];
  }
  return MakeState({ items: items });
};

export const clearProfitability = (state: BaseState, a: ProfitabilityAPIAction, extra) => {
  return MakeState({ items: {}, loading: false, error: null });
};

const checkStateName = (profitabilityArr: any, stateName: string) => {
  for (const i in profitabilityArr) {
    if (profitabilityArr[i].periodType === stateName) {
      return true;
    }
  }
  return false;
};

/**
 * BASE METHODS
 */
export const checkState = (stateName: string) => (state: BaseState, a: ProfitabilityAPIAction, parameters: any) => {
  const atype = a.type.toString();
  const getPeriodType = (profitabilityType) => (
    ([
      parameters.apiActions.addMultipleProfitability,
      parameters.apiActions.loadedMultiProfitability
    ].indexOf(atype) !== -1) ? profitabilityType[0].periodType : profitabilityType.periodType
  );
  const isNotPnl = (actype) => (
    ([
      parameters.apiActions.addPnl,
      parameters.apiActions.loadedPnl
    ].indexOf(actype) !== -1) ? false : true
  );

  if (!isNotPnl(atype)) { return false; }
  if (!a.meta || !a.meta.profitabilityType) { return false; }

  if (parameters.apiActions.updateGroup === atype) {
    try {
      return checkStateName(a.meta.profitabilityType.profit, stateName);
    } catch (e) {
      console.warn(e);
      return false;
    }
  }
  return getPeriodType(a.meta.profitabilityType) === stateName;
};

export const beforeEachProfitability = (state: BaseState, a: ProfitabilityAPIAction, parameters: any) => {
  return {
    key: ProfitabilityGetKey(a.meta.profitabilityType),
    items: or(state['items'], {}),
    params: parameters
  };
};

export const profitabilityReducer = (checkStateFn, extra) => (state: BaseState = {}, a: ProfitabilityAPIAction) => {
  return ReducerBase({
    [extra.apiActions.addProfitability]: addProfitability,
    [extra.apiActions.loadedProfitability]: loadedProfitability,
    [extra.apiActions.setCurrent]: setCurrent,
    [extra.apiActions.updateGroup]: updateGroup,
    [extra.apiActions.addMultipleProfitability]: addMultipleProfitability,
    [extra.apiActions.loadedMultiProfitability]: loadedMultiProfitability,
    [extra.apiActions.clear]: clearProfitability
  }, state, a, extra.stateType, {
      beforeEach: beforeEachProfitability,
      checkState: checkStateFn,
      parameters: extra
    });
};

export const profitabilityDailyReducer = profitabilityReducer(
  checkState('DAILY'),
  { type: 'DAILY', apiActions: ProfitabilityDailyActions.actions, stateType: STATE_TYPES.PROFITABILITY_DAILY }
);
export const profitabilityMonthlyReducer = profitabilityReducer(
  checkState('MONTHLY'),
  { type: 'MONTHLY', apiActions: ProfitabilityMonthlyActions.actions, stateType: STATE_TYPES.PROFITABILITY_MONTHLY }
);
export const profitabilityYearlyReducer = profitabilityReducer(
  checkState('YEARLY'),
  { type: 'YEARLY', apiActions: ProfitabilityYearlyActions.actions, stateType: STATE_TYPES.PROFITABILITY_YEARLY }
);
