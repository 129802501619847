/* Angular modules */
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

/* Third-party modules */
import { TranslateService } from '@ngx-translate/core';

/* Own modules */
import { PositionPipe } from '@gorila/pages/wallet/pipes';

@Injectable()
export class PositionModel {

  private uncommonType = ['TREASURY', 'CORPORATE_BONDS', 'SAVINGS', 'OTHERS'];
  private translationList: Array<string> = [];
  private positionPipe: PositionPipe;

  public constructor(
    datePipe: DatePipe,
    private translate: TranslateService
  ) {
    this.translate.get(this.uncommonType).subscribe((data) => this.translationList = data);
    this.positionPipe = new PositionPipe(datePipe, translate);
  }

  public hasSamePositionId(position: any, data: any) {
    try {
      if (typeof data === 'string') {
        if (typeof position['_id'] !== 'undefined' && position['_id'] === data) { return true; }
        if (typeof position['Key'] !== 'undefined' && position['Key'] === data) { return true; }
        return false;
      }

      if (typeof position['_id'] !== 'undefined' &&
        typeof data['_id'] !== 'undefined' &&
        position['_id'] === data['_id']
      ) { return true; }

      if (typeof position['Key'] !== 'undefined' &&
        typeof data['Key'] !== 'undefined' &&
        position['Key'] === data['Key']
      ) { return true; }

      return false;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  public preparePosition(item: any) {
    return this.positionPipe.transform(item);
  }

  public groupItens(data: Array<any>, categoryOnly: boolean): Array<any> {
    const out = new Array();

    for (let i = 0; i < data.length; i++) {
      try {
        if (typeof data[i] === 'undefined') { continue; }
        data[i]['BrokerList'] = new Array();
        data[i]['BrokerList'].push(data[i]['BrokerName']);
        if (categoryOnly) {
          out.push(data[i]);
          continue;
        }
        if (data[i]['SecurityGlobalType'] !== 'CORPORATE_BONDS' && data[i]['SecurityGlobalType'] !== 'OTHERS') {
          data = this.groupEquals(data, i);
        }
        out.push(data[i]);
      } catch (e) { console.warn(e); }
    }
    return out;
  }

  private groupEquals(data: Array<any>, i: number): Array<any> {
    for (let j = i + 1; j < data.length; j++) {
      if (typeof data[j] === 'undefined') { continue; }
      // not equal type
      if (data[i]['SecurityGlobalType'] !== data[j]['SecurityGlobalType']) { continue; }

      // not same asset
      if (data[i]['title'] !== data[j]['title']) { continue; }

      data[i]['MtmNotional'] += data[j]['MtmNotional'];
      data[i]['Notional'] += data[j]['Notional'];
      data[i]['Quantity'] += data[j]['Quantity'];
      data[i]['TotalPnl'] += data[j]['TotalPnl'];
      if (data[i]['BrokerName'] !== data[j]['BrokerName']) {
        data[i]['BrokerList'].push(data[j]['BrokerName']);
      }
      delete data[j];
    }
    return data;
  }

  public checkEqualsSecurityType(type1: string, type2: string) {
    if (type1 === type2) { return true; }
    if (type1 + '_LOCAL' === type2) { return true; }
    if (type2 + '_LOCAL' === type1) { return true; }
    return false;
  }

  public prepareName(securityName: string): string {
    if (this.needTranslation()) {
      this.translate.get(this.uncommonType).subscribe((data) => this.translationList = data);
    }
    if (this.hasSpace(securityName)) { return securityName; }
    const temp = securityName.split('_');
    if (temp.length < 2) {
      return this.prepareTreasuriesTitle(securityName);
    }

    if (temp[0] === 'SAVINGS') {
      return this.prepareSavingsTitle(securityName);
    }

    if (['CDB', 'LCI', 'LCA'].indexOf(temp[0]) !== -1) {
      return this.prepareCorporateTitle(securityName);
    }

    return temp.join(' ');
  }

  public prepareTreasuriesTitle(securityName: string): string {
    if (this.hasSpace(securityName)) { return securityName; }
    if (['LTN', 'LFT', 'NTN'].indexOf(securityName.substr(0, 3)) === -1) { return securityName; }
    const temp = securityName.split(' ');
    if (temp.length > 1) { return securityName; }

    const yy = securityName.substr(securityName.length - 8, 4);
    const mm = securityName.substr(securityName.length - 4, 2);
    const dd = securityName.substr(securityName.length - 2, 2);
    const nm = securityName.substr(0, securityName.length - 8);
    return nm + ' ' + dd + '/' + mm + '/' + yy;
  }

  public prepareSavingsTitle(securityName: string): string {
    if (this.hasSpace(securityName)) { return securityName; }
    const temp = securityName.split('_');
    if (temp.length < 2) { return securityName; }
    temp.shift();
    temp.splice(temp.length - 3, 3);
    if (typeof this.translationList['SAVINGS'] !== 'undefined') {
      temp.unshift(this.translationList['SAVINGS']);
    }
    return temp.join(' ');
  }

  public prepareCorporateTitle(securityName: string): string {
    if (this.hasSpace(securityName)) { return securityName; }
    const temp = securityName.split('_');
    if (temp.length < 2) { return securityName; }
    temp.splice(temp.length - 6, 6);
    if (temp[1] === 'POS') { temp.splice(2, 1); }
    return temp.join(' ');
  }

  public hasSpace(securityName: string): boolean {
    const t = securityName.split(' ');
    return (t.length > 1);
  }

  public needTranslation() {
    if (!this.translationList) { return true; }
    let keys = Object.keys(this.translationList);
    keys = keys.filter(key => this.translationList[key] === key);
    return keys.length;
  }
}
