import * as Actions from './actions/allocation-percentages.actions';
import * as fromReducer from './reducers/allocation-percentages.reducer';
import { AllocationPercentagesModule } from './allocation-percentages.module';
import * as State from './allocation-percentages.state';
import * as Selectors from './allocation-percentages.selector';

export {
  Actions as AllocationPercentagesActions,
  AllocationPercentagesModule,
  fromReducer as fromAllocationPercentagesReducer,
  State as AllocationPercentagesState,
  Selectors as AllocationPercentagesSelectors,
};
