import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CssChangerService {

  constructor(private domSanitizer: DomSanitizer) { }

  public setLinearTransformStyle(degrees: number) {
    return this.domSanitizer.bypassSecurityTrustStyle('rotate(' + degrees + 'deg)');
  }

  public setLinearGradientStyle(degrees: number | string, begin: string, end: string) {
    const deg = (typeof degrees === 'number' && !isNaN(degrees)) ? degrees + 'deg' : degrees;
    return this.domSanitizer.bypassSecurityTrustStyle('linear-gradient(' + deg + ', ' + begin + ', ' + end + ')');
  }
}
