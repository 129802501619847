import { DashboardRoutePath } from './dashboard/metadata';

export const AppShellRoutePath = 'app';
export const AppShellFallbackRoutePath = DashboardRoutePath;
export const ACCOUNT_SETTINGS_PATH = 'configuracoes-da-conta';

export * from './dashboard/metadata';
export * from './manager/metadata';
export * from './mobile-warning/metadata';
export * from './tools/metadata';
export * from './wallet/metadata';
export * from './user/metadata';
