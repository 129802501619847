import { Injectable } from '@angular/core';
import { CounterpartiesActions } from '@gorila-bot/counterparties-store';
import { PositionActions } from '@gorila/root-store/position';
import { ToastService } from '@gorila-bot/toast';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { IOperation } from '@gorila-bot/gorila-front-models';
import { path, uniq } from 'ramda';

import { _LoadTrade, LoadTrade, TradeActionTypes, _RemoveTrade, _SetTrade } from '../actions/trade.actions';
import { OperationTradeService } from '../services/operation-trade.service';
import { selectTradeList } from '../trade.selectors';

@Injectable()
export class TradeEffects {
  @Effect() public loadTrades$ = this.actions$.pipe(
    ofType(TradeActionTypes.LoadTradeAction),
    concatMap(({ options }: LoadTrade) =>
      this.service.getOperations(options.params, options.forceUpdate).pipe(
        map((operation: IOperation[]) => ({
          operations: operation.map(t => ({
            ...t,
            assetClass: path<string>(['security', 'assetClass'], t),
            notional: (t.deal || 1) * t.orderQuantity * (t.security.multiplier || 1),
          })),
          brokers: uniq(operation.map(t => t.broker)),
          options
        })),
      )
    ),
    switchMap(({ operations, brokers, options }) => [
      !!options.forceUpdate
        ? new _SetTrade({ operations })
        : new _LoadTrade({ operations, options }),
      new CounterpartiesActions.SetFavoritesBroker(brokers)
    ]),
    catchError(err => from([new _LoadTrade([], !!err)]))
  );

  @Effect() public _loadTrades$ = this.actions$.pipe(
    ofType(TradeActionTypes._LoadTradeAction),
    map(() => new PositionActions.MapTrades())
  );

  @Effect() public removeTrade$ = this.actions$.pipe(
    ofType(TradeActionTypes.RemoveTradeAction),
    withLatestFrom(this.store$.pipe(select(selectTradeList))),
    // @ts-ignore
    filter(([{ tradeId }, entities]) => !this.removeWIPMap[tradeId] || entities[tradeId]),
    concatMap(([{ tradeId, walletId }, entities]) => {
      this.removeWIPMap = { [tradeId]: true };
      // if request returns ok, pass ahead the trade ID
      return this.service.deleteTrade(tradeId, walletId).pipe(
        map(() => tradeId),
        catchError((err) => {
          const trade: IOperation = entities[tradeId];
          console.error(err);
          this.toast.addRaw(
            'error',
            this.translate.instant('TOAST.TRADE_REMOVE_HTTP_ERROR.SUMMARY'),
            trade ? {
              detail: this.translate.instant('TOAST.TRADE_REMOVE_HTTP_ERROR.DETAIL', {
                broker: trade.brokerName,
                security: trade.security.displayName
              })
            } : undefined
          );
          return from([null]);
        })
      );
    }),
    switchMap((tradeId: _RemoveTrade['tradeId']) => {
      delete this.removeWIPMap[tradeId];
      return [new _RemoveTrade(tradeId)];
    })
  );

  private removeWIPMap = {};

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private service: OperationTradeService,
    private toast: ToastService,
    private translate: TranslateService
  ) { }
}
