import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';

import { STATE_TYPES } from '../../../store.model';
import {
  addMultipleProfitability,
  addPnl,
  addProfitability,
  loadedMultiProfitability,
  loadedPnl,
  loadedProfitability,
  loadError,
  profitabilityActionsData,
  ProfitabilityActionsDataType,
  ProfitabilityBaseActions,
  setCurrent,
  updateGroup,
  updateProfitability
} from './profitability-base.actions';

const stateType = STATE_TYPES['PROFITABILITY_PNL'];

@Injectable()
export class ProfitabilityPnlActions extends ProfitabilityBaseActions {

  public static actions: ProfitabilityActionsDataType = profitabilityActionsData(stateType);
  protected readonly type = stateType;

  @dispatch()
  public addMultipleProfitability = addMultipleProfitability(stateType, this, ProfitabilityPnlActions.actions);

  @dispatch()
  public addProfitability = addProfitability(stateType, this, ProfitabilityPnlActions.actions);

  @dispatch()
  public updateProfitability = updateProfitability(stateType, ProfitabilityPnlActions.actions);

  @dispatch()
  public setCurrent = setCurrent(stateType, ProfitabilityPnlActions.actions);

  public loadedMultiProfitability = loadedMultiProfitability(ProfitabilityPnlActions.actions);
  public loadedProfitability = loadedProfitability(ProfitabilityPnlActions.actions);

  public loadError = loadError(ProfitabilityPnlActions.actions);

  @dispatch()
  public addPnl = addPnl(stateType, ProfitabilityPnlActions.actions);

  @dispatch()
  public updateGroup = updateGroup(stateType, ProfitabilityPnlActions.actions);

  public loadedPnl = loadedPnl(ProfitabilityPnlActions.actions);

}
