import {
  InjectionToken,
  NgModule
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AlertContainerComponent } from './components/container/alert-container.component';
import { AlertGroupComponent } from './components/group/alert-group.component';
import { AlertItemComponent } from './components/item/alert-item.component';
import { AlertService } from './services/alert.service';

export const FEATURE_ALERT = new InjectionToken<boolean>('featureAlert');

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  declarations: [
    AlertContainerComponent,
    AlertGroupComponent,
    AlertItemComponent
  ],
  exports: [
    AlertContainerComponent,
    AlertGroupComponent,
    AlertItemComponent
  ],
  providers: [AlertService]
})
export class AlertModule { }
