import { CardsService } from './shared/services/cards.service';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@env/environment';

import { RELOAD_DATA_ON_DASH_PRO } from '@gorila-bot/advisor/models';
import {
  BILLING_FULL_DATE_FORMAT,
  BILLING_MONTH_YEAR_FORMAT,
  BILLING_SERVICE_URL,
  BillingFeatureStoreModule,
  GORILA_PRO_PRICE,
  GORILA_USER_PLANS,
  VINDI_CONFIG,
} from '@gorila-bot/billing-store';
import { ptCreditCard } from '@gorila-bot/billing-widgets';
import { BLOTTER_FEATURE_TOGGLE } from '@gorila-bot/blotter';
import { AMERICAN_DATE_FORMAT, BRAZILIAN_DATE_FORMAT } from '@gorila-bot/blotter-trade';
import { CASH_FLOW_SERVICE_URL, CashFlowStoreModule } from '@gorila-bot/cash-flow-store';
import { GORILA_LANDING_PAGE_URL } from '@gorila-bot/comparator';
import { COMPANY_SERVICE_URL } from '@gorila-bot/counterparties-store';
import { FORM_DATE_FORMAT, GorilaFormsModule } from '@gorila-bot/forms';
import { GORILA_REPORT_LANDING_PAGE_URL } from '@gorila-bot/generic-report';
import { SOCKET_URL as SOCKET_URL2 } from '@gorila-bot/gorila-base';
import {
  DATE_FORMAT,
  LoginStatusService,
  MIN_BOOK_DATE,
  SELECTOR_CURRENT_FUND_ID,
  SELECTOR_LOGGED_FUND_ID,
  SERVICE_BASE_URL,
  SOCKET_URL,
} from '@gorila-bot/gorila-base2';
import { createTranslateLoader, ILocalesType, LOCALE_ID_PROVIDER, updateMomentLocale } from '@gorila-bot/gorila-front-utils';
import { LOCATION_SERVICE_URL } from '@gorila-bot/location-store';
import { GORILA_LOGO_URL } from '@gorila-bot/logo';
import { PERSONAL_FEATURE_TOGGLE } from '@gorila-bot/personal-shell';
import { PERSONAL_SERVICE_BASE_URL } from '@gorila-bot/personal-store';
import { CONNECTIONS_SERVICE_BASE_URL } from '@gorila-bot/connections-store';
import { BENCHMARK_LIST, CHART_PERIOD, DEFAULT_BASE_COLOR, DEFAULT_MAIN_SERIES } from '@gorila-bot/profitability-container';
import { FEATURE_REDUCER_TOKEN } from '@gorila-bot/profitability-store';
import { REPORT_CONTAINER_CONFIG } from '@gorila-bot/report-container';
import {
  AUTH0_CLIENTID,
  AUTH0_DATABASE,
  AUTH0_DOMAIN,
  SERVICE_TERMS_URL_B2B as SERVICE_TERMS_URL_B2B_SIGNUP,
  SERVICE_TERMS_URL_B2C as SERVICE_TERMS_URL_B2C_SIGNUP,
  SIGNUP_SERVICE_BASE_URL,
  SignupModule,
} from '@gorila-bot/signup';
import { FundRequestService, UserDataModule, UserDataSelectors } from '@gorila-bot/user-data-store';
import { clearState } from '@gorila-bot/user-data-store/logout';
import { WhiteLabelModule } from '@gorila-bot/white-label';
import { CoreModule } from '@gorila/core/module';
import { AppShellModule } from '@gorila/pages/app-shell/app-shell.module';
import { AuthModule } from '@gorila/pages/auth/auth.module';
import { TOKEN_KEY } from '@gorila/pages/auth/models/storage-keys.model';
import { SERVICE_TERMS_URL_B2B, SERVICE_TERMS_URL_B2C } from '@gorila/pages/auth/services/tokens';
import { SharedModule } from '@gorila/shared/module';
import { HttpErrorInterceptor } from '@gorila/shared/services/http-interceptor-error.service';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from '@gorila/shared/services/http-interceptor.service';
import { TradeModule } from '@gorila/trade/module';
import { AllocationModule } from '@gorila/widgets/allocation/module';
import { HeaderModule } from '@gorila/widgets/header/module';
import Colors from '@gorilainvest/styles/js/Colors';
import { ICON_BASE_SRC } from '@gorilainvest/ui-toolkit/icon';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NxModule } from '@nrwl/angular';
import * as moment from 'moment';

import * as pt from '../assets/i18n/pt-br.lang.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConstants } from './app.constants';
import { AppShellFiltersService } from './pages/app-shell/app-shell-filters.service';
import { RootStoreModule } from './root-store';
import { getErrorHandler } from './sentry/sentry-error-handler';
import { OldStoreModule, StoreViewModule } from './store/store.module';
import { UserPlans } from './user-plans';
import { ptPremium, PremiumService, PREMIUM_FEATURES_ACCESS, PremiumModule } from '@gorila-bot/premium';
import { UserService } from './shared/services/user.service';
import { GorilaPaginatorIntl } from './shared/components/gorila-paginator/gorila-paginator';
import { ReportService } from '@gorila-bot/report-store';
import { FeatureToggleService, SERVICE_OPTIMIZELY_URL, OPTIMIZELY_KEY } from '@gorila-bot/gorila-toggle';

registerLocaleData(localePt, 'pt-BR');
export const locales: ILocalesType = {
  'pt-br': {
    ...(pt as any).default,
    ...(ptCreditCard as any).default,
    ...(ptPremium as any).default
  }
};
updateMomentLocale();
moment.locale('pt-BR');

export const LOCALES_TOKEN = new InjectionToken<ILocalesType>(
  'A map with translations for APP languages keys'
);

export function tokenGetter() {
  return localStorage.getItem(TOKEN_KEY) || '';
}
export const jwtConf = {
  config: {
    tokenGetter: tokenGetter,
    whitelistedDomains: environment.whitelistedDomains,
    blacklistedRoutes: [
      environment.Vindi.b2b.apiUrl,
      environment.Vindi.b2c.apiUrl
    ]
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HeaderModule,
    HttpClientModule,
    MatIconModule,
    MatMomentDateModule,
    MatProgressBarModule,
    JwtModule.forRoot(jwtConf),
    LoadingBarModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [LOCALES_TOKEN],
      }
    }),
    CoreModule.forRoot(),
    OldStoreModule.forRoot(),
    StoreViewModule,
    AllocationModule,
    AppRoutingModule,
    AuthModule,
    AppShellModule,
    RootStoreModule,
    TradeModule,
    WhiteLabelModule,
    NxModule.forRoot(),
    BillingFeatureStoreModule,
    CashFlowStoreModule,
    GorilaFormsModule.forRoot(),
    SignupModule.forRoot(),
    UserDataModule,
    PremiumModule.forRoot(),
  ],
  exports: [AppComponent],
  providers: [
    AppShellFiltersService,
    UserService,
    PremiumService,
    CardsService,
    FundRequestService,
    LoginStatusService,
    FeatureToggleService,
    ReportService,
    LOCALE_ID_PROVIDER,
    { provide: SERVICE_OPTIMIZELY_URL, useValue: environment.serviceBaseUrl },
    { provide: OPTIMIZELY_KEY, useValue: environment.Optimizely.key },
    { provide: LOCALES_TOKEN, useValue: locales },
    { provide: FORM_DATE_FORMAT, useValue: AppConstants.Format.Date },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: AppConstants.HttpDefaultTimeout }],
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: GORILA_LOGO_URL, useValue: AppConstants.gorilaLogoUrl },
    { provide: GORILA_PRO_PRICE, useValue: 14.9 },
    { provide: GORILA_USER_PLANS, useClass: UserPlans },
    { provide: MatPaginatorIntl, useClass: GorilaPaginatorIntl },
    { provide: BILLING_SERVICE_URL, useValue: environment.serviceBaseUrl },
    { provide: BILLING_FULL_DATE_FORMAT, useValue: 'dd/MM/yyyy' },
    { provide: BILLING_MONTH_YEAR_FORMAT, useValue: 'MM/yyyy' },
    { provide: CASH_FLOW_SERVICE_URL, useValue: environment.serviceBaseUrl },
    {
      provide: ICON_BASE_SRC,
      useValue: 'https://s3.amazonaws.com/gorila-images/app/'
    },
    { provide: AUTH0_CLIENTID, useValue: environment.Auth0.clientID },
    { provide: AUTH0_DATABASE, useValue: environment.Auth0.database },
    { provide: AUTH0_DOMAIN, useValue: environment.Auth0.domain },
    { provide: SIGNUP_SERVICE_BASE_URL, useValue: environment.serviceBaseUrl },
    { provide: SERVICE_TERMS_URL_B2B, useValue: AppConstants.serviceTermsUrl.b2b },
    { provide: SERVICE_TERMS_URL_B2C, useValue: AppConstants.serviceTermsUrl.b2c },
    { provide: SERVICE_TERMS_URL_B2B_SIGNUP, useValue: AppConstants.serviceTermsUrl.b2b },
    { provide: SERVICE_TERMS_URL_B2C_SIGNUP, useValue: AppConstants.serviceTermsUrl.b2c },
    { provide: VINDI_CONFIG, useValue: environment.Vindi },
    // blotter tokens
    { provide: AMERICAN_DATE_FORMAT, useValue: 'YYYY-MM-DD' },
    { provide: BRAZILIAN_DATE_FORMAT, useValue: 'DD/MM/YYYY' },
    { provide: COMPANY_SERVICE_URL, useValue: environment.serviceBaseUrl + 'company' },
    { provide: BLOTTER_FEATURE_TOGGLE, useValue: environment.Blotter },
    // user details tokens
    {
      provide: PERSONAL_FEATURE_TOGGLE, useValue: {
        grossup: environment.features.user.preferences.grossup,
        premium: environment.features.user.premium,
        termsAndPolicyInApp: environment.features.user.termsAndPolicyInApp
      }
    },
    { provide: PERSONAL_SERVICE_BASE_URL, useValue: environment.serviceBaseUrl },
    { provide: CONNECTIONS_SERVICE_BASE_URL, useValue: environment.serviceBaseUrl },
    { provide: LOCATION_SERVICE_URL, useValue: environment.serviceBaseUrl + 'locale' },
    { provide: FEATURE_REDUCER_TOKEN, useValue: { metaReducers: [clearState] } },
    { provide: SERVICE_BASE_URL, useValue: environment.serviceBaseUrl },
    { provide: MIN_BOOK_DATE, useValue: AppConstants.Blotter.minBookDate },
    { provide: BENCHMARK_LIST, useValue: AppConstants.benchmarkList },
    { provide: DATE_FORMAT, useValue: AppConstants.Format.Date },
    { provide: DEFAULT_MAIN_SERIES, useValue: AppConstants.report.defaultMainSeries },
    {
      provide: REPORT_CONTAINER_CONFIG, useValue: {
        displayNetProfit: environment.features.portfolio.displayNetProfit,
        downloadButton: environment.features.tools.report.reportDownloadButton,
        downloadXLSButton: environment.features.tools.report.tabularReportDownloadButton,
        freemium: environment.features.freemium,
      }
    },
    { provide: DEFAULT_BASE_COLOR, useValue: Colors.GorilaPrimary },
    { provide: SELECTOR_CURRENT_FUND_ID, useFactory: UserDataSelectors.selectFeatureCurrentFundId },
    { provide: SELECTOR_LOGGED_FUND_ID, useFactory: UserDataSelectors.selectFeatureLoggedUserFundId },
    { provide: CHART_PERIOD, useValue: AppConstants.Profitability.chartDefaultPeriod },
    { provide: SOCKET_URL, useValue: environment.socketUrl },
    { provide: SOCKET_URL2, useValue: environment.socketUrl },
    // advisor page tokens
    { provide: RELOAD_DATA_ON_DASH_PRO, useValue: environment.features.reloadDataOnDashbordPro },
    // reports
    { provide: GORILA_LANDING_PAGE_URL, useValue: AppConstants.HomePageNoWWW },
    { provide: GORILA_REPORT_LANDING_PAGE_URL, useValue: AppConstants.HomePageNoWWW },
    // Sentry
    { provide: ErrorHandler, useValue: getErrorHandler() },
    // premium feature token
    { provide: PREMIUM_FEATURES_ACCESS, useValue: environment.features.premiumAccess }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
