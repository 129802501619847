import { LogService } from '@gorila/shared/services/log.service';
import { findIndex, forEachObjIndexed, indexBy, insert, length, prop, propEq, update } from 'ramda';
import { Action } from 'redux';

import { STATE_TYPES } from '../../store.model';
import { TypeReducer } from '../../store.utils';
import { ActionTransition, actionTransitionNameFor, ActionType, actionTypeNameFor } from '../actions.model';
import { ProductAPIAction, ProductAPIActions } from './product.actions';
import { insertSubProductOnProduct, mapSubProductToProduct } from './product.model';

const productReducerMap = (action, state) => {
  let products = [];
  const byPercentual = (prod1, prod2) => prod2.percentual - prod1.percentual;
  const mapSubProductsToProduct = (subProduct) => {
    const productIndex = findIndex(propEq('id', subProduct.assetClass.id))(products);
    let product = products[productIndex];
    if (!product) {
      products = insert(length(products), mapSubProductToProduct(subProduct), products);
    } else {
      product = insertSubProductOnProduct(subProduct, product);
      products = update(productIndex, product, products);
    }
  };

  const subProducts = action.payload;
  forEachObjIndexed(mapSubProductsToProduct, subProducts);
  const totalValue = products.reduce((accValue, product) => accValue + product.value, 0);
  products = products.map(product => {
    product.percentual = (totalValue === 0) ? 0 : product.value / totalValue;
    return product;
  });
  return {
    ...state,
    items: indexBy(prop('id'), products.sort(byPercentual))
  };
};

export function productReducer(state = {}, a: Action) {
  try {
    const action = a as ProductAPIAction;
    if (!action.meta || action.meta.stateType !== STATE_TYPES.PRODUCT) {
      return state;
    }

    if (action.meta.actionType === ActionType.CLEAR) {
      return {
        ...state,
        items: {}
      };
    } else if (action.meta.actionType === ActionType.MAP) {
      return productReducerMap(action, state);
    }

    const caseAction = TypeReducer(actionTypeNameFor(action.meta.actionType), STATE_TYPES.PRODUCT);
    switch (action.type) {
      case caseAction(actionTransitionNameFor(ActionTransition.STARTED)):
        return {
          ...state,
          items: {},
          loading: true,
          error: null,
        };
      case caseAction(actionTransitionNameFor(ActionTransition.SUCCEEDED)):
        return {
          ...state,
          items: indexBy(prop('id'), action.payload),
          loading: false,
          error: null,
        };
      case caseAction(actionTransitionNameFor(ActionTransition.FAILED)):
        return {
          ...state,
          items: {},
          loading: false,
          error: action.error,
        };
      case caseAction(ProductAPIActions.CLEAR):
        return {
          ...state,
          items: {},
          loading: false,
          error: action.error,
        };
    }

    if (action.meta.stateType === STATE_TYPES.PRODUCT) {
      LogService.reduxLog(`@@@ NOT FILTERED STATE ${STATE_TYPES.PRODUCT} @@@ `, action);
    }
  } catch (e) { console.warn(e); }
  return state;
}
