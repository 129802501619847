import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PartnerHeaderComponentInputs } from './partner-header.model';

@Component({
  selector: 'gor-partner-header',
  templateUrl: 'partner-header.component.html',
  styleUrls: ['partner-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerHeaderComponent implements PartnerHeaderComponentInputs {

  @Input() public set customTitleText(titleText: string) {
    this.titleText = titleText || this.defaultTitleText;
  }

  @Input() public borderless = false;
  @Input() public helpLink: string;
  @Input() public isBeta = false;
  @Input() public partnerLogoFolder = 'Partner/Logo/Broker';
  @Input() public partnerLogoName = '';
  @Input() public partnerName = '';
  @Input() public partnerType = '';
  @Input() public step = 0;
  @Input() public showCloseButton = false;

  public titleText: string;
  private readonly defaultTitleText = 'PARTNER.CONNECTION.MODAL_TITLE';

  constructor() {
    this.titleText = this.defaultTitleText;
  }

}
