import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule, MatDialogModule, MatInputModule, MatRadioModule } from '@angular/material';
import { GorilaFormsModule } from '@gorila-bot/forms';
import { SharedPipesModule } from '@gorila/shared/pipes/module';
import { UiProgressBarModule } from '@gorila/ui/ui-progress-bar';
import { ButtonModule } from '@gorilainvest/ui-toolkit/button';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { LoadingModule } from '@gorilainvest/ui-toolkit/loading';
import { PipesModule } from '@gorilainvest/ui-toolkit/pipes';
import { TranslateModule } from '@ngx-translate/core';

import {
    ConnectionExpectationsComponent,
} from '../components/modals/connection-expectations/connection-expectations.component';
import { ConnectionSecurityComponent } from '../components/modals/connection-security/connection-security.component';
import { DisconnectionComponent } from '../components/modals/disconnection/disconnection.component';
import { PartnerHeaderComponent } from '../components/modals/partner-header/partner-header.component';
import { PartnerModalComponent } from '../components/modals/partner-modal/partner-modal.component';
import { SecurityDisclaimerComponent } from '../components/modals/security-disclaimer/security-disclaimer.component';
import { SharedModalsUserAccountsService } from '../components/modals/shared-modals.service';
import { UserAccountDetailsComponent } from '../components/modals/user-account-details/user-account-details.component';
import { UserAccountDetailsService } from '../components/modals/user-account-details/user-account-details.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        TranslateModule,
        ButtonModule,
        IconModule,
        LoadingModule,
        PipesModule,
        GorilaFormsModule,
        UiProgressBarModule,
        SharedPipesModule
    ],
    exports: [
        ConnectionExpectationsComponent,
        ConnectionSecurityComponent,
        PartnerModalComponent,
        PartnerHeaderComponent,
        DisconnectionComponent,
        SecurityDisclaimerComponent,
        UserAccountDetailsComponent
    ],
    declarations: [
        ConnectionExpectationsComponent,
        ConnectionSecurityComponent,
        PartnerModalComponent,
        PartnerHeaderComponent,
        DisconnectionComponent,
        SecurityDisclaimerComponent,
        UserAccountDetailsComponent
    ],
    entryComponents: [
        ConnectionExpectationsComponent,
        ConnectionSecurityComponent,
        PartnerModalComponent,
        DisconnectionComponent,
        UserAccountDetailsComponent
    ],
    providers: [
        SharedModalsUserAccountsService,
        UserAccountDetailsService
    ],
})
export class UserAccountModalsModule { }
