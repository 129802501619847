import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@gorilainvest/ui-toolkit/directives';

import { HeaderHideComponent } from './header-hide.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule
  ],
  declarations: [
    HeaderHideComponent
  ],
  exports: [
    HeaderHideComponent
  ]
})
export class HeaderHideModule { }
