import {
  animate,
  transition,
  trigger,
  style
} from '@angular/animations';

export const rowsAnimation =
    trigger('rowsAnimation', [
      transition('void => newRow', [
        style({ opacity: '0', transform: 'rotateX(-90deg)' }),
        animate(
          '0.5s cubic-bezier(.36,-0.64,.34,1.76)',
          style({ opacity: '1', transform: 'none' })
        )
      ]),
      transition('markedForDelete => void', [
        style({ opacity: '1', transform: 'none' }),
        animate(
          '0.5s ease-out',
          style({ opacity: '0', transform: 'translateY(-100%)' })
        )
      ])
    ]);
