import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { clearState } from '@gorila/root-store/logout/logout.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LocationStoreModule } from '@gorila-bot/location-store';

import { AllocationPercentagesModule } from './allocation-percentages';
import { AppInfoReduxModule } from './app-info';
import { UserAccountModule } from './user-account';
import { UserPortfolioModule } from './user-portfolio';

@NgModule({
  imports: [
    AllocationPercentagesModule,
    CommonModule,
    UserAccountModule,
    UserPortfolioModule,
    AppInfoReduxModule,
    LocationStoreModule,
    StoreModule.forRoot({}, { metaReducers: [clearState] }),
    environment.name === 'production' ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([])
  ],
  declarations: []
})
export class RootStoreModule {}
