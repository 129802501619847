import { BaseState, ReducerBase } from '../../base/reducer.base';
import { STATE_TYPES } from '../../store.model';
import { BlotterAPIAction, BlotterAPIActions } from './blotter.actions';

export const blotterPositionStarted = (state: BaseState, a: BlotterAPIAction, extra) => ({ position: {} });
export const blotterPositionSucceeded = (state: BaseState, a: BlotterAPIAction, extra) => {
  return { position: a.payload };
};

export const blotterReducer = (state: BaseState = {}, a: BlotterAPIAction) => {
  return ReducerBase({
    [BlotterAPIActions.actions.position.started]: blotterPositionStarted,
    [BlotterAPIActions.actions.position.succeeded]: blotterPositionSucceeded
  }, state, a, STATE_TYPES.BLOTTER);
};
