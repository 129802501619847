<gor-filter-container *ngIf="clientTypeClass"
  class="global-filter-container"
  [class.b2c]="clientTypeClass === clientType.B2C"
  [class.b2b]="clientTypeClass === clientType.B2B"
  [class.b2b2c]="clientTypeClass === clientType.B2B2C"
  [class.hidden]="hideFilter"
  [filterServiceItems]="filterServiceItems$ | async"
  [filterServiceDefinitions]="appFiltersContainerService.filterServiceDefinitions$ | async"
  [b2b]="clientTypeClass === clientType.B2B"
  [minDate]="minDate$ | async"
  [startDate]="startDate$ | async"
  [endDate]="endDate$ | async"
  [filterOptions]="filterOptions$ | async"
  [options]="options$ | async"
  (reduce)="appFiltersContainerService.reduceFilter($event)"
></gor-filter-container>
