import { ActionTypes } from './wallet.actions';

export function clearWalletState(reducer) {
  return function (state, action) {

    if (action.type === ActionTypes.WALLET_CLEAR) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
