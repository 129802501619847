import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'input-error-handler',
  templateUrl: './input-error-handler.component.html',
  styleUrls: ['./input-error-handler.component.scss']
})
export class InputErrorHandlerComponent implements OnInit, OnDestroy {
  @Input() public inputControl: FormControl;
  @Input() public onlyFirst = true;

  /**
   * contains all user errors
   */
  public errors = new Array();
  private subscription: Subscription = null;

  public ngOnInit() {
    try {
      if (typeof this.inputControl === 'undefined' || this.inputControl === null) { return; }
      this.subscription = this.inputControl.statusChanges.subscribe(() => this.isValid());
    } catch (e) { console.warn(e); }
  }

  public ngOnDestroy() {
    try {
      if (this.subscription === null) { return; }
      if (this.subscription.closed) { return; }
      this.subscription.unsubscribe();
    } catch (e) { console.warn(e); }
  }

  /**
   * This method verify if FormControl has error (touched only)
   * @returns true if isValid, false otherwise
   */
  public isValid() {
    try {
      this.errors = new Array();
      if (
        this.inputControl === null || !this.inputControl.touched ||
        this.inputControl.valid || typeof this.inputControl.errors === 'undefined'
      ) { return true; }

      if (!this.isNgxValid()) {
        return false;
      }

      if (this.onlyFirst) {
        const firstKey = Object.keys(this.inputControl.errors)[0];
        this.errors.push(this.getErrorObject(firstKey));
      } else {
        Object.keys(this.inputControl.errors)
          .forEach(key => this.errors.push(this.getErrorObject(key)), this);
      }
      return false;
    } catch (e) { console.warn(e); }
    return true;
  }

  private getErrorObject(key: string) {
    const err = this.inputControl.errors[key];
    return { error: key, params: err };
  }

  private isNgxValid(): boolean {
    try {
      if (this.inputControl.errors === null) { return; }
      if (this.inputControl.errors.hasOwnProperty('requiredValue')) {
        const keys = Object.keys(this.inputControl.errors);
        const typeErrIndex = keys.length - 1;

        if (!this.inputControl.errors['requiredValue'].hasOwnProperty('length')) {
          this.errors.push({ error: keys[typeErrIndex], params: this.inputControl.errors['requiredValue'] });
          return false;
        }

        const params = {};
        const el = this.inputControl.errors['requiredValue'].length;
        for (let i = 0; i < el; i++) {
          params['value' + i] = this.inputControl.errors['requiredValue'][i];
        }
        this.errors.push({ error: keys[typeErrIndex], params: params });
        return false;
      }
    } catch (e) { console.warn(e); }
    return true;
  }
}
