<div class="app-header">
  <div class="logo-wrapper" [ngClass]="{'pro': advisorMode$|async}">
    <gor-logo [pro]="advisorMode$|async" [url]="(advisorMode$|async) ? '/app/area-do-consultor' : '/app/resumo'">
    </gor-logo>
    <ng-container *ngIf="!(advisorMode$|async) && isKongUser">
      <gor-icon class="premium-icon" icon="tags/tag_kong" height="33px" width="33px"></gor-icon>
    </ng-container>
    <ng-container *ngIf="!(advisorMode$|async) && isPoppyUser">
      <gor-icon class="premium-icon" icon="tags/tag_poppy" height="33px" width="33px"></gor-icon>
    </ng-container>
  </div>
  <ng-container *ngIf="isMobile; else desktopTemplate">
    <a class="mobile-logout" [routerLink]="'/saindo'">
      {{ 'exit' | translate }}
      <gor-icon class="color-filter--gorila-primary" width="12px" height="12px" iconFolder="Navigation/Main-Nav" icon="Logout"></gor-icon>
    </a>
  </ng-container>
</div>
<div class="advisor-client-infos" *ngIf="(advisorClient$ | async) && (advisorAsClient | async)">
  <span class="label">{{'client'|translate}}:</span>
  <span class="client-name">{{(advisorClient$|async).traderName}}</span>
  <span *ngIf="!(advisorClient$|async).isAnonymousWallet"
    class="client-email">{{(advisorClient$|async).traderEmail}}</span>
</div>

<ng-template #desktopTemplate>
  <gor-header-hide *ngIf="!(advisorMode$|async)" [hide]="hide$ | async" (reduce)="reduce($event)"></gor-header-hide>
  <header-menu [menuItems]="menuItems" [userMenuDropdown]="userMenuDropdown" [toolsMenuData]="toolsMenuData"
    [advisorAsClient]="(advisorAsClient | async)" [advisorMode]="advisorMode$|async" [userTier]="userTier"
    [brokerClientMode]="brokerClientMode$|async" [routerEvent]="routerEvents$ | async" [clientType]="clientType"
    [user]="user$ | async"></header-menu>
</ng-template>
