import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppShellRoutePath, WalletRoutePath } from '@gorila/core/router';
import { UserDataSelectors } from '@gorila-bot/user-data-store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UserAccountModel } from '@gorila/root-store/user-account/src/models/user-account.model';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalExpectationType, UserAccountDetailsModel } from './user-account-details/user-account.model';
import { B3, CEI, UserAccountData } from '../user-account.data';
import { B3PartnerConnectionTypeComponent } from './b3-partner-connection-type/b3-partner-connection-type.component';
import { ConnectionExpectationsComponent } from './connection-expectations/connection-expectations.component';
import { ConnectionSecurityComponent } from './connection-security/connection-security.component';
import { DisconnectionComponent } from './disconnection/disconnection.component';
import { PartnerModalComponent } from './partner-modal/partner-modal.component';
import {
  B3CEIDisconnectDisclaimerModalDataFn,
  B3ConnectErrorModalDataFn,
  B3CPFInUseModalDataFn,
  B3SyncErrorModalDataFn,
  B3SynchronizedModalDataFn,
  B3SynchronizingModalDataFn,
  CEIDisclaimer,
  DeleteTransactionsData,
  DeleteTransactionsErrorData,
  DeleteTransactionsLoaderData,
  DeleteTransactionsSuccessData,
  DisconnectModalDataFn,
  MBAPIInstructions,
  PartnerRedirectAwaitModalDataFn,
  SyncErrorModalDataFn,
} from './partner-modal/partner-modal.data';
import { UserAccountRequestService } from '@gorila/root-store/user-account/src/services/user-account-request.service';
import { TradeActions } from '@gorila/root-store/trade';

@Injectable({ providedIn: 'root' })
export class SharedModalsUserAccountsService  implements OnDestroy {

  public static accountConnectionStarted: string;
  private dialogLoaderDeleteTransactions: MatDialogRef<PartnerModalComponent>;
  public fundID$: Observable<string>;

  constructor(
    protected dialog: MatDialog,
    protected translate: TranslateService,
    protected store$: Store<any>,
    protected router: Router,
    protected uaRequestService: UserAccountRequestService,
  ) {
    this.initListenners();
  }

  private initListenners() {
    this.fundID$ = this.store$.pipe(
      select(UserDataSelectors.selectFeatureCurrentFundId),
      untilDestroyed(this));
  }

  public openB3CEIDisconnectDisclaimer() {
    return this.dialog.open(PartnerModalComponent, {
      width: '576px',
      height: '292px',
      panelClass: 'no-padding-dialog',
      data: B3CEIDisconnectDisclaimerModalDataFn(this.translate)
    });
  }

  public openB3ConnectError(): MatDialogRef<PartnerModalComponent> {
    return this.dialog.open(PartnerModalComponent, {
      width: '584px',
      height: '396px',
      panelClass: 'no-padding-dialog',
      data: B3ConnectErrorModalDataFn(this.translate)
    });
  }

  public openB3ConnectionInfo(infoOnly = false): MatDialogRef<B3PartnerConnectionTypeComponent> {
    return this.dialog.open(B3PartnerConnectionTypeComponent, {
      width: '584px',
      panelClass: 'no-padding-dialog',
      data: {
        infoOnly
      }
    });
  }

  public openB3Connected(): MatDialogRef<PartnerModalComponent> {
    const dialogRef = this.dialog.open(PartnerModalComponent, {
      width: '584px',
      height: '482px',
      panelClass: 'no-padding-dialog',
      data: B3SynchronizedModalDataFn(this.translate)
    });

    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(action => {
        if (action === 'wallet') {
          this.router.navigate([AppShellRoutePath, WalletRoutePath]);
        }
      });

    return dialogRef;
  }

  public openB3CPFAlreayInUse(): MatDialogRef<PartnerModalComponent> {
    const data = B3CPFInUseModalDataFn(this.translate);

    return this.dialog.open(PartnerModalComponent, {
      width: '584px',
      height: '365px',
      panelClass: 'no-padding-dialog',
      data
    });
  }

  public openSyncError(provider: UserAccountModel, helpLink?: string): MatDialogRef<PartnerModalComponent> {
    if (provider.providerId === CEI.id && !provider.errorDescription) {
      return this.dialog.open(PartnerModalComponent, {
        width: '584px',
        height: '416px',
        panelClass: 'no-padding-dialog',
        data: B3SyncErrorModalDataFn(this.translate)
      });
    }

    return this.dialog.open(PartnerModalComponent, {
      width: '584px',
      panelClass: 'no-padding-dialog',
      data: SyncErrorModalDataFn({
        providerName: provider.providerName,
        htmlContent: provider.errorDescription,
        helpLink
      })
    });
  }

  public openB3Synchronizing(): MatDialogRef<PartnerModalComponent> {
    const data = B3SynchronizingModalDataFn(this.translate);

    return this.dialog.open(PartnerModalComponent, {
      width: '584px',
      panelClass: 'no-padding-dialog',
      data
    });
  }

  public openConnectionSecurity({ height, width, urlGuia }): MatDialogRef<ConnectionSecurityComponent> {
    return this.dialog.open(ConnectionSecurityComponent, {
      height,
      width,
      panelClass: 'no-padding-dialog',
      data: {
        urlGuia
      }
    });
  }

  public openDeleteSyncedTransactions(provider: UserAccountModel): MatDialogRef<PartnerModalComponent> {
    const dialogRef =  this.dialog.open(PartnerModalComponent, {
      height: '406px',
      width: '576px',
      panelClass: 'no-padding-dialog',
      disableClose: true,
      data: DeleteTransactionsData(this.translate, provider)
    });

    dialogRef.afterClosed()
    .pipe(take(1))
    .subscribe(action => {
      if (action === 'confirm') {
        this.deleteSyncedTransactions(provider);
      }
    });

    return dialogRef;
  }

  private updateTrades() {
    this.store$.dispatch(
      new TradeActions.LoadTrade({ forceUpdate: true, params: { } })
    );
  }

  private deleteSyncedTransactions(provider: UserAccountModel) {
    this.openDeleteSyncedLoadingTransactions(provider);
    this.fundID$.subscribe( fundID => this.uaRequestService
      .deleteSyncedTransactions(fundID, provider.providerId)
      .subscribe(
        () => {
          this.openDeleteSyncedSuccessTransactions(provider);
        },
        () => this.openDeleteSyncedErrorTransactions(provider)
      )
    );
  }

  private openDeleteSyncedSuccessTransactions(provider: UserAccountModel): MatDialogRef<PartnerModalComponent> {
    this.closeDeleteSyncedLoadingTransactions();
    const dialogRef =  this.dialog.open(PartnerModalComponent, {
      height: '326px',
      width: '576px',
      panelClass: 'no-padding-dialog',
      disableClose: true,
      data: DeleteTransactionsSuccessData(this.translate, provider)
    });

    dialogRef.afterClosed()
    .pipe(take(1))
    .subscribe(action => {
      this.updateTrades();
      if (action === 'wallet') {
        this.router.navigate([AppShellRoutePath, WalletRoutePath]);
      }
    });

    return dialogRef;
  }

  private openDeleteSyncedErrorTransactions(provider: UserAccountModel): MatDialogRef<PartnerModalComponent> {
    this.closeDeleteSyncedLoadingTransactions();
    const dialogRef =  this.dialog.open(PartnerModalComponent, {
      height: '390px',
      width: '584px',
      panelClass: 'no-padding-dialog',
      disableClose: true,
      data: DeleteTransactionsErrorData(this.translate, provider)
    });

    dialogRef.afterClosed()
    .pipe(take(1))
    .subscribe(action => {
      if (action === 'retry') {
        this.deleteSyncedTransactions(provider);
      }
    });

    return dialogRef;
  }

  private openDeleteSyncedLoadingTransactions(provider: UserAccountModel): void {
    this.dialogLoaderDeleteTransactions =  this.dialog.open(PartnerModalComponent, {
      height: '228px',
      width: '584px',
      panelClass: 'no-padding-dialog',
      data: DeleteTransactionsLoaderData(this.translate, provider)
    });
  }

  private closeDeleteSyncedLoadingTransactions(): void {
    if (this.dialogLoaderDeleteTransactions) {
      this.dialogLoaderDeleteTransactions.close();
    }
  }

  public openConnectionExpectation(
    userAccount: UserAccountModel,
    enableClose: boolean,
    typeModal: ModalExpectationType
  ): MatDialogRef<ConnectionExpectationsComponent> {
    document.body.classList.add('hide-lastpass');
    const accountData: UserAccountDetailsModel = UserAccountData[userAccount.providerId];
    const dialogRef = this.dialog.open(ConnectionExpectationsComponent, {
      height: (typeModal !== 'expectations') ? '380px' : '510px',
      width: '675px',
      data: {
        typeModal,
        enableClose: enableClose,
        buttonColor: 'green',
        providerName: accountData.fullName ? accountData.fullName : userAccount.providerName,
        text: {
          title: accountData[typeModal].title,
          body1: accountData[typeModal].textBody1,
          body2: accountData[typeModal].textBody2,
          list: accountData[typeModal].list,
          button: accountData[typeModal].buttonLabel || 'Understood',
          footer: accountData[typeModal].footer
        }
      }
    });
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        document.body.classList.remove('hide-lastpass');
      });
    return dialogRef;
  }

  /**
   * Temporary CEI modal, will be removed with new B3 API integration.
   */
  public openCEIDisclaimer(): MatDialogRef<PartnerModalComponent> {
    return this.dialog.open(PartnerModalComponent, {
      width: '624px',
      panelClass: 'no-padding-dialog',
      data: CEIDisclaimer(this.translate)
    });
  }

  public openDisconnect(provider: UserAccountModel): MatDialogRef<PartnerModalComponent | DisconnectionComponent> {
    if (provider.providerId === B3.id) {
      return this.dialog.open(DisconnectionComponent, {
        width: '510px',
        height: '670px',
        panelClass: 'no-padding-dialog',
        data: {
          guideLink: 'https://guia.gorila.com.br/hc/pt-br/articles/4411570523163-Como-desconectar-sua-conta-da-B3'
        }
      });
    }
    const { logo } = UserAccountData[provider.providerId];
    return this.dialog.open(PartnerModalComponent, {
      width: '656px',
      panelClass: 'no-padding-dialog',
      disableClose: true,
      data: DisconnectModalDataFn(this.translate, provider, logo)
    });
  }

  public openLoadingRedirect(): MatDialogRef<PartnerModalComponent> {
    return this.dialog.open(PartnerModalComponent, {
      height: '248px',
      width: '430px',
      panelClass: 'no-padding-dialog',
      disableClose: true,
      data: PartnerRedirectAwaitModalDataFn(this.translate)
    });
  }

  public openMBAPIInstructions(provider: UserAccountModel): MatDialogRef<PartnerModalComponent> {
    return this.dialog.open(PartnerModalComponent, {
      height: '639px',
      width: '584px',
      panelClass: 'no-padding-dialog',
      disableClose: true,
      data: MBAPIInstructions(this.translate, provider)
    });
  }

  /**
   * ngOnDestroy to use untilDestroyed
   */
  public ngOnDestroy(): void {}
}
