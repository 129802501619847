export class CookieService {

  public static setCookie(name: string, value: any) {
    document.cookie = name + '=' + value + ';path=/;';
  }

  public static setCookieWithExpiration(name: string, value: any, expirationTime: any) {
    document.cookie = name + '=' + value + ';path=/;' + 'expires=' + expirationTime;
  }

  public static deleteCookie(name: string) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  public static getCookie(name: string): any {
    const data = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (!data) { return null; }
    if (!data[1]) { return ''; }
    return data[1];
  }

  public static getCookies() {
    return document.cookie;
  }

}
