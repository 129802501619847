import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppRoutes } from './core/router/app-routes.config';

export const AppBaseHref = { provide: APP_BASE_HREF, useValue: '/' };

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { enableTracing: false, useHash: location.host.indexOf('ssr-front') !== -1 })],
  providers: [AppBaseHref],
  exports: [RouterModule],
})
export class AppRoutingModule { }
