import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { ComparatorToolRoutePath } from './metadata';

export const ComparatorToolMenuData = {
  featureName: 'comparator',
  routePath: ComparatorToolRoutePath,
  icon: 'Nav/Advanced-Tools/Wallet-Comparator',
  text: 'TOOLS_DROPDOWN.COMPARATOR',
  dataTracking: 'Menu - Wallet Comparator'
};

export const ComparatorToolRoute: Route = {
  data: {
    title: 'Comparador de Carteiras',
    hideFilter: true,
    isB2BRoute: true,
  },
  path: ComparatorToolRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/comparator-container#ComparatorContainerModule'
};
