import { createSelector } from '@ngrx/store';

import { initialState, ITransferState, Key } from './transfer.state';

const mapIcons = data => ({
  ...data,
  icon: data.eventType === 'Custody Transfer'
    ? 'Custody-Transfer-White'
    : data.eventType.includes('Exercise')
      ? 'Exercise' : 'Corporate-Actions'
});
const filterFn = (securityName: string, broker: string) => event =>
  event.securityName === securityName && event.broker === broker;
export const selectFeature = state => state[Key] || initialState;
export const selectCurrentFundTransfers = (broker: string, securityName: string) => createSelector(
  selectFeature,
  (state: ITransferState) => ({
    events: state.events.filter(filterFn(securityName, broker)).map(mapIcons),
    isEmpty: state.isEmpty,
    trades: state.trades.map(trade => trade.id)
  })
);
export const selectAllEvents = () => createSelector(
  selectFeature,
  (state: ITransferState) => ({
    events: state.events.map(mapIcons),
    isEmpty: state.isEmpty,
    trades: state.trades.map(trade => trade.id)
  })
);
export const selectIsLoading = createSelector(
  selectFeature,
  (state: ITransferState) => state.loading
);
export const selectHasError = createSelector(
  selectFeature,
  (state: ITransferState) => state.error
);
export const selectIsEmpty = createSelector(
  selectFeature,
  (state: ITransferState) => state.isEmpty
);
export const selectEventById = id => createSelector(
  selectFeature,
  (state: ITransferState) => state.events.find(event => event.custodyTransferID === id)
);
