import { Pipe, PipeTransform } from '@angular/core';
import { IPosition } from '@gorila-bot/gorila-front-models';
import { AppConstants } from '@gorila/constants';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'positionDescription',
  pure: true
})
export class PositionDescriptionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  public transform(position: IPosition, shorten = false): string {
    if (!position || !position.description) { return ''; }
    try {
      const securityType = position.security.type;
      if (securityType === 'SAVINGS') {
        const savings = this.translate.instant(securityType);
        return `${savings} ${position.issuerName && (position.issuerName === '-') ? position.brokerName : position.issuerName}`;
      }
      if (securityType === 'CASH') {
        const cashName = this.translate.instant(`CASH_${position.security.name}`);
        return `${cashName} (${position.security.name})`;
      }
      if (securityType.startsWith('TREASURY')) {
        const productSubType = this.translate.instant(securityType);
        const maturity = moment.utc(position.maturity).format(AppConstants.Format.Date.Brazilian);
        return `${productSubType} ${maturity} - ${position.brokerName}`;
      }
    } catch (e) {
      console.warn(e);
    }
    let description = position.description;
    if (shorten && (description.length > 40)) {
      description = description.substring(0, 41) + '...';
    }

    return description;
  }
}
