import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'b3-partner-connection-type',
  templateUrl: './b3-partner-connection-type.component.html',
  styleUrls: ['./b3-partner-connection-type.component.scss']
})
export class B3PartnerConnectionTypeComponent {
  public infoOnly = false;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: { infoOnly: boolean, providerName: string },
  ) {
    this.infoOnly = data.infoOnly;
  }
}
