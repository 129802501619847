import { AmChartsService } from '@amcharts/amcharts3-angular';
import { AfterViewInit, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BaseDataSourceService } from '@gorila-bot/gorila-base';
import { UtilsService } from '@gorila/core/utils';
import { Subscription } from 'rxjs';

/**
 * @deprecated since version 3.25.<?> due to update to amchart v4, use ChartBase instead.
 */

export abstract class DeprecatedChartBase<T> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() public dataSource: BaseDataSourceService<T>;
  public dataProvider: T[] = [];
  public divID = '';
  public chart: any;
  private started = false;
  private subscription: Subscription = null;
  protected amChartsService: AmChartsService;
  constructor() {
    this.amChartsService = UtilsService.injector.get(AmChartsService);
    this.divID = this.getDivId();
    this.subscription = null;
  }

  public getDivId() {
    return 'chart_' + Math.ceil(Math.random() * 10000000);
  }

  public abstract getOptions(): any;
  public abstract onUpdate(chart: any): any;
  public abstract mapData(chartData: T[]): T[];
  public abstract onDataChange(chartData: T[]): T[];

  private listenDataSource() {
    if (!this.dataSource || this.subscription !== null) {
      return;
    }
    this.subscription = this.dataSource.connect().subscribe((data: T[]) => {
      if (!data || !data.length) {
        return;
      }
      this.dataProvider = this.onDataChange(data);
      if (typeof this.dataProvider[0] === 'undefined') {
        console.warn('Falha ao gerar gráfico, pois array enviado possui itens indefinidos', this.dataProvider);
        return;
      }
      this.genChart();
    });
  }

  public ngOnInit() {
    this.listenDataSource();
  }

  public ngAfterViewInit() {
    this.started = true;
    if (!this.chart) {
      this.genChart();
    }
  }

  public ngOnChanges(data: any) {
    try {
      if (data['dataSource']) {
        this.unsubscribe();
        this.listenDataSource();
      }
      this.genChart();
    } catch (e) {
      console.warn(e);
    }
  }

  private genChart() {
    if (!this.started) {
      return;
    }
    const options = this.getOptions();
    options['hideCredits'] = true;
    const data = this.mapData(this.dataProvider ? [...this.dataProvider] : []);
    return !this.chart ? this.createChart(data, options) : this.updateChart(data, options);
  }

  protected createChart(dataProvider: Array<any>, options?: any) {
    try {
      if (!options) {
        options = {};
      }
      if (!dataProvider || !dataProvider.length) {
        return;
      }
      options['dataProvider'] = [...dataProvider];
      this.chart = this.amChartsService.makeChart(this.getChartDiv(), options);
    } catch (e) {
      console.warn(e);
    }
  }

  protected updateChart(dataProvider?: Array<T>, chartOptions?: any) {
    if (!this.chart || (!dataProvider && !chartOptions)) {
      return;
    }
    // tslint:disable-next-line
    var self = this;
    const options = chartOptions ? { ...chartOptions } : null;
    try {
      this.amChartsService.updateChart(this.chart, () => {
        try {
          this.chart['dataProvider'] = [...dataProvider];
          if (options) {
            self.updateOptions(options);
            self.onUpdate(self.chart);
          }
        } catch (e) {
          console.warn(e);
        }
      });
    } catch (e) {
      console.warn(e);
    }
  }

  private updateOptions(options?: any) {
    // tslint:disable-next-line
    for (var i in options) {
      if (i === 'type') {
        continue;
      }
      try {
        this.chart[i] = options[i];
      } catch (e) {
        console.warn(i, e);
      }
    }
  }

  public ngOnDestroy() {
    this.unsubscribe();
    if (!this.chart) {
      return;
    }
    this.amChartsService.destroyChart(this.chart);
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = null;
  }

  public getChartDiv() {
    return this.divID;
  }
}
