import { Injectable } from '@angular/core';
import { IProfitabilityType } from '@gorila-bot/gorila-front-models';
import { FundIdType } from '@gorila-bot/user-data-store';
import { ApiService } from '@gorila-bot/gorila-base';
import { Store } from '@ngrx/store';

import { RequestServiceBase } from '../../base/request-service.base';

@Injectable()
export class ProfitabilityRequestService extends RequestServiceBase<IProfitabilityType> {

  constructor(
    protected apiService: ApiService,
    protected store: Store<any>
  ) {
    super(apiService, store);
  }

  public getParams(params: IProfitabilityType): IProfitabilityType {
    const _params = { ...params };
    if (
      (typeof _params.productType !== 'undefined' && _params.productType !== '') &&
      (typeof _params.securityName !== 'undefined' && _params.securityName !== '')
    ) {
      _params.productType = '';
    }
    if (!_params.groupBySecurity) {
      _params.groupBySecurity = (typeof _params.securityName !== 'undefined' && _params.securityName !== '');
    }
    if (_params.id) {
      delete _params.id;
    }
    return _params;
  }

  public getBaseUrl(fundId: FundIdType): string {
    return `portfolio/${fundId}/profit`;
  }

}
