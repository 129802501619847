import { values } from 'ramda';
import { AssetClass } from '../asset-class.model';
import { mergePnlData, PnlData, unmergePnlData } from '../pnl.model';
import { Position, Positions } from '../position/position.model';

/**
 * Interface for objects that represents a subproduct.
 *
 * @interface
 */
export interface SubProduct {
  /**
   * Unique identifier of a subproduct.
   *
   * @type {string}
   */
  id: string;

  /**
   * The name of the subproduct.
   *
   * @type {string}
   */
  name: string;

  /**
   * How many money the user have with the subproduct.
   *
   * @type {number}
   */
  value: number;

  /**
   * Percentage of this subproduct based on all subproducts from the product
   *
   * @type {number}
   */
  percentual: number;

  /**
   * The type of the product this belongs
   *
   * @type {string}
   */
  productType: string;

  /**
   * Describes the pnl information (nominal and percentual) total for the
   * prodyuct and for given periods:
   * - daily
   * - monthly
   * - yearly
   *
   * @type {PnlData}
   * @see {PnlData}
   */
  pnl: PnlData;

  /**
   * A list of positions related to the subproduct, identified by an unique identifier.
   *
   * @type {Array<string>}
   * @see {Position}
   */
  positions: Array<string>;

  /**
   * The asset class of the subproduct.
   *
   * @type {AssetClass}
   * @see {AssetClass}
   */
  assetClass: AssetClass;

  /**
   * The fund id that owns the subproduct.
   *
   * @type {string}
   */
  fundId: string;
}
export type SubProducts = Array<SubProduct>;

export const composeSubProductIdFromPosition = position => position.security.type;

export const insertPositionOnSubProduct = (position: Position, subProduct: SubProduct): SubProduct => {
  if (subProduct.id !== position.security.type) { return subProduct; }

  const newSubProduct = subProduct;
  newSubProduct.value = subProduct.value + position.value;
  newSubProduct.pnl = mergePnlData(subProduct.pnl, position.pnl);
  if (subProduct.positions.indexOf(position.id) === -1) {
    newSubProduct.positions = [...subProduct.positions, position.id];
  }
  return newSubProduct;
};

export const mapPositionToSubProduct = (position: Position): SubProduct => ({
  id: position.security.type,
  name: position.security.type,
  productType: position.security.type,
  value: position.value,
  percentual: 0,
  pnl: position.pnl,
  positions: [position.id],
  assetClass: position.assetClass,
  fundId: position.fundId
});

export const mapPositionsToSubProducts = (positions: Positions, sortFn = (s, t) => t.percentual - s.percentual): SubProducts => {
  const indexedSubProducts: {[key: string]: SubProduct} = {};
  const totalValue = positions.reduce((total: number, position: Position) => total + position.value, 0);
  positions.forEach((position: Position) => {
    const idFromPosition = composeSubProductIdFromPosition(position);
    if (indexedSubProducts[idFromPosition]) {
      indexedSubProducts[idFromPosition] = insertPositionOnSubProduct(position, indexedSubProducts[idFromPosition]);
    } else {
      indexedSubProducts[idFromPosition] = mapPositionToSubProduct(position);
    }
  });
  return values(indexedSubProducts)
    .map((subProduct: SubProduct) => ({ ...subProduct, percentual: subProduct.value / totalValue }))
    .sort(sortFn);
};

export const removePositionOnSubProduct = (position: Position, subProduct: SubProduct): SubProduct => {
  if (subProduct.id !== position.security.type) { return subProduct; }

  const newSubProduct = subProduct;
  newSubProduct.value = subProduct.value - position.value;
  newSubProduct.pnl = unmergePnlData(subProduct.pnl, position.pnl);
  newSubProduct.positions = [...subProduct.positions, position.id];
  return newSubProduct;
};
