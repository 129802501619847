import { Injectable } from '@angular/core';

import { STATE_TYPES } from '../../../store.model';
import { ProfitabilityMonthlyActions } from '../actions/profitability-monthly.actions';
import { ProfitabilityRequestService } from '../profitability-request.service';
import { ProfitabilityBaseAPIEpics } from './profitability-base.epics';

@Injectable()
export class ProfitabilityMonthlyEpics extends ProfitabilityBaseAPIEpics {
  constructor(
    private profitabilityActions: ProfitabilityMonthlyActions,
    profitabilityRequestService: ProfitabilityRequestService
  ) {
    super(profitabilityRequestService);
  }

  public getStateType(): string {
    return STATE_TYPES.PROFITABILITY_MONTHLY;
  }

  public getApiAction(): ProfitabilityMonthlyActions {
    return this.profitabilityActions;
  }

  public getActionsOfApiAction() {
    return ProfitabilityMonthlyActions.actions;
  }
}
