import { IntegrationType } from '@gorila/root-store/user-account/src/models/user-account.model';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { FieldDataObject, FormBaseComponent, FormCreatorService } from '@gorila-bot/forms';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';
import { UserDataSelectors } from '@gorila-bot/user-data-store';
import {
  PROVIDER_STATE,
  ProviderStateType,
  UserAccountModel
} from '@gorila/root-store/user-account/src/models/user-account.model';
import * as UserAccountActions from '@gorila/root-store/user-account/src/actions/user-account.actions';
import * as UserAccountState from '@gorila/root-store/user-account/src/user-account.state';
import { selectProviderById } from '@gorila/root-store/user-account/src/user-account.selector';
import { select, Store } from '@ngrx/store';
import * as auth0 from 'auth0-js';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { keys, path } from 'ramda';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, pairwise, take } from 'rxjs/operators';

import { B3, CEI, UserAccountData, XP } from '../../user-account.data';
import { SharedModalsUserAccountsService } from './../../modals/shared-modals.service';
import { UserAccountDetailsService } from './user-account-details.service';
import { validateCEI } from './loginValidator';
import { TRY_FAILED_MESSAGE } from './user-account-details.messages';
import { UserAccountDialogData, UserAccountDetailsModel } from './user-account.model';
import { UserAccountRequestService } from '@gorila/root-store/user-account/src/services/user-account-request.service';
import { TranslateService } from '@ngx-translate/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { unmaskNumber } from '@gorila/utils/unmaskNumber';
import { PersonalFacade } from '@gorila-bot/personal-store';

const TRYED_DIV = 3;
const MAX_STEP = 4;

@Component({
  selector: 'user-account-details',
  templateUrl: './user-account-details.component.html',
  styleUrls: ['./user-account-details.component.scss'],
  providers: [SharedModalsUserAccountsService, UserAccountRequestService],
  animations: [
    trigger(
      'stepChangeAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0, position: 'absolute'}),
          animate('300ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1, position: 'absolute'}),
          animate('300ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class UserAccountDetailsComponent extends FormBaseComponent implements OnDestroy, OnInit {
  public animate = false;
  // If check is true, it means FOCUS_ON_POSITION and false is FOCUS_ON_TRADE
  public check = true;
  public loginFailure: string | number;
  public terms: string;
  public errorMessage: string;
  public step = 0;
  public debouncedLoginValue$: Observable<string>;
  private fields = {
    login: { value: '', required: true, disabled: false },
    password: { value: '', required: true, disabled: false },
    key: { value: '', required: true, disabled: false },
    key_id: { value: '', required: true, disabled: false }
  };
  private triedNTimes = 0;
  public integrationType: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserAccountDialogData,
    @Optional() private dialogRef: MatDialogRef<UserAccountDetailsComponent>,
    private translate: TranslateService,
    protected dialog: MatDialog,
    protected formCreatorService: FormCreatorService,
    protected uaRequestService: UserAccountRequestService,
    protected userAccountDetailsService: UserAccountDetailsService,
    private store: Store<UserAccountState.State>,
    protected sharedModalsUserAccountsService: SharedModalsUserAccountsService,
    private personalFacade: PersonalFacade,
  ) {
    super(formCreatorService);
    if (this.data.userAccountData.providerId === B3.id) {
      this.step = 1;
    }
  }

  public ngOnInit() {
      super.ngOnInit();
      this.debouncedLoginValue$ = this.form.get('login').valueChanges.pipe(
        pairwise(),
        filter(([previousValue, currentValue]) =>
          unmaskNumber(currentValue) !== unmaskNumber(previousValue)
        ),
        map(([_, value]) => value),
        debounceTime(100)
      );
      this.personalFacade.preferences$
      .pipe(
        untilDestroyed(this),
      ).subscribe(preferencesSettingsData =>
        this.integrationType = path(['settings', 'Provider', 'B3', 'IntegrationType'], preferencesSettingsData) || 'FOCUS_ON_POSITION'
        );
  }

  public ngOnDestroy() { }

  public getFields(): FieldDataObject {
    this.terms = this.data.terms;
    let out = {};
    if (this.data.fields.password) {
      this.fields.password.required = false;
    }
    out = this.data.signUpFields
      .reduce((acc, field) => ({ ...acc, [field]: this.fields[field]}), {});
    out['check'] = { value: false, required: true, disabled: false };
    return out;
  }

  public getName(): string {
    return 'UserAccountDetailsComponent';
  }

  public openSecurityModal() {
    if (this.dialogRef) {
      this.dialogRef.addPanelClass('hide');
    }

    this.unhideAfterclosed(this.sharedModalsUserAccountsService.openConnectionSecurity({
      height: '454px',
      width: '584px',
      urlGuia: this.data.urlGuia
    }));
  }

  public save(dialogRef?: MatDialogRef<any>, delayRequest?: number) {
    const userAccountData = this.data.userAccountData;
    const providerId = userAccountData.providerId;
    const data = { ...this.form.value, providerId };
    if (keys(this.data.fields).length === 0 && !userAccountData.connectionParameters) {
      return combineLatest([
        this.store.pipe(select(UserDataSelectors.selectFeatureCurrentFundId)),
        this.uaRequestService.connect({ providerId: data.providerId }),
      ])
        .pipe(
          untilDestroyed(this),
          catchError((error) => {
            console.error('an error occurred while synchronizing', error);
            return of(error);
          })
        )
        .subscribe(([fundId, res]: [any, any]) => {
          this.sendAnalyticsConnect(this.data.userAccountData.providerName);
          const AUTH0 = new auth0.WebAuth({
            domain: environment.Auth0.domain,
            clientID: environment.Auth0.clientID,
            redirectUri: `${environment.Auth0.redirectUrl}conectar-contas`,
            responseType: 'token id_token',
            scope: environment.Auth0.scope,
          });
          const resource = 'Gorila';
          const accountData = UserAccountData[data.providerId];
          return AUTH0.authorize({
            resource,
            connection: accountData.integrationType.connection,
          });
        });
    }
    if (this.data.title === 'CEI') {
      this.triggersCEIValidation();
    }
    if (this.form.invalid) {
      this.loginFailure = (keys(this.form.get('login').errors || []).shift()) || 'invalidLogin';
      return;
    }
    this.animate = true;
    const connect = () => this.uaRequestService.connect({ ...data, integrationType: this.data.integrationType }).subscribe(
      (response: UserAccountModel) => {
        if (dialogRef) {
          dialogRef.close();
        }

        let providerInfo: {
          providerState: ProviderStateType;
          lastUpdate?: string;
        };
        //  TODO - remove this handler after change the response status from 203 to 401
        //
        // message received when the key has write and read permission instead of just read
        if (<any>response === 'not reading key') {
          this.setErrorMessage(<any>response);
          this.animate = false;
          this.unhide();
          return;
        }
        if (typeof response === 'string') {
          if ((response as string).indexOf('login') >= 0) {
            providerInfo = { providerState: 'ERROR_AUTHENTICATION' };
          } else {
            providerInfo = { providerState: 'ERROR' };
          }
        } else {
          providerInfo = {
            providerState: response.providerState,
            lastUpdate: response.lastUpdate
          };
        }
        this.sendAnalyticsConnect(userAccountData.providerName);
        this.close();

        if (this.areModalsShownAfterConnection(userAccountData)) {
          if (userAccountData.providerId !== XP.id) {
            return this.openInformativeExpectationModal(userAccountData);
          }
          this.openModalAfterConnection(userAccountData);
        }

        if (providerInfo.providerState !== 'AWAITING_DATA') {
          this.openModalAfterConnection(userAccountData);
        }
      },
      (err) => {
        if (dialogRef) {
          dialogRef.close();
        }
        this.unhide();

        // message received when the key has write and read permission instead of just read
        if (err === 'not reading key') {
          this.animate = true;
          this.setErrorMessage(err);
          return;
        }
        console.warn('Unexpected error on account signup with external provider.');
        setTimeout(() => {
          this.animate = false;
          this.triedNTimes++;
          this.setTriedAttemptMessage();
        }, 500);
      }
    );

    if (delayRequest) {
      timer(delayRequest).subscribe(connect);
    } else {
      connect();
    }
  }

  public close() {
    if (!this.dialogRef) {
      return;
    }

    this.dialogRef.close();
  }

  private setErrorMessage(msg: string) {
    this.errorMessage = msg;
  }

  private setTriedAttemptMessage() {
    const i = Math.ceil(this.triedNTimes / TRYED_DIV);
    this.errorMessage = TRY_FAILED_MESSAGE[Math.min(i, TRY_FAILED_MESSAGE.length - 1)];
  }

  public back() {
    if (this.step === 0) {
      this.step = 2;
    } else {
      this.step--;
    }
  }

  public nextStep() {
    if (this.step === MAX_STEP) {
      this.preSave();
    } else {
      this.step++;
    }
  }
  public preSave() {
    this.data.integrationType = this.integrationType as IntegrationType;
    const { userAccountData: { providerId } } = this.data;
    const accountData: UserAccountDetailsModel = UserAccountData[providerId];
    if (providerId === B3.id) {
      this.connectB3();
      return;
    }
    // tslint:enable triple-equals
    if (accountData.expectations && accountData.expectations.showModalBeforeConnect) {
      this.openModalConnectionExpectation(true, true);
    } else {
      this.save();
    }
  }

  private openModalAfterConnection(userAccountData: UserAccountModel) {
    if (userAccountData.providerId === B3.id) {
      return;
    }

    const accountData: UserAccountDetailsModel = UserAccountData[userAccountData.providerId];
    if (accountData.accountSync) {
      // display account sync modal
      return this.sharedModalsUserAccountsService.openConnectionExpectation(
        this.data.userAccountData,
        true,
        'accountSync'
      );
    }
  }

  public openModalConnectionExpectation(isToSave: boolean, enableClose: boolean) {
    const dialogRef = this.sharedModalsUserAccountsService.openConnectionExpectation(
      this.data.userAccountData,
      enableClose,
      'expectations'
    );
    if (!isToSave) {
      return;
    }
    dialogRef.beforeClosed()
      .pipe(take(1))
      .subscribe((success: boolean) => {
        if (success) {
          setTimeout(() => this.save(), 650);
        }
      });
  }

  private areModalsShownAfterConnection(userAccountData: UserAccountModel): boolean {
    const accountData = UserAccountData[userAccountData.providerId];

    return userAccountData.connectionParameters &&
      accountData.expectations &&
      accountData.expectations.showModalBeforeConnect;
  }

  private cpfAlreadyInUse() {
    this.sharedModalsUserAccountsService.openB3CPFAlreayInUse().afterClosed()
      .pipe(take(1), filter(result => !!result))
      .subscribe(() => {
        this.sharedModalsUserAccountsService
          .openDisconnect(this.data.userAccountData)
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result) {
              this.store.dispatch(new UserAccountActions.Disconnect(CEI.id, 'CEI', true));
            }
            this.close();
          });
      });
  }

  private connectB3() {
    this.triggersCEIValidation();
    if (this.form.invalid) {
      this.loginFailure = (keys(this.form.get('login').errors || []).shift()) || 'invalidLogin';
      return;
    }

    if (this.dialogRef) {
      this.dialogRef.addPanelClass('hide');
    }

    const loadingDialogRef = this.sharedModalsUserAccountsService.openLoadingRedirect();

    let subscription = null;
    subscription = this.store.pipe(
      select(selectProviderById(B3.id)),
      filter(({ providerState }) => [
        PROVIDER_STATE.awaiting_authorization,
        PROVIDER_STATE.error_already_in_use,
        PROVIDER_STATE.updating,
        PROVIDER_STATE.error_authentication
      ].includes(providerState)),
      distinctUntilChanged((x, y) => x.providerState === y.providerState)
    ).subscribe(({ providerName, providerState }) => {
      if (providerState === PROVIDER_STATE.awaiting_authorization) {
        loadingDialogRef.componentInstance.data.footerDisclaimer += `
          <div style="font-size:14px;margin-top:14px">
            ${this.translate.instant('PARTNER.REDIRECT_MODAL.REDIRECT_TEXT')}
            <a style="text-decoration:none;color:#3366FF" href=${B3.urlB3Authorize} target="_blank">
              ${this.translate.instant('PARTNER.REDIRECT_MODAL.REDIRECT_TEXT_LINK')}
            </a>
          </div>
          `;
        window.open(B3.urlB3Authorize);
        return;
      }

      if (providerState === PROVIDER_STATE.error_already_in_use) {
        loadingDialogRef.close();
        this.cpfAlreadyInUse();
        subscription.unsubscribe();
        subscription = null;
        return;
      }

      if (subscription === null) {
        return;
      }

      loadingDialogRef.close();

      if (providerState === PROVIDER_STATE.updating) {
        this.sharedModalsUserAccountsService.openB3Synchronizing();
        this.sendAnalyticsConnect(providerName);
      }
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
      subscription = null;
      this.close();
    });

    this.store.dispatch(new UserAccountActions.Signup(B3.id, {
      check: true,
      integrationType: this.data.integrationType,
      login: unmaskNumber(this.form.get('login').value)
    }));

    const newValue = this.check;
    this.personalFacade.saveMetadata({
      Property: 'Provider.B3.EnableInitialAdjustment',
      Value: newValue,
    });
    this.personalFacade.saveMetadata({
      Property: 'Provider.B3.EnableCurrentAdjustment',
      Value: newValue,
    });

  }

  private openInformativeExpectationModal(userAccountData: UserAccountModel): void {
    const dialogRef = this.sharedModalsUserAccountsService.openConnectionExpectation(
      userAccountData,
      true,
      'expectations'
    );

    dialogRef.beforeClosed()
      .pipe(take(1))
      .subscribe(() => {
        setTimeout(() => this.openModalAfterConnection(userAccountData), 650);
      });
  }

  private sendAnalyticsConnect(providerName: string) {
    AnalyticsService.setEvent('broker_connect', { broker: providerName });
  }

  private triggersCEIValidation() {
    this.form.get('login').setValidators([Validators.required, validateCEI]);
    this.form.get('login').updateValueAndValidity();
  }

  private unhide() {
    this.dialogRef.removePanelClass('hide');
  }

  private unhideAfterclosed(dialogRef: MatDialogRef<any>) {
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(() => this.unhide());
  }
}
