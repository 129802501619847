import {
  IProfitability,
  IProfitabilityIndexed,
  IProfitabilityList,
  IProfitabilityType
} from '@gorila-bot/gorila-front-models';
import { FluxStandardAction } from 'flux-standard-action';

import { Separator } from '../../../store.utils';
import { APIActions, getTypeFor, MetaData } from '../../actions';
import { ActionTransition, ActionType } from '../../actions.model';

export interface ProfitabilityMetaData extends MetaData { profitabilityType: any; forceUpdate?: boolean; }

export type ProfitabilityAPIAction = FluxStandardAction<IProfitabilityList, ProfitabilityMetaData>;

export abstract class ProfitabilityBaseActions extends APIActions<IProfitability> {
  public profitabilityTypes: IProfitabilityType[] = [];
  public updateGroup = (group: string, changedProfit?: any, changedPnl?: any)
  : FluxStandardAction<IProfitability, ProfitabilityMetaData> => {
    return null;
  }
  public doUpdateGroup = (group: string) => {
    this.profitabilityTypes
      .filter((data) => data.assetClass === group || data.assetClass === '')
      .map(data => {
        this.updateGroup(group, data);
      });
  }

  public isAdded = (p: IProfitabilityType, profitabilityTypes: IProfitabilityType[]) => {
    for (const i in profitabilityTypes) {
      if (!profitabilityTypes[i]) { continue; }
      let equals = false;
      for (const j in p) {
        if (typeof p[j] === 'undefined' || typeof profitabilityTypes[i][j] === 'undefined') { continue; }
        if (p[j] !== profitabilityTypes[i][j]) {
          equals = false;
          break;
        }
        equals = true;
      }
      if (equals) { return true; }
    }
    return false;
  }

}

export interface ProfitabilityActionsDataType {
  addProfitability: string;
  addMultipleProfitability: string;
  updateProfitability: string;
  updateGroup: string;
  setCurrent: string;
  loadedProfitability: string;
  loadedMultiProfitability: string;
  loadError: string;
  addPnl: string;
  loadedPnl: string;
  clear: string;
}

export const profitabilityActionsData = (stateType: string): ProfitabilityActionsDataType => ({
  addProfitability: getTypeFor(ActionType.ADD, [stateType], ActionTransition.DATA),
  addMultipleProfitability: getTypeFor(ActionType.ADD, [stateType, 'MULTI'], ActionTransition.DATA),
  updateProfitability: getTypeFor(ActionType.UPDATE, [stateType], ActionTransition.DATA),
  updateGroup: getTypeFor(ActionType.UPDATE, [stateType], ActionTransition.STARTED),
  setCurrent: getTypeFor(ActionType.NULL, [stateType, 'SET_CURRENT'], ActionTransition.DATA),
  loadedProfitability: getTypeFor(ActionType.LOAD, [stateType], ActionTransition.SUCCEEDED),
  loadedMultiProfitability: getTypeFor(ActionType.LOAD, [stateType, 'MULTI'], ActionTransition.SUCCEEDED),
  loadError: getTypeFor(ActionType.LOAD, [stateType], ActionTransition.FAILED),
  addPnl: getTypeFor(ActionType.ADD, [stateType, 'PNL'], ActionTransition.DATA),
  loadedPnl: getTypeFor(ActionType.LOAD, [stateType, 'PNL'], ActionTransition.SUCCEEDED),
  clear: stateType + Separator + APIActions.CLEAR
});

export const addMultipleProfitability = (stateType: string, self: ProfitabilityBaseActions, actions: ProfitabilityActionsDataType) =>
  (p: IProfitabilityType[]): FluxStandardAction<IProfitabilityIndexed, ProfitabilityMetaData> => {
    // needed for update function
    for (const i in p) {
      if (!self.isAdded(p[i], self.profitabilityTypes)) {
        self.profitabilityTypes.push(p[i]);
      }
    }
    return {
      type: actions.addMultipleProfitability,
      meta: { actionType: ActionType.ADD, stateType: stateType, profitabilityType: p },
      payload: null
    };
  };

export const addProfitability =
 (stateType: string, self: ProfitabilityBaseActions, actions: ProfitabilityActionsDataType) =>
 (p: IProfitabilityType, forceUpdate = false): FluxStandardAction<IProfitabilityIndexed, ProfitabilityMetaData> => {
    // needed for update function
    if (forceUpdate || !self.isAdded(p, self.profitabilityTypes)) {
      self.profitabilityTypes.push(p);
    }
    return {
      type: actions.addProfitability,
      meta: { actionType: ActionType.ADD, stateType: stateType, profitabilityType: p , forceUpdate},
      payload: null
    };
  };

export const updateProfitability = (stateType: string, actions: ProfitabilityActionsDataType) =>
  (): FluxStandardAction<IProfitabilityIndexed, ProfitabilityMetaData> => ({
    type: actions.updateProfitability,
    meta: { actionType: ActionType.UPDATE, stateType: stateType, profitabilityType: this.profitabilityTypes },
    payload: null
  });

export const setCurrent = (stateType: string, actions: ProfitabilityActionsDataType) =>
  (p: IProfitabilityType): FluxStandardAction<IProfitabilityIndexed, ProfitabilityMetaData> => ({
    type: actions.setCurrent,
    meta: { actionType: ActionType.NULL, stateType: stateType, profitabilityType: p },
    payload: null
  });

export const loadedMultiProfitability = (profitabilityData: ProfitabilityActionsDataType) =>
  (m: ProfitabilityMetaData, p: IProfitabilityList[]): FluxStandardAction<IProfitabilityList[], MetaData> => ({
    type: profitabilityData.loadedMultiProfitability,
    meta: m,
    payload: p
  });

export const loadedProfitability = (profitabilityData: ProfitabilityActionsDataType) =>
  (meta: ProfitabilityMetaData, payload: IProfitabilityList): FluxStandardAction<IProfitabilityList, MetaData> => ({
    type: profitabilityData.loadedProfitability,
    meta: meta,
    payload
  });

export const loadError = (profitabilityData: ProfitabilityActionsDataType) =>
  (meta: ProfitabilityMetaData, error: any): FluxStandardAction<IProfitabilityList, MetaData> => ({
    type: profitabilityData.loadError,
    meta: meta,
    payload: null,
    error
  });

export const addPnl = (stateType: string, profitabilityData: ProfitabilityActionsDataType) =>
  (p: IProfitabilityType[]): FluxStandardAction<IProfitabilityIndexed, ProfitabilityMetaData> => {
    this.pnlTypes = p;
    return {
      type: profitabilityData.addPnl,
      meta: { actionType: ActionType.ADD, stateType: stateType, profitabilityType: p },
      payload: null
    };
  };

export const updateGroup = (stateType: string, profitabilityData: ProfitabilityActionsDataType) =>
  (group: string, changedProfit?: any, changedPnl?: any): FluxStandardAction<IProfitability, ProfitabilityMetaData> => {
    const profitType = { profit: changedProfit, pnl: changedPnl, group: group };
    return {
      type: profitabilityData.updateGroup,
      meta: {
        actionType: ActionType.UPDATE,
        stateType: stateType,
        profitabilityType: profitType
      },
      payload: null
    };
  };

export const loadedPnl = (profitabilityData: ProfitabilityActionsDataType) =>
  (meta: ProfitabilityMetaData, payload: IProfitabilityList): FluxStandardAction<IProfitabilityList, MetaData> => ({
    type: profitabilityData.loadedPnl,
    meta: meta,
    payload
  });
