export enum LoginStatusEnum {
  Auth0NotLogged= 0,
  ConnectingWithServices,
  Error,
  UserLogged,
  UserLogoutStarted
}

export const LoginStatusString = [
  'Auth0NotLogged',
  'ConnectingWithServices',
  'Error',
  'UserLogged',
  'UserLogoutStarted'
];
