import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { getInitials } from '@gorila/utils';

import { HeaderComponentEvent } from '../events.model';

@Component({
  selector: 'gor-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent {
  private _name = '';
  @Input() public set name(username: string) {
    this._name = getInitials(username);
  }
  public get name() {
    return this._name;
  }

  @Input() public active = false;

  @Output() public reduce = new EventEmitter<HeaderComponentEvent>();

  private lastEmit = false;

  public handleMouseEvent(enter: boolean) {
    if (this.lastEmit === enter)  {
      return;
    }
    this.reduce.emit({ type: 'UserMenuEvent', data: { enter } });
    this.lastEmit = enter;
  }
}
