import { Observable, Observer } from 'rxjs';

export const convertFileToBase64 = (file: File) => {
  return new Observable((observer: Observer<any>) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      observer.next(reader.result);
      observer.complete();
    };
    reader.onerror = error => observer.error(error);
  });
};
