import { Route } from '@angular/router';

import { DARFRoutePath } from './metadata';

export const DARFMenuData = {
  featureName: 'darf',
  routePath: DARFRoutePath,
  icon: 'Nav/Advanced-Tools/DARF-White',
  text: 'DARF',
  dataTracking: 'Menu - DARF'
};

export const DARFRoute: Route = {
  data: {
    title: 'DARF',
    hideFilter: true
  },
  path: DARFRoutePath,
  loadChildren: '@gorila-bot/darf-shell#DarfShellModule'
};
