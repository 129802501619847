import { STATE_TYPES } from '../../../store.model';
import { or } from 'ramda';
import { BenchmarkAPIAction, BenchmarkAPIActions } from '../benchmark.actions';
import { BenchmarkGetType } from '../benchmark.model';
import { ReducerBase, MakeState } from '../../../base/reducer.base';
import { loadError, clearBenchmark } from './benchmark.reducer';

export const addPnl = (state: any, a: BenchmarkAPIAction, extra) => {
  if (typeof extra.items[extra.key] === 'undefined') {
    extra.items[extra.key] = {};
  }
  return MakeState({ items: extra.items });
};

export const loadedPnl = (state: any, a: BenchmarkAPIAction, extra) => {
  extra.items[extra.key] = {};
  extra.items[extra.key] = {};
  let n = 0;
  // tslint:disable-next-line:forin
  for (const i in a.meta.benchmarkType) {
    const k = a.meta.benchmarkType[i].periodType;
    extra.items[extra.key][k] = a.payload[n];
    n++;
  }
  return MakeState({ items: extra.items });
};

export const beforeEachBenchmark = (state: any, a: BenchmarkAPIAction) => ({
  key: BenchmarkGetType(a.meta.benchmarkType[0]),
  items: or(state['items'], {})
});

export const checkState = (state: any, a: BenchmarkAPIAction) => {
  if ([
    BenchmarkAPIActions.actions.addBenchmark,
    BenchmarkAPIActions.actions.loadedBenchmark
  ].indexOf(a.type.toString()) !== -1) { return false; }
  if (!a.meta || !a.meta.benchmarkType) { return false; }
  return a.meta.benchmarkType instanceof Array;
};

export const benchmarkPnlReducer = (state = {}, a: BenchmarkAPIAction) => {
  return ReducerBase({
    [BenchmarkAPIActions.actions.addPnl]: addPnl,
    [BenchmarkAPIActions.actions.loadedPnl]: loadedPnl,
    [BenchmarkAPIActions.actions.loadError]: loadError,
    [BenchmarkAPIActions.actions.clear]: clearBenchmark
  }, state, a, STATE_TYPES.BENCHMARK, {
      beforeEach: beforeEachBenchmark,
      checkState: checkState
    });
};
