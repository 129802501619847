import { Routes, UrlSegment } from '@angular/router';
import {
  AuthNoLockComponent,
  Auth0LockComponent,
  CallbackComponent,
} from '@gorila/pages/auth';
import { MobileWarningComponent } from '@gorila/pages/mobile-warning';
import { MobileWarningRoute } from './../../router/app-shell/mobile-warning';

import { LoggedOutGuard } from '../guards/logged-out.guard';
import { MobileGuard } from '../guards/mobile.guard';
import { RouteData } from '../route-data';
import {
  AuthNoLockRoutePath,
  LoginRoutePath,
  LogoutRoutePath,
  AuthNoLockSignupRoutePath,
  AuthNoLockForgotPasswordRoutePath,
  AuthNoLockResetPasswordRoutePath,
  AuthNoLockEmailRoutePath,
  AuthGorilaPROSignupRoutePath,
  AuthConnectRoutePath,
} from './metadata';

export const AuthRouteData: RouteData = {
  title: 'Entrar',
};

export function authMatcher(url: UrlSegment[]) {
  if (url.length === 1) {
    const path = url[0].path;
    const authPaths = [
      AuthConnectRoutePath,
      AuthNoLockRoutePath,
      AuthNoLockSignupRoutePath,
      AuthNoLockForgotPasswordRoutePath,
      AuthNoLockResetPasswordRoutePath,
      AuthNoLockEmailRoutePath,
      AuthGorilaPROSignupRoutePath,
    ];
    if (authPaths.indexOf(path) !== -1) {
      return { consumed: url };
    }
  }
  return null;
}

export const AuthRoutes: Routes = [
          MobileWarningRoute,
         {
           path: AuthConnectRoutePath,
           component: AuthNoLockComponent,
           canLoad: [LoggedOutGuard],
           data: {
             title: 'Continuar conexão de contas'
           },
         },
         {
           path: AuthNoLockEmailRoutePath,
           component: AuthNoLockComponent,
           canLoad: [LoggedOutGuard],
           data: {
             title: 'Complete seu e-mail'
           },
         },
         {
           component: AuthNoLockComponent,
           data: AuthRouteData,
           path: '',
           canLoad: [LoggedOutGuard],
           children: [
             { path: '', redirectTo: AuthNoLockRoutePath, pathMatch: 'full' },
             { matcher: authMatcher, component: AuthNoLockComponent },
           ],
         },
       ];

export const AuthCallbackRoutes: Routes = [
  {
    component: CallbackComponent,
    data: { title: 'Entrando' },
    path: LoginRoutePath,
    canLoad: [LoggedOutGuard],
  },
  {
    component: CallbackComponent,
    data: { title: 'Saindo' },
    path: LogoutRoutePath,
  },
];
