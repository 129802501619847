import { Parity } from '@gorila/shared';

export const PositionColumns = {
  Broker: 'Broker',
  Maturity: 'Maturity',
  OperationalCosts: 'OperationalCosts',
  PriceDate: 'PriceDate',
  PriceToday: 'PriceToday',
  ProfitDaily: 'ProfitDaily',
  ProfitMonthly: 'ProfitMonthly',
  ProfitPeriod: 'ProfitPeriod',
  Quantity: 'Quantity',
  Total: 'Total'
};

export interface PositionBehavioralState {
  columnsToShow?: string[];
  expandable: Parity;
  selectable: Parity;
  showActions: boolean;
  descriptionLimit?: number;
}
