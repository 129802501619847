<div class='loading-component' [ngClass]="{'absolute': !!centered}" *ngIf="!hide">
  <div class="loading-spinner"
    [style.height.px]="_size"
    [style.width.px]="_size"
    [style.border-width.px]="circleSize"
  >
    <i [ngClass]="icon"></i>
  </div>
  <div *ngIf="message">{{message}}</div>
</div>
