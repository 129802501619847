import { Injectable } from '@angular/core';
import { AnalyticsService } from '@gorila-bot/gorila-front-utils';
import { Auth0Service } from '@gorila/pages/auth/services/auth0.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { is } from 'ramda';
import { catchError, filter, map, switchMap, delay } from 'rxjs/operators';

import * as UserAccountActions from '../actions/user-account.actions';
import { PROVIDER_STATE, UserAccountList, UserAccountModel } from '../models/user-account.model';
import { UserAccountRequestService } from '../services/user-account-request.service';

@Injectable()
export class UserAccountEffects {
  @Effect() public loadAccount$ = this.actions$.pipe(
    ofType(UserAccountActions.UserAccountActionTypes.UserAccountLoad),
    switchMap((action: UserAccountActions.Load) =>
      this.requestService.doRequest({})
    ),
    filter((data) => is(Array, data)),
    map((data: UserAccountList) =>
      data.map((d) => ({ ...d, providerType: d.providerType || 'broker' }))
    ),
    map((data: UserAccountList) => new UserAccountActions._Load(data)),
    catchError((err) => {
      console.warn('error while requesting user data', err);
      return null;
    })
  );

  @Effect() public connectAccount$ = this.actions$.pipe(
    ofType(UserAccountActions.UserAccountActionTypes.Signup),
    switchMap((action: UserAccountActions.Signup) => this.requestService.connect({
      providerId: action.payload.providerId,
      ...action.payload.signupPayload
    }).pipe(map((res: UserAccountModel) => new UserAccountActions.UpdateState(res.providerId, res, true))))
  );

  @Effect() public disconnectAccount$ = this.actions$.pipe(
    ofType(UserAccountActions.UserAccountActionTypes.Disconnect),
    switchMap((action: UserAccountActions.Disconnect) => {
      const providerId = action.payload.providerId;
      const providerName = action.payload.providerName;
      const actionName = action.payload.dropAll ? 'disconnect' : 'deactivate';
      return this.requestService[actionName](providerId).pipe(
        map((data: any) => {
          AnalyticsService.setEvent('broker_disconnect', {
            broker: providerName,
          });
          this.auth0.refreshToken();
          return new UserAccountActions.UpdateState(providerId, {
            providerState: data.providerState,
            connectionParameters: data.connectionParameters ? data.connectionParameters : undefined,
          });
        })
      );
    })
  );

  @Effect() public forceUpdateStatus$ = this.actions$.pipe(
    ofType(UserAccountActions.UserAccountActionTypes.ForceUpdateStatus),
    switchMap((action: UserAccountActions.ForceUpdateStatus) => [
      new UserAccountActions.UpdateState(action.providerId, {
        providerState: PROVIDER_STATE.updating,
      }),
      new UserAccountActions.Load(),
    ])
  );

  @Effect({ dispatch: false }) public checkAuthorization$ = this.actions$.pipe(
    ofType(UserAccountActions.UserAccountActionTypes.CheckAuthorization),
    switchMap((action: UserAccountActions.CheckAuthorization) => this.requestService.checkAuthorization(action.payload))
  );

  constructor(
    private actions$: Actions,
    private auth0: Auth0Service,
    private requestService: UserAccountRequestService
  ) {}
}
