import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { HeaderDropdownItem } from '../model';

@Component({
  selector: 'gor-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownButtonComponent {
  @HostBinding('attr.data-tracking') public dataTracking = '';

  private _item: HeaderDropdownItem;
  @Input() public set item(it: HeaderDropdownItem) {
    this._item = it;
    this.dataTracking = it.dataTracking;
  }
  public get item() {
    return this._item;
  }
}
