export type AppInfoAvailableSizes = 'xs' | 'sm' | 'md' | 'lg';

export const getSize = (size: number): AppInfoAvailableSizes => {
  return (size < 992)
    ? size < 768
      ? 'xs'
      : 'sm'
    : (size > 1200)
      ? 'lg'
      : 'md';
};
