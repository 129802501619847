<form class="form" *ngIf="form" [formGroup]="form">

  <div *ngIf="isRequestInvalid; else resetFormTemplate" class="button-container">

    <div class="message-container">
      <span [class]="'error-message'">{{ 'LOGIN.RESET_PASSWORD.INVALID' | translate }}</span>
    </div>
    <gor-base-button cssClass="btn-action btn-action--primary" (click)="onClick(eventType.NAV_LOGIN)"
      [title]="'Return to login screen' | translate">
    </gor-base-button>

  </div>

  <ng-template #resetFormTemplate>
    <div>
      <span for="email" class="form-label">{{ 'password' | translate }}</span>
      <div>
        <input matInput class="form-input" type="password" name="password" id="password" placeholder="Digite sua senha"
          formControlName="password">
      </div>
      <span *ngIf="!form.get('password').valid && form.get('password').touched" class="form-invalid">
        <i *ngIf="(form.get('password').errors?.required || form.get('password').errors?.minlength)">{{ 'Invalid password min' | translate
          }}</i>
      </span>
    </div>.
    <div>
      <span for="confirm-password" class="form-label">{{ 'Confirm Password' | translate }}</span>
      <div>
        <input matInput class="form-input" type="password" name="confirm-password" id="confirm-password" placeholder="Confirm Password"
          formControlName="confirmPassword">
      </div>
      <span class="form-invalid">
        <i
          *ngIf="form.get('confirmPassword').errors?.required && form.get('confirmPassword').touched">{{ 'Invalid password' | translate }}</i>
        <i
          *ngIf="form.errors?.passwordMatch && form.get('confirmPassword').touched">{{ 'Password do not match' | translate }}</i>
      </span>
    </div>

    <div *ngIf="!!message.text" class="message-container">
      <span [class]="message.text ? message.error ? 'error-message' : 'message' : 'hide-message'">{{ message.text | translate }}</span>
    </div>

    <div class="button-container" *ngIf="message.text !== 'LOGIN.RESET_PASSWORD.SUCCESS'">
      <gor-base-button id="reset_password_submit" cssClass="btn-action btn-action--primary"
        [disabled]="form.errors || !form.valid  || _waitingAuthResponse"
        (click)="onClick(eventType.RESET_PASSWORD)" [title]="this._btnText | translate">
      </gor-base-button>
    </div>

    <div class="button-container" *ngIf="message.text === 'LOGIN.RESET_PASSWORD.SUCCESS'">
      <gor-base-button id="login_password_submit" cssClass="btn-action btn-action--primary"
        (click)="onClick(eventType.NAV_LOGIN)" [title]="'Go to login'| translate">
      </gor-base-button>
    </div>

    <div class="button-container" *ngIf="message.text === 'LOGIN.RESET_PASSWORD.ERROR_GENERIC'">
      <gor-base-button id="recover_password_submit" cssClass="btn-action btn-action--primary"
        (click)="onClick(eventType.NAV_RESET)" [title]="'Forgot password'| translate">
      </gor-base-button>
    </div>

  </ng-template>
</form>
