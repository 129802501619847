import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ManagerStoreModule } from '@gorila-bot/advisor/store';
import { CounterpartiesStoreModule } from '@gorila-bot/counterparties-store';
import { FilterContainerModule } from '@gorila-bot/filter-container';
import { DarfStoreModule } from '@gorila-bot/darf-store';
import { GenericAssetStoreModule } from '@gorila-bot/generic-asset-store';
import { PersonalFeatureStoreModule } from '@gorila-bot/personal-store';
import { ConnectionsFeatureStoreModule } from '@gorila-bot/connections-store';
import { ToastModule } from '@gorila-bot/toast';
import { TransferStoreModule } from '@gorila-bot/transfer-store';
import { AllocationPercentagesModule } from '@gorila/root-store/allocation-percentages';
import { NgrxAPIService } from '@gorila/root-store/ngrx-api.service';
import { PositionReduxModule } from '@gorila/root-store/position';
import { PositionEventListenerService } from '@gorila/root-store/position/src/services/position-event-listener.service';
import { RoutesReduxModule } from '@gorila/root-store/routes';
import { TradeReduxModule } from '@gorila/root-store/trade';
import {
  UserAccountEventListenerService,
} from '@gorila/root-store/user-account/src/services/user-account-event-listener.service';
import { SharedModule } from '@gorila/shared/module';
import { UserService } from '@gorila/shared/services/user.service';
import { UiIconModule } from '@gorila/ui/ui-icon';
import { UiModalModule } from '@gorila/ui/ui-modal';
import { HeaderModule } from '@gorila/widgets/header/module';
import { ButtonModule } from '@gorilainvest/ui-toolkit/button';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { LoadingModule } from '@gorilainvest/ui-toolkit/loading';
import { PipesModule } from '@gorilainvest/ui-toolkit/pipes';
import { PopupConfirmModule } from '@gorilainvest/ui-toolkit/popup-confirm';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from '../../app-routing.module';
import { Auth0Service } from '../auth/services/auth0.service';
import { WalletPipesModule } from '../wallet/modules';
import { PartnerActionsComponent } from './../user/user-account/components/modals/partner-actions/partner-actions.component';
import { AppShellComponent } from './app-shell.component';
import { AppShellFilterComponent } from './filters/app-shell-filter.component';
import { PartnerConnectionEnabledComponent } from './modals/partner-connection-enabled/partner-connection-enabled.component';
import { PartnerConnectionErrorComponent } from './modals/partner-connection-error/partner-connection-error.component';
import { UserAccountModalsModule } from '../user/user-account/modules/user-account-modals.module';

@NgModule({
  imports: [
    AllocationPercentagesModule,
    CommonModule,
    ScrollingModule,
    HeaderModule,
    AppRoutingModule,
    CounterpartiesStoreModule,
    DarfStoreModule,
    GenericAssetStoreModule,
    ManagerStoreModule,
    PersonalFeatureStoreModule,
    ConnectionsFeatureStoreModule,
    MatDialogModule,
    PopupConfirmModule,
    PositionReduxModule,
    RoutesReduxModule,
    SharedModule,
    TradeReduxModule,
    TranslateModule,
    TransferStoreModule,
    UiModalModule,
    UiIconModule,
    IconModule,
    ButtonModule,
    LoadingModule,
    PipesModule,
    FilterContainerModule,
    WalletPipesModule,
    ToastModule,
    UserAccountModalsModule
  ],
  providers: [
    Auth0Service,
    NgrxAPIService,
    PositionEventListenerService,
    UserService,
    UserAccountEventListenerService,
  ],
  declarations: [
    AppShellComponent,
    AppShellFilterComponent,
    PartnerConnectionEnabledComponent,
    PartnerConnectionErrorComponent
  ],
  exports: [AppShellComponent],
  entryComponents: [PartnerConnectionEnabledComponent, PartnerConnectionErrorComponent, PartnerActionsComponent],
})
export class AppShellModule { }
