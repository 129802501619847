import {
  _AllocationPercentagesActions,
  _AllocationPercentagesReportActions,
  AllocationPercentagesActions,
  AllocationPercentagesActionTypes,
  AllocationPercentagesReportActions,
  ClearAllocationPercentagesActions,
} from '../actions/allocation-percentages.actions';
import {
  IAllocationPercentagesReportState,
  IAllocationPercentageState,
  initialReportState,
  initialState,
} from '../allocation-percentages.state';

/**
 * AllocationPercentages reducer
 * @param state the current AllocationPercentages states
 * @param action the action dispatched
 */
export function reducer(state = initialState,
  action: _AllocationPercentagesActions | AllocationPercentagesActions | ClearAllocationPercentagesActions): IAllocationPercentageState {
  switch (action.type) {
    case AllocationPercentagesActionTypes.AllocationPercentagesClearAction:
      return { ...initialState };
    case AllocationPercentagesActionTypes.AllocationPercentagesLoadAction:
      return {
        ...state,
        error: null,
        loading: true
      };
    case AllocationPercentagesActionTypes._AllocationPercentagesLoadAction:
      // Load data received from back-end into store.
      return {
        ...state,
        error: action.error || initialState.error,
        loading: false,
        allocationData: action.data || initialState.allocationData
      };
    default:
      return state;
  }
}

export function reportReducer(state = initialReportState,
  action: _AllocationPercentagesReportActions | AllocationPercentagesReportActions): IAllocationPercentagesReportState {
    switch (action.type) {
      case AllocationPercentagesActionTypes.AllocationPercentagesReportLoad:
        return {
          ...state,
          error: null,
          loading: true
        };
      case AllocationPercentagesActionTypes._AllocationPercentagesReportLoad:
        return {
          ...state,
          error: action.error || initialReportState.error,
          loading: false,
          percentages: action.data.percentages || initialReportState.percentages,
          currentGrossEquity: action.data.currentGrossEquity || initialReportState.currentGrossEquity
        };
      default:
        return state;
    }
}
