<ng-container *ngIf="data">
  <div class="modal-container">
    <div class="modal-header">
      <div class="btn-container">
        <button mat-button mat-dialog-close>
          <gor-icon iconFolder="Platform" icon="Close" width="24px" height="24px"></gor-icon>
        </button>
      </div>
      <div class="success-img">
        <gor-icon
          iconFolder="Status"
          icon="success"
          imgFormat="svg"
          width="32px"
          height="32px"
        ></gor-icon>
      </div>
      <div class="title-container">
        <h4 class="title-container__text" translate>{{ data.text?.title }}</h4>
        <h4 class="title-container__provider">{{ data.providerName }}</h4>
      </div>
    </div>
    <div class="modal-body">
      <span class="modal-body__text" translate>{{ data.text?.body1 }}</span>
      <span class="modal-body__text" translate>{{ data.text?.body2 }}</span>
    </div>
    <div class="modal-footer" mat-dialog-actions>
      <gor-button [btnType]="data.buttonColor" mat-button [mat-dialog-close]="true">
        {{ data.text?.button | translate }}
      </gor-button>
    </div>
    <div *ngIf="!!data.text?.footer" class="modal-footer__text">
      {{ data.text.footer | translate }}
      <gor-icon iconFolder="Platform" icon="Settings" width="14px" height="14px"></gor-icon>
      .
    </div>
  </div>
</ng-container>
