import {
  UrlTree,
  DefaultUrlSerializer,
  PRIMARY_OUTLET,
  UrlSegmentGroup,
} from '@angular/router';
import {
  encodeUriFragment,
  encodeUriQuery,
  mapChildrenIntoArray,
  serializePaths,
} from '@angular/router/esm5/src/url_tree';
import { forEach } from '@angular/router/esm5/src/utils/collection';
export function serializeQueryParams(params) {
  const strParams = Object.keys(params).map(function (name) {
    const value = params[name];
    return Array.isArray(value)
      ? value
          .map(function (v) {
            return encodeUriQuery(name) + '=' + encodeUriQuery(v);
          })
          .join('&')
      : encodeUriQuery(name) + '=' + encodeUriQuery(value);
  });
  return strParams.length ? '?' + strParams.join('&') : '';
}

export function serializeSegment(
  segment: UrlSegmentGroup,
  root: boolean
): string {
  if (!segment.hasChildren()) {
    return serializePaths(segment);
  }

  if (root) {
    const primary = segment.children[PRIMARY_OUTLET]
      ? serializeSegment(segment.children[PRIMARY_OUTLET], false)
      : '';
    const children: string[] = [];

    forEach(segment.children, (v: UrlSegmentGroup, k: string) => {
      if (k !== PRIMARY_OUTLET) {
        children.push(`${k}:${serializeSegment(v, false)}`);
      }
    });

    if (children.length > 0) {
      // Do not remove ( ) when no more than one chilren exist
      if (children.length > 1) {
        return `${serializePaths(segment)}/(${children.join('//')})`;
      }
      return `${serializePaths(segment)}/${children}`;
    }

    return primary;
  } else {
    const children = mapChildrenIntoArray(
      segment,
      (v: UrlSegmentGroup, k: string) => {
        if (k === PRIMARY_OUTLET) {
          return [serializeSegment(segment.children[PRIMARY_OUTLET], false)];
        }

        return [`${k}:${serializeSegment(v, false)}`];
      }
    );

    // Do not remove ( ) when no more than one chilren exist
    if (children.length > 1) {
      return `${serializePaths(segment)}/(${children.join('//')})`;
    }
    return `${serializePaths(segment)}/${children}`;
  }
}

/**
 * A custom url serializer that is responsible to not leave `( )` after
 * closing the blotter.
 *
 * For implementation details, @see DefaultUrlSerializer.
 */
export class GorilaUrlSerializer extends DefaultUrlSerializer {
  public parse(url: string): UrlTree {
    return super.parse(url);
  }

  public serialize(tree: UrlTree): string {
    const segment = `/${serializeSegment(tree.root, true)}`;
    const query = serializeQueryParams(tree.queryParams);
    const fragment =
      typeof tree.fragment === `string`
        ? `#${encodeUriFragment(tree.fragment)}`
        : '';

    return `${segment}${query}${fragment}`;
  }
}
