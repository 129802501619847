<div class="progress-bar-component" [ngClass]="{'lt10':title<10, 'gt85':title>=85}">
  <span *ngIf="text" class="progress-text">{{text}}</span>
  <div class="progress" *ngIf="!waiting" [style.background]="nonFilledBackground">
    <div #progressBar class="progress-bar" role="progressbar" [ngStyle]="{'width': width}" [style.background]="filledBackground" [style.left.%]="offsetLeft * 100">
      <span [@titleOpacity]="animateTitleState" *ngIf="showProgress" class="progress-status" [style.justifyContent]="textAlign" [style.fontSize]="textSize">
        <ng-container
          *ngTemplateOutlet="!!hoverCompletedText ? tooltipTemplate : noTooltipTemplate
            context: {displayCondition: title>=100 && completedText, tippyContent: hoverCompletedText, text: completedText }">
        </ng-container>
        <ng-container
          *ngTemplateOutlet="!!hoverCompletedText ? tooltipTemplate : noTooltipTemplate
            context: {displayCondition: title<100&&shouldDisplayValue(progressBar), tippyContent: hoverText, text: title + '%' }">
        </ng-container>
      </span>
    </div>
    <ng-container *ngIf="progress<innerProgress">
      <div  #progressBarShadow class="progress-bar progress-bar__shadow" role="progressbar" [ngStyle]="{'width': shadowWidth}" [style.background]="shadowFilledBackground" [style.left.%]="offsetLeft * 100">
        <span *ngIf="showProgress" class="progress-status" [style.justifyContent]="'flex-end'" [style.fontSize]="shadowTextSize" [style.height]="textSize">
          <span *ngIf="title>=100 && completedText">
            {{completedText}}
          </span>
          <span *ngIf="title<100&&shouldDisplayShadowValue(progressBar, progressBarShadow)">
            {{shadowTitle}}%
          </span>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="progress" *ngIf="waiting && waitingText">
    {{waitingText}}
  </div>
</div>

<ng-template #tooltipTemplate let-displayCondition="displayCondition" let-tippyContent="tippyContent" let-text="text">
  <span *ngIf="displayCondition" gorTippy [tippyOptions]="{content: tippyContent}">
    {{text}}
  </span>
</ng-template>

<ng-template #noTooltipTemplate let-displayCondition="displayCondition" let-text="text">
  <span *ngIf="displayCondition">
    {{text}}
  </span>
</ng-template>
