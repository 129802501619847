import { Action } from '@ngrx/store';
import { PortfolioEvents } from '../models/portfolio-event.model';

export enum PortfolioEventsActionTypes {
  PortfolioEventsLoadAction = '[PortfolioEvents] Load Corporate Actions',
  _PortfolioEventsLoadAction = '[PortfolioEvents] Load Corporate Actions END'
}

export interface LoadPortfolioEventsOptions {
  forceLoad?: boolean;
  minDate?: string;
  maxDate?: string;
}

export class PortfolioEventsLoad implements Action {
  public readonly type = PortfolioEventsActionTypes.PortfolioEventsLoadAction;
  constructor(public options: LoadPortfolioEventsOptions) {}
}
// tslint:disable:class-name
export class _PortfolioEventsLoad implements Action {
  public readonly type = PortfolioEventsActionTypes._PortfolioEventsLoadAction;
  constructor(public data: PortfolioEvents, public error?: any) {}
}

export type PortfolioEventsActions = PortfolioEventsLoad;
export type _PortfolioEventsActions = _PortfolioEventsLoad;
