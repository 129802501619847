import { Pipe, PipeTransform } from '@angular/core';

const sliceData = (items: any[], currentPage: number, itemsPerPage: number, initial = 0): any[] => {
  return !itemsPerPage
    ? [...items]
    : [...items].slice(initial * itemsPerPage, (currentPage + 1) * itemsPerPage);
};

@Pipe({
  name: 'paginator'
})
export class PaginatorPipe implements PipeTransform {

  public transform(items: any[], currentPage: number, itemsPerPage = 10, initial = 0): any[] {
    return sliceData(items, currentPage, itemsPerPage, initial);
  }

}
