import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { clearState } from '@gorila/root-store/logout/logout.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { Key as AllocationPercentagesKey } from './allocation-percentages.state';
import { AllocationPercentagesEffects } from './effects/allocation-percentages.effects';
import * as fromAllocationPercentages from './reducers/allocation-percentages.reducer';
import { AllocationPercentagesRequestService } from './services/allocation-percentages-request.service';

const metaReducers = [clearState];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AllocationPercentagesKey, fromAllocationPercentages.reducer, { metaReducers }),
    EffectsModule.forFeature([AllocationPercentagesEffects])
  ],
  declarations: [],
  providers: [ AllocationPercentagesRequestService ]
})
export class AllocationPercentagesModule { }
