import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LogoutRoutePathUrl
} from '@gorila/core/router';

@Component({
  selector: 'partner-connection-error',
  templateUrl: 'partner-connection-error.html',
  styleUrls: ['partner-connection-error.scss']
})
export class PartnerConnectionErrorComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { providerName: string },
    private dialogRef: MatDialogRef<PartnerConnectionErrorComponent>,
  ) {

  }

  public close() {
    this.dialogRef.close();
  }

  public setLogout() {
    this.close();
  }
}
