import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES, StateType } from '../../store.model';
import { APIActions, clearAction, MetaData } from '../actions';
import { Product } from './product.model';

export type ProductAPIAction = FluxStandardAction<Product[], MetaData>;

@Injectable()
export class ProductAPIActions extends APIActions<Product> {
  protected readonly type = STATE_TYPES['PRODUCT'];

  @dispatch()
  public clear = (stateType: StateType): FluxStandardAction<Product[], MetaData> => clearAction(STATE_TYPES.PRODUCT, stateType)
}
