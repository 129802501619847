import { IProfitabilityType } from '@gorila-bot/gorila-front-models';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { BaseEpic } from '../../../base/epic.base';
import { AppState, StateType } from '../../../store.model';
import { ProfitabilityAPIAction } from '../actions/profitability-base.actions';
import { ProfitabilityDailyActions } from '../actions/profitability-daily.actions';
import { ProfitabilityMonthlyActions } from '../actions/profitability-monthly.actions';
import { ProfitabilityPnlActions } from '../actions/profitability-pnl.actions';
import { ProfitabilityYearlyActions } from '../actions/profitability-yearly.actions';
import { ProfitabilityRequestService } from '../profitability-request.service';
import { profitabilityFromServer } from '@gorila-bot/gorila-front-models';

export const mapPnl = (data, current) => {
  const out = [];
  let n = 0;
  // tslint:disable-next-line:forin
  for (const i in current) {
    const k = current[i].periodType;
    out[k] = data[n++][0].Profit;
  }
  return out;
};

type ProfitabilityActions = ProfitabilityPnlActions | ProfitabilityDailyActions | ProfitabilityMonthlyActions | ProfitabilityYearlyActions;

export abstract class ProfitabilityBaseAPIEpics extends BaseEpic {
  constructor(
    protected profitabilityRequestService: ProfitabilityRequestService
  ) {
    super();
  }

  public abstract getApiAction(): ProfitabilityActions;
  public abstract getActionsOfApiAction();

  public notAlreadyFetched = (stateType: StateType, state: AppState): boolean => !(
    state[stateType]
  )

  public createEpic(filterIfNotLogged = (..._) => true) {
    return combineEpics<Action, any, AppState, any>(
      this.createMultiEpic(filterIfNotLogged),
      this.createLoadProfitabilityEpic(filterIfNotLogged),
      this.createUpdateEpic(filterIfNotLogged)
    );
  }

  public createMultiEpic(ifNotLogged): Epic<ProfitabilityAPIAction, ProfitabilityAPIAction, AppState> {
    const actionType = this.getActionsOfApiAction().addMultipleProfitability;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const current: IProfitabilityType[] = action.meta.profitabilityType;
      return this.profitabilityRequestService.doMultiRequests(current).pipe(
        map(data => {
          return this.getApiAction().loadedMultiProfitability({
            profitabilityType: current,
            stateType: this.getStateType()
          }, data.map(dt => dt[0].Profit.map(profitabilityFromServer).filter(p => !!p)));
        }),
        catchError(response =>
          from([this.getApiAction().loadError({ profitabilityType: current[0], stateType: this.getStateType() }, {
            status: '' + response,
          })])
        )
      );
    }, false);
  }

  public createLoadProfitabilityEpic = (ifNotLogged: Function) => {
    const actionType = this.getActionsOfApiAction().addProfitability;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const current: IProfitabilityType = action.meta.profitabilityType;
      const cached = !action.meta.forceUpdate && this.getCached(store.value, current);
      const loadedMeta = { profitabilityType: current, stateType: this.getStateType() };
      if (cached) {
        return from([this.getApiAction().loadedProfitability(loadedMeta, cached)]);
      }
      return this.profitabilityRequestService.doRequest(current).pipe(
        map(data => {
          const profit = data[0].Profit;
          const newData = profit.map(profitabilityFromServer).filter(p => !!p);
          return this.getApiAction().loadedProfitability(loadedMeta, newData);
        }),
        catchError(response =>
          from([this.getApiAction().loadError(loadedMeta, {
            status: '' + response,
          })])
        )
      );
    }, false);
  }

  private getCached(state, current) {
    try {
      const key = current.periodType.toLowerCase();
      const cached = state['profitability'][key].items[current && current.assetClass];
      return (cached && cached.length > 0) ? cached : null;
    } catch (e) { console.warn(e); }
    return null;
  }

  public createUpdateEpic(ifNotLogged): Epic<ProfitabilityAPIAction, ProfitabilityAPIAction, AppState> {
    const actionType = this.getActionsOfApiAction().updateGroup;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      const current = action.meta.profitabilityType.pnl || action.meta.profitabilityType.profit;

      if (action.meta.profitabilityType.pnl) {
        return this.profitabilityRequestService.doMultiRequests(current).pipe(
          map(data => mapPnl(data, current)),
          filter(() => ifNotLogged(store.value)),
          map(data => {
            const meta = { profitabilityType: current, stateType: this.getStateType() };
            return this.getApiAction().loadedPnl(meta, data);
          }),
          catchError(response => from([this.getApiAction().loadError(
            { profitabilityType: current, stateType: this.getStateType() },
            { status: '' + response }
          )]))
        );
      }
      return this.profitabilityRequestService.doRequest(current).pipe(
        filter(() => ifNotLogged(store.value)),
        map(data => {
          return this.getApiAction().loadedProfitability({
            profitabilityType: current, stateType: this.getStateType()
          }, data.map(d => d.Profit[0]).map(profitabilityFromServer).filter(p => !!p));
        }),
        catchError(response => from([this.getApiAction().loadError(
          { profitabilityType: current, stateType: this.getStateType() },
          { status: '' + response }
        )]))
      );
    }, false);
  }
}
