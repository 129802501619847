import { AdvisorFundGuard } from './../../guards/advisor-fund.guard';
import { Route } from '@angular/router';

import { RouteData } from '../../route-data';
import { DashboardRoutePath } from './metadata';

export const DashboardRouteData: RouteData = {
  title: 'Resumo'
};

export const DashboardRoute: Route = {
  data: DashboardRouteData,
  path: DashboardRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
};
