import { NgReduxRouter, NgReduxRouterModule } from '@angular-redux/router';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { LogService } from '@gorila/shared/services/log.service';
import { SocketModule } from '@gorila/socket/module';
import { LOCALE_ID_PROVIDER } from '@gorila-bot/gorila-front-utils';
import { createLogger } from 'redux-logger';

import { BenchmarkRequestService } from './api/benchmark/benchmark-request.service';
import { BenchmarkAPIActions } from './api/benchmark/benchmark.actions';
import { BenchmarkAPIEpics } from './api/benchmark/benchmark.epics';
import { BlotterAPIActions } from './api/blotter/blotter.actions';
import { BlotterAPIEpics } from './api/blotter/blotter.epics';
import { PortfolioAPIActions } from './api/portfolio/portfolio.actions';
import { PortfolioAPIEpics } from './api/portfolio/portfolio.epics';
import { PositionEventListenerService } from './api/position/position-event-listener.service';
import { PositionRequestService } from './api/position/position-request.service';
import { PositionAPIActions } from './api/position/position.actions';
import { PositionAPIEpics } from './api/position/position.epics';
import { ProductAPIActions } from './api/product/product.actions';
import { ProductAPIEpics } from './api/product/product.epics';
import { ProfitabilityDailyActions } from './api/profitability/actions/profitability-daily.actions';
import { ProfitabilityMonthlyActions } from './api/profitability/actions/profitability-monthly.actions';
import { ProfitabilityPnlActions } from './api/profitability/actions/profitability-pnl.actions';
import { ProfitabilityYearlyActions } from './api/profitability/actions/profitability-yearly.actions';
import { ProfitabilityDailyEpics } from './api/profitability/epics/profitability-daily.epic';
import { ProfitabilityMonthlyEpics } from './api/profitability/epics/profitability-monthly.epic';
import { ProfitabilityPNLEpics } from './api/profitability/epics/profitability-pnl.epic';
import { ProfitabilityYearlyEpics } from './api/profitability/epics/profitability-yearly.epic';
import { ProfitabilityRequestService } from './api/profitability/profitability-request.service';
import { ReduxAPIService } from './api/redux-api.service';
import { SubProductAPIActions } from './api/subproduct/subproduct.actions';
import { SubProductAPIEpics } from './api/subproduct/subproduct.epics';
import { TradeAPIActions } from './api/trade/trade.actions';
import { TradeAPIEpics } from './api/trade/trade.epics';
import { Auth0UserService } from './api/user/auth0-user.service';
import { UserAPIActions } from './api/user/user.actions';
import { UserAPIEpics } from './api/user/user.epics';
import { RootEpics } from './store.epics';
import { AppState, INITIAL_STATE } from './store.model';
import { rootReducer } from './store.reducers';

@NgModule({
  imports: [CommonModule, NgReduxModule, NgReduxRouterModule.forRoot(), SocketModule],
  exports: [NgReduxModule, NgReduxRouterModule]
})
export class OldStoreModule {
  constructor(public store: NgRedux<AppState>, devTools: DevToolsExtension, ngReduxRouter: NgReduxRouter, rootEpics: RootEpics) {
    // Tell Redux about our reducers and epics. If the Redux DevTools
    // chrome extension is available in the browser, tell Redux about
    // it too.
    let middlewares = [];

    // Not enable redux-logger in prod env and if not wanted to.
    if (LogService.enabledReduxLog && !environment.production) {
      middlewares.push(createLogger());
    }

    if (rootEpics) {
      middlewares = [...middlewares, rootEpics.createEpics()];
    }

    if (OldStoreModule.store) {
      store.provideStore(OldStoreModule.store);
    } else {
      store.configureStore(rootReducer, INITIAL_STATE, middlewares, []);
      OldStoreModule.store = store;
    }

    if (ngReduxRouter) {
      ngReduxRouter.initialize((_) => 'routes');
    }

    if (rootEpics) {
      rootEpics.run();
    }
  }

  public static store: NgRedux<AppState> = null;

  private static mwp = {
    ngModule: OldStoreModule,
    providers: [
      RootEpics,
      ReduxAPIService,
      BenchmarkAPIActions,
      BenchmarkAPIEpics,
      BenchmarkRequestService,
      BlotterAPIActions,
      BlotterAPIEpics,
      PortfolioAPIActions,
      PortfolioAPIEpics,
      PositionAPIActions,
      PositionAPIEpics,
      PositionEventListenerService,
      PositionRequestService,
      ProductAPIActions,
      ProductAPIEpics,
      ProfitabilityDailyActions,
      ProfitabilityMonthlyActions,
      ProfitabilityYearlyActions,
      ProfitabilityPnlActions,
      ProfitabilityDailyEpics,
      ProfitabilityMonthlyEpics,
      ProfitabilityYearlyEpics,
      ProfitabilityPNLEpics,
      ProfitabilityRequestService,
      SubProductAPIActions,
      SubProductAPIEpics,
      TradeAPIActions,
      TradeAPIEpics,
      UserAPIActions,
      UserAPIEpics,
      Auth0UserService
    ]
  };

  public static forChild(): ModuleWithProviders {
    return OldStoreModule.mwp;
  }

  public static forRoot(): ModuleWithProviders {
    return OldStoreModule.mwp;
  }

  public static reset = () => (OldStoreModule.store = null);
}

@NgModule({
  providers: [
    LOCALE_ID_PROVIDER,
    DecimalPipe,
    PercentPipe
  ]
})
export class StoreViewModule {}
