import { createSelector } from '@ngrx/store';

import { PROVIDER_STATE, UserAccountModel, UserAccountList } from './models/user-account.model';
import { initialState, Key, State } from './user-account.state';

const erroredStates = [
  PROVIDER_STATE.error,
  PROVIDER_STATE.error_authentication
];
const erroredAccount = (ua: UserAccountModel) => erroredStates.indexOf(ua.providerState) !== -1;

export const selectFeature = state => state[Key] || {};

export const selectFeatureAccounts = createSelector(
  selectFeature,
  (state: State) => state.accounts || initialState.accounts
);

export const selectProviderById = (providerId: number|string)  => createSelector(
  selectFeature,
  (state: State): UserAccountModel => state.accounts.find(account => account.providerId === providerId)
);

export const selectFeatureErroreds = createSelector(
  selectFeatureAccounts,
  (accounts: UserAccountList) => accounts.filter(erroredAccount)
);

export const selectFeatureAuthenticationError = createSelector(
  selectFeatureAccounts,
  (accounts: UserAccountList) => accounts.filter((ua: UserAccountModel) => ua.providerState === PROVIDER_STATE.error_authentication)
);

export const selectFeaturePayloadError = createSelector(
  selectFeatureAccounts,
  (accounts: UserAccountList) => accounts.filter((ua: UserAccountModel) => ua.providerState === PROVIDER_STATE.error_payload)
);

export const selectFeatureServiceUnavailableError = createSelector(
  selectFeatureAccounts,
  (accounts: UserAccountList) => accounts.filter((ua: UserAccountModel) => ua.providerState === PROVIDER_STATE.error_service)
);

export const selectFeatureUnexpectedError = createSelector(
  selectFeatureAccounts,
  (accounts: UserAccountList) => accounts.filter((ua: UserAccountModel) => ua.providerState === PROVIDER_STATE.error_unexpected)
);
