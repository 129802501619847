import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GorListItemTitleComponent } from './gro-card-field-title.component';

@NgModule({
  declarations: [GorListItemTitleComponent],
  imports: [
    CommonModule
  ],
  exports: [GorListItemTitleComponent]
})
export class GroCardFieldTitleModule { }
