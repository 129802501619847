import { Injectable } from '@angular/core';
import { ApiService } from '@gorila-bot/gorila-base';
import { AppConstants } from '@gorila/constants';
import { FundIdType } from '@gorila-bot/user-data-store';
import { Store } from '@ngrx/store';
import * as moment from 'moment-business-days';

import { RequestServiceBase } from '../../base/request-service.base';
import { PositionType } from './position-type.model';

@Injectable()
// WARNING if you are changing something in that class, problably the problem is you
// The reason is why we will deprecate the module that holds this service!
// Use root-store service instead.
export class PositionRequestService extends RequestServiceBase<PositionType> {
  public readonly dateFormats = [
    AppConstants.Format.Date.American,
    AppConstants.Format.Date.Brazilian
  ];

  constructor(
    protected apiService: ApiService,
    protected store: Store<any>
  ) {
    super(apiService, store);
  }

  public getParams(params: PositionType): PositionType {
    if (!!params.minDate) {
      params.minDate = this.getDate(params.minDate);
    }
    if (!!params.maxDate) {
      params.maxDate = this.getDate(params.maxDate);
    }
    return params;
  }

  public getBaseUrl(fundId: FundIdType): string {
    return `portfolio/${fundId}/positions`;
  }

  private getDate(date: string) {
    let dt;
    if (date === '' || date === null || !(dt = moment.utc(date, this.dateFormats)).isValid()) { return ''; }
    return dt.format(AppConstants.Format.Date.American);
  }

  public getHeader = () => ({timeout: 60000});
}
