import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailBreak'
})
export class EmailBreakPipe implements PipeTransform {

  public transform(email: string): any {
    if (!email || email.indexOf('@') === -1) {
      return '';
    }
    if (email.length <= 20) {
      return email;
    }
    const index = email.indexOf('@');
    return email.substring(0, index) + '<br />' + email.substring(index, email.length);
  }

}
