import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { clearState } from '@gorila/root-store/logout/logout.state';
import { clearDashboardState } from '@gorila/root-store/pages/dashboard/dashboard.state';
import { clearWalletState } from '@gorila/root-store/pages/wallet/wallet.state';
import { TradeEffects } from '@gorila/root-store/trade/src/effects/trade.effects';
import * as fromTrade from '@gorila/root-store/trade/src/reducers/trade.reducer';
import { Key } from '@gorila/root-store/trade/src/trade.state';
import { OperationTradeService } from './services/operation-trade.service';

const metaReducers = [clearDashboardState, clearWalletState, clearState];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(Key, fromTrade.reducer, { metaReducers }),
    EffectsModule.forFeature([TradeEffects])
  ],
  providers: [ OperationTradeService ],
  declarations: []
})
export class TradeReduxModule { }
