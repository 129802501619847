import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { ManagerList } from '@gorila-bot/advisor/models';
import { BillingFacade, getSubscriptionStatus, PremiumFacade, SubscriptionStatus } from '@gorila-bot/billing-store';
import { ConnectionsFacade } from '@gorila-bot/connections-store';
import { FilterSelectors } from '@gorila-bot/filter-container/store';
import { AnalyticsService, isValidFundId } from '@gorila-bot/gorila-front-utils';
import { PersonalFacade } from '@gorila-bot/personal-store';
import { PremiumService } from '@gorila-bot/premium';
import { UserDataSelectors } from '@gorila-bot/user-data-store';
import { WhiteLabelService } from '@gorila-bot/white-label';
import { RouterWatcherService } from '@gorila/core';
import { NgrxAPIService } from '@gorila/root-store/ngrx-api.service';
import { PositionActions } from '@gorila/root-store/position';
import { State } from '@gorila/root-store/state';
import { TradeActions } from '@gorila/root-store/trade';
import { PROVIDER_STATE, UserAccountActions, UserAccountList, UserAccountModel } from '@gorila/root-store/user-account';
import {
  selectFeatureAccounts,
  selectFeatureAuthenticationError,
  selectFeaturePayloadError,
  selectFeatureServiceUnavailableError,
  selectFeatureUnexpectedError,
} from '@gorila/root-store/user-account/src/user-account.selector';
import { UiSimpleModalComponent } from '@gorila/ui/ui-modal';
import { getAppMetadata } from '@gorila/utils/token';
import { PopupConfirmComponent } from '@gorilainvest/ui-toolkit/popup-confirm';
import { DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { equals, path, without } from 'ramda';
import { BehaviorSubject, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { Auth0Service } from '../auth/services/auth0.service';
import { B3, CEI } from '../user/user-account/components/user-account.data';
import { URL_OAUTH_TOKEN_KEY, URL_OAUTH_TOKEN_KEY_ERROR } from './../auth/models/storage-keys.model';
import { SharedModalsUserAccountsService } from './../user/user-account/components/modals/shared-modals.service';
import * as DialogModel from './app-shell-dialog.model';
import { SelectedDate } from './app-shell-filters.service';
import { PartnerConnectionEnabledComponent } from './modals/partner-connection-enabled/partner-connection-enabled.component';
import { PartnerConnectionErrorComponent } from './modals/partner-connection-error/partner-connection-error.component';

@Component({
  selector: 'app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  providers: [
    SharedModalsUserAccountsService,
    PremiumService,
    ConnectionsFacade
  ],
})
export class AppShellComponent implements OnInit, OnDestroy {
  public static manualLogin: boolean;
  public accounts: UserAccountList = [];
  public isLoading = false;
  public firstLoad = true;
  private providersIdDisabled = [];
  private currentUrl = '';
  private errorSelectors: MemoizedSelector<any, UserAccountList, DefaultProjectorFn<UserAccountList>>[];
  private filterDate$: BehaviorSubject<SelectedDate> = new BehaviorSubject(null);
  public filtered = false;
  public hideFilter = false;
  public clientList$: BehaviorSubject<ManagerList> = new BehaviorSubject([]);
  public currentFilter$: BehaviorSubject<{ [s: string]: any }> = new BehaviorSubject([]);

  constructor(
    private billingFacade: BillingFacade,
    private connectionsFacade: ConnectionsFacade,
    private dialog: MatDialog,
    private translate: TranslateService,
    private ngrxApi: NgrxAPIService,
    private store$: Store<any>,
    private router: Router,
    private wlService: WhiteLabelService,
    private jwtHelper: JwtHelperService,
    private sharedModalsUserAccountsService: SharedModalsUserAccountsService,
    private auth0: Auth0Service,
    private personalFacade: PersonalFacade,
    private premiumFacade: PremiumFacade,
    private routerWatcherService: RouterWatcherService
  ) { }

  public ngOnInit() {
    this.loadUserAccountData();
    this.errorSelectors = [
      selectFeatureAuthenticationError,
      selectFeaturePayloadError,
      selectFeatureServiceUnavailableError,
      selectFeatureUnexpectedError,
    ];
    this.filterDateChange();
    this.listenDisplayFilter();
    this.auth0.refreshToken$.pipe(untilDestroyed(this), filter(_ => !!_)).subscribe(() => this.loadData());
    this.checkAccountConnection();
    this.ngrxApi.init();
    this.listenUserAccountErrors();
    this.listenUserAccountFirstLogin();
    this.listenUserData();
    this.loadPremiumData();
    this.checkDissociatingB2BFromB2B2C();
    setTimeout(() => this.listenSubscriptionStatus()); // timeout used to avoid ExpressionChangedAfterItHasBeenCheckedError
  }

  // filter >>>>>
  private filterDateChange() {
    this.store$.pipe(
      select(FilterSelectors.getOneFilter('datePicker', false)),
      distinctUntilChanged((a, b) => equals(a, b)),
      filter(data => !!data),
      take(1)
    ).subscribe(({ startDate, endDate }) => {
      this.filterDate$.next({ minDate: startDate, maxDate: endDate });
      this.loadData();
    });
  }
  // <<<<< filter

  private listenDisplayFilter = () => this.routerWatcherService.watchData().pipe(
    untilDestroyed(this),
    map(data => data.some(dt => dt.hideFilter === true))
  ).subscribe((hideFilter: boolean) => this.hideFilter = hideFilter)

  public ngOnDestroy() { }

  public checkGorilaProSubscriptionStatus(status: SubscriptionStatus) {
    if (!environment.features.manager.blockUiForUserInDebt || [SubscriptionStatus.DEBIT].indexOf(status) === -1) {
      return;
    }
    const dialogRef = this.dialog.open(
      UiSimpleModalComponent,
      DialogModel.gorilaProSubscriptionDebit(this.translate, this.wlService)
    );
    dialogRef.afterClosed().subscribe(() =>
      this.router.navigate(['app', 'area-do-usuario', 'pagamento'], {
        queryParams: { form: true },
      })
    );
  }

  private fetchSubscription() {
    this.billingFacade.dispatch(
      getSubscriptionStatus({ data: { forceUpdate: true } })
    );
  }

  private listenRouterEvents() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationCancel ||
            event instanceof NavigationEnd ||
            event instanceof NavigationError
        )
      )
      .subscribe(
        (event) => {
          // page with route 'cadastrar-preco' has a guard to cancel navigation to not navigate to other pages
          // before saving the data inputed by user, so when user is in this page, it can not change to load
          // state to avoid lose these data.
          if (!this.currentUrl.includes('cadastrar-preco')) {
            this.isLoading = event instanceof NavigationStart;
          }
          if (event instanceof NavigationEnd) {
            this.currentUrl = path<string>(['url'], event) || '';
          }
        }
      );
  }

  private listenSubscriptionStatus() {
    this.billingFacade.billingSubscriptionStatus$
      .pipe(
        filter((status: SubscriptionStatus) => !!status),
        take(1)
      )
      .subscribe((status: SubscriptionStatus) =>
        this.checkGorilaProSubscriptionStatus(status)
      );
  }

  private listenUserAccountFirstLogin() {
    this.store$.pipe(
      untilDestroyed(this),
      select(selectFeatureAccounts),
      filter((accounts: UserAccountList) => (accounts || []).length > 0)
    ).subscribe((accounts: UserAccountList) => {
      // update providersIdDisabled array
      accounts.forEach(account => {
        if (account.providerState === 'DISABLED' && !this.providersIdDisabled.includes(account.providerId)) {
          this.providersIdDisabled.push(account.providerId);
        }

        if (account.providerState === 'CALCULATING_WALLET') {
          this.ngrxApi.muteSocket();
        }
      });

      accounts
        .filter(account => account.providerState === 'UPDATED' && this.providersIdDisabled.includes(account.providerId))
        .forEach(account => {
          if (+account.providerId === B3.id) {
            this.sharedModalsUserAccountsService.openB3Connected();
          }
          this.store$.dispatch(
            new TradeActions.LoadTrade({ forceUpdate: true, params: { } })
          );
          // send BI event
          AnalyticsService.setEvent('broker_connected', {
            broker: account.providerName
          });
          // remove id of displayed provider
          this.providersIdDisabled = without([account.providerId], this.providersIdDisabled);
        });
    });
  }

  private handleDisplayedErrors(accounts: UserAccountList): UserAccountModel[] {
    // get ids of displayed account error
    const lastAccountsId = this.accounts.map(
      (account: UserAccountModel) => account.providerId
    );
    // remove already displayed error
    const newError = accounts.filter(
      (account: UserAccountModel) =>
        ![B3.id].includes(+account.providerId)
        && !lastAccountsId.includes(account.providerId)
        && !account.errorDescription
    );
    this.accounts = accounts;
    return newError;
  }

  private listenUserAccountErrors() {
    this.errorSelectors.forEach(selector => {
      this.store$
        .pipe(
          untilDestroyed(this),
          select(selector),
          filter((accounts: UserAccountList) => (accounts || []).length > 0),
          distinctUntilChanged((a, b) => equals(a, b))
        )
        .subscribe((accounts: UserAccountList) => {
          const newAccountError = this.handleDisplayedErrors(accounts);
          this.openIntegrationAlert(newAccountError);
        });
    });
  }

  private listenUserData() {
    this.store$.pipe(
      untilDestroyed(this),
      filter((state: State) => isValidFundId(path(['user-data', 'currentFundId'], state))),
      select(UserDataSelectors.selectAdvisorMode),
    ).subscribe((advisorMode: boolean) => {
      if (advisorMode) {
        this.fetchSubscription();
        return;
      }

      this.loadPreferencesData();
      this.listenRouterEvents();
    });
  }
  private loadUserAccountData() {
    this.store$.dispatch(new UserAccountActions.Load());
  }

  private loadData() {
    const filterDateVal = this.filterDate$.getValue();
    if (!filterDateVal) {
      return;
    }

    const { minDate, maxDate } = filterDateVal;
    this.store$.dispatch(new TradeActions.LoadTrade({ params: { maxDate } }));
    this.store$.dispatch(new PositionActions.PositionLoad({ minDate, maxDate }));
  }

  private loadPreferencesData() {
    this.personalFacade.loadPreferencesData();
  }

  public openB3IntegrationAlert(provider: UserAccountModel) {
    if (provider.providerState = PROVIDER_STATE.error_authorization) {
      this.sharedModalsUserAccountsService.openB3ConnectError();
    }
  }

  private loadPremiumData() {
    this.premiumFacade.load();
    this.premiumFacade.loadPremiumData();
  }

  private openIntegrationAlert(providers: UserAccountModel[]) {
    providers.forEach((provider) => {
      if (provider.providerId === CEI.id && provider.signUpFields.length === 1) {
        this.openB3IntegrationAlert(provider);
        return;
      }

      const dialog = this.dialog.open(
        PopupConfirmComponent,
        DialogModel.errorIntegration(this.translate, provider)
      );
      if (!dialog) {
        console.warn('Dialog for error integration is undefined');
        return;
      }
      dialog
        .afterClosed()
        .pipe(
          take(1),
          filter(
            (result) =>
              !!result && result !== DialogModel.INTEGRATION_ERROR_ACTIONS.CANCEL
          )
        )
        .subscribe((response) => {
          const data = this.accounts.find(
            (a) => a.providerName === provider.providerName
          );
          if (response === DialogModel.INTEGRATION_ERROR_ACTIONS.DISCONNECT) {
            this.store$.dispatch(
              new UserAccountActions.Disconnect(
                data.providerId,
                data.providerName,
                true
              )
            );
          } else if (response === DialogModel.INTEGRATION_ERROR_ACTIONS.UPDATE) {
            const queryParams = { provider_id: data.providerId };
            this.router.navigate(['app', 'area-do-usuario', 'contas'], {
              queryParams,
            });
          }
        });
    });
  }

  /**
   * update the b2b2c user's token after he disconnects b2b from his account.
   * @gorila-bot/connections-shell
   */
  private checkDissociatingB2BFromB2B2C(): void {
    this.connectionsFacade.getDissociateAdvisorSuccess$.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
      filter(advisorInfos => !!advisorInfos),
    ).subscribe(() => {
      const dissociateAdvisorAndRefreshTimeout = (3 * 1000);
      timer(dissociateAdvisorAndRefreshTimeout).pipe(
        untilDestroyed(this)
      ).subscribe((token) => {
        this.auth0.refreshToken( () => { } );
      });
    });
  }

  private checkAccountConnection() {
    const TOKEN_ERROR = localStorage.getItem(URL_OAUTH_TOKEN_KEY_ERROR);

    if (!!TOKEN_ERROR) {
      const TOKEN_INFO = this.jwtHelper.decodeToken(TOKEN_ERROR);
      const appMetadata = getAppMetadata(TOKEN_INFO);
      localStorage.removeItem(URL_OAUTH_TOKEN_KEY_ERROR);
      this.dialog.open(PartnerConnectionErrorComponent, {
        height: '260px',
        width: '450px',
        data: {
          providerName: appMetadata.providerName,
        },
      });
      return;
    }
    const TOKEN = localStorage.getItem(URL_OAUTH_TOKEN_KEY);
    const forceLogout = environment.features.user.account.connect.forceLogout;
    let providerName = '';
    if (TOKEN) {
      const TOKEN_INFO = this.jwtHelper.decodeToken(TOKEN);
      const appMetadata = getAppMetadata(TOKEN_INFO);
      providerName = appMetadata.providerName;
      localStorage.removeItem(URL_OAUTH_TOKEN_KEY);
      this.dialog
        .open(PartnerConnectionEnabledComponent, {
          height: '300px',
          width: '600px',
          disableClose: true,
          data: {
            providerName,
            forceLogout,
          },
        })
        .afterClosed()
        .pipe(
          take(1),
          filter(() => !forceLogout)
        )
        .subscribe(() => {
          this.auth0.refreshToken(() =>
            this.openRemovePositionsConfirmation(providerName)
          );
        });
    }

    if (SharedModalsUserAccountsService.accountConnectionStarted) {
      this.openRemovePositionsConfirmation(providerName);
      SharedModalsUserAccountsService.accountConnectionStarted = null;
    }
    if (sessionStorage.getItem('connection_started') === 'Genial') {
      sessionStorage.removeItem('connection_started');
      SharedModalsUserAccountsService.accountConnectionStarted = 'Genial';
    }
  }

  private openRemovePositionsConfirmation(providerName: string) {
    this.store$
      .pipe(
        select(selectFeatureAccounts),
        filter((accounts: UserAccountList) => (accounts || []).length > 0),
        take(1)
      )
      .subscribe((accounts: UserAccountList) => {
        const ACCOUNT = accounts.find(account => account.providerName === providerName);
        if (ACCOUNT) {
          // send BI event
          AnalyticsService.setEvent('broker_connected', {
            broker: ACCOUNT.providerName
          });
        }
      });
  }
}
