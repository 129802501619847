import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';
import { FluxStandardAction } from 'flux-standard-action';

import { STATE_TYPES, StateType } from '../../store.model';
import { APIActions, clearAction, MetaData } from '../actions';
import { SubProduct } from './subproduct.model';

export type SubProductAPIAction = FluxStandardAction<SubProduct[], MetaData>;

@Injectable()
export class SubProductAPIActions extends APIActions<SubProduct> {
  protected readonly type = STATE_TYPES['SUBPRODUCT'];

  @dispatch()
  public clear = (stateType: StateType): FluxStandardAction<SubProduct[], MetaData> => clearAction(STATE_TYPES.SUBPRODUCT, stateType)
}
