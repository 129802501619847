import {
  PositionBehavioralState,
  PositionColumns,
  AssetBehavioralState,
  ProductTypeBehavioralState,
  ProductTypeColumns,
  PortfolioBehavioralState
} from '@gorila/pages/store/wallet';
import { Parity } from '@gorila/shared';

export interface WalletState {
  components: {
    position: PositionBehavioralState;
    product: AssetBehavioralState;
    subproduct: ProductTypeBehavioralState;
    portfolio: PortfolioBehavioralState;
  };
}

export const INITIAL_STATE: WalletState = {
  components: {
    position: {
      expandable: Parity.MANY,
      selectable: Parity.NONE,
      showActions: true,
      descriptionLimit: 42,
      columnsToShow: [
        PositionColumns.Broker,
        PositionColumns.PriceToday,
        PositionColumns.ProfitMonthly,
        PositionColumns.Quantity,
        PositionColumns.Total
      ]
    },
    product: {
      expandable: Parity.MANY,
      selectable: Parity.NONE,
      animatePercentage: {
        text: true,
        width: true
      }
    },
    subproduct: {
      columnsToShow: [
        ProductTypeColumns.ProfitDaily,
        ProductTypeColumns.ProfitMonthly,
        ProductTypeColumns.Total
      ],
      expandable: Parity.MANY,
      selectable: Parity.NONE,
      waitAnimationOnClose: 250
    },
    portfolio: {
      multiexpandable: true,
      multiselectable: false
    }
  }
};
