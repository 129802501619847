import { objectToQueryParams } from '@gorila-bot/gorila-front-utils/dist/queryParams';

export class WindowService {
  public static open(url: string, options: { omitUtm: boolean } = { omitUtm: false }) {
    const storage = localStorage || {};
    let _url = url;
    if (!options.omitUtm && storage['utm']) {
      _url += objectToQueryParams(JSON.parse(storage['utm']));
    }
    window.open(_url);
  }
}
