import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '@gorila-bot/gorila-base';
import { ITradeServer } from '@gorila-bot/gorila-front-models';
import { FundIdType, FundRequestService } from '@gorila-bot/user-data-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class BookerService extends FundRequestService {
  private nodeUrl = environment.serviceBaseUrl;

  constructor(
    private apiService: ApiService,
    protected store: Store<any>
  ) {
    super(store);
  }

  public save(body: ITradeServer): Observable<any> {
    // tangible products has a different end point as it behavior differs from others products
    const isTangible = body.ProductSubTypeName.indexOf('MOVABLE') !== -1 || body.ProductSubTypeName.indexOf('IMMOVABLE') !== -1;
    const url = isTangible ? this.getTangibleUrl() : this.getBookerUrl();
    return this.apiService.doPost(url, {}, body);
  }

  // TODO - create a new interface for trade data sent to back
  //
  // Sending an ITradeServer causes problems on backend due to fields which
  // are filled with 0 being recognized as data
  public saveList(body: ITradeServer[] | any[]): Observable<any> {
    const url = this.getBookerUrl({}, {id: '', list: true});
    return this.apiService.doPost(url, {}, body);
  }

  public update(fundId, body: ITradeServer) {
    const url = this.getBookerUrl({fundId});
    return this.apiService.doUpdate(url, body);
  }

  public getTrades(forceUpdate?: boolean, minDate?: string, maxDate?: string): Observable<any> {
    return this.getFundId().pipe(concatMap(fundId => {
      const url = this.getBookerUrl({ fundId } , { id: '', list: false, maxDate, minDate});
      return this.apiService.getData(url, {}, true, null, forceUpdate);
    }));
  }

  public getTrade(id: string): Observable<any> {
    return this.getFundId().pipe(concatMap(fundId => {
      const url = this.getBookerUrl({ fundId }, {id});
      return this.apiService.getData(url, {}, true, null, true);
    }));
  }

  public dropTrade(id: string) {
    // The subscribe is needed to dropData work, who calls dropData doesn't do some subscribe
    this.getFundId().pipe(concatMap(fundId => {
      const url = this.getBookerUrl({ fundId }, {id});
      return this.apiService.dropData(url);
    })).subscribe(() => {});
  }

  public dropTrades(ids: string[]) {
    this.getFundId().pipe(concatMap(fundId => {
      const url = this.getBookerUrl({ fundId }, {id: '', list: true });
      return this.apiService.dropList(url, {}, ids);
    })).subscribe(() => {});
  }

  private getBookerUrl(
    fundIds?: { fundId?: FundIdType, fundIdRequester?: FundIdType },
    options: { id?: string, list?: boolean, maxDate?: string, minDate?: string } = {}) {
    let url = this.nodeUrl + 'booker';

    if (options.id) {
      url += `/${options.id}`;
    }

    if (options.list) {
      url += `/list`;
    }

    if (!fundIds) {
      return url;
    }

    if (fundIds.fundId || fundIds.fundIdRequester) {
      let paramChar = '?';
      const addParam = (param: string, value: FundIdType) => { url += `${paramChar}${param}=${value}`; };

      if (fundIds.fundId) {
        addParam('fundId', fundIds.fundId);
        paramChar = '&';
      }

      if (fundIds.fundIdRequester) {
        addParam('fundIdRequester', fundIds.fundIdRequester);
        paramChar = '&';
      }

      if (options.maxDate) {
        addParam('maxDate', options.maxDate);
      }

      if (options.minDate) {
        addParam('minDate', options.minDate);
      }
    }

    return url;
  }

  private getTangibleUrl() {
    return this.nodeUrl + 'tangible';
  }
}
