import { Injectable } from '@angular/core';
import {
  mapAllocationPercentageFromServer,
  mapMultiAllocationPercentageFromServer,
} from '@gorila-bot/gorila-front-models';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { from } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  AllocationPercentagesActionTypes,
  AllocationPercentagesLoad,
  AllocationPercentagesReportLoad,
  _AllocationPercentagesLoad,
  _AllocationPercentagesReportLoad
} from '../actions/allocation-percentages.actions';
import { AllocationPercentagesRequestService } from '../services/allocation-percentages-request.service';

/**
 * Describes effects used to manipulate allocation percentage data in store.
 */
@Injectable()
export class AllocationPercentagesEffects {

  @Effect()
  public loadEffect$ = this.actions$.pipe(
    ofType(AllocationPercentagesActionTypes.AllocationPercentagesLoadAction),
    switchMap((action: AllocationPercentagesLoad) => {
      return this.requestService.doRequest(action.payload || {}).pipe(
        map(res => mapAllocationPercentageFromServer(res)),
        map(res => new _AllocationPercentagesLoad(res)),
        catchError(err => from([new _AllocationPercentagesLoad({}, !!err)]))
      ); }
    ),
    filter(data => !!data),
  );

  @Effect()
  public loadReportEffect$ = this.actions$.pipe(
    ofType(AllocationPercentagesActionTypes.AllocationPercentagesReportLoad),
    switchMap((action: AllocationPercentagesReportLoad) =>
      this.requestService.doMultiRequests(action.payload).pipe(
        map(res => {
          const out = [];
          res.forEach(allocationObj => out.push(allocationObj[Object.keys(allocationObj)[0]]));
          return out;
        }),
        map(res => new _AllocationPercentagesReportLoad(mapMultiAllocationPercentageFromServer(res))),
        catchError(err => from(
          [new _AllocationPercentagesReportLoad({ currentGrossEquity: 0, percentages: [] }, !!err)]))
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private requestService: AllocationPercentagesRequestService,
    private store$: Store<any>
  ) { }
}
