// tslint:disable deprecation

/**
 * Interface for objects that represents the source of a trade.
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 * @interface
 */
export interface TradeSource {
  /**
   * The value that identify which source is.
   *
   * @type {TradeSourceEnum}
   */
  value: TradeSourceEnum;

  /**
   * The name of the source.
   *
   * @type {string}
   */
  name: string;

  /**
   * The text that represents the source (the first letter).
   *
   * @type {string}
   */
  text: string;
}

/**
 * Enum for source values.
 *
 * @readonly
 * @enum {number}
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export enum TradeSourceEnum {
  AUTOMATIC = 'A',
  BROKER = 'B',
  EXCHANGE = 'E',
  MANUAL = 'M',
  UNKNOWN = 'U'
}

// String value name for automatic inserted trades
const Automatic = 'AUTOMATIC';
// String value name for broker inserted trades via API
const Broker = 'BROKER';
// String value name for exchange inserted trades via API
const Exchange = 'EXCHANGE';
// String value name for manual inserted trades
const Manual = 'MANUAL';
// String value name for unknown inserted trades
const Unknown = 'UNKNOWN';

/**
 * Get a TradeSource object based on a TradeSourceEnum input.
 *
 * @param  {TradeSourceEnum} tradeSourceEnum=TradeSourceEnum.AUTOMATIC Says if
 * the trade was inserted automatic or manual.
 * @return {TradeSource} A object representation of a trade source.
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export function getTradeSourceObject(tradeSourceEnum = TradeSourceEnum.UNKNOWN): TradeSource {
  const value = tradeSourceEnum;

  switch (tradeSourceEnum) {
    case TradeSourceEnum.AUTOMATIC: {
      return {
        name: Automatic,
        text: Automatic[0],
        value
      };
    }
    case TradeSourceEnum.BROKER: {
      return {
        name: Broker,
        text: Broker[0],
        value
      };
    }
    case TradeSourceEnum.EXCHANGE: {
      return {
        name: Exchange,
        text: Exchange[0],
        value
      };
    }
    case TradeSourceEnum.MANUAL: {
      return {
        name: Manual,
        text: Manual[0],
        value
      };
    }
  }

  return {
    name: Unknown,
    text: Unknown[0],
    value: TradeSourceEnum.UNKNOWN
  };
}

/**
 * Get a TradeSource name based on a TradeSourceEnum input.
 *
 * @param  {TradeSourceEnum} tradeSourceEnum=TradeSourceEnum.AUTOMATIC Says if
 * the trade was inserted automatic or manual.
 * @return {string} The name of the respective trade source.
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export function getTradeSourceName(tradeSourceEnum = TradeSourceEnum.MANUAL): string {
  return tradeSourceEnum === TradeSourceEnum.AUTOMATIC && Automatic || Manual;
}
