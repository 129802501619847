import { INITIAL_STATE as WALLET_INITIAL_STATE, WalletState } from './app/carteira/model';
import { PROFIT_INITIAL_STATE, ProfitabilityState, REPORT_INITIAL_STATE, ReportState } from './app/ferramentas';

export const Key = 'routes';


export interface State {
  router: string;
  app: {
    carteira: WalletState;
    ferramentas: {
      rentabilidade: ProfitabilityState;
      relatorio: ReportState;
    };
  };
}

export const initialState: State = {
  router: '',
  app: {
    carteira: WALLET_INITIAL_STATE,
    ferramentas: {
      rentabilidade: PROFIT_INITIAL_STATE,
      relatorio: REPORT_INITIAL_STATE
    }
  }
};
