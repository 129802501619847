import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { BreadcrumbItem } from '../../model/breadcrumb-item.interface';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnChanges {

  @Input() public items: BreadcrumbItem[] = [];
  @Input() public title = '';
  @Output() public delete: EventEmitter<number> = new EventEmitter<number>();
  @Output() public clicked: EventEmitter<number> = new EventEmitter<number>();

  @Input() public displayFn = (s: string) => s;

  constructor() { }


  public deleteItem(index: number) {
    this.delete.emit(index);
  }

  public clickedItem(index: number) {
    if (!this.items[index] || !this.items[index].clickable) {
      return;
    }
    this.clicked.emit(index);
  }

  public ngOnChanges(data: SimpleChanges) {
    if (data['items']) {
      this.items = this.items.map(item => {
        item.class = item.class ? item.class : 'breadcrumb-item-color';
        if (item.clickable) {
          item.class += ' clickable';
        }
        return item;
      });
    }
  }
}
