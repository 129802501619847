import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'partner-actions',
  templateUrl: './partner-actions.component.html',
  styleUrls: ['./partner-actions.component.scss']
})
export class PartnerActionsComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { providerName: string },
    private dialogRef: MatDialogRef<PartnerActionsComponent>
  ) { }

  public close() {
    this.dialogRef.close();
  }
}
