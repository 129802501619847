import { Injectable, Inject, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

import { ApiService  } from '@gorila-bot/gorila-base';
import { SERVICE_BASE_URL } from '@gorila-bot/gorila-base2';

@Injectable()
export class TipsService {

  constructor(
    private apiService: ApiService,
    @Inject(SERVICE_BASE_URL) private baseUrl: string,
  ) { }

  public getResearchHouseUrl(): Observable<{ url: string }> {
    const url = this.baseUrl + 'research-house/authenticate';
    return this.apiService.getData(url);
  }

}
