import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProfitabilityStoreModule } from '@gorila-bot/profitability-store';
import { clearState } from '@gorila/root-store/logout/logout.state';
import { clearDashboardState } from '@gorila/root-store/pages/dashboard/dashboard.state';
import { clearWalletState } from '@gorila/root-store/pages/wallet/wallet.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PositionEffects } from './effects/position.effects';
import { Key as PositionKey } from './position.state';
import * as fromPosition from './reducers/position.reducer';
import { PositionRequestService } from './services/position-request.service';

const metaReducers = [clearDashboardState, clearWalletState, clearState];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PositionKey, fromPosition.reducer, { metaReducers }),
    EffectsModule.forFeature([PositionEffects]),
    ProfitabilityStoreModule
  ],
  providers: [PositionRequestService],
  declarations: []
})
export class PositionReduxModule { }
