import { Injectable } from '@angular/core';
import { SocketEventService } from '@gorila-bot/gorila-base';

import { STATE_TYPES } from '../../store.model';
import {
  ProfitabilityDailyActions,
  ProfitabilityMonthlyActions,
  ProfitabilityPnlActions,
  ProfitabilityYearlyActions
} from '../profitability/actions';
import { PositionAPIActions } from './position.actions';

@Injectable()
export class PositionEventListenerService {

  private started = false;
  public constructor(
    private socketEventService: SocketEventService,
    private positionAPIActions: PositionAPIActions,
    private profitabilityPnlActions: ProfitabilityPnlActions,
    private profitabilityDailyActions: ProfitabilityDailyActions,
    private profitabilityMonthlyActions: ProfitabilityMonthlyActions,
    private profitabilityYearlyActions: ProfitabilityYearlyActions
  ) { }

  public listenEvents() {
    try {
      if (!!this.started) {
        return;
      }
      this.started = true;
      this.socketEventService.getObserver('POSITION_UPDATE').subscribe(
        (data) => { this.positionUpdate(data); }
      );
      this.socketEventService.getObserver('POSITION_REMOVE').subscribe(
        (data) => { this.positionRemove(data); }
      );
    } catch (e) { console.warn(e); }
  }

  private positionUpdate(data: any) {
    try {
      if (data == null || data.Status !== 'FINISH') { return; }
      this.positionAPIActions.dispatchUpdate(STATE_TYPES.POSITION, data.Position || data);
      if (!!data.Position && !!data.Position.AssetClass) {
        this.profitabilityPnlActions.doUpdateGroup(data.Position.AssetClass);
        this.profitabilityDailyActions.doUpdateGroup(data.Position.AssetClass);
        this.profitabilityMonthlyActions.doUpdateGroup(data.Position.AssetClass);
        this.profitabilityYearlyActions.doUpdateGroup(data.Position.AssetClass);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  private positionRemove(data: any) {
    try {
      if (data == null || data.Status !== 'FINISH') { return; }
      if (typeof data.Position === 'undefined' || !data.Position.Key) {
        console.warn(data, 'variable Key doesn\'t exists', data);
        return;
      }
      this.positionAPIActions.dispatchRemove(STATE_TYPES.POSITION, data.Position.Key);
    } catch (e) {
      console.warn(e);
    }
  }

}
