import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import * as Colors from '@gorilainvest/styles/js/Colors';

export const AppConstants = {
  HttpDefaultTimeout: 45000,
  BenchmarkSettings: [
    { color: Colors.default.DarkGray, cssClass: 'favorite', width: 1.1 },
    { color: Colors.default.ExtraDarkGray, cssClass: 'dashed', dash: '2,2', width: 2 },
    { color: Colors.default.Black, cssClass: 'line', width: 1.1 }
  ],
  Benchmark: {
    // Note that the checked value represent the INITIAL STATE ONLY - Gois
    CDI: { cod: 'CDI', title: 'CDI', color: Colors.default.DarkGray, checked: true },
    IBOVESPA: { cod: 'IBOVESPA', title: 'IBOVESPA', color: Colors.default.ExtraDarkGray, checked: false },
    IPCA: { cod: 'IPCA', title: 'IPCA', color: Colors.default.Black, checked: false }
  },
  Blotter: {
    sellAllByDefault: false,
    minBookDate: '04/01/2010',
    maxMaturityDate: '31/12/2099'
  },
  Profitability: {
    chartDefaultPeriod: 'month',
    maxReducePoint: 300
  },
  colors: {
    TREASURY: { primary: '#316968', secondary: '#4f7a79', tertiary: '#689796', dark: '#162f2f', gray: '#a0a0a0' },
    TREASURY_LOCAL: { primary: '#316968', secondary: '#4f7a79', tertiary: '#689796', dark: '#162f2f', gray: '#a0a0a0' },
    CORPORATE_BONDS: { primary: '#008A8B', secondary: '#339999', tertiary: '#5FB3B3', dark: '#003334', gray: '#acacac' },
    FUNDS: { primary: '#005A71', secondary: '#207489', tertiary: '#3792A9', dark: '#00222A', gray: '#929292' },
    FUNDQUOTE: { primary: '#005A71', secondary: '#207489', tertiary: '#3792A9', dark: '#00222A', gray: '#929292' },
    FUNDQUOTE_FUNDS: { primary: '#005A71', secondary: '#207489', tertiary: '#3792A9', dark: '#00222A', gray: '#929292' },
    STOCK: { primary: '#6ea637', secondary: '#79b240', tertiary: '#99cc66', dark: '#203A08', gray: '#bcbcbc' },
    STOCK_LOCAL: { primary: '#6ea637', secondary: '#79b240', tertiary: '#99cc66', dark: '#203A08', gray: '#bcbcbc' },
    STOCK_SHARE: { primary: '#6ea637', secondary: '#79b240', tertiary: '#99cc66', dark: '#203A08', gray: '#bcbcbc' },
    SAVINGS: { primary: '#FBBC43', secondary: '#f9c258', tertiary: '#FFDB97', dark: '#5E4619', gray: '#f9f9f9' },
    OTHERS: { primary: '#9a9a9a', secondary: '#adadad', tertiary: '#c5c5c5', dark: '#5E4619', gray: '#f9f9f9' },

    // extra colors (use in another securities)
    OPTION: { primary: '#254c01', secondary: '#99CC66', tertiary: '#B7E984', dark: '#203A08', gray: '#c3c3c3' },
    ETF: { primary: '#5DB9CC', secondary: '#82C8D6', tertiary: '#B7DCE3', dark: '#21454C', gray: '#d6d6d6' },
    FUTURES: { primary: '#c56b13', secondary: '#d37416', tertiary: '#e98019', dark: '#593009', gray: '#727272' }
  },
  colorsBrokers: ['#1f2835', '#008dff', '#00ca9b', '#334764', '#15609c', '#048869'],
  Decimals: {
    CURRENCY: {
      Price: 2,
      Quantity: 2
    },
    CRYPTOCURRENCY: {
      Price: 8,
      Quantity: 8
    },
    TREASURY: {
      Price: 6,
      Quantity: 2
    },
    FUNDQUOTE: {
      Price: 9,
      Quantity: 8
    },
    CORPORATE: {
      Price: 2,
      Quantity: 2
    },
    SAVINGS: {
      Price: 2,
      Quantity: 2
    },
    DEFAULT: {
      Price: 2,
      Quantity: 0
    },
    COE: {
      Price: 2,
      Quantity: 0
    },
    OFFSHORE: {
      Price: 2,
      Quantity: 8
    },
    GENERIC: {
      Price: 2,
      Quantity: 5
    },
    CASH: {
      Price: 2,
      Quantity: 2
    }
  },
  Format: {
    Date: {
      American: 'YYYY-MM-DD',
      Brazilian: 'DD/MM/YYYY',
      BrazilianMonthYear: 'MMMM / YYYY',
      BrazilianMonthYear2D: 'DD/MM/YY'
    },
    DateTime: {
      American: 'YYYY-MM-DDTHH:mm:ss',
      Brazilian: 'DD/MM/YYYY HH:mm:ss',
      ISO: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
    },
    Material: {
      ...MAT_MOMENT_DATE_FORMATS,
      display: {
        ...MAT_MOMENT_DATE_FORMATS.display,
        dateInput: 'DD/MM/YYYY'
      }
    }
  },
  fullPageTitle: false,
  gorilaLogoUrl: '/assets/img/logo/logo_gorila.svg',
  gorilaLogoPrintUrl: '/assets/img/logo/gorila.png',
  serviceTermsUrl: {
    b2b: 'https://gorila.com.br/termos-de-uso-gorilapro',
    b2c: 'https://gorila.com.br/termos-de-uso',
  },
  GroupControl: {
    SelectList: [{ id: 'securityType', name: 'groupSecurityType' }, { id: 'BrokerName', name: 'groupBrokerName' }],
    Default: 'securityType' // securityType||BrokerName
  },
  HomePage: 'https://www.gorila.com.br/',
  HomePageNoWWW: 'gorila.com.br',
  socialMediaArray: [
    {
      img: '/assets/img/facebook_modal.svg',
      URL: 'https://www.facebook.com/GorilaInvest/',
      id: 'Facebook'
    },
    {
      img: '/assets/img/instagram.svg',
      URL: 'https://www.instagram.com/gorilainvest',
      id: 'Instagram'
    },
    {
      img: '/assets/img/twitter.svg',
      URL: 'https://twitter.com/gorilainvest',
      id: 'Twitter'
    },
    {
      img: '/assets/img/linked-in.svg',
      URL: 'https://www.linkedin.com/company/gorila',
      id: 'Linked-in'
    }
  ],
  Position: {
    tooltipPriceDecimals: {
      CORPORATE_BONDS_DEBENTURE: 4,
      CRYPTOCURRENCY: 8,
      DEFAULT: 2
    },
    priceDecimals: {
      CORPORATE_BONDS_DEBENTURE: 4,
      CRYPTOCURRENCY: 4,
      DEFAULT: 2
    },
    decimals: {
      FUNDQUOTE: 8,
      DEFAULT: 2
    }
  },
  PricedSecurities: [
    'TREASURY',
    'TREASURY_LOCAL',
    'CORPORATE_BONDS',
    'SAVINGS',
    'STOCK',
    'STOCK_LOCAL',
    'STOCK_SHARE',
    'FUNDS',
    'FUNDQUOTE'
  ],
  productSubTypes: {
    withPositionExplanation: ['STOCK_', 'FORWARD_STOCKS'],
    withoutPortfolioEvents: { 'FUNDQUOTE': true, 'CRYPTOCURRENCY': true, 'SAVINGS': true }
  },
  SecurityOrder: ['STOCK', 'STOCK_LOCAL', 'FUNDQUOTE', 'FUNDS', 'TREASURY', 'TREASURY_LOCAL', 'CORPORATE_BONDS', 'SAVINGS', 'OTHERS'],
  hideAveragePrice: ['FUNDQUOTE_STOCK', 'FUTURES_INDEX', 'FUTURES_ONSHORE_FUTURES_INDEX', 'FUTURES_STOCK_LOCAL'],
  Social: {
    facebook: 'https://www.facebook.com/GorilaInvest/',
    twitter: 'https://twitter.com/gorilainvest/',
    instagram: 'https://www.instagram.com/gorilainvest/',
  },
  SocketEvents: {
    'PORTFOLIO_UPDATE:START': 'portfolioUpdateStart',
    'PORTFOLIO_UPDATE:FINISH': 'portfolioUpdateFinish',
    'POSITION_UPDATE:FINISH': 'positionUpdateFinish',
    'POSITION_REMOVE:FINISH': 'positionRemoveFinish',
    'POSITION_HISTORY:START': 'positionHistoryStart',
    'POSITION_HISTORY:FINISH': 'positionHistoryFinish',
    'POSITION_HISTORY:STEP': 'positionHistoryStep',
    'POSITION_HISTORY:WAIT': 'positionHistoryWait',
    'PRICE_HISTORY:START': 'priceHistoryStart',
    'PRICE_HISTORY:FINISH': 'priceHistoryFinish',
    'TRADE_ADD:FINISH': 'tradeAddFinish',
    'TRADE_REMOVE:FINISH': 'tradeRemoveFinish'
  },
  SourceWhitelist: ['lp-produtos'],
  Subscription: {
    blockedPeriod: 0, // set to 0 to remove warning text from modal
    pricePerClient: 14.9,
    allowedIssuers: ['Mastercard', 'Visa', 'Elo', 'Hipercard']
  },
  Table: {
    Row: {
      Height: 50
    }
  },
  Timeouts: {
    Price: 5000
  },
  Trades: {
    currencySymbol: {
      CURRENCY: 'USD',
      OFFSHORE_ETF: 'USD',
      OFFSHORE_FUNDQUOTE: 'USD',
      OFFSHORE_REIT: 'USD',
      OFFSHORE_STOCK: 'USD',
    },
    tooltipPriceDecimals: {
      CORPORATE_BONDS_DEBENTURE: 4,
      CRYPTOCURRENCY: 8,
    }
  },
  WhiteLabelURLMarkers: [
    '/advisor/',
    '/partner/'
  ],
  WordTransformMap: {
    DO: 'do',
    DE: 'de',
    DA: 'da',
    'S.A.': 'S.A.',
    'S/A': 'S.A.',
    AO: 'ao',
    AS: 'as',
    LA: 'la',
    OF: 'of',
    UM: 'Um',
    A: 'a',
    E: 'e',
    BB: 'BB',
    BR: 'BR',
    BV: 'BV',
    CA: 'CA',
    CM: 'CM',
    COE: 'COE',
    CP: 'CP',
    HP: 'HP',
    HR: 'HR',
    HS: 'HS',
    MS: 'MS',
    OM: 'OM',
    RB: 'RB',
    SG: 'SG',
    SP: 'SP',
    VR: 'VR',
    XP: 'XP'
  },
  benchmarkList: {
    CDI: {
      cod: 'CDI',
      title: 'CDI',
      color: '#838383',
      checked: true,
    },
    IBOVESPA: {
      cod: 'IBOVESPA',
      title: 'IBOVESPA',
      color: '#545454',
      checked: false,
    },
    IPCA: {
      cod: 'IPCA',
      title: 'IPCA',
      color: '#000000',
      checked: false,
    },
  },
  report: {
    defaultMainSeries: 'profit'
  }
};
