import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '@gorila/constants';
import { ITradeServer } from '@gorila-bot/gorila-front-models';
import { TranslatePipe } from '@ngx-translate/core';
import * as moment from 'moment';

import { TradeConstants } from '../../../../trade/config/trade.constants';
import { SidePipe } from './side.pipe';

@Pipe({name: 'trade'})
export class TradePipe implements PipeTransform {
  private readonly CopyPaste = ['_id', 'Description', 'ProductTypeName'];
  private readonly Dynamics = ['BrokerName', 'IssuerName', 'Description'];
  private readonly FilterDates = ['TradeDate', 'Maturity'];
  private readonly FilterNumbers = ['Notional', 'Quantity', 'Price'];
  private readonly Ignored = [];

  constructor(
    private percentPipe: PercentPipe,
    private sidePipe: SidePipe,
    private translatePipe: TranslatePipe
  ) {}

  public transform(trade: ITradeServer) {
    const newTrade: any = {};

    try {
      for (const field in trade) {
        if (!trade.hasOwnProperty(field)) { continue; }

        const value = trade[field];

        if (this.Dynamics.indexOf(field) !== -1) {
          continue;
        }

        if (this.Ignored.indexOf(field) !== -1) {
          continue;
        }

        if (this.FilterDates.indexOf(field) !== -1) {
          newTrade[field] = this.filterDate(value);
          continue;
        }

        if (typeof value === 'number') {
          newTrade[field] = this.fixNumber(field, value, trade['ProductTypeName']);
          continue;
        }

        if (this.CopyPaste.indexOf(field) !== -1) {
          newTrade[field] = value;
          continue;
        }

        if (field === 'Key' && this.missedProperty(trade['_id'])) {
          newTrade['_id'] = value;
        }

        if (isNaN(parseFloat(value))) {
          newTrade[field] = value;
        }
      }
      newTrade['IssuerName'] = this.formatIssuerBroker(trade['IssuerName']);
      newTrade['BrokerName'] = trade['BrokerName'] || this.formatIssuerBroker(trade['BrokerName']);
      if (trade['ProductTypeName'] === 'SAVINGS') {
        newTrade['SecurityUniqueName'] = trade['ProductTypeName'] + ' ' + trade['IssuerName'];
      }
      newTrade['Description'] = this.formatTitle(trade);
      // TODO We needs a better way to deal with it, maybe the pipe was wrong
      // used in redux pipeline
      newTrade['_Side'] = trade['Side'];
      newTrade['Side'] = this.sidePipe.transform(trade['Side'], trade['ProductTypeName']);
      newTrade['Currency'] = trade['Currency'] || TradeConstants.DefaultCurrency;
    } catch (e) {
      console.warn(e);
      return {};
    }

    return newTrade;
  }

  private filterDate(candidate: Date | string): Date {
    try {
      if (typeof candidate === 'undefined' || candidate === null) {
        return null;
      }
      if (candidate instanceof Date) { return candidate; }
      const date = moment.utc(candidate.split('T')[0]).format(AppConstants.Format.Date.American).toString();
      return new Date(date);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }

  private fixNumber(field: string, num: number, productType: string): number {
    if (this.FilterNumbers.indexOf(field) === -1) { return num; }

    if (field !== 'Price') { return +num.toFixed(TradeConstants.NumberDecimals['Default']); }

    const types = Object.keys(TradeConstants.NumberDecimals).filter((t) => t !== 'Default');
    for (const type of types) {
      if (type === productType) {
        return +num.toFixed(TradeConstants.NumberDecimals[type]);
      }
    }

    return +num.toFixed(TradeConstants.NumberDecimals['Default']);
  }

  private formatCorporateBondsTitle(item: any): string {
    if (!item['ProductSubTypeName']) { return ''; }

    try {
      const fix = (s) => s.replace('_', ' ') + ' ';
      let title = fix(this.translatePipe.transform(item['ProductSubTypeName'])['value']);

      if (item['IssuerName']) { title = fix(title.concat(item['IssuerName'])); }
      if (item['Rate']) {
        title = fix(title.concat(this.percentPipe.transform(item['Rate'] / 100, '1.2-2')));
        title = fix(title.concat(this.translatePipe.transform('InYear')['value']));
      }

      return title;
    } catch (e) {
      return this.translatePipe.transform(item['ProductSubTypeName'])['value'];
    }
  }

  private formatIssuerBroker(issuerBroker: string) {
    if (!issuerBroker) { return ''; }
    return issuerBroker.replace(/_/g, ' ');
  }

  private formatTitle(item: any) {
    try {
      switch (item['ProductTypeName']) {
        case 'TREASURY':
          return this.translatePipe.transform(item['ProductSubTypeName'])['value'];
        case 'CORPORATE_BONDS':
          return this.formatCorporateBondsTitle(item);
        case 'SAVINGS':
          return item['BrokerName'];
        case 'OTHERS':
          return this.translatePipe.transform(item['ProductSubTypeName'])['value'] + ' ' + item['BrokerName'];
        default:
          return item['SecurityName'];
      }
    } catch (e) {
      console.warn(e);
      return (typeof item['SecurityName'] === 'undefined') ? '' : item['SecurityName'];
    }
  }

  private missedProperty(value: any) {
    if (typeof value === 'undefined' || value === null) {
      return true;
    }

    if (typeof value === 'string' && value === '') {
      return true;
    }

    return false;
  }
}
