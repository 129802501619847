import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LOCALE_ID_PROVIDER } from '@gorila-bot/gorila-front-utils';
import { TranslateModule } from '@ngx-translate/core';

import { CnpjPipe } from './cnpj.pipe';
import { CpfPipe } from './cpf.pipe';
import { EmailBreakPipe } from './email-break.pipe';
import { EventDescriptionPipe } from './event-description.pipe';
import { EventTypePipe } from './event-type.pipe';
import { EscapeHtmlPipe } from './keep-html.pipe';
import { KeysPipe } from './keys.pipe';
import { PaginatorPipe } from './paginator.pipe';
import { PriceByUnitPipe } from './price-by-unit.pipe';
import { ProductIndexPipe } from './product-index.pipe';
import { StringBreakPipe } from './string-break.pipe';
import { TableFilterPipe } from './table-filter.pipe';
import { UcfirstPipe } from './ucfirst.pipe';
import { UtcPipe } from './utc.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  providers: [
    LOCALE_ID_PROVIDER,
    CnpjPipe,
    CpfPipe,
    EmailBreakPipe,
    EscapeHtmlPipe,
    EventDescriptionPipe,
    EventTypePipe,
    KeysPipe,
    PaginatorPipe,
    PriceByUnitPipe,
    ProductIndexPipe,
    StringBreakPipe,
    TableFilterPipe,
    UcfirstPipe,
    UtcPipe,
  ],
  declarations: [
    CnpjPipe,
    CpfPipe,
    EmailBreakPipe,
    EscapeHtmlPipe,
    EventDescriptionPipe,
    EventTypePipe,
    KeysPipe,
    PaginatorPipe,
    PriceByUnitPipe,
    ProductIndexPipe,
    StringBreakPipe,
    TableFilterPipe,
    UcfirstPipe,
    UtcPipe,
  ],
  exports: [
    CnpjPipe,
    CpfPipe,
    EmailBreakPipe,
    EscapeHtmlPipe,
    EventDescriptionPipe,
    EventTypePipe,
    KeysPipe,
    PaginatorPipe,
    PriceByUnitPipe,
    ProductIndexPipe,
    StringBreakPipe,
    TableFilterPipe,
    UcfirstPipe,
    UtcPipe,
  ]
})
export class SharedPipesModule { }
