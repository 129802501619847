// tslint:disable deprecation
import { brokerFromServer, IBroker, IIssuer, issuerFromServer, ITrade } from '@gorila-bot/gorila-front-models';
import { QuotingTypeID } from '@gorila-bot/gorila-front-models/dist/security/security-server.model';

import { FLAGS, StateItemList } from '../../store.model';
import { getSideObject, Side } from '../side.model';
import { getTradeSourceObject, TradeSource } from './trade-source.model';


/**
 * Interface for objects that represents a trade.
 *
 * @interface
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export interface Trade {
  /**
   * Unique identifier of a trade.
   *
   * @type {string}
   */
  id: string;

  /**
   * The side representation (buy OR sell) of trade.
   *
   * @type {Side}
   * @see {Side}
   */
  side: Side;

  /**
   * The trade date of the trade.
   *
   * @type {string}
   */
  tradeDate: string;

  /**
   * The issue date of the trade.
   *
   * @type {string}
   */
  issueDate: string;

  /**
   * The maturity date of the trade.
   *
   * @type {string}
   */
  maturityDate: string;

  /**
   * How many unit have in the trade. (?)
   *
   * @type {number}
   */
  quantity: number;

  /**
   * The price of the active at trade date.
   *
   * @type {number}
   */
  price: number;

  /**
   * The total value of the trade.
   *
   * @type {number}
   */
  value: number;

  /**
   * The broker that sell the trade;
   *
   * type {IBroker}
   * see {IBroker}
   */
  broker: IBroker;

  /**
   * The issuer that sell the trade;
   *
   * type {IIssuer}
   * see {IIssuer}
   */
  issuer: IIssuer;

  /**
   * The source representation (auto OR manual) of trade.
   *
   * @type {TradeSource}
   * @see {TradeSource}
   */
  source: TradeSource;

  /**
   * The fund id that owns the trade.
   *
   * @type {string}
   */
  fundId: number;

  /*
   * Security Rate
   *
   * @type {string}
   */
  quotingType?: string;

  /*
   * Security Rate
   *
   * @type {number}
   */
  rate?: number;

  /**
   * The security name of the trade.
   *
   * @type {string}
   */
  security: string;

  /**
   * The security name (CNPJ for funds) of the trade.
   *
   * @type {string}
   */
  securityName: string;

  /**
   * The security id.
   *
   * @type {number}
   */
  securityID: string;


   /**
   * The security description of the trade.
   *
   * @type {string}
   */
  securityDescription: string;

  /**
   * The asset class name of the trade.
   *
   * @type {string}
   */
  assetClass: string;

  /**
   * The product type name of the trade.
   *
   * @type {string}
   */
  productType: string;

  /**
   * The product subtype name of the trade.
   *
   * @type {string}
   */
  productSubType?: string;

  /**
   * The product subtype name of the trade.
   *
   * @type {number}
   */
  productSubTypeID?: number;

  /**
   * The product underlyingID of the trade.
   *
   * @type {number}
   */
  underlyingID?: number;
}

/**
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export const getSecurityName = (trade): string => {
  try {
    switch (trade.ProductTypeName) {
      case 'CORPORATE_BONDS':
        const simbol = trade.QuotingTypeId === QuotingTypeID.Times ? '+' : '%';
        const issuer = trade.IssuerName.replace(/_/gi, ' ');
        return `${issuer} ${trade.Rate + simbol}`;
      case 'SAVINGS':
        return trade.IssuerName.replace(/_/gi, ' ');
    }
  } catch (e) {
    console.warn(e);
  }
  return trade.SecurityName;
};

/**
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export const fromServer = (trade): Trade => ({
  id: `${trade.Id}`,
  side: getSideObject(trade.Side),
  tradeDate: trade.TradeDate,
  maturityDate: trade.Maturity,
  quantity: trade.Quantity,
  price: trade.Price,
  value: trade.Notional || trade.Price * trade.Quantity,
  broker: brokerFromServer(trade),
  issuer: issuerFromServer(trade),
  issueDate: trade.IssueDate || null,
  source: getTradeSourceObject(trade.Source),
  fundId: trade.FundID,
  quotingType: `${trade.QuotingTypeID}`,
  rate: trade.Rate || 0,
  security: trade.SecurityName,
  securityID: trade.SecurityID,
  securityName: getSecurityName(trade),
  securityDescription: trade.SecurityDescription,
  assetClass: trade.AssetClassName,
  productType: trade.ProductTypeName,
  productSubType: trade.ProductSubTypeName,
  productSubTypeID: trade.ProductSubTypeID,
  underlyingID: trade.UnderlyingID
});

export const INITIAL_STATE: StateItemList<ITrade> = {
  items: {},
  [FLAGS.ADDING]: false,
  [FLAGS.LOADING]: false,
  [FLAGS.REMOVING]: false,
  error: null
};

/**
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export const AvailableUnderlyings = {
  26: 'CDI',
  29: 'IPCA',
  'CDI': 26,
  'IPCA': 29
};

/**
 * @deprecated since version 3.16.<?>, use @gorila-bot/gorila-front-models instead.
 */
export const getUnderlyingFromSecurityAsNumber = (security: string) =>
  security.indexOf('CDI') !== -1
    ? AvailableUnderlyings.CDI
    : security.indexOf('IPCA') !== -1
      ? AvailableUnderlyings.IPCA
      : 0;
