import { environment } from '@env/environment';
import { AppConstants } from '@gorila/constants';
import { UserAccountModel } from '@gorila/root-store/user-account';
import { UiSimpleModalData } from '@gorila/ui/ui-modal';
import { WhiteLabelService } from '@gorila-bot/white-label';
import { TranslateService } from '@ngx-translate/core';
import { is, path } from 'ramda';

export enum INTEGRATION_ERROR_ACTIONS {
  UPDATE = 'update account info',
  CANCEL = 'cancel',
  DISCONNECT = 'disconnect',
}

const PROVIDER_STATUS_TRANSLATION_KEYS = {
  blocked: {
    content: 'authentication error due to blocked access to @provider',
    title: 'account authentication blocked',
  },
  expired: {
    content: 'authentication error when using expired credential to @provider',
    title: 'account authentication expired information',
    updateAction: 'try to connect',
  },
  invalid: {
    content: 'authentication error when connecting to @provider',
    title: 'account authentication error',
  },
  invalidconnection: {
    content: 'connection error when connecting to @provider',
    title: 'account connection error',
  },
  invalidpayload: {
    content: 'payload error when connecting to @provider',
    title: 'account payload error',
    updateAction: 'try to connect',
  },
  serviceunavailable: {
    content: 'service unavailable error when connecting to @provider',
    title: 'account service unavailable error',
    updateAction: 'try again now',
  },
  unexpected: {
    content: 'unexpected error when connecting to @provider',
    title: 'account unexpected error',
    updateAction: 'try to connect',
  },
};

const getTranslationKeyFromProviderStatus = (
  stateDescription: string,
  key: string
): string =>
  (PROVIDER_STATUS_TRANSLATION_KEYS[
    (stateDescription || 'invalid').toLowerCase()
  ] || PROVIDER_STATUS_TRANSLATION_KEYS.invalid)[key];

export const errorIntegration = (
  translate: TranslateService,
  provider: UserAccountModel
) => {
  const auth0Connection = (provider.signUpFields || []).length === 0 && !provider.connectionParameters;
  const stateDescription = auth0Connection
    ? 'invalidconnection'
    : provider.stateDescription;
  const updateActionTranslationKey = getTranslationKeyFromProviderStatus(stateDescription, 'updateAction');
  const actionTranslationKey = updateActionTranslationKey ? updateActionTranslationKey : 'update login information';
  const content: {
    actions: {
      class: string;
      text: string;
      value: INTEGRATION_ERROR_ACTIONS;
    }[];
    text: string;
    separator?: string;
  }[] = [
    {
      text: translate.instant(
        getTranslationKeyFromProviderStatus(stateDescription, 'content'),
        { provider: provider.providerName }
      ),
      actions: [
        {
          value: INTEGRATION_ERROR_ACTIONS.UPDATE,
          text: translate.instant(
            auth0Connection ? 'try to connect' : actionTranslationKey
          ),
          class: 'btn-action btn-action--primary',
        },
        {
          value: INTEGRATION_ERROR_ACTIONS.CANCEL,
          text: translate.instant('remind me later'),
          class: 'btn-action btn-action--secondary',
        },
      ],
    },
  ];

  if (
    environment.features.user.account.connect.disconnect ||
    !auth0Connection
  ) {
    content.push({
      text: translate.instant(
        auth0Connection
          ? 'disconnectAccount'
          : 'disconnectAccountAndDeleteTrades'
      ),
      actions: [
        {
          value: INTEGRATION_ERROR_ACTIONS.DISCONNECT,
          text: translate.instant(
            auth0Connection ? 'disconnect' : 'disconnect all'
          ),
          class: 'btn-action btn-action--secondary',
        },
      ],
      separator: translate.instant('or'),
    });
  }
  return {
    width: '556px',
    data: {
      title: translate.instant(
        getTranslationKeyFromProviderStatus(stateDescription, 'title')
      ),
      content,
    },
    disableClose: true,
  };
};

const gorilaProTask = (data: UiSimpleModalData, height?: string) => ({
  height: height || '350px',
  width: '550px',
  data: {
    ...data,
  },
  disableClose: true,
});

export const gorilaProTaskFailed = (translate: TranslateService, err: any) => {
  const defaultMessage = 'advisorInviteAcceptFail';
  const bodyText: string =
    path(['error', 'error', 'message'], err) || defaultMessage;
  return gorilaProTask(
    {
      bodyHtml: translate.instant(
        is(String, bodyText) ? bodyText : defaultMessage
      ),
      titleText: translate.instant('Invite accepted fail'),
      yesText: translate.instant('Try again'),
    },
    '320px'
  );
};

export const gorilaProTaskSuccess = (translate: TranslateService) =>
  gorilaProTask({
    bodyHtml: translate.instant('advisorInviteAcceptSuccess'),
    titleText: translate.instant('Invite accepted successfully!'),
    yesText: translate.instant('Understood!'),
  });

const generateModalHtml = (
  translate: TranslateService,
  wlService: WhiteLabelService
) => {
  const period = AppConstants.Subscription.blockedPeriod;
  const warning = !!period
    ? `
    <div class='secondary'>
      ${translate.instant('Important')}:
      ${translate.instant('removeClientAfterPeriod', { n: period })}
    </div>
  `
    : '';

  return `
      <div class='app-shell-block-ui'>
        <div class='text'>${wlService.translate('blockedAccount')}</div>
        ${warning}
      </div>
  `;
};

export const gorilaProSubscriptionDebit = (
  translate: TranslateService,
  wlService: WhiteLabelService
) =>
  gorilaProTask({
    bodyText: '',
    bodyHtml: generateModalHtml(translate, wlService),
    titleText: translate.instant('Your account is blocked'),
    yesText: translate.instant('Register new card'),
  });
