import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import {
  AuthConnectRoutePathUrl,
  AuthNoLockRoutePath, AuthNoLockSignupRoutePath,
  MobileWarningRoutePath
} from '@gorila/core/router';
import { Location } from '@angular/common';
import { URL_OAUTH_TOKEN_KEY } from '@gorila/pages/auth/models/storage-keys.model';

@Injectable()
export class MobileGuard implements CanActivate {

  private isMobile: boolean;

  constructor(
    protected router: Router,
    public platform: Platform,
    protected location: Location,
  ) {
    this.isMobile = (this.platform.IOS || this.platform.ANDROID);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isMobile) {
      const oauthToken = localStorage.getItem(URL_OAUTH_TOKEN_KEY);
      const lastRoute = localStorage.getItem('lastroute') || '';
      const page = state.url.substr(1).split('#')[0];
      const pagesAllowed = [
        AuthNoLockSignupRoutePath,
        AuthNoLockRoutePath,
        // These routes bellow works with the back-end invite service
        // If you have to modify then, please update then too in the
        // "advisor-feat" and "advisor tools" repositories
        'invite',
        'invite-accept',
        'invite-reject',
      ];
      if (lastRoute.startsWith('/m/') || pagesAllowed.includes(page) && (this.location.path() === AuthConnectRoutePathUrl || oauthToken)) {
        return true;
      }

      return this.router.navigateByUrl(`${MobileWarningRoutePath}`);
    }

    return true;
  }
}
