import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { PositionEventListenerService } from './position/src/services/position-event-listener.service';
import { UserAccountEventListenerService } from './user-account/src/services/user-account-event-listener.service';

export type SocketNotificationTopic = 'POSITION_UPDATE';

@Injectable()
export class NgrxAPIService implements OnDestroy {
  private startMuteTimer$ = new Subject<void>();
  private stopMuteTimer$ = new Subject<void>();
  private notifier$ = new Subject<any>();

  constructor(
    private positionEventListenerService: PositionEventListenerService,
    private userAccountEventListenerService: UserAccountEventListenerService,
  ) { }

  public ngOnDestroy() { }

  public init() {
    this.positionEventListenerService.configure(this.notifier$);
    this.positionEventListenerService.listenPositionEvents();
    this.userAccountEventListenerService.listenUserAccountEvents();
    this.initMuteTimer();
  }

  /**
   * Get notifications of incoming messages in socket by topic. The returned observable,
   * is a safe one, that is, the flow will be closed when the service instance is destroyed.
   */
  public getNotifications(topic: SocketNotificationTopic): Observable<any> {
    return this.notifier$.asObservable().pipe(filter(notification => notification.type === topic));
  }

  public setDates(minDate: string, maxDate: string) {
    this.positionEventListenerService.setDates(minDate, maxDate);
  }

  public muteSocket() {
    this.positionEventListenerService.mute();
    this.startMuteTimer$.next();
  }

  public unmuteSocket() {
    this.positionEventListenerService.unmute();
    this.stopMuteTimer$.next();
  }

  private initMuteTimer() {
    this.startMuteTimer$.asObservable().pipe(
      switchMap(() => timer(5 * 60_000).pipe(
        takeUntil(this.stopMuteTimer$)
      )),
    ).subscribe(() => this.unmuteSocket());
  }
}
