import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../guards/advisor-fund.guard';
import { RouteData } from '../../route-data';
import { WalletRoutePath } from './metadata';

export const WalletRouteData: RouteData = {
  title: 'Carteira'
};

export const WalletRoute: Route = {
  data: WalletRouteData,
  path: WalletRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: './pages/wallet/src/wallet.module#WalletModule'
};
