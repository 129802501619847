import { Injectable } from '@angular/core';
import { ClientType } from '@gorila-bot/user-data-store/lib/models/fund.model';
import { ApiService } from '@gorila-bot/gorila-base';
import { environment } from '@env/environment';

@Injectable()
export class ChatService {

  constructor(
    protected apiService: ApiService,
  ) { }

  private openZendesk(name: string, email: string, windowRef?: Window): void {
    const window = windowRef as any;

    if (!!window.zE) {
      window.zE.identify({ name, email });
      window.zE.activate({ hideOnClose: true });
    }
  }

  private loadHubspotWidget(window: Window) {
    const widget = (window as any).HubSpotConversations.widget;

    if (widget.status().loaded) {
      widget.remove();
    } else {
      widget.load({ widgetOpen: true });
    }
  }

  private openHubspotChat(windowRef: Window, email: string, fullName: string): void {
    const window = windowRef as any;
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.split(' ')[1] || null;

    const payload = {
      email, firstName, lastName
    };

    const path = 'support/hubspot/auth';
    const url = environment.serviceBaseUrl + path;

    this.apiService.doPost(url, {}, payload).subscribe(
      (data: {token: string}) => {

        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: data.token,
        };
        this.loadHubspotWidget(window);
      },
      (error) => console.error('Error getting user from Hubspot', error)
    );
  }

  public openChat(window: Window, clientType: ClientType, name?: string, email?: string): void {
    const hubspotEnabled = environment.features.manager.hubspot;
    if (clientType === 'B2B' && hubspotEnabled) {
      this.openHubspotChat(window, email, name);
    } else {
      this.openZendesk((name || ''), (email || ''), window);
    }
  }
}
