import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Fund, UserDataSelectors, UserDataState } from '@gorila-bot/user-data-store';
import { PremiumPlanTier, PremiumService, PremiumSubscribeComponent } from '@gorila-bot/premium';
import { TipsService } from '@gorila/widgets/tips/services/tips.service';
import { select, Store } from '@ngrx/store';
import { EMPTY, iif, Observable, of, zip } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

@Injectable()
export class ResearchHousesGuard implements CanLoad {
  public constructor(
    protected router: Router,
    protected store: Store<UserDataState.State>,
    private premiumService: PremiumService,
    // TODO: move this service to Research Houses context
    private tipsService: TipsService,
    private dialog: MatDialog,
  ) { }

  private isUserPremiumKong(fundData: Fund, tier: PremiumPlanTier): boolean {
    return tier === PremiumPlanTier.KONG && fundData.clientType !== 'B2B';
  }

  private openResearchHouseLink(service: TipsService): void {
    service.getResearchHouseUrl()
      .subscribe(
        ({ url }: { url: string }) => window.open(url, '_blank'));
  }

  private openSubscriptionModal(dialog: MatDialog): void {
    dialog.open(PremiumSubscribeComponent);
  }

  public canLoad(): boolean {
    zip(
      this.store.pipe(
        select(UserDataSelectors.selectFeatureCurrentLoggedFundData)),
      this.premiumService.userTier$,
    ).pipe(take(1)).subscribe(
      ([fund, tier]: [Fund, PremiumPlanTier]) => this.isUserPremiumKong(fund, tier)
      ? this.openResearchHouseLink(this.tipsService)
      : this.openSubscriptionModal(this.dialog)
      );

    return false;
  }
}
