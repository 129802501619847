import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { UserDataActions } from '@gorila-bot/user-data-store';
import { WhiteLabelService } from '@gorila-bot/white-label';
import {
  AuthGorilaPROSignupRoutePath,
  AuthNoLockForgotPasswordRoutePath,
  AuthNoLockSignupRoutePath,
  AuthNoLockSignupRoutePathUrl,
} from '@gorila/core/router';
import { CookieService } from '@gorila/core/utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import Auth0Lock from 'auth0-lock';
import { path } from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';

import { SignupUserType } from '../models/auth.model';
import { TOKEN_KEY } from '../models/storage-keys.model';
import { SERVICE_TERMS_URL_B2B, SERVICE_TERMS_URL_B2C } from './tokens';
import * as moment from 'moment';

export const AUTH_CONFIG = {
  clientID: environment.Auth0.clientID,
  domain: environment.Auth0.domain,
  callbackURL: environment.Auth0.redirectUrl,
  params: {
    responseType: 'token id_token',
    scope: 'openid update:current_user_identities',
    realm: environment.Auth0.database,
    device: 'gorila',
    grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
    audience: environment.useAccessToken
      ? environment.Auth0.audience
      : undefined,
  },
};

@Injectable()
export class Auth0Service {
  // auth0Lock instance
  private lock;
  private userType: SignupUserType = 'client';
  private isWhiteLabel = false;
  // Login='' or 'SignUp'
  private lockCustomOptions = {
    initialScreen: '',
    allowSignup: false,
    allowedConnections: [
      'Username-Password-Authentication',
      'facebook',
      'apple',
      'google',
    ],
  };
  private refreshTokenBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshToken$: Observable<boolean>;
  private signParams: any = { name: null, email: null };

  public serviceTerms = {
    text: 'Service Terms',
    url: this.serviceTermsURLB2C,
  };

  constructor(
    public router: Router,
    private jwtHelper: JwtHelperService,
    private store: Store<any>,
    private translate: TranslateService,
    private wlService: WhiteLabelService,
    private route: ActivatedRoute,
    @Inject(SERVICE_TERMS_URL_B2B) private serviceTermsURLB2B: string,
    @Inject(SERVICE_TERMS_URL_B2C) private serviceTermsURLB2C: string
  ) {
    this.refreshToken$ = this.refreshTokenBS.asObservable();
    this.getSignParams();
  }

  /**
   * get name and email from querystring url to prefill signup form with advisor created data
   * using cookie to get name and email after user see user terms
   */
  private getSignParams() {
    this.route.queryParams.subscribe(params => {
      this.signParams = { ...this.signParams, ...params };

      const expireCookieDate = moment().add(3, 'days').utc().endOf('day').toString();

      if (params.name) {
        CookieService.setCookieWithExpiration('auth0ServiceSignParamsName', params.name, expireCookieDate);
      }
      if (params.email) {
        CookieService.setCookieWithExpiration('auth0ServiceSignParamsEmail', params.email, expireCookieDate);
      }

      if ( this.router.url !== AuthNoLockSignupRoutePathUrl ) {
        return;
      }

      const cookieName = CookieService.getCookie('auth0ServiceSignParamsName');
      const cookieEmail = CookieService.getCookie('auth0ServiceSignParamsEmail');

      if (cookieName) {
        this.signParams.name = cookieName;
      }
      if (cookieEmail) {
        this.signParams.email = cookieEmail;
      }
    });
  }

  public initLockControl(
    userType: SignupUserType,
    pageRouteURL: string,
    isWhiteLabel: boolean,
    show = true
  ): void {
    this.userType = userType;
    this.isWhiteLabel = isWhiteLabel;

    this.checkIsAdvisor();

    this.checkIsWhiteLabel();

    this.checkInitialScreenLockByUrl(pageRouteURL);

    this.autoLockStartConfig();

    this.lock.show();
  }

  private checkIsWhiteLabel(): void {
    if (!this.isWhiteLabel) {
      this.lockCustomOptions.allowSignup = true;
    }
  }

  private checkIsAdvisor(): void {
    if (this.userType === 'advisor') {
      this.serviceTerms.url = this.serviceTermsURLB2B;
      this.lockCustomOptions.allowedConnections = [
        'Username-Password-Authentication',
      ];
    }
  }

  private checkInitialScreenLockByUrl(pageRouteURL: string): void {
    if (
      pageRouteURL === AuthGorilaPROSignupRoutePath ||
      pageRouteURL === AuthNoLockSignupRoutePath
    ) {
      this.lockCustomOptions.initialScreen = 'signUp';
    } else if (pageRouteURL === AuthNoLockForgotPasswordRoutePath) {
      this.lockCustomOptions.initialScreen = 'forgotPassword';
    } else {
      this.lockCustomOptions.initialScreen = '';
    }
  }

  public getLockInstance() {
    return this.lock;
  }

  public refreshToken(cb?: () => void) {
    if (!this.lock) {
      this.autoLockStartConfig();
    }

    this.lock.checkSession(AUTH_CONFIG.params, (err, result) => {
      if (err) {
        console.error(err);
        return;
      }

      const resultKey = `${TOKEN_KEY.split('_')[0]}Token`;
      const token = result[resultKey];
      localStorage.setItem(TOKEN_KEY, token);
      this.store.dispatch(
        new UserDataActions.UserSetAuthData(this.jwtHelper.decodeToken(token), token)
      );
      this.refreshTokenBS.next(true);
      if (cb) {
        cb();
      }
    });
  }

  private autoLockStartConfig(): void {
    let titleKey = 'Access your Gorila account';
    titleKey = this.isWhiteLabel
      ? (this.wlService.normalizeTranslationKey(titleKey) as string)
      : titleKey;
    const title = this.translate.instant(titleKey);

    const prefillSignUp = path(['email'], this.signParams) ? {
      prefill: {
        email: this.signParams.email
      }
    } : {};

    this.lock = new Auth0Lock(AUTH_CONFIG.clientID, AUTH_CONFIG.domain, {
      container: 'auth0-lock',
      configurationBaseUrl: 'https://cdn.auth0.com',
      initialScreen: this.lockCustomOptions.initialScreen,
      allowedConnections: this.lockCustomOptions.allowedConnections,
      allowSignUp: this.lockCustomOptions.allowSignup,
      auth: {
        redirectUrl: AUTH_CONFIG.callbackURL,
        responseType: 'token id_token',
        params: AUTH_CONFIG.params,
      },
      theme: {
        primaryColor: '',
        logo: 'https://dev.gorila.com.br/assets/img/logo/logo_gorila.svg',
        title,
        authButtons: {
          facebook: {
            primaryColor: 'white',
            foregroundColor: '',
          },
        },
      },
      mustAcceptTerms: true,
      rememberLastLogin: false,
      language: 'pt-br',
      allowShowPassword: true,
      languageDictionary: {
        loginWithLabel: this.translate.instant('Enter With'),
        signUpWithLabel: this.translate.instant('Singup With'),
        title,
        forgotPasswordTitle:
          this.translate.instant('Gorila, Help me') +
          ' ' +
          this.translate.instant('Forgot Password') +
          ' 🤔',
        forgotPasswordInstructions: this.translate.instant(
          'Please, inform your email'
        ),
        signUpLabel: this.translate.instant('Create Account In Gorila'),
        signUpSubmitLabel: this.translate.instant('Create new account'),
        loginSubmitLabel: this.translate.instant('Sign In'),
        emailInputPlaceholder: this.translate.instant('email'),
        passwordInputPlaceholder: this.translate.instant('password'),
        signUpTitle: this.translate.instant('Create your Gorila account'),
        signUpTerms: ` ${this.translate.instant('I accept the')}
        <a target="_blank" href="${
          this.serviceTerms.url
        }"><strong>${this.translate.instant(
          this.serviceTerms.text
        )}</strong></a>
        ${this.translate.instant('and')}
        <a target="_blank" href="https://gorila.com.br/politica-de-privacidade/">
          <strong>${this.translate.instant('Privacy Policy')}</strong>
        </a>`,
      },
      additionalSignUpFields: this.generateCustomFields(),
      ...prefillSignUp
    });
  }

  private generateCustomFields(): Array<any> {
    const FIELDS = [];

    const INVALID_NAME = this.translate.instant('Invalid Name');

    if (this.userType === 'advisor') {
      FIELDS.push({
        type: 'hidden',
        name: 'isGorilaPRO',
        value: 'true',
      });
    }
    FIELDS.push({
      name: 'name',
      prefill: path(['name'], this.signParams),
      placeholder: this.translate.instant('Input your full name'),
      validator: function (value) {
        return {
          valid: value !== '',
          hint: INVALID_NAME, // optional
        };
      },
    });

    return FIELDS;
  }
}
