import { Action } from '@ngrx/store';
import { CheckAuthorizationPayload, SignupPayload, UserAccountList } from '../models/user-account.model';

export enum UserAccountActionTypes {
  UserAccountLoad = '[UserAccount] Load Accounts',
  _UserAccountLoad = '[UserAccount] Load Accounts END',
  UpdateState = '[UserAccount] Update State',
  ForceUpdateStatus = '[UserAccount] Force Update Status',
  Signup = '[UserAccount] Signup',
  Disconnect = '[UserAccount] Disconnect',
  CheckAuthorization = '[UserAccount] Check Authorization'
}

export class Load implements Action {
  public readonly type = UserAccountActionTypes.UserAccountLoad;
}

// tslint:disable-next-line:class-name
export class _Load implements Action {
  public readonly type = UserAccountActionTypes._UserAccountLoad;
  public payload: UserAccountList = [];
  constructor(payload: UserAccountList) {
    this.payload = payload;
  }
}

export class ForceUpdateStatus implements Action {
  public readonly type = UserAccountActionTypes.ForceUpdateStatus;
  constructor(public providerId: number) { }
}

export class UpdateState implements Action {
  public readonly type = UserAccountActionTypes.UpdateState;
  public payload: any;
  constructor(providerId: number, payload: any, replaceAll = false) {
    this.payload = { ...payload, providerId, replaceAll };
  }
}

export class Signup implements Action {
  public readonly type = UserAccountActionTypes.Signup;
  public payload: any;

  constructor(providerId: number, signupPayload: SignupPayload) {
    this.payload = { providerId, signupPayload };
  }
}

export class Disconnect implements Action {
  public readonly type = UserAccountActionTypes.Disconnect;
  public payload: any;
  constructor(providerId: number, providerName: string, dropAll: boolean) {
    this.payload = { providerId, providerName, dropAll };
  }
}

export class CheckAuthorization implements Action {
  public readonly type = UserAccountActionTypes.CheckAuthorization;
  constructor(public payload: CheckAuthorizationPayload) { }
}

export type UserAccountActions = Load | _Load | UpdateState | Disconnect;
