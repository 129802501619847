import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';

export const AdvisorInviteRoutePath = 'convidar';

export const AdvisorInviteRoute: Route = {
  data: {
    title: 'Convidar usuário',
    hideFilter: true,
    isB2BRoute: true,
  },
  path: AdvisorInviteRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/invite-shell#InviteShellModule'
};
