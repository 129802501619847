import { Route } from '@angular/router';

import { AdvisorFundGuard } from '../../../guards/advisor-fund.guard';
import { ReportToolRoutePath } from './metadata';
import { environment } from '@env/environment';

export const ReportToolMenuData = {
  featureName: 'report',
  routePath: ReportToolRoutePath,
  icon: 'Nav/Advanced-Tools/Report-White',
  text: 'TOOLS_DROPDOWN.REPORT',
  cancelNavigation: environment.features.tools.report.modal
};

export const ReportToolRoute: Route = {
  data: {
    title: 'Relatório por Período'
  },
  path: ReportToolRoutePath,
  canActivate: [AdvisorFundGuard],
  loadChildren: '@gorila-bot/report-container#ReportContainerModule'
};
