import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderButtonsModule } from './header-buttons';
import { HeaderDropdownModule } from './header-dropdown';
import { HeaderHideModule } from './header-hide';
import { HeaderIconsModule } from './header-icons';
import { UserMenuModule } from './user-menu';

@NgModule({
  imports: [
    CommonModule,
    HeaderButtonsModule,
    HeaderDropdownModule,
    HeaderHideModule,
    HeaderIconsModule,
    UserMenuModule
  ],
  exports: [
    HeaderButtonsModule,
    HeaderDropdownModule,
    HeaderHideModule,
    HeaderIconsModule,
    UserMenuModule
  ]
})
export class HeaderComponentsModule {}
