import { Injectable } from '@angular/core';
import { IProfitabilityType } from '@gorila-bot/gorila-front-models';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { AppState, STATE_TYPES } from '../../../store.model';
import { ProfitabilityAPIAction } from '../actions/profitability-base.actions';
import { ProfitabilityPnlActions } from '../actions/profitability-pnl.actions';
import { ProfitabilityRequestService } from '../profitability-request.service';
import { mapPnl, ProfitabilityBaseAPIEpics } from './profitability-base.epics';

@Injectable()
export class ProfitabilityPNLEpics extends ProfitabilityBaseAPIEpics {
  constructor(
    private profitabilityActions: ProfitabilityPnlActions,
    protected profitabilityRequestService: ProfitabilityRequestService
  ) {
    super(profitabilityRequestService);
  }

  public getApiAction(): ProfitabilityPnlActions {
    return this.profitabilityActions;
  }

  public getActionsOfApiAction() {
    return ProfitabilityPnlActions.actions;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return combineEpics<Action, any, AppState, any>(
      this.createUpdateEpic(filterIfNotLogged),
      this.createAddPNLEpic(filterIfNotLogged)
    );
  }

  public getStateType(): string {
    return STATE_TYPES.PROFITABILITY_PNL;
  }

  public createAddPNLEpic(ifNotLogged): Epic<ProfitabilityAPIAction, ProfitabilityAPIAction, AppState> {
    const actionType = ProfitabilityPnlActions.actions.addPnl;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => {
      try {
        const current: IProfitabilityType[] = action.meta.profitabilityType;
        const observable = this.profitabilityRequestService.doMultiRequests(current).pipe(
          map(data => mapPnl(data, current))
        );

        return observable.pipe(
          filter(() => ifNotLogged(store.value)),
          map(data => {
            const meta = { profitabilityType: current, stateType: this.getStateType() };
            return this.profitabilityActions.loadedPnl(meta, data);
          }),
          catchError(response => from([this.profitabilityActions.loadError(
            { profitabilityType: current, stateType: this.getStateType() },
            { status: '' + response }
          )]))
        );
      } catch (e) { console.warn(e); }
    });
  }
}
