import { Injectable } from '@angular/core';

import { STATE_TYPES } from '../../../store.model';
import { ProfitabilityYearlyActions } from '../actions/profitability-yearly.actions';
import { ProfitabilityRequestService } from '../profitability-request.service';
import { ProfitabilityBaseAPIEpics } from './profitability-base.epics';

@Injectable()
export class ProfitabilityYearlyEpics extends ProfitabilityBaseAPIEpics {
  constructor(
    private profitabilityActions: ProfitabilityYearlyActions,
    profitabilityRequestService: ProfitabilityRequestService
  ) {
    super(profitabilityRequestService);
  }

  public getApiAction(): ProfitabilityYearlyActions {
    return this.profitabilityActions;
  }

  public getActionsOfApiAction() {
    return ProfitabilityYearlyActions.actions;
  }

  public getStateType(): string {
    return STATE_TYPES.PROFITABILITY_YEARLY;
  }
}
