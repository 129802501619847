import { UserAccountActions, UserAccountActionTypes } from '../actions/user-account.actions';
import { initialState, State } from '../user-account.state';

export function reducer(state = initialState, action: UserAccountActions): State {
  switch (action.type) {
    case UserAccountActionTypes._UserAccountLoad:
      return { ...state, accounts: action.payload };

    case UserAccountActionTypes.UpdateState:
      const {
        providerId,
        providerState,
        stateDescription,
        connectionParameters,
        replaceAll
      } = action.payload;
      const temp = state.accounts.map(data => {
        if (data.providerId === providerId) {
          if (replaceAll) {
            delete action.payload.replaceAll;
            return {
              ...data,
              ...action.payload
            };
          }
          data.providerState = providerState;
          data.connectionParameters = connectionParameters !== undefined ? connectionParameters : data.connectionParameters;
          data.stateDescription = stateDescription;
        }
        return { ...data };
      });
      return {...state, accounts: [...temp]};

    default:
      return state;
  }
}
