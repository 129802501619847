import { Injectable } from '@angular/core';
import { AppConstants } from '@gorila/constants';
import * as moment from 'moment';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { filter ,  switchMap ,  map ,  catchError ,  startWith } from 'rxjs/operators';

import { BaseEpic } from '../../base/epic.base';
import { AppState, STATE_TYPES, StateType } from '../../store.model';
import { positionFromServer, PositionRequestService } from '../position';
import { BlotterAPIAction, BlotterAPIActions } from './blotter.actions';

const blotterNotAlreadyFetched = (
  stateType: StateType,
  state: AppState): boolean => !(
    state[stateType] &&
    state[stateType].items &&
    Object.keys(state[stateType].items).length);

const actionIsForCorrectStateType = (action: BlotterAPIAction): boolean => action.meta.stateType === STATE_TYPES.BLOTTER;

const isRequestedPosition = payload => position => {
  if (position.BrokerName === payload.brokerName && position.SecurityName === payload.securityName) { return true; }
  return moment.utc(position.RefDate, AppConstants.Format.Date.American).isSameOrAfter(payload.minDate);
};

@Injectable()
export class BlotterAPIEpics extends BaseEpic {
  constructor(
    private blotterActions: BlotterAPIActions,
    private positionRequestService: PositionRequestService
  ) {
    super();
  }

  public getStateType(): string {
    return STATE_TYPES.BLOTTER;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return combineEpics<Action, any, AppState, any>(this.createLoadPositionEpic(filterIfNotLogged));
  }

  private createLoadPositionEpic(ifNotLogged): Epic<BlotterAPIAction, BlotterAPIAction, AppState> {
    return (action$, store) => action$.pipe(
      ofType(BlotterAPIActions.actions.position.data),
      filter(actionIsForCorrectStateType),
      filter(action => blotterNotAlreadyFetched(STATE_TYPES.BLOTTER, store.value)),
      filter(() => ifNotLogged(store.value)),
      switchMap(action => this.positionRequestService.doRequest({
        securityName: action.payload.securityName,
        minDate: action.payload.minDate,
        maxDate: action.payload.minDate
      }).pipe(
        map(positions => positions.filter(isRequestedPosition(action))[0]),
        filter(position => !!position),
        map(positionFromServer),
        map(position => this.blotterActions.succeeded(position)),
        catchError(error => from([this.blotterActions.failed(error)])),
        startWith(this.blotterActions.started()),
      )
    ));
  }
}
