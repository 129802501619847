import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { Fund, UserDataSelectors, UserDataState } from '@gorila-bot/user-data-store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map ,  take } from 'rxjs/operators';

@Injectable()
export class PermissionGuard implements CanLoad, CanActivateChild {
  constructor(private store: Store<UserDataState.State>) { }

  public canLoad(route: Route): Observable<boolean> {
    return this.checkPermissions(route.data);
  }

  public canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkPermissions(next.data);
  }

  private checkPermissions(data): Observable<boolean> {
      const stringPermission = data && typeof data.permissions === 'string';
      const existPermission = data && data.permissions && (stringPermission || data.permissions.length);
      const checkPermission = (fundData: any) => stringPermission
        ? fundData.fundTypeName === data.permissions
        : data.permissions.indexOf(fundData.fundTypeName) > -1;
      return this.store
        .pipe(
          select(UserDataSelectors.selectFeatureCurrentLoggedFundData),
          take(1),
          map((fundData: Fund) => !existPermission || checkPermission(fundData)),
        );
    }
}
