import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { path } from 'ramda';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { Alert } from '../../model/alert';

@Component({
  selector: 'app-alert-item',
  templateUrl: './alert-item.component.html',
  styleUrls: ['./alert-item.component.scss']
})
export class AlertItemComponent implements OnChanges {
  @Input() public object: Alert;
  @Output() public removed = new EventEmitter();
  public classData = {};

  constructor() { }

  public ngOnChanges(data: SimpleChanges) {
    try {
      this.classData = this.getClass();
      const t: number = path(['timer'], this.object);
      if (!t) { return; }
      timer(t).pipe(take(1), untilDestroyed(this)).subscribe(() => this.remove());
    } catch (e) { console.warn(e); }
  }

  public getClass(): string {
    try {
      switch (this.object.type) {
        case 'error':
        case 'erro':
        case 'danger': return 'alert-danger';
        case 'warning': return 'alert-warning';
        case 'success': return 'alert-success';
        case 'info': return 'alert-info';
      }
    } catch (e) { console.warn(e); }
    return 'alert-success';
  }

  public remove() {
    this.removed.emit(this.object._id);
  }

}
