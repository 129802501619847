import { BaseEpic } from '../../base/epic.base';
import { Injectable } from '@angular/core';
import { STATE_TYPES } from '../../store.model';
@Injectable()
export class PortfolioAPIEpics extends BaseEpic {
  constructor() {
    super();
  }

  public getStateType(): string {
    return STATE_TYPES.PORTFOLIO;
  }

  public createEpic(): void {
    return null;
  }

}
