import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAccountModel } from '@gorila/root-store/user-account/src/models/user-account.model';
import { UserAccountDetailsComponent } from './user-account-details.component';
import { buildModalConfig } from './user-account.model';
@Injectable({
  providedIn: 'root'
})
export class UserAccountDetailsService {
    public constructor(
        private dialog: MatDialog,
        private sanitizer: DomSanitizer
    ) { }

    public open(provider: UserAccountModel): MatDialogRef<UserAccountDetailsComponent> {
        const iframeUrl = provider.connectionParameters ? this.buildIframeUrl(provider.connectionParameters) : undefined;
        return this.dialog.open(UserAccountDetailsComponent, buildModalConfig(provider, iframeUrl));
    }

    private buildIframeUrl(connectionParameters: any) {
        let iframeUrl = connectionParameters.domain;

        if (connectionParameters.query) {
            iframeUrl += this.buildQueryParams(connectionParameters.query);
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
    }

    private buildQueryParams(queryParams: object) {
        let params = '?';

        Object.keys(queryParams).forEach((key, i) => {
            if (i) {
                params += '&';
            }
            params += `${key}=${queryParams[key]}`;
        });

        return params;
    }
}
