<gor-partner-header partnerLogoFolder="Illustrations/Tools" customTitleText="PARTNER.CONNECTION_SECURITY.HEADER_TITLE" [borderless]="true" [helpLink]="data?.urlGuia" [showCloseButton]="true"></gor-partner-header>
<ul class="connection-security__items">
  <li *ngFor="let item of [1,2,3]" class="connection-security__item">
    <gor-icon class="connection-security__item-icon color-filter--gorila-primary" iconFolder="Platform/Information/Security" icon="Locked" width="24px" height="24px"></gor-icon>
    <span [innerHTML]="('PARTNER.CONNECTION_SECURITY.LIST_ITEM_'+item)|translate"></span>
  </li>
</ul>
<div class="connection-security__footer" mat-dialog-actions>
  <gor-button btnType="green" mat-button [mat-dialog-close]="true">
    {{ 'PARTNER.CONNECTION_SECURITY.BUTTON_TEXT'|translate }}
  </gor-button>
</div>