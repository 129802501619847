export type ProviderStateType =
  'AWAITING_AUTHORIZATION'
  | 'AWAITING_UNAUTHORIZATION'
  | 'AWAITING_DATA'
  | 'BUILDING'
  | 'CALCULATING_WALLET'
  | 'CONNECTED'
  | 'DISABLED'
  | 'DISCONNECTED'
  | 'ERROR'
  | 'ERROR_ALREADY_IN_USE'
  | 'ERROR_AUTHORIZATION'
  | 'ERROR_AUTHENTICATION'
  | 'ERROR_PAYLOAD'
  | 'ERROR_SERVICE'
  | 'ERROR_UNEXPECTED'
  | 'ERROR_TIMEOUT'
  | 'READY_TO_UPDATE'
  | 'TESTING'
  | 'TOKEN_EXPIRED'
  | 'UPDATED'
  | 'UPDATING';

export const PROVIDER_STATE: { [key: string]: ProviderStateType } = {
  awaiting_authorization: 'AWAITING_AUTHORIZATION',
  awaiting_unauthorization: 'AWAITING_UNAUTHORIZATION',
  building: 'BUILDING',
  calculating_wallet: 'CALCULATING_WALLET',
  connected: 'CONNECTED',
  disabled: 'DISABLED',
  disconnected: 'DISABLED',
  error: 'ERROR',
  error_already_in_use: 'ERROR_ALREADY_IN_USE',
  error_authorization: 'ERROR_AUTHORIZATION',
  error_authentication: 'ERROR_AUTHENTICATION',
  error_payload: 'ERROR_PAYLOAD',
  error_service: 'ERROR_SERVICE',
  error_unexpected: 'ERROR_UNEXPECTED',
  error_timeout: 'ERROR_TIMEOUT',
  ready_to_update: 'READY_TO_UPDATE',
  testing: 'TESTING',
  token_expired: 'TOKEN_EXPIRED',
  updated: 'UPDATED',
  updating: 'UPDATING'
};

export const AVAILABLE_PROVIDERS: ProviderStateType[] = [
  'AWAITING_AUTHORIZATION',
  'AWAITING_UNAUTHORIZATION',
  'CALCULATING_WALLET',
  'CONNECTED',
  'DISABLED',
  'ERROR',
  'ERROR_ALREADY_IN_USE',
  'ERROR_AUTHENTICATION',
  'ERROR_AUTHORIZATION',
  'ERROR_PAYLOAD',
  'ERROR_SERVICE',
  'ERROR_UNEXPECTED',
  'ERROR_TIMEOUT',
  'READY_TO_UPDATE',
  'TOKEN_EXPIRED',
  'UPDATED',
  'UPDATING'
];

export interface UserAccountModel {
  legacyProviderId?: number;
  walletId?: number;
  providerId: number;
  providerName: string;
  providerState: ProviderStateType;
  providerType: string;
  signUpFields: string[];
  stateDescription: string;
  lastUpdate: string;
  errorDescription?: string;
  hasTrades?: boolean;
  integrationType?: IntegrationType;
  isBeta?: boolean;
  isDeprecated?: boolean;
  isInMaintenance?: boolean;
  connectionParameters?: {
    domain: string;
    query?: any;
  };
}

export enum IntegrationType {
  FOCUS_ON_TRADE = 'FOCUS_ON_TRADE',
  FOCUS_ON_POSITION = 'FOCUS_ON_POSITION',
}

export interface UserAccountAvailableIntegrationType {
  type: IntegrationType;
  description: string;
  prosCons: { icon: string, text: string }[];
}

export type UserAccountList = UserAccountModel[];

export interface CheckAuthorizationPayload {
  check: boolean;
  integrationType: IntegrationType;
  login: string;
  providerId: number;
}

export interface SignupPayload {
  login?: string;
  password?: string;
  check?: boolean;
  integrationType?: IntegrationType;
  key_id?: string;
  key?: string;
}
