<span *ngIf="item.isBeta" class="beta">BETA</span>
<a
  [id]="item.id"
  class="icon__button"
  gorCancelableRouterLink
  [cancelableRouterLink]="item.link"
  [cancel]="!!item.cancelNavigation || !item.link"
>
  <span
    class="icon__container"
    [class.disabled]="!item.link"
    [class.active]="item.active"
  >
    <gor-icon
      [icon]="item.icon"
      width="32px"
      height="32px"
      class="icon--white"
    ></gor-icon>
    <span *ngIf="item.displayPremiumIcon" class="premium-logo">
      <gor-icon
        iconFolder="Status"
        icon="lock-background"
      ></gor-icon>
    </span>
  </span>
  <span
    class="icon__text"
  >
    {{ item.text | translate }}
  </span>
</a>
