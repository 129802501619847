import { Injectable } from '@angular/core';
import { SocketEventService } from '@gorila-bot/gorila-base';
import { ToastService, ToastSeverityIcons } from '@gorila-bot/toast';
import { UserDataActions } from '@gorila-bot/user-data-store';
import { SharedModalsUserAccountsService } from '@gorila/pages/user/user-account/components/modals/shared-modals.service';
import { UserAccountDetailsModel } from '@gorila/pages/user/user-account/components/modals/user-account-details/user-account.model';
import { UserAccountData } from '@gorila/pages/user/user-account/components/user-account.data';
import { PositionEventListenerService } from '@gorila/root-store/position/src/services/position-event-listener.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { path } from 'ramda';
import { skip, take } from 'rxjs/operators';
import * as UserAccountSelectors from '../user-account.selector';

import { UpdateState } from '../actions/user-account.actions';
import { PROVIDER_STATE, UserAccountList, UserAccountModel } from '../models/user-account.model';
import { State } from '../user-account.state';

@Injectable()
export class UserAccountEventListenerService {
  private notConnected = {};
  private lastEvents = {};
  private readonly syncToastId = 'calc_wallet' as const;

  public constructor(
    private positionEventListenerService: PositionEventListenerService,
    private socketEventService: SocketEventService,
    private modalsService: SharedModalsUserAccountsService,
    private store: Store<State>,
    private toast: ToastService,
    private translate: TranslateService
  ) { }

  public listenUserAccountEvents() {
    try {
      this.store.pipe(
        select(UserAccountSelectors.selectFeatureAccounts),
        skip(1),
        take(1)
      ).subscribe((providers: UserAccountList) => {
        providers.forEach(provider => {
          this.updateNotConnected(provider);
        });
      });
      this.socketEventService.getObserver('UPDATE_PROVIDER_STATUS')
        .pipe(skip(1))
        .subscribe(data => {
          const status: UserAccountModel = path(['ProviderStatus'], data);
          this.checkProvider(status);
          this.store.dispatch(new UpdateState(status.providerId, status, true));
        });
    } catch (e) { console.warn(e); }
  }

  private checkProvider(provider: UserAccountModel) {
    const { errorDescription, providerId, providerState, walletId } = provider;
    if (this.lastEvents[providerId] === providerState) {
      return;
    }

    if (providerState === PROVIDER_STATE.calculating_wallet) {
      this.toast.add({
        id: this.syncToastId,
        severity: 'sync',
        icon: {
          name: ToastSeverityIcons.SYNC,
          height: '14px',
          width: '14px'
        },
        summary: this.translate.instant('TOAST.CALCULATING_WALLET.SUMMARY'),
        detail: this.translate.instant('TOAST.CALCULATING_WALLET.DETAIL')
      });
    } else if (this.lastEvents[providerId] === PROVIDER_STATE.calculating_wallet) {
      if (walletId) {
        this.store.dispatch(new UserDataActions.UserAddWalletId(walletId));
      }
      this.positionEventListenerService.refreshPortfolio();
      this.toast.removeById(this.syncToastId, true);

      if (providerState.startsWith('ERROR') || providerState === PROVIDER_STATE.token_expired) {
        this.toast.addRaw(
          'error',
          this.translate.instant('TOAST.CONNECT_SYNC_ERROR.SUMMARY'),
          {
            actions: [{
              text: this.translate.instant('TOAST.CONNECT_SYNC_ERROR.ACTION_TEXT'),
              routerLink: ['app', 'area-do-usuario', 'contas']
            }]
          }
        );
      }
    }

    if (providerState === PROVIDER_STATE.error_authentication && errorDescription) {
      this.modalsService.openSyncError(provider, path([providerId, 'urlGuia'], UserAccountData));
    } else if (providerState === PROVIDER_STATE.updated) {
      this.showModalAfterConnectSuccess(provider);
    }

    if ([PROVIDER_STATE.disabled, PROVIDER_STATE.updated].includes(providerState) || providerState.startsWith('ERROR')) {
      this.updateNotConnected(provider);
    }
    this.lastEvents[providerId] = providerState;
  }

  private showModalAfterConnectSuccess(provider: UserAccountModel) {
    const accountData: UserAccountDetailsModel = UserAccountData[provider.providerId];
    if (accountData.accountSync) {
      // display account sync modal
      return this.modalsService.openConnectionExpectation(
        provider,
        true,
        'accountSync'
      );
    }

    if (!!accountData.posConnect && provider.providerState === PROVIDER_STATE.updated && this.notConnected[provider.providerId]) {
      this.modalsService.openConnectionExpectation(provider, true, 'posConnect');
    }
  }

  private updateNotConnected(provider: UserAccountModel): boolean {
    return this.notConnected[provider.providerId] =
      provider.providerState === PROVIDER_STATE.disabled || (provider.providerState && provider.providerState.startsWith('ERROR'));
  }
}
