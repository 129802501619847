// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { environmentBase } from './environment.base';
import { Env } from './models/env.model';

const inS3 = location.host.indexOf('ssr-front') !== -1;
const env: Env = {
  ...environmentBase,
  useAccessToken: false,
  name: 'development',
  Auth0: {
    allowSignUp: true,
    clientID: 'AsogTpZExRxMKSE2cLQrYu18u9WUVqNJ',
    domain: 'gorilainvest.auth0.com',
    responseType: 'token id_token',
    audience: 'https://gorilainvest.auth0.com/api/v2/',
    redirectUrl: `${location.protocol}//${location.host}${inS3 ? `?path=${location.pathname}` : '/'}`,
    scope: 'openid email app_metadata user_metadata', // Learn about scopes: https://auth0.com/docs/scopes
    database: 'Username-Password-Authentication'
  },
  serviceBaseUrl: 'https://api.dev.gorila.com.br/',
  whitelistedDomains: [
    'api.dev.gorila.com.br',
    'dev.gorila.com.br',
    'gorilainvest.auth0.com'
  ],
  socketUrl: 'wss://dev.gorila.com.br/',
};

env.features.manager.inviteClient = true;
env.features.profitabilityChart.displayNewChart = true;
env.features.tools.availableInvestments.page = false;
env.features.tools.advancedProfitability.page = true;
env.features.tools.advancedProfitability.menu = true;
env.features.tools.portfolioEvent.page = true;
env.features.tools.portfolioEvent.menu = true;
env.features.tools.header.premium = true;
env.features.tools.tradeTool.page = true;
env.features.tools.tradeTool.menu = true;
env.features.trades.edit = true;
env.features.user.account.page = true;
env.features.user.account.menu = true;
env.features.user.payment.page = false;
env.features.user.payment.menu = false;
env.features.user.payment.history = false;
env.features.user.payment.updateCard = false;
env.features.user.payment.cancelSubscription = false;
env.features.wallet.displayPositionGenerators = true;

export const environment = env;
