<gor-toast></gor-toast>
<app-header></app-header>
<app-shell-filter [hideFilter]="hideFilter"></app-shell-filter>
<div class="router-container" cdk-scrollable>
  <ng-container *ngIf="!isLoading; else loadingTmpl">
    <router-outlet></router-outlet>
  </ng-container>
</div>
<div class="overlay-container">
  <router-outlet name="transacoes"></router-outlet>
</div>

<ng-template #loadingTmpl>
  <gor-loading [stopLoading]="!isLoading" [message]="'loading' | translate"></gor-loading>
</ng-template>
