import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {

  public transform(jsobject: any) {
    try {
        return Object.keys(jsobject);
    } catch (e) {
        console.warn(e);
        return [];
    }
  }
}
