import { PlatformModule } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';
import { BaseButtonModule } from '@gorila-bot/base-button';
import { LogoModule } from '@gorila-bot/logo';
import { SignupModule } from '@gorila-bot/signup';
import { WhiteLabelModule } from '@gorila-bot/white-label';
import { CoreModule } from '@gorila/core/module';

import { SharedModule } from '@gorila/shared/module';
import { SocketModule } from '@gorila/socket/module';
import { ButtonModule } from '@gorilainvest/ui-toolkit/button';
import { IconModule } from '@gorilainvest/ui-toolkit/icon';
import { LoadingModule } from '@gorilainvest/ui-toolkit/loading';
import { TranslateModule } from '@ngx-translate/core';

import { Auth0LockComponent } from './auth0lock/auth0lock.component';
import { AuthNoLockComponent } from './authnolock/authnolock.component';
import { ConnectComponent } from './authnolock/connect/connect.component';
import { EmailComponent } from './authnolock/email/email.component';
import { ResetPasswordComponent } from './authnolock/reset-password/reset-password.component';
import { LoginComponent } from './authnolock/login/login.component';
import { CallbackComponent } from './callback/callback.component';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    CoreModule,
    PlatformModule,
    ButtonModule,
    BaseButtonModule,
    IconModule,
    SharedModule,
    SocketModule,
    TranslateModule,
    LoadingModule,
    LogoModule,
    WhiteLabelModule,
    SignupModule,
  ],
  declarations: [
    Auth0LockComponent,
    AuthNoLockComponent,
    CallbackComponent,
    ConnectComponent,
    LoginComponent,
    EmailComponent,
    ResetPasswordComponent,
  ],
  exports: [AuthNoLockComponent],
  providers: [AuthService],
})
export class AuthModule {}
