export const getInitials = (name: string): string => {
  if (!name) {
    return '';
  }
  const nameParts = name.split(' ');
  if (nameParts.length === 0) {
    return '';
  }
  if (nameParts.length === 1) {
    if (nameParts[0].length === 0) {
      return '';
    }
    if (nameParts[0].length === 1) {
      return nameParts[0];
    }
    return nameParts[0].charAt(0) + nameParts[0].charAt(1);
  }
  return nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0);
};
