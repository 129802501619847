import { IPositionIndexed, IPositionServer } from '@gorila-bot/gorila-front-models';

export const Key = 'position';

export interface PositionState {
  positionsServer: IPositionServer[];
  positions: IPositionIndexed;
  loading?: boolean;
  error?: any;
}

export const initialState: PositionState = {
  positionsServer: null,
  positions: {},
  loading: false,
  error: null
};
