import { Component, EventEmitter, Output } from '@angular/core';
import { AuthFormEvent } from '../../models/authnolock.model';

@Component({
  selector: 'connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent {
  @Output() public triggerNav = new EventEmitter<AuthFormEvent>();

  public readonly navLogin = AuthFormEvent.NAV_LOGIN;
  public readonly navSignup = AuthFormEvent.NAV_SIGNUP;
}
