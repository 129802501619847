import { values } from 'ramda';

import { SubProduct, SubProducts } from '../subproduct/subproduct.model';

/**
 * Interface for objects that represents a product.
 *
 * @interface
 */
export interface Product {
  /**
   * Unique identifier of a product.
   *
   * @type {string}
   */
  id: string;

  /**
   * The name of the product.
   *
   * @type {string}
   */
  name: string;

  /**
   * How many money the user have with the product.
   *
   * @type {number}
   */
  value: number;

  /**
   * Percentage of this product based on all products from wallet
   *
   * @type {number}
   */
  percentual: number;

  /**
   * A list of subproducts related to the product, identified by an unique identifier.
   *
   * @type {Array<string>}
   * @see {SubProduct}
   */
  subproducts?: Array<string>;

  /**
   * The fund id that owns the subproduct.
   *
   * @type {string}
   */
  fundId: string;
}
export type Products = Array<Product>;

export const insertSubProductOnProduct = (subProduct: SubProduct, product: Product): Product => {
  if (product.id !== subProduct.assetClass.id) { return product; }

  const newSubProduct = product;
  newSubProduct.value = product.value + subProduct.value;
  if (product.subproducts.indexOf(subProduct.id) === -1) {
    newSubProduct.subproducts = [...product.subproducts, subProduct.id];
  }
  return newSubProduct;
};

export const mapSubProductToProduct = (subProduct: SubProduct): Product => ({
  id: subProduct.assetClass.id,
  name: subProduct.assetClass.name,
  value: subProduct.value,
  percentual: 0,
  subproducts: [subProduct.id],
  fundId: subProduct.fundId
});

export const mapSubProductsToProducts = (subProducts: SubProducts, sortFn = (s, t) => t.percentual - s.percentual): Product => {
  const indexedProducts: {[key: string]: Product} = {};
  const totalValue = subProducts.reduce((total: number, subProduct: SubProduct) => total + subProduct.value, 0);
  subProducts.forEach((subProduct: SubProduct) =>
    indexedProducts[subProduct.assetClass.id] = indexedProducts[subProduct.assetClass.id]
      && insertSubProductOnProduct(subProduct, indexedProducts[subProduct.assetClass.id])
      || mapSubProductToProduct(subProduct));
  return values(indexedProducts)
    .map((product: Product) => ({ ...product, percentual: product.value / totalValue }))
    .sort(sortFn) as any;
};
