import { Injectable } from '@angular/core';
import { IProfitabilityType } from '@gorila-bot/gorila-front-models';
import { AppConstants } from '@gorila/constants';
import * as moment from 'moment';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { combineLatest, from, merge } from 'rxjs';
import { catchError, filter, map, startWith } from 'rxjs/operators';

import { BaseEpic } from '../../base/epic.base';
import { AppState, STATE_TYPES } from '../../store.model';
import { ActionType } from '../actions.model';
import { isValid } from '../asset-class.model';
import { ProfitabilityRequestService } from '../profitability';
import { SubProductAPIAction, SubProductAPIActions } from '../subproduct/subproduct.actions';
import { PositionRequestService } from './position-request.service';
import { PositionAPIAction, PositionAPIActions, PositionRemoveAPIAction } from './position.actions';
import { fromServer, Position } from './position.model';

const haveAssetClass = (position: Position) => {
  if (isValid(position.assetClass)) { return true; }
  console.warn('### Position without asset class:', position);
  return false;
};

const positionResponse = res => res.Positions;
const profitResponse = res => res.Profit;

const mapCombineLatestPnl = combined => ({
  daily: combined[1].map(profitResponse),
  monthly: combined[2].map(profitResponse),
  yearly: combined[3].map(profitResponse),
  total: combined[4].map(profitResponse)
});
const validCombineLatest = combined => !!combined && !!combined[0];

@Injectable()
export class PositionAPIEpics extends BaseEpic {
  private lastDay: string;
  private lastMonth: string;

  constructor(
    private service: PositionRequestService,
    private positionActions: PositionAPIActions,
    private profitabilityRequester: ProfitabilityRequestService,
    private subProductActions: SubProductAPIActions
  ) {
    super();
    this.lastDay = moment.utc().subtract(1, 'days').format(AppConstants.Format.Date.American);
    this.lastMonth = moment.utc().subtract(1, 'months').endOf('month').format(AppConstants.Format.Date.American);
  }

  public getStateType(): string {
    return STATE_TYPES.POSITION;
  }

  public createEpic(filterIfNotLogged = (..._) => true) {
    return combineEpics<Action, any, AppState, any>(
      this.createLoadPositionEpic(filterIfNotLogged),
      this.createLoadSuccessPositionEpic(filterIfNotLogged),
      this.createRemovePositionEpic(filterIfNotLogged),
      this.createRemoveSuccessPositionEpic(filterIfNotLogged),
      this.createUpdatePositionEpic(filterIfNotLogged),
      this.createUpdateSuccessPositionEpic(filterIfNotLogged)
    );
  }

  private createLoadPositionEpic(ifNotLogged): Epic<PositionAPIAction, PositionAPIAction, AppState> {
    const params: IProfitabilityType = {
      groupBySecurity: true,
      groupByBroker: true,
    };
    const actionType = PositionAPIActions.actionType.load.data;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => combineLatest([
      this.service.doRequest({}),
      this.profitabilityRequester.doRequest({ ...params, periodType: 'DAILY', minDate: this.lastDay }),
      this.profitabilityRequester.doRequest({ ...params, periodType: 'MONTHLY', minDate: this.lastMonth }),
      from([[]]),
      from([[]])
    ]).pipe(
      map(combined => [combined[0].map(positionResponse).map(p => p.map(fromServer)), mapCombineLatestPnl(combined)]),
      map(data => this.positionActions.succeededWithProfits(
        action.meta.actionType,
        this.getStateType(),
        (data[0][0] || data[0]) as Position[],
        data[1]
      )),
      catchError(error => from([this.positionActions.failed(action.meta.actionType, this.getStateType(), error)])),
      startWith(this.positionActions.started(action.meta.actionType, this.getStateType()))
    ));
  }

  private createLoadSuccessPositionEpic(ifNotLogged): Epic<SubProductAPIAction, SubProductAPIAction, AppState> {
    const actionType = PositionAPIActions.actionType.load.success;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => merge(
      from([this.positionActions.map(this.getStateType(), store.value['trade']['items'])]),
      from([this.subProductActions.map(STATE_TYPES.SUBPRODUCT, store.value['position']['items'])])
    ), false);
  }

  private createUpdatePositionEpic(ifNotLogged): Epic<PositionAPIAction, PositionAPIAction, AppState> {
    const params: IProfitabilityType = {
      groupByBroker: true
    };
    const actionType = PositionAPIActions.actionType.update.data;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => combineLatest([
      from(action.payload),
      this.profitabilityRequester.doRequest({
        ...params,
        periodType: 'DAILY',
        minDate: this.lastDay,
        securityName: action.payload['SecurityName']
      }),
      this.profitabilityRequester.doRequest({
        ...params,
        periodType: 'MONTHLY',
        minDate: this.lastMonth,
        securityName: action.payload['SecurityName']
      }),
      from([]),
      from([])
    ]).pipe(
      filter(validCombineLatest),
      map(combined => [fromServer(combined[0]), mapCombineLatestPnl(combined)]),
      filter(combined => haveAssetClass(combined[0] as Position)),
      map(data => this.positionActions.updateSucceededWithProfits(
        action.meta.actionType,
        this.getStateType(),
        data[0] as Position,
        data[1]
      )),
      catchError(error => from([this.positionActions.failed(action.meta.actionType, this.getStateType(), error)])),
      startWith(this.positionActions.started(action.meta.actionType, this.getStateType()))
    ),
    false
    );
  }

  private createUpdateSuccessPositionEpic(ifNotLogged): Epic<SubProductAPIAction, SubProductAPIAction, AppState> {
    const actionType = PositionAPIActions.actionType.update.success;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => merge(
      from([this.positionActions.map(this.getStateType(), store.value['trade']['items'])]),
      from([this.subProductActions.map(STATE_TYPES.SUBPRODUCT, store.value['position']['items'])])
    ), false);
  }

  private createRemovePositionEpic(ifNotLogged): Epic<PositionRemoveAPIAction, PositionRemoveAPIAction, AppState> {
    const actionType = PositionAPIActions.actionType.remove.data;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) => from([action.payload]).pipe(
      filter(positionId => !!store.value.position.items[positionId]),
      map(position => this.positionActions.removeSucceeded(this.getStateType(), position)),
      catchError(error => from([this.positionActions.failed(ActionType.REMOVE, this.getStateType(), error)])),
      startWith(this.positionActions.started(ActionType.REMOVE, this.getStateType()))
    ), false);
  }

  private createRemoveSuccessPositionEpic(ifNotLogged): Epic<SubProductAPIAction, SubProductAPIAction, AppState> {
    const actionType = PositionAPIActions.actionType.remove.success;
    return this.createBaseEpic(actionType, ifNotLogged, (action, store) =>
      from([this.subProductActions.map(STATE_TYPES.SUBPRODUCT, store.value['position']['items'])]), false);
  }
}
