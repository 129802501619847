import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldDataObject, FormBaseComponent, FormCreatorService } from '@gorila-bot/forms';
import { AuthFormEvent, AuthFormEventData } from '../../models/authnolock.model';

@Component({
  selector: 'email-form',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailComponent extends FormBaseComponent implements OnDestroy, OnInit {
  public readonly eventType = AuthFormEvent;

  @Input() public message = { type: '', error: false };
  @Input() public isResetPassword: boolean;

  @Output() public emailEvent = new EventEmitter<AuthFormEventData>();

  public fields: FieldDataObject = {
    email: {
      value: '',
      required: true,
      disabled: false,
      type: 'email',
      validators: [Validators.email]
    }
  };

  constructor(
    protected formCreatorService: FormCreatorService
  ) {
    super(formCreatorService);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
  }

  public getFields(): FieldDataObject {
    return this.fields;
  }

  public getName(): string {
    return 'SignupComponent';
  }

  public onClick(type: AuthFormEvent) {
    const data = { email: this.form.get('email').value };
    this.emailEvent.emit({ type, data });
  }
}
